import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal, displayWarningModal } from "../../utils/messageServices/modalService";
import { displayErrorMessage } from "../../utils/messageServices/messageService";

const API_URL = v4_setAPI();



// /new/pdf/acceptance

/**
* 
* @param {ObjectId} userId - required
* @param {ObjectId} projectId - required
* @param {Object} invoiceDetails - required
* @returns Buffer as Downloadable File
* @description - This function is used to create a new invoice for a project. The user_id is required to identify the user who is creating the invoice. The projectId is required to identify the project for which the invoice is created. The invoiceDetails are required to create the invoice.
*/
export async function v4_create_document_acceptance(projectId, user_id, invoiceDetails) {
    if (user_id === undefined) {
        displayWarningModal("Es ist ein Fehler aufgetreten...😱", "Wir haben eine ungültige Anfrage erhalten. Bitte versuche es erneut. Sollte der Fehler weiterhin bestehen, kontaktiere bitte den Support.")
        return;
    };
  
    try {
  
        const data = await axiosInstance.post(`${API_URL}/documents/new/pdf/acceptance/${user_id}/${projectId}`, { configuration: invoiceDetails })
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                console.log(err);
            });
  
        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim Erstellen der Rechnung", error?.response?.data?.error, error?.response?.data?.stack)
        displayErrorMessage("Es ist ein Fehler aufgetreten...😱", `Wir konnten die Aufgabe nicht korrekt an den Server übermitteln oder haben eine unerwartete Antwort erhalten. Bitte versuche es erneut. Fehler: ${error.message}`)
        return [];
    }
  };