import React from "react";
import { Box, Stack } from "@mui/system";
import { Typography, Avatar, IconButton, Tooltip } from "@mui/material";
import parsePhoneNumber from 'libphonenumber-js';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import { Link } from "react-router-dom";

function ContactCard(props) {

    const phoneNumber = parsePhoneNumber(props.Phone);
    const formattedNumber = phoneNumber && phoneNumber.isValid() ? phoneNumber.formatInternational() : props.Phone;

    return (
        <Box>
            <Stack>
                <Stack direction={"row"} sx={{ paddingTop: 2, paddingBottom: 2, }} spacing={2}>
                    <Avatar
                        alt="Projektleiter Bild"
                        src={props.Image}
                        sx={{ width: 56, height: 56 }}
                    />
                    <Stack>
                        <Typography variant="body1">{props.Name}</Typography>
                        <Typography variant="body1">{formattedNumber}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={"row"} alignItems="center">
                    <Typography variant="caption">
                        {props.Position}
                    </Typography>
                    <Tooltip title={props.Mail}>
                    <IconButton LinkComponent={'a'} href={`mailto:${props.Mail}`} >
                        <MailRoundedIcon />
                    </IconButton>
                    </Tooltip>
                </Stack>

            </Stack>
        </Box>
    );
};

export default ContactCard;