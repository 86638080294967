import axios from "axios";
import { setAPI } from "./api";
import { auth } from "../firebase";

const API_URL = setAPI();

export async function getDashboardVolumes() {
    try {
      const token = await auth.currentUser.getIdToken();
      return await axios.post(`${API_URL}/api/kpi/insights/default`, { data: null }, { headers: { Authorization: `Bearer ${token}` } });
      //Es werden die umsätze Berechnet
    } catch (error) {
      console.log(error);
    };
  };

  export async function getKpiFromProject(projectId) {
    try {
      const token = await auth.currentUser.getIdToken();
      return await axios.post(`${API_URL}/api/kpi/insights/fromProject`, { projectId: projectId }, { headers: { Authorization: `Bearer ${token}` } });
      //Es werden alle KPIs aus dem Projekt berechnet
    } catch (error) {
      console.log(error);
      return error;
    };
  };