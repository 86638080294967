import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    autoSession: { },
    autoSelect_0: {  },
    autoSelect_1: { },
    autoAxiosResult: { },
    lastDataEntries: [],
}

const mainSlicer = createSlice({
  name: 'mainSlicer',
  initialState: initialState,
  reducers: {
    slice_upadteAutoSession: (state, action) => {
        state.autoSession = action.payload;
    },
    slice_updateAutoSelect_0: (state, action) => {
        state.autoSelect_0 = action.payload;
    },
    slice_updateAutoSelect_1: (state, action) => {
        state.autoSelect_1 = action.payload;
    },
    slice_updateAutoAxiosResult: (state, action) => {
        state.autoAxiosResult = action.payload;
    },
    slice_updateLastDataEntries: (state, action) => {
        state.lastDataEntries = action.payload;
    },
  },
});

export const {
    slice_upadteAutoSession,
    slice_updateAutoSelect_0,
    slice_updateAutoSelect_1,
    slice_updateAutoAxiosResult,
    slice_updateLastDataEntries,
} = mainSlicer.actions;

export default mainSlicer.reducer;
