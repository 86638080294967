import { PreviewRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { openFileInBrowser } from "../../../utils/firebaseStorage";

function FileViewInBrowserButton(props) {
    const dispatch = useDispatch();

    const handlePreviewFile = () => {
        dispatch(updateFetchingData(true))
        openFileInBrowser(props.StorageRef )
            .then(() => {
                console.log('Datei erfolgreich im Browser geöffnet.');
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.log(error)
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                    default:
                        break;
                }
            })
            .finally(() => {
                dispatch(updateFetchingData(false));
            });
    };

    return (
        <Tooltip title="Datei im Browser ansehen">
        <IconButton onClick={handlePreviewFile}>
            <PreviewRounded />
        </IconButton>
        </Tooltip>
    );
};

export default FileViewInBrowserButton;