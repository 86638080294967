import { Card, Statistic } from "antd";
import CountUp from "react-countup";
import React from "react";

function StatisticsCard(props) {

    const formatter = (value) => <CountUp end={value} separator="." duration={3.5}  />

    return (
        <div>
            <Card bordered={props.Bordered}>
                <Statistic
                    title={props.Title}
                    value={props.Value}
                    precision={props.Precision}
                    prefix={props.Prefix}
                    suffix={props.Suffix}
                    valueStyle={{ color: '#1F271B' }}
                    formatter={formatter}
                />
            </Card>
        </div>
    );
};

export { StatisticsCard };