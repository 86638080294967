import { Button, Modal } from "antd";
import React, { useState } from "react";


export function SingleDetailModal({ children}) {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>
                {children?.title || "Details ansehen"}
            </Button>
            <Modal
                title="Details"
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Schließen
                    </Button>,
                ]}
                >
                {children}
                </Modal>
        </>
    );
};