import { Button, Descriptions, Typography, Tag, Modal, Form, Input, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { crudUser, resetUserPassword } from "../../../routing/userManagement";
import { util_UserUpdate, util_UserUpdateFS } from "../../../utils/updaterUtils/userUpdateUtil";
import { useUpdate } from "ahooks";
const { Paragraph } = Typography;


function UserDetailsForm(props) {

    const [user, setUser] = useState({});
    const [showUserAccountForm, setShowUserAccountForm] = useState(false);
    const handlePasswortReset = async () => {
        await resetUserPassword(user._id);
    };
    const update = useUpdate();

    const formRef = useRef();

    const initForm = () => {
        formRef.current?.setFieldsValue({
            email: props.user.email,
            mobileNumber: props.user.mobileNumber
        });
    };

    useEffect(() => {
        const initSite = () => {
            setUser(props.user);
            initForm();
        };

        initSite();
    }, [props])

   const fetchTeam = () => {
    props.fetchData();
    };

   const handleChange = async (value, fieldName) => {
    await util_UserUpdate(user._id, fieldName, value);
    fetchTeam();
    };

    const deactivateUser = async () => {
        await crudUser("deactivate", user._id)
        fetchTeam();
    };

    const activateUser = async () => {
        await crudUser("activate", user._id)
        fetchTeam();
    };

    const kickUser = async () => {
        await crudUser("kick", user._id)
        fetchTeam();
    };

    const kickLockUser = async () => {
        await crudUser("kick-lock", user._id)
        fetchTeam();
    };

    const handleUserActivation = () => {
        if (user.active) {
            deactivateUser();
        } else {
            activateUser();
        };
    };

    const handleAccountUpdate = async (values) => {
        await util_UserUpdate(user._id, "email", values.email);
        await util_UserUpdate(user._id, "mobileNumber", values.mobileNumber);
        await util_UserUpdateFS(user.userName, values);
        fetchTeam();
        setShowUserAccountForm(false);
    }

    return (
        <>
        <div>
                <Descriptions title="Allgemeine" bordered>
                    <Descriptions.Item label="Name">
                        <Paragraph
                            editable={{
                                onChange: (value) => handleChange(value, "firstName"),
                            }}
                        >
                            {user.firstName}
                        </Paragraph>
                        <Paragraph
                            editable={{
                                onChange: (value) => handleChange(value, "lastName"),
                            }}
                        >
                        {user.lastName}
                        </Paragraph>
                        </Descriptions.Item>
                    <Descriptions.Item label="eMail">{user.email} <Button onClick={() => {setShowUserAccountForm(true)}} type="link">ändern</Button></Descriptions.Item>
                    <Descriptions.Item label="Status"><Tag color={user.active ? "success" : "error"}>{user.active ? "Aktiv" : "Deaktiviert"}</Tag></Descriptions.Item>
                    <Descriptions.Item label="Berechtigungen (System)">{user.projectLead ? "Projektleiter*in" : user.isConsultant ? "Projektbetreuuer*in" : user.isAdmin ? "Administrator" : "Projektmitarbeiter*in"}</Descriptions.Item>
                    <Descriptions.Item label="User Id">{_.has(user, 'userName') ? user.userName : "N/A"}</Descriptions.Item>
                    <Descriptions.Item label="Telefonnummer">{user.mobileNumber}</Descriptions.Item>
                    <Descriptions.Item label="TimeBoard-Id">
                        <Paragraph
                            editable={{
                                onChange: (value) => handleChange(value, "timeBoardId"),
                            }}
                        >{_.has(user, "timeBoardId") ? user.timeBoardId : "TimeBoard-Id nicht angegeben. !"}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="SharePoint-Id">
                        <Paragraph
                            editable={{
                                onChange: (value) => handleChange(value, "sharePointId"),
                            }}
                        >{_.has(user, "sharePointId") ? user.sharePointId : "sharePointId angegeben. !"}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="Arbeitsgruppe">HPM</Descriptions.Item>
                    <Descriptions.Item label="Weitere Aktionen">
                        <div>
                            <Button danger onClick={handleUserActivation} style={{ marginRight: "10px" }}>Nutzer aktivieren/deaktivieren</Button>
                            <Button danger onClick={kickLockUser} style={{ marginRight: "10px" }}>Nutzerkonto sperren</Button>
                            <Button danger onClick={handlePasswortReset} style={{ marginRight: "10px" }}>Passwort zurücksetzen</Button>
                            <Button danger onClick={kickUser} style={{ marginRight: "10px" }}>Nutzertoken für ungültig erklären</Button>
                        </div>
                    </Descriptions.Item>
                    
                </Descriptions>
                
                </div>

                <Modal
                    title="Benutzerkonto bearbeiten"
                    open={showUserAccountForm}
                    onCancel={() => setShowUserAccountForm(false)}
                    footer={null}
                    maskClosable={false}
                    destroyOnClose
                    closable={false}
                    >
                    <div style={{
                        marginBottom: "20px"
                    
                    }}>
                        <Typography.Text>Hier können die Werte des Accounts geändert werden. Es muss immer eine gültige E-Mail hinterlegt sein, ebenso bei aktualiserung eine Telefonnummer angegeben werden.</Typography.Text>
                        <br /><br />
                        <Typography.Text>Bis die änderungen im System angezeigt werden, können bis zu 30 Minuten vergehen.</Typography.Text>
                    </div>
                    
                    <Form
                        layout="vertical"
                        name="userAccountForm"
                        initialValues={{ remember: true }}
                        onFinish={handleAccountUpdate}
                        ref={formRef}
                    >
                    <Form.Item
                        label="E-Mail-Adresse"
                        name="email"
                        rules={[{ required: true, message: 'Bitte geben Sie eine gültige eMail-Adresse an!' }]}
                    >
                        <Input type="email" placeholder="vorname.nachname@hpm.services" />   
                    </Form.Item>
                    <Form.Item
                        label="Telefonnummer"
                        name="mobileNumber"
                        rules={[{ required: true, message: 'Bitte geben Sie eine gültige Telefonnummer an!' }]}
                    >
                        <Input type="tel" placeholder="Bitte in diesem Format: +4915112345678" />
                    </Form.Item>
                    <Space>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Speichern</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" danger onClick={() => setShowUserAccountForm(false)}>Abbrechen</Button>
                    </Form.Item>
                    <Form.Item>
                    <Button onClick={initForm} type="link">Daten ins Formular laden</Button>
                    </Form.Item>
                    </Space>
                    </Form>

                    </Modal>
        </>
    );
};

export { UserDetailsForm };