import { Alert } from "antd";
import React from "react";


export function UnhandledErrorPage(props) {
    const error = props.error;

    const [err, setErr] = React.useState();

    React.useEffect(() => {
        setErr(error);

        return () => {
            setErr();
        };

    }, [error]);

    const Error = () => {
        if (err) {
            throw err;
        };
    };

    return (
        <div className="bounds">
        <p>Sorry! We just encountered an unexpected error.</p>
        <Alert.ErrorBoundary>
            <Error />
        </Alert.ErrorBoundary>
        </div>
    );
};