

//sample project JSON
export const sampleProject = [{
        id: 1,
        title: "Test Projekt 1",
        projectId: "test-projekt-1",
        imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/BMW_logo_%28gray%29.svg/2048px-BMW_logo_%28gray%29.svg.png",
        projectLocation: {
            adress: "String",
            country: "String",
            county: "String",
            latitude: "String",
            longitude: "String",
            cityName: "String",
            zipCode: "String",
            companyName: "String",
        },
        projectLead: {
            fullName: "User 2",
            eMail: "test@hpm.services",
            mobile: "12435346"
        },
        projectConsultant: {
            fullName: "User 3",
            eMail: "test@hpm.services",
            mobile: "12435346"
        },
        administrationNote: "## Das ist ein Testdatenbestand ##",
        companyIdentifier: "de.hpm.assembly.com",
        customFields: [
            { fieldName: "Testfeld-1", fieldType: "number", fieldRequired: false }, { fieldName: "Testfeld-2", fieldType: "string", fieldRequired: false },
        ],
        allowedUserId: [
            "sampleuser", "testuser",
        ],
        dataMode: "time-tracking", //Mögliche werte "standard" oder "time-tracking"
    },

    {
        id: 2,
        title: "Test Projekt 2",
        projectId: "test-projekt-2",
        imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Deloitte.svg/2560px-Deloitte.svg.png",
        projectLocation: {
            adress: "String",
            country: "String",
            county: "String",
            latitude: "String",
            longitude: "String",
            cityName: "String",
            zipCode: "String",
            companyName: "String",
        },
        projectLead: {
            fullName: "User 2",
            eMail: "test@hpm.services",
            mobile: "12435346"
        },
        projectConsultant: {
            fullName: "User 3",
            eMail: "test@hpm.services",
            mobile: "12435346"
        },
        administrationNote: "## Das ist ein Testdatenbestand ##",
        companyIdentifier: "de.hpm.assembly.com",
        customFields: [
            { fieldName: "Testfeld-1", fieldType: "number", fieldRequired: false }, { fieldName: "Testfeld-2", fieldType: "string", fieldRequired: false },
        ],
        allowedUserId: [
            "sampleuser", "testuser",
        ],
        dataMode: "standard", //Mögliche werte "standard" oder "time-tracking"
    },
{
    id: 3,
        title: "Test Projekt 3",
        projectId: "test-projekt-3",
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbIBTZn_lFsgBHmQKyt950iuT7lThLe2BANw&usqp=CAU",
        projectLocation: {
            adress: "String",
            country: "String",
            county: "String",
            latitude: "String",
            longitude: "String",
            cityName: "String",
            zipCode: "String",
            companyName: "String",
        },
        projectLead: {
            fullName: "User 2",
            eMail: "test@hpm.services",
            mobile: "12435346"
        },
        projectConsultant: {
            fullName: "User 3",
            eMail: "test@hpm.services",
            mobile: "12435346"
        },
        administrationNote: "## Das ist ein Testdatenbestand ##",
        companyIdentifier: "de.hpm.assembly.com",
        customFields: [
            { fieldName: "Testfeld-1", fieldType: "number", fieldRequired: false }, { fieldName: "Testfeld-2", fieldType: "string", fieldRequired: false },
        ],
        allowedUserId: [
            "sampleuser", "testuser",
        ],
        dataMode: "standard", //Mögliche werte "standard" oder "time-tracking"
    },

];

//sample user Data JSON
export const userData = {
    firstName: "Test",
    lastName: "User",
    email: "testuser@hpm.services",
    company: "I.K. Hofmann Projektmanagement GmbH",
    companyIdentifier: "de.hpm.assembly.com",
    mobileNumber: "00087/55874",
    userId: "test-user",
    projects: [
        { projectId: "test-projekt", companyIdentifier: "de.hpm.assembly.com", title: "Test Projekt" }
    ],
    userManager: [
        { firstName: "Test2", lastName: "Last10", uid: "test2-last10", companyIdentifier: "de.hpm.assembly.com" }
    ],
    systemPosition: "project-lead",
    isManager: true,
    isAdmin: false,
    _id: "6447bc4d3dbf50b269c0a77b",
};

export const ProjectSchema = [{
    id: 1,
    fieldName: "Auftragsnummer",
    fieldType: "string",
    fieldRequired: true,
    fieldDefaultValue: "",
    fieldValidation:  ""
},
{
    id: 2,
    fieldName: "Beschreibung",
    fieldType: "string",
    fieldRequired: false,
    fieldDefaultValue: "N/A",
    fieldValidation:  "N/A"
},
{
    id: 3,
    fieldName: "Leistung",
    fieldType: "string",
    fieldRequired: true,
    fieldDefaultValue: "",
    fieldValidation:  {
        type: "string",
        enum: ['value1', 'value2', 'value3']
    }
},
{
    id: 4,
    fieldName: "Erstellt von",
    fieldType: "string",
    fieldRequired: true,
    fieldDefaultValue: userData._id,
    fieldValidation:  "N/A"
},
{
    id: 5,
    fieldName: "Erstellt um",
    fieldType: "date",
    fieldRequired: true,
    fieldDefaultValue: "N/A",
    fieldValidation:  "N/A"
},
{
    id: 6,
    fieldName: "Geändert von",
    fieldType: "string",
    fieldRequired: true,
    fieldDefaultValue: userData._id,
    fieldValidation:  "N/A"
},
{
    id: 7,
    fieldName: "Geändert am",
    fieldType: "date",
    fieldRequired: true,
    fieldDefaultValue: "N/A",
    fieldValidation:  ""
},
]