import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal, displayInfoModal } from "../../utils/messageServices/modalService";
import { Divider, Table, Typography } from "antd";

const API_URL = v4_setAPI();


export async function v4_upload_timeboard_data(file) {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await axiosInstance.post(`${API_URL}/timeboard/upload/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        displayInfoModal(response?.data?.message, <div>
            <Divider>Informationen</Divider>
            <Typography.Paragraph>
                Die Fehlermeldungen und Warnungen, die entstanden sind, wurden in LogAnalytics gespeichert.
            </Typography.Paragraph>
            <Divider>Warnungen</Divider>
            <Table
                rowKey={(record) => record.key}
                dataSource={response?.data?.errors || []}
                columns={[
                    { title: "Warnung", dataIndex: "message", key: "message" },
                    {
                        title: "Typ", dataIndex: "type", key: "type", render: (text) => {
                            switch (text) {
                                case "warning": return "Warnung";
                                case "error": return "Fehler";
                                default: return "Unbekannt";
                            }
                        },
                        filters: [
                            { text: 'Warnung', value: 'warning' },
                            { text: 'Fehler', value: 'error' },
                        ],
                        onFilter: (value, record) => record.type.indexOf(value) === 0,
                    },
                    { title: "Schlüssel", dataIndex: "key", key: "key" },
                ]}
                pagination={{
                    locale: { items_per_page: "Einträge pro Seite", page: "Seite" },
                    pageSize: 10
                }}
            />
        </div>, "80%");
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};