import FileResizer from "react-image-file-resizer";


function resizeImage(file, maxWidth = 800, maxHeight = 600) {
  return new Promise((resolve) => {
    FileResizer.imageFileResizer(
      file,
      maxWidth,  // Neue Breite
      maxHeight,  // Neue Höhe
      'JPEG', // Bildformat
      70,    // Qualität in Prozent
      0,     // Drehung in Grad
      (uri) => {
        resolve(uri);
      },
      'blob' // Ausgabeformat: 'blob' oder 'base64'
    );
  });
};

export default resizeImage;