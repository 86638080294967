import _ from "lodash";
import { displayErrorMessage, displayInfoMessage, displayWarningMessage } from "./messageServices/messageService";


export function errorDisplayHelper(errorsAndWarnings) {
    if (_.isEmpty(errorsAndWarnings)) {
        return null;
    } else {
        const errors = [...errorsAndWarnings]
        console.log(errors);
        errors.forEach((error, index) => {
            switch (error.type) {
                case "error":
                    displayErrorMessage(error.level, error.message);
                    break;
                case "warning":
                    displayWarningMessage(error.level, error.message);
                    break;
                default:
                    displayInfoMessage(error.level, error.message);
            }  
        });
    }
}