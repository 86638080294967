import { v4_setAPI } from "../api";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();

export async function v4_test_route_error() {
    try {
        const response = await axiosInstance.get(`${API_URL}/manage/tests/error`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};