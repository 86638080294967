import { Button, Checkbox, Descriptions, Drawer, Form, Modal, Popconfirm, Select, Space, Tag, Transfer, Typography } from 'antd';
import react, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { statusArray, statusColorHelper, statusHelper } from '../../utils/helpers/statusHelper';
import { util_ProjectDetailsUpdate } from '../../utils/updaterUtils/projectUpdateUtil';
import { fetchCustomFields } from '../../data/fetchData';
import { updateCustomFields } from '../../redux/projectSlicer';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { route_updateCustomField } from '../../routing/v3_Routes/v3_Routes';

const { Title, Paragraph } = Typography;

export function EntryMaskDrawer(props) {

    const project = useSelector((state) => state.projectState.selectedProject);
    const customFields = useSelector((state) => state.projectState.customFields);

    const dispatch = useDispatch();

    const [targetKeys, setTargetKeys] = react.useState([]);
    const [showTaskStatusModal, setShowTaskStatusModal] = react.useState(false);
    const [showDuplicateValidationModal, setShowDuplicateValidationModal] = react.useState(false);
    const [selectedKeys, setSelectedKeys] = react.useState([]);
    const [validatedField, setValidatedField] = react.useState(false);
    const [loading, setLoading] = react.useState(false);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        console.log('targetKeys:', nextTargetKeys);
        console.log('direction:', direction);
        console.log('moveKeys:', moveKeys);
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        console.log('sourceSelectedKeys:', sourceSelectedKeys);
        console.log('targetSelectedKeys:', targetSelectedKeys);
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const handleUpperPropertyChange = async (value, fieldName) => {
        setLoading(true);
        await util_ProjectDetailsUpdate(project._id, fieldName, value)
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            });
    };

    useEffect(() => {
        setTargetKeys(project?.defaultTaskStatusSelections?.map((st, i) => ({ key: st, title: statusHelper(st), disabled: false })));
    }, [project]);

    useEffect(() => {
        setSelectedKeys(project?.defaultTaskStatusSelections?.map((st, i) => ({ key: st, title: statusHelper(st), disabled: false })));
    }, [project]);

    const filterOption = (inputValue, option) => option?.title?.toLowerCase() === inputValue?.toLowerCase();

    useEffect(() => {
        if (project === undefined || project === null) {
            return;
        } else {
            if (project?._id !== undefined) {
                fetchCustomFields(project?._id ?? "")
                .then((result) => {
                    dispatch(updateCustomFields(result.data));
                }).catch((err) => {
                    console.log(err);
                });
            };
        }
    }, []);

    return (
        <Drawer
            title="Einstellungen der Eingabemaske"
            placement="right"
            closable={true}
            onClose={props.onClose}
            open={props.open}
            maskClosable={true}
            width={"100%"}
        >
            <div>
                <div>
                    <Title level={4}>Projekt: {project?.title ?? ""}</Title>
                </div>

                <Descriptions title="Einstellungen der Eingabemaske" layout="vertical" bordered column={1}>
                    <Descriptions.Item label="Verfügbare Statusoptionen in der Eingabemaske">
                        {`Bezeichnungen: `}
                        {project?.defaultTaskStatusSelections?.map((st, i) => (
                            <Tag key={i} color={statusColorHelper(st)}>{statusHelper(st)}</Tag>
                        ))}
                        <Button type="link" disabled onClick={() => { setShowTaskStatusModal(true) }}>ändern</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Ersteller kann manuell angepasst werden">
                        <>
                            Anpassbar: <Tag color={project?.creatorCanChangeUser ?? false ? "green" : "red"}> {project?.creatorCanChangeUser ?? false ? "Ja" : "Nein"}</Tag>
                            <Button disabled type="link" onClick={props.onClose}>ändern</Button>
                        </>
                    </Descriptions.Item>
                    <Descriptions.Item label="Eindeutige Überprüfungsspalte">
                        <Paragraph>Legen Sie eine eindeutige "Überprüfungsspalte" fest um z.B. Doppelte Werte zu verhindern.</Paragraph><br />
                        <Tag color={project?.usesUniqueDulicateValidationField ?? false ? "green" : "red"}> {project?.usesUniqueDulicateValidationField ?? false ? "Ja, Duplikatsvalidierung ist vorhanden." : "Nein, Duplikatsvalidierung ist nicht vorhanden."}</Tag>
                        {!project?.usesUniqueDulicateValidationField ? <>
                            <Popconfirm
                                title="Möchten Sie die Duplikatsvalidierung aktivieren? Diese Aktion kann nicht rückgängig gemacht werden."
                                onConfirm={() => { handleUpperPropertyChange(true, "usesUniqueDulicateValidationField") }}
                                onCancel={() => { console.log("cancel") }}
                                okText="Ja"
                                cancelText="Nein"
                                okType='danger'
                            >
                                <Button type="link">aktivieren</Button>
                            </Popconfirm>
                        </> : <>
                            <Button type="link" onClick={() => { setShowDuplicateValidationModal(true) }}>Einstellungen bearbeiten</Button>
                        </>}
                    </Descriptions.Item>
                </Descriptions>
            </div>

            {/* 
                label={field.fieldName}
                                            name={`${field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_')}`}
                                             */}

            <Modal
                open={showDuplicateValidationModal}
                closable={true}
                maskClosable={false}
                onCancel={() => setShowDuplicateValidationModal(false)}
                title="Einstellungen der Duplikatsvalidierung"
                footer={null}
                width={"40%"}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <Form
                        layout="vertical"
                        name="duplicateValidationForm"
                        onFinish={async (values) => {
                            const updateData = {
                                ..._.find(customFields, { fieldNameId: values.field })
                            };
                            console.log(updateData);
                            _.set(updateData, "uniqueDuplicateValidation", validatedField);
                            handleUpperPropertyChange(updateData?._id || "error", "uniqueDuplicateValidationField");
                            //Update Custom field with prop "unique"
                            
                            await route_updateCustomField({
                                updateValue: validatedField,
                                updateField: "uniqueDuplicateValidation",
                            }, updateData?._id || "error", project?._id || "error");
                            setShowDuplicateValidationModal(false);
                        }}
                    >
                        <Form.Item label="Eindeutige Überprüfungsspalte">
                            <Paragraph>Wählen Sie die Spalte aus, die als eindeutige Überprüfungsspalte verwendet werden soll.</Paragraph>
                            <Paragraph>Beispiel: "Auftragsnummer"</Paragraph>
                        </Form.Item>
                        <Form.Item label="Feld" name={"field"}
                        initialValue={project?.uniqueDuplicateValidationField?._id ?? ""}
                        >
                            <Select
                            options={customFields.map((field, i) => ({ label: field?.fieldName , value: field?.fieldNameId.replace(/ /g, '_') }))}
                            />
                        </Form.Item>
                        <Form.Item
                        name="uniqueDuplicateValidation"
                        >
                            <Checkbox onChange={
                                (e) => {
                                    setValidatedField(e.target.checked);
                                }
                            }>Validierung aktivieren</Checkbox>
                        </Form.Item>
                        <Space>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>Speichern</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="default" onClick={() => setShowDuplicateValidationModal(false)}>Abbrechen</Button>
                        </Form.Item>
                        </Space>
                    </Form>
                </div>
            </Modal>

            <Modal
                open={showTaskStatusModal}
                closable={true}
                maskClosable={false}
                onCancel={() => setShowTaskStatusModal(false)}
                title="Statusoptionen in der Eingabemaske"
                footer={null}
                width={"40%"}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <Transfer
                        dataSource={statusArray.map((st, i) => ({ key: st, title: statusHelper(st), disabled: false }))}
                        targetKeys={targetKeys}
                        onChange={onChange}
                        onSelectChange={onSelectChange}
                        titles={['Verfügbare Statusoptionen', 'Statusoptionen in der Eingabemaske']}
                        render={item => item.title}
                        listStyle={{
                            width: 300,
                            height: 300,
                        }}
                        disabled={true}
                    />
                </div>
            </Modal>
        </Drawer>

    );
};