import { Badge, Descriptions, Space, Tag, Typography } from "antd";
import React from "react";

const { Title, Text } = Typography;


function StatusPage() {

    return (
        <>
            <div>
                <Title level={2}>Allgemeiner Systemstatus</Title>
            </div>
            {/* <img src="https://github.com/hofmann-projektmanagement/assembly-backend/actions/workflows/main_assembly-backend.yml/badge.svg" alt="CI/CD" /> */}
            {/* <script src="https://assembly2.statuspage.io/embed/script.js"></script> */}
            {/* Display GitHub Status badge */}

            <div>
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="ATLAS CLUSTER">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="BIG QUERY">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="FST.">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="F. AUTH">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="Az. NODE 1">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="Az. NODE 2">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="TWILLO SG">
                        <div>
                            <Space>
                                <Badge status="warning" size="large" />
                                <Text>Maximum tokens reached. OK</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="VERCEL STAGE M">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="VERCEL DEV STAGING 1">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>

                    <Descriptions.Item label="VERCEL DEV STAGING 2">
                        <div>
                            <Space>
                                <Badge status="processing" color="blue" size="large" />
                                <Text>Running - all systems normal</Text>
                            </Space>
                        </div>
                    </Descriptions.Item>
                    
                </Descriptions>
            </div>
        </>
    );
};


export { StatusPage };