import { combineReducers, configureStore } from '@reduxjs/toolkit';
import projectReducer from './projectSlicer';
import userReducer from './userSlices';
import streamReducer from './streamSlicer';
import customerReducer from './customerSlicer';
import mainReducer from './mainSlicer';

const appReducer = combineReducers({
  userState: userReducer,
  projectState: projectReducer,
  fetchingState: streamReducer,
  customerState: customerReducer,
  mainState: mainReducer,
});

const RESET_STORE = 'RESET_STORE';

export const resetStore = () => ({
  type: RESET_STORE,
});

export const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }

  // Andere Reducer-Logik hier ...

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
