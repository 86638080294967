import { Button, Input, Modal, Space, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { EmailAuthProvider, PhoneAuthProvider, RecaptchaVerifier, multiFactor, reauthenticateWithCredential } from "firebase/auth";
import { displayErrorNotification } from "../../utils/messageServices/notificationService";
import { motion } from "framer-motion";
import _ from "lodash";
import { LoginOutlined } from "@ant-design/icons";

const maxErrorCount = 2;
const maxSecondsCount = 10;
const { Text } = Typography;

/**
 * 
 * @param {Object} props - title, text, OnSuccess
 * @returns 
 */
export function MultiFactorModal(props) {

    const [errorCount, setErrorCount] = React.useState(0);
    const [secondsCount, setSecondsCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = useState(auth?.currentUser?.email || '');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false);

    function setMultifactor(phoneNumber) {
        const appVer = new RecaptchaVerifier(auth, 'recaptcha-container', undefined);
        multiFactor(auth.currentUser).getSession().then((session) => {
          // Send verification code
          const phoneInfoOptions = {
            phoneNumber: '+11234567890',
            session: session
          };
          const phoneAuthProvider = new PhoneAuthProvider();
          const phoneInfo = phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            appVer
          );
        });
        appVer.clear();
      }

    // const reAuthenticateUser = async () => {
    //     setLoading(true);
    //     try {
    //         const user = auth.currentUser;
    //         const credential = EmailAuthProvider.credential(email, password);

    //         await reauthenticateWithCredential(user, credential);
    //         props.OnSuccess();
    //         setOpen(false);
    //         Modal.destroyAll();
    //     } catch (error) {
    //         console.error("Re-Authentifizierung fehlgeschlagen:", error);
    //         // Hier kannst du zusätzliche Fehlerbehandlung hinzufügen
    //         displayErrorNotification("Re-Authentifizierung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.");
    //         setErrorCount(errorCount + 1);
    //         if (errorCount >= 1) {
    //             setOpen(false);
    //             Modal.destroyAll();
    //             auth.signOut();
    //         }
    //     }
    //     setLoading(false);
    // };

    // useEffect(() => {
    //     let timer;
    //     if (open) {
    //         timer = setTimeout(() => {
    //             setOpen(false);
    //             displayErrorNotification("Zeitüberschreitung. Der Vorgang wurde abgebrochen.");
    //         }, maxSecondsCount * 1000);
    //     }
    //     return () => clearTimeout(timer);
    // }, [open]);

    const onChange = (text) => {
        console.log('onChange:', text);
      };
      const sharedProps = {
        onChange,
      };

    return (
        <>
            <Modal
                title={"2-Faktor-Authentifizierung"}
                open={open}
                centered
                onCancel={() => setOpen(false)}
                footer={[
                    // <Tooltip title="Ist eine Authentifizierung nicht möglich, wird der Vorgang abgebrochen. Und der Nutzer wird ausgeloggt.">
                    //     <Button key="submit" type="primary" 
                    //     // onClick={async () => { await reAuthenticateUser() }}
                    //         loading={loading}
                    //         style={{
                    //             backgroundColor: 'rgba(234, 40, 45, 1)'
                    //         }}
                    //         icon={<LoginOutlined />}
                    //     >
                    //         Authentifizieren
                    //     </Button>
                    // </Tooltip>,
                ]}
                maskClosable={false}
                mask={true}
                closable={false}
                styles={{
                    mask: {
                        backdropFilter: 'blur(10px)', // Hintergrund weichzeichnen
                        WebkitBackdropFilter: 'blur(10px)', // Unterstützung für Safari
                        backgroundColor: 'rgba(234, 40, 45, 0.8)'
                    }
                }}
            >
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.5,
                        duration: 0.2,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                >
                <Text>Wir haben eine SMS mit einem Code versendet. Bitte geben Sie diesen hier ein.</Text>
                <div style={{
                    padding: 15,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}>
                    <Input.OTP mask="🔒" {...sharedProps} size="large" />
                </div>
                </motion.div>
            </Modal>
        </>
    )
};