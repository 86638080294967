import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IconButton } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { StyledContainer } from "../../../global/StyledContainers";
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { convertColumsForProjects } from "../../../../utils/cleanArray";
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Button, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import {  EllipsisOutlined } from '@ant-design/icons'
import { EntryMaskDrawer } from "../../../drawers/EntryMaskDrawer";
import Search from "antd/es/input/Search";
import _ from "lodash";
import { motion } from "framer-motion";

const { Title } = Typography;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'title', headerName: 'Projektname', width: 250, editable: false },
    { field: 'projectId', headerName: 'Projekt ID', width: 250, editable: false },
    { field: 'companyIdentifier', headerName: 'Unternehmenskennung', width: 200, editable: false },
    { field: 'dataMode', headerName: 'Datenmodus', width: 120, editable: false },
    { field: 'modelName', headerName: 'Modellname', width: 120, editable: false },
    { field: 'location', headerName: 'Standort', width: 400, editable: false },
    { field: 'imageUrl', headerName: 'Bildadresse', width: 200, editable: false },
];

function ManageProjectsBoard() {
    const projects = useSelector((state) => state.projectState.projects);
    const [open, setOpen] = useState(false);
    const [showEntryMaskSettings, setShowEntryMaskSettings] = useState(false);
    const [filteredProjects, setFilteredProjects] = useState(projects);


    const navigate = useNavigate();

    const handleSearch = (value) => {
        console.log(value);
        setFilteredProjects(_.filter(projects, (project) => {
            const { title, projectId, _id } = project;
            const searchValueLower = value.toLowerCase();
            return (
                title?.toLowerCase().includes(searchValueLower) ||
                (_id?.toLowerCase().includes(searchValueLower)) ||
                (projectId?.toLowerCase().includes(searchValueLower))
            );
        }));
    };

    const handleClearSearch = () => {
        setFilteredProjects(projects);
    };

    useEffect(() => {
        setFilteredProjects(projects);
    }, []);

    return (
        <>
            {/* <HeaderWithBackButton backLink="/manage" /> */}
            <StyledContainer>

                <Box>
                    <Stack direction="row" alignItems="center">
                        <img
                            src="../assets/assembly-logo.png"
                            alt="Project_Logo_Image"
                            width="64px"
                            style={{ resize: 'both' }}
                            className="ButtonIcons"
                        />
                        <Stack sx={{ flexGrow: 1 }}>
                            <Title level={4} sx={{ paddingLeft: 2 }}>
                                Board - Übersicht
                            </Title>
                            <Title level={5} sx={{ paddingLeft: 2 }}>
                                Übersicht aller verfügaberen Projekte zum konfigurieren.
                            </Title>
                        </Stack>
                        <Stack sx={{ justifyContent: 'flex-end' }}>
                            <Box sx={{
                                padding: 1
                            }}>
                                <Stack direction={"row"}>
                                    {/** Schnellaktionen */}
                                    <Tooltip title="Neues Projekt erstellen">
                                        <IconButton onClick={() => { navigate('/manage/projects/create') }}>
                                            <NoteAddRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Schnellansicht Projektstammdaten">
                                        <IconButton onClick={() => { setOpen(true) }}>
                                            <ContentPasteSearchRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>

                <div>
                    <Search placeholder="Suche nach Projekten" size="large" onChange={(e) => handleSearch(e.target.value)} onSearch={(value) => handleSearch(value)} onReset={handleClearSearch} />
                </div>

                <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
                    {
                        filteredProjects.map((project, index) => {
                            const delay = index * 0.1;

                            return (

                                <Col span={12}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{
                                            delay: delay,
                                            duration: 0.5,
                                            type: 'spring',
                                            stiffness: 100,
                                            damping: 10,
                                        }}
                                    >
                                        <Card style={{
                                            width: '100%',
                                        }}
                                            key={index}
                                            actions={[
                                                <Button icon={<EllipsisOutlined />} onClick={() => { navigate(`/manage/projects/project/${project._id}/${project.costCentre}`) }} type="text">
                                                    Projektmanagement
                                                </Button>
                                            ]}
                                        >
                                            <Card.Meta
                                                description={`${project.title}`}
                                            />
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <img
                                                    src={project.imageUrl}
                                                    alt="Project_Logo_Image"
                                                    width="64px"
                                                    style={{ resize: 'both' }}
                                                    className="ButtonIcons"
                                                />
                                                <Space>
                                                    <Title level={4}>
                                                        {_.truncate(project.title, {
                                                            length: 30
                                                        })} - Kst. {project?.costCentre || "NV!"}
                                                    </Title>
                                                </Space>

                                            </Stack>
                                        </Card>
                                    </motion.div>
                                </Col>

                            )
                        })
                    }
                </Row>
                <EntryMaskDrawer open={showEntryMaskSettings} onClose={() => { setShowEntryMaskSettings(false) }} />
                <div>
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={() => { setOpen(false) }}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => { setOpen(false) }}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Tabelarische Übersicht (Stammdaten)
                                </Typography>
                                {/* <Button autoFocus color="inherit" onClick={() => { setOpen(false)}}>
              save
            </Button> */}
                            </Toolbar>
                        </AppBar>
                        <Box sx={{
                            height: 400, width: '100%', p: 2
                        }}>
                            <DataGrid rows={convertColumsForProjects(projects)} columns={columns} />
                        </Box>
                    </Dialog>
                </div>
                {/* Footer only for this site, down here */}
            </StyledContainer>
            {/* Zweiter Dialog */}
        </>
    );
};

export default ManageProjectsBoard;