import { Button, DatePicker, Modal, Space, Tooltip, Typography } from "antd";
import React, { useRef, useState } from "react";
import { LoginOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import jsonLocale from "../../../locale/datePickerLocale.json";

const { Text } = Typography;

export function FilterDateTimeModal(props) {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [kpis, setKpis] = React.useState(null);
    const localNow = DateTime.now().toJSDate();
    const datePickerRef = useRef();

    const [filter, setFilter] = useState({
        gte: localNow,
        lte: localNow,
    });

    async function handleDateChange(dates) {
        if (dates && dates.length === 2) {
            const [startDate, endDate] = dates;
            const luxonStartDate = DateTime.fromJSDate(startDate.toDate());
            const luxonEndDate = DateTime.fromJSDate(endDate.toDate());

            // Jetzt haben Sie luxonStartDate und luxonEndDate, die Sie mit Luxon verwenden können.
            console.debug('Start Date (Luxon):', luxonStartDate.toString());
            console.debug('End Date (Luxon):', luxonEndDate.toString());

            setFilter({
                gte: startDate,
                lte: endDate,
            });

            console.debug(filter);
        };
    };

    return (
        <div>
            
            <Button
                type="primary"
                onClick={() => setOpen(true)}

            >
                {props?.text || 'Filter modifizieren'}
            </Button>
            
            <Modal
                title={props?.title || "Filter anpassen"}
                open={open}
                centered
                onCancel={() => setOpen(false)}
                footer={[
                    <Tooltip title="Filter übernehmen und Abfrage ausführen.">
                        <Button key="submit" type="primary" onClick={async () => {
                            setLoading(true);
                            await props.OnFilter(filter);
                            setLoading(false);
                            setOpen(false);
                        }}
                            loading={loading}
                            style={{
                                backgroundColor: 'rgba(234, 40, 45, 1)'
                            }}
                            icon={<LoginOutlined />}
                        >
                            Filtern
                        </Button>
                    </Tooltip>,
                    <Button key="back" onClick={() => setOpen(false)}>
                        Abbrechen
                    </Button>,
                ]}
                maskClosable={false}
                mask={true}
                closable={false}
                styles={{
                    mask: {
                        backdropFilter: 'blur(10px)', // Hintergrund weichzeichnen
                        WebkitBackdropFilter: 'blur(10px)', // Unterstützung für Safari
                        backgroundColor: 'rgba(234, 40, 45, 0.2)'
                    }
                }}

            >
                <div style={{
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>

                <Space direction="vertical" size={12}>
                <Text>Wählen Sie den Zeitraum aus, um einen Filter für die Abfrage zu übergeben.</Text>
                    <DatePicker.RangePicker
                        format={"DD.MM.YYYY"}
                        ref={datePickerRef}
                        locale={jsonLocale}
                        onChange={(value) => {
                            handleDateChange(value)
                        }}
                    />
                </Space>
                </div>
            </Modal>
        </div>
    )
}