import React, { useState } from 'react';
import { Tabs, Avatar, Alert, Typography } from 'antd';
import { Container } from '@mui/system';
import Logo from '../../assets/Logo.png';
import { LoginForm } from '../forms/LoginForm';
import { DateTime } from 'luxon';


function NewLoginPage() {

  const [visible, setVisible] = useState(false);

  const items = [
    {
      key: '1',
      label: 'Anmelden',
      children: <LoginForm />,
    }
  ];

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container sx={{ marginTop: 0 }}>
        <div>
          <Avatar
            size={128}
            src={Logo}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </div>
        {DateTime.now().toISODate() <= "2024-07-08" && (<Alert
          message="Ankündigung von Wartungsarbeiten am System"
          description="Das System wird zwischen dem 05.07.2024 und 08.07.2024 gewartet. In dieser Zeit ist kein Login möglich."
          type="warning"
          showIcon
        />)}

        {(DateTime.now().toISODate() >= "2024-07-05" & DateTime.now().toISODate() <= "2024-07-08T11:00:00") ? (
          <Alert
            message="Der Login wurde deaktiviert"
            description={<>
              <Typography.Text onClick={() => setVisible(!visible)}>Der Login wurde deaktiviert, bitte wenden Sie sich an den Support.</Typography.Text>
            </>}
            type="error"
            showIcon
          />
        ) : (
          <>
          <Tabs defaultActiveKey="1" size="large" centered items={items} />
          </>
        )}

        {visible && (
          <>
          <Tabs defaultActiveKey="1" size="large" centered items={items} />
          </>
        )}
      </Container>
    </div>
  );
};



export default NewLoginPage;