import { Button, Modal, Result } from "antd";
import React from "react";


export function ResultModal(props) {

    return (
        <div>
            <Modal
                title={props.title}
                open={props.open}
                footer={<Button type="primary" onClick={props.onClose}> Schließen </Button>}
                width={"60%"}
                maskClosable={false}
                closable={false}
                onCancel={props.onClose}
            >
                <Result
                    status={props.status}
                    title={props.title}
                    subTitle={props.subTitle}
                    extra={props.extra}
                />
            </Modal>
        </div>
    );
}