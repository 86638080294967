import React from 'react';
import { Breadcrumb, Button, Layout, Menu, Space, Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { hasConsentChecker, saveConsent } from '../cookies/consentManager';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

function PublicLayout({ children }) {

  const navigateAway = () => {
    window.location.href = 'https://www.hpm.services/';
    };

    const [showCookieModal, setShowCookieModal] = React.useState(false);

    React.useEffect(() => {
        setShowCookieModal(!hasConsentChecker());
    }, []);


    return (
        <Layout
            style={{
                background: '#fff',
            }}
        >
        <Header
            style={{
                position: 'fixed',
                zIndex: 1,
                width: '100%',
                backgroundColor: '#DA3E52',
            }}
        >
        <Typography.Title level={3} style={{ color: 'white', textAlign: 'center', marginTop: 15 }}>
            <a href="/" style={{ color: 'white' }}>Assembly</a>
        </Typography.Title>
        </Header>

            <Content style={{
                minHeight: '100vh',
            }}>
                <div className="site-layout-content">{children}</div>
            </Content>
            <Footer style={{
                position: 'fixed',
                bottom: 0,
                zIndex: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}>
            <Space direction="vertical" size={0}>
            © 2023 - 2024 | I.K. Hofmann Projektmanagement GmbH
            <a href="https://www.hpm.services/impressum" target="https://www.hpm.services/impressum" rel="noreferrer" >Impressum</a>
            <a href="https://www.hpm.services/datenschutz" target="https://www.hpm.services/datenschutz" rel="noreferrer" >Datenschutz</a>
            <a href="https://www.hpm.services/datenschutz" target="https://www.hpm.services/datenschutz" rel="noreferrer" >Cookie Nutzung</a>
            </Space>
            </Footer>
            <Modal
                title="Hinweise zur Nutzung von Cookies"
                open={showCookieModal}
                onOk={() => { }}
                closable={false}
                footer={[
                    <div>
                        <Space>
                            <Button onClick={() => { setShowCookieModal(false); saveConsent(true) }} type="primary">Akzeptieren</Button>
                            <Button onClick={navigateAway} type="dashed">Ablehnen</Button>
                        </Space>
                    </div>
                ]}
            >
                <p>Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Dazu zählen Cookies, die für den Betrieb der Seite notwendig sind, sowie solche, die lediglich zu anonymen Statistikzwecken genutzt werden. <strong>Sie können die Seite ohne Einwilligung nicht nutzen. </strong> 
                Bitte beachten Sie, dass auf Basis Ihrer Einstellungen womöglich nicht mehr alle Funktionalitäten der Seite zur Verfügung stehen. Weitere Informationen finden Sie in unseren Datenschutzhinweisen.</p>  

            </Modal>
        </Layout>
    );
};

export default PublicLayout;