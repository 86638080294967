// theme.js

import { theme } from "antd";

// themes.js
const lightTheme = {
  token: {
      "colorPrimary": "#fa541c",
      "colorError": "#ff393d",
      "colorInfo": "#1782ff",
//       // "colorBgBase": '#141414',
//       // "cologrBgContainer": '#141414'
  },
};

const darkTheme = {
  algorithm: [theme.darkAlgorithm],
  token: {
      "colorPrimary": "#fa541c",
      "colorError": "#ff393d",
      "colorInfo": "#1782ff",
  },
};

export { lightTheme, darkTheme };

// module.exports = {
//     "token": {
//       "colorPrimary": "#fa541c",
//       "colorError": "#ff393d",
//       "colorInfo": "#1782ff",
//       // "colorBgBase": '#141414',
//       // "cologrBgContainer": '#141414'
//     }
//   }
