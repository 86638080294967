import {
    UserOutlined,
    ProjectOutlined,
    UsergroupAddOutlined,
    ContainerOutlined,
    FolderViewOutlined,
    FileSyncOutlined,
    SolutionOutlined,
    StockOutlined,
    MergeOutlined,
    TableOutlined,
} from '@ant-design/icons';
import { Add, AdminPanelSettingsRounded, DashboardOutlined, EuroRounded, SettingsRounded, Sync, TimelapseRounded } from '@mui/icons-material';
import store from '../../redux/store';
import { updateSelectedproject } from '../../redux/projectSlicer';

export function returnProjectsAsSidebarChildren(projects) {
    const elements = [];
    projects.forEach((project) => {
        elements.push({
            label: project.title,
            target: `v2/projects/project/${project._id}`,
            key: `v2/projects/project/${project._id}`,
            icon: <FolderViewOutlined />,
            onClick: (e) => {
                console.log(project);
                store.dispatch(updateSelectedproject(project));
            }
        });
    });
    return elements;
};

export const adminSidebarItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
        {
        label: 'My Business',
        target: '/company/insights/',
        key: 'company/insights/',
        icon: <StockOutlined />,
    },
    {
        label: "Systemstatus",
        target: "/assembly/status",
        key: "assembly/status",
        icon: <Sync />
    },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [
            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: 'Abrechnungen',
        target: `invoices`,
        key: `invoices`,
        icon: <FileSyncOutlined />,
    },
    {
        label: 'TimeBoard Zeiten',
        target: `users/timeboard`,
        key: `users/timeboard`,
        icon: <TimelapseRounded />,
    },
    {
        label: "User Management",
        icon: <UserOutlined />,
        key: "userManagement",
        children: [
            {
                label: 'User Management',
                target: `/usermanagement`,
                key: `usermanagement`,
                icon: <UsergroupAddOutlined />,
            },
        ]
    },
    {
        label: 'Projektmanagement',
        icon: <ProjectOutlined />,
        key: 'projectmanagement',
        children: [
            {
                label: 'Projekt Einstellungen',
                target: `/manage/projects`,
                key: `manage/projects`,
                icon: <SettingsRounded />,
            },
            {
                label: 'Neues Projekt',
                target: `/manage/projects/create`,
                key: `manage/projects/create`,
                icon: <Add />,
            },
            {
                label: 'Projektverwaltung (Tabelle)',
                target: `/tableview/manage/projects`,
                key: `tableview/manage/projects`,
                icon: <TableOutlined />,
            },
        ]
    },
    {
        label: 'Kostenmanagement',
        icon: <EuroRounded />,
        key: 'costmanagement',
        children: [
            {
                label: 'Kostenübersicht',
                target: `/accounting`,
                key: `accounting`,
                icon: <EuroRounded />,
            },
            {
                label: 'Tabelle Personalkosten',
                target: `/tables/personalcosts`,
                key: `tables/personalcosts`,
                icon: <TableOutlined />,
            },
            {
                label: 'Tabelle Projektkosten',
                target: `/tables/projectcosts`,
                key: `tables/projectcosts`,
                icon: <TableOutlined />,
            },
        ]
    },
    {
        label: 'Administrator',
        icon: <AdminPanelSettingsRounded />,
        children: [
            {
                label: 'Einstellungen',
                target: `/admin/manage`,
                key: `admin/manage`,
                icon: <AdminPanelSettingsRounded />
            },
        ]
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },

];

export const managementSidebarItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
    {
        label: 'My Business',
        target: '/company/insights/',
        key: 'company/insights/',
        icon: <StockOutlined />,
    },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: 'Abrechnungen',
        target: `invoices`,
        key: `invoices`,
        icon: <FileSyncOutlined />,
    },
    {
        label: "User Management",
        icon: <UserOutlined />,
        key: "userManagement",
        children: [
            {
                label: 'User Management',
                target: `/usermanagement`,
                key: `usermanagement`,
                icon: <UsergroupAddOutlined />,
            },
        ]
    },
    {
        label: 'Projektmanagement',
        icon: <ProjectOutlined />,
        key: 'projectmanagement',
        children: [
            {
                label: 'Projekt Einstellungen',
                target: `/manage/projects`,
                key: `manage/projects`,
                icon: <SettingsRounded />,
            },
            {
                label: 'Projektverwaltung (Tabelle)',
                target: `/tableview/manage/projects`,
                key: `tableview/manage/projects`,
                icon: <TableOutlined />,
            },
            {
                label: 'Neues Projekt',
                target: `/manage/projects/create`,
                key: `manage/projects/create`,
                icon: <Add />,
            },
        ]
    },
    {
        label: 'Kostenmanagement',
        icon: <EuroRounded />,
        key: 'costmanagement',
        children: [
            {
                label: 'Kostenübersicht',
                target: `/accounting`,
                key: `accounting`,
                icon: <EuroRounded />,
            },
            {
                label: 'Tabelle Personalkosten',
                target: `/tables/personalcosts`,
                key: `tables/personalcosts`,
                icon: <TableOutlined />,
            },
            {
                label: 'Tabelle Projektkosten',
                target: `/tables/projectcosts`,
                key: `tables/projectcosts`,
                icon: <TableOutlined />,
            },
        ]
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },
];


export const pmaSidebarItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: 'Mein Konto',
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            }
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },

];


export const plSidebarItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: 'Projektmanagement',
        icon: <ProjectOutlined />,
        children: [
            {
                label: 'Projekt Einstellungen',
                target: `/manage/projects`,
                key: `manage/projects`,
                icon: <SettingsRounded />,
            },
            {
                label: 'Neues Projekt',
                target: `/manage/projects/create`,
                key: `manage/projects/create`,
                icon: <Add />,
            },
            {
                label: 'Abrechnungen',
                target: `invoices`,
                key: `invoices`,
                icon: <FileSyncOutlined />,
            },
        ]
    },
    {
        label: 'Kostenmanagement',
        icon: <EuroRounded />,
        key: 'costmanagement',
        children: [
            {
                label: 'Kostenübersicht',
                target: `/accounting`,
                key: `accounting`,
                icon: <EuroRounded />,
            },
        ]
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },
];

export const pbSideBarItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
    // {
    //     label: 'Support',
    //     target: '/support',
    //     key: 'support',
    //     icon: <SupportAgentOutlined />,
    // },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: 'Projektmanagement',
        icon: <ProjectOutlined />,
        children: [
            {
                label: 'Projekt Einstellungen',
                target: `/manage/projects`,
                key: `manage/projects`,
                icon: <SettingsRounded />,
            },
            // {
            //     label: 'Team Management',
            //     target: `/users/manage/team`,
            //     key: `users/manage/team`,
            //     icon: <UsergroupAddOutlined />,
            // },
            {
                label: 'Neues Projekt',
                target: `/manage/projects/create`,
                key: `manage/projects/create`,
                icon: <Add />,
            },
            // {
            //     label: 'Abrechnungen',
            //     target: `invoices`,
            //     key: `invoices`,
            //     icon: <FileSyncOutlined />,
            // },
        ]
    },
    {
        label: 'Kostenmanagement',
        icon: <EuroRounded />,
        key: 'costmanagement',
        children: [
            {
                label: 'Kostenübersicht',
                target: `/accounting`,
                key: `accounting`,
                icon: <EuroRounded />,
            },
        ]
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },
];

export const hrSidebarIstems = (uid, children) => [
    {
        label: 'Home',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: "User Management",
        icon: <UserOutlined />,
        key: "userManagement",
        children: [
            {
                label: 'User Management',
                target: `/usermanagement`,
                key: `usermanagement`,
                icon: <UsergroupAddOutlined />,
            },
        ]
    },
    {
        label: 'Projektmanagement',
        icon: <ProjectOutlined />,
        key: 'projectmanagement',
        children: [
            {
                label: 'Projekt Einstellungen',
                target: `/manage/projects`,
                key: `manage/projects`,
                icon: <SettingsRounded />,
            },
            {
                label: 'TimeBoard Zeiten',
                target: `users/timeboard`,
                key: `users/timeboard`,
                icon: <TimelapseRounded />,
            },
        ]
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },
];

export const allProjectsSidebarItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
    // {
    //     label: 'Support',
    //     target: '/support',
    //     key: 'support',
    //     icon: <SupportAgentOutlined />,
    // },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: 'Abrechnungen',
        target: `invoices`,
        key: `invoices`,
        icon: <FileSyncOutlined />,
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },
];

export const accountingSidebarItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
        {
        label: 'My Business',
        target: '/company/insights/',
        key: 'company/insights/',
        icon: <StockOutlined />,
    },
    {
        label: 'Abrechnungen',
        target: `invoices`,
        key: `invoices`,
        icon: <FileSyncOutlined />,
    },
    {
        label: 'TimeBoard Zeiten',
        target: `users/timeboard`,
        key: `users/timeboard`,
        icon: <TimelapseRounded />,
    },
    // { 
    //     label: 'Reklamationen',
    //     key: 'reklamationen',
    //     icon: <ExceptionOutlined />,
    //     children: [
    //         {
    //             label: 'Übersicht',
    //             target: `tickets`,
    //             key: `tickets`,
    //             icon: <AuditOutlined />,
    //         },
    //     ]
    // },
    // {
    //     label: "User Management",
    //     icon: <UserOutlined />,
    //     key: "userManagement",
    //     children: [
    //         {
    //             label: 'User Management',
    //             target: `/usermanagement`,
    //             key: `usermanagement`,
    //             icon: <UsergroupAddOutlined />,
    //         },
    //     ]
    // },
    {
        label: 'Projektmanagement',
        icon: <ProjectOutlined />,
        key: 'projectmanagement',
        children: [
            {
                label: 'Projekt Einstellungen',
                target: `/manage/projects`,
                key: `manage/projects`,
                icon: <SettingsRounded />,
            },
            {
                label: 'Projektverwaltung (Tabelle)',
                target: `/tableview/manage/projects`,
                key: `tableview/manage/projects`,
                icon: <TableOutlined />,
            },
        ]
    },
    {
        label: 'Kostenmanagement',
        icon: <EuroRounded />,
        key: 'costmanagement',
        children: [
            {
                label: 'Kostenübersicht',
                target: `/accounting`,
                key: `accounting`,
                icon: <EuroRounded />,
            },
            {
                label: 'Tabelle Personalkosten',
                target: `/tables/personalcosts`,
                key: `tables/personalcosts`,
                icon: <TableOutlined />,
            },
            {
                label: 'Tabelle Projektkosten',
                target: `/tables/projectcosts`,
                key: `tables/projectcosts`,
                icon: <TableOutlined />,
            },
        ]
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },
];

//project-assistance
export const projectAssistanceItems = (uid, children) => [
    {
        label: 'Übersicht',
        target: '/',
        key: '',
        icon: <DashboardOutlined />,
    },
    {
        label: "Systemstatus",
        target: "/assembly/status",
        key: "assembly/status",
        icon: <Sync />
    },
    {
        label: 'Alle Projekte',
        key: 'projectsOverview',
        icon: <ContainerOutlined />,
        children: [
            {
                label: 'Übersicht',
                target: `projects`,
                key: `projects`,
                icon: <ProjectOutlined />,
            },
            ...children, 
        ]
    },
    {
        label: 'Abrechnungen',
        target: `invoices`,
        key: `invoices`,
        icon: <FileSyncOutlined />,
    },
    {
        label: 'TimeBoard Zeiten',
        target: `users/timeboard`,
        key: `users/timeboard`,
        icon: <TimelapseRounded />,
    },
    {
        label: "User Management",
        icon: <UserOutlined />,
        key: "userManagement",
        children: [
            {
                label: 'User Management',
                target: `/usermanagement`,
                key: `usermanagement`,
                icon: <UsergroupAddOutlined />,
            },
        ]
    },
    {
        label: 'Projektmanagement',
        icon: <ProjectOutlined />,
        key: 'projectmanagement',
        children: [
            {
                label: 'Projekt Einstellungen',
                target: `/manage/projects`,
                key: `manage/projects`,
                icon: <SettingsRounded />,
            },
            {
                label: 'Projektverwaltung (Tabelle)',
                target: `/tableview/manage/projects`,
                key: `tableview/manage/projects`,
                icon: <TableOutlined />,
            },
            {
                label: 'Neues Projekt',
                target: `/manage/projects/create`,
                key: `manage/projects/create`,
                icon: <Add />,
            },
        ]
    },
    {
        label: 'Kostenmanagement',
        icon: <EuroRounded />,
        key: 'costmanagement',
        children: [
            {
                label: 'Kostenübersicht',
                target: `/accounting`,
                key: `accounting`,
                icon: <EuroRounded />,
            },
            {
                label: 'Tabelle Personalkosten',
                target: `/tables/personalcosts`,
                key: `tables/personalcosts`,
                icon: <TableOutlined />,
            },
            {
                label: 'Tabelle Projektkosten',
                target: `/tables/projectcosts`,
                key: `tables/projectcosts`,
                icon: <TableOutlined />,
            },
        ]
    },
    {
        label: 'Mein Konto',
        key: 'myaccount',
        icon: <SolutionOutlined />,
        children: [
            {
                label: 'Meine Dateien',
                target: `users/${uid}/files`,
                key: `users/${uid}/files`,
                icon: <UserOutlined />,
            },
            {
                label: 'Mein Account',
                target: `users/${uid}/profile`,
                key: `users/${uid}/profile`,
                icon: <SettingsRounded />,
            },
        ]
    },
    {
        label: 'Changelog',
        target: `cangelog`,
        key: `changelog`,
        icon: <MergeOutlined />,
    },

];