/**
 * @description
 * Ruft alle User von Firebase ab
 */

import { auth, db } from "../firebase";
import { collection, getDocs, getDoc, doc, query, where, and, or } from "firebase/firestore";


async function fetchUsersV1(isManager, isAdmin, isConsultant, isLead) {
    /**
     * @description
     * Ruft alle User die "Projektmitarbeiter sind ab"
     * @requires Firebase
     */

    try {
        const tenant = collection(db, "users");
        const q = query(tenant, and(
            where("role", "!=", "administrator"),
        ));

        // const q = query(tenant, where("role", "in", ["manager", "admin", "consultant", "lead"]));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // Das Dokument existiert
            const documentSnapshot = querySnapshot;
            const newUsersArray = [];
            documentSnapshot.forEach(snapshot => {
                // console.log("Snapshot: ", snapshot.data());
                const doc = snapshot.data();
                delete doc.createdAt;
                delete doc.updatedAt;
                delete doc.modifiedAt;
                delete doc.created;
                delete doc.modified;
                newUsersArray.push(doc);
            });

            return newUsersArray;
        } else {
            // Das Dokument existiert nicht
            console.log("Es wurden keine nutzer gefunden.")
            return null;
        }
    } catch (error) {
        console.log(error)
    }
};

async function fetchCurrentUserV1(user) {
    if (user == null) {
        return null;
    };
    try {
        const docRef = doc(db, `users/${user.uid}`);
        const userData = await getDoc(docRef);
        if (!userData.exists) {
            throw new Error("Can't get required Data.");
        };
        const data = userData.data();
        delete data.createdAt;
        delete data.updatedAt;
        delete data.modifiedAt;
        delete data.created;
        delete data.modified;
        return data
    } catch (error) {
        console.log("Error: ", error);
        return null;
    };
};

export { fetchUsersV1, fetchCurrentUserV1 }