import * as React from 'react';
import { useState } from 'react';
import { Button, Modal, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { v4_upload_timeboard_data } from '../../../routing/v4_Routes/timeboard.routes';
import _ from 'lodash';

export default function TimeBoardUploadComponent(props) {
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);

    const handleUpload = async () => {
        setUploading(true);
        setLoading(true);

        if (fileList.length === 0) {
            setLoading(false);
            setUploading(false);
            return;
        };

        const response = await v4_upload_timeboard_data(fileList[0]);

        if (!_.isEmpty(response)) {
            setFileList([]);
            setLoading(false);
            setUploading(false);
        } else {
            setLoading(false);
            setFileList([]);
            setUploading(false);
            props.handleCancel();
        };


    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        maxCount: 1,
        fileTypes: ["csv"],
    };

    const handleCancel = () => {
        setFileList([]);
        props.handleCancel();
    };


    let content = (
        <div>

            <div>
                <Modal
                    title={`Timeboard Tabelle hochladen`}
                    centered
                    open={props.open}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                    width={"40%"}
                    footer={[
                        <Button type='primary' onClick={handleCancel} key={1}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleUpload}>
                            Hochladen
                        </Button>
                    ]}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "1rem"

                    }}>
                        <Upload 
                        maxCount={1}
                        multiple={false}
                        {...uploadProps}>
                            <Button
                                type="primary"
                                loading={loading}
                                icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
                            >Datei auswählen</Button>
                        </Upload>
                        <p><strong>Bitte jede Datei einzeln hochladen.</strong></p>
                    </div>
                    
                </Modal>
            </div>

        </div>

    )

    return content;
};