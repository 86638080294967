import { Alert, Button, Descriptions, Form, Input, Modal, Space, Table, Tag, Typography } from 'antd';
import React, { useContext, useRef } from 'react';
import { auth } from '../../../firebase';
import axios from 'axios';
import _ from 'lodash';
import { SingleDetailModal } from '../../modals/accounting/SingleDetail.modal';
import { v4_setAPI } from '../../../routing/api';
import { TitleImage } from '../project/PublicProjectPage';
import { useNavigate } from 'react-router-dom';
import { v4_setSollKpi } from '../../../routing/v4_Routes/project.routes';
import { useUpdate } from 'ahooks';

const { useState, useEffect, createContext } = React;
const { Text, Title } = Typography;
const API_URL = v4_setAPI();

const EditableContext = createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
    let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export function ProjectsTableView() {
  const update = useUpdate()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedModalProject, setSelectedModalProject] = useState(null);
    const [settableObject, setSettableObject] = useState({});
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });

    const onChangeSettable = (object) => {
      const oldObject = settableObject;
      console.log(oldObject, object)
      setSettableObject(_.merge(oldObject, object))
      console.log(settableObject)
      update()
    };

    const saveChangesOfSettable = async () => {
      try {
        setLoading(true)
        const r = await v4_setSollKpi(selectedModalProject._id, settableObject);
        setSelectedModalProject(r);
        await fetchData();
      } catch (error) {
        console.error(error)
      } finally {
        fetchData()
        setLoading(false)
      }
    };

    useEffect(() => {
      if (modalVisible) {
        setSettableObject(selectedModalProject?.sollValues);
      };

      return () => {
        setSettableObject({});
      }
    }, [modalVisible])

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(`${API_URL}/projects/projects/getall/stream-edit`, {
                params: {
                    page: tableParams.pagination.current,
                    limit: tableParams.pagination.pageSize
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.totalCount, // Annahme, dass der Server die Gesamtzahl der Einträge zurückgibt
                },
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: sorter.order,
            sortField: sorter.field,
        });

        if (pagination.pageSize !== tableParams.pagination.pageSize) {
            setData([]);
        }
    };

    const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };

      const handleSave = async (row, t) => {
        console.log('row', row, t);
      };

    return (
        <div>
            <div style={{
                marginBottom: '20px',
            }}>
            <TitleImage title="Projekte" overwrite={true} children={
                <>
                    <Title style={{ color: "white" }} level={4}>Einstellung der Sollwerte im Projekt</Title>
                    <Alert message="Diese Funktion wird gerade angepasst." type="info" />
                </>
            } />

            </div>



            <div>
            <div>
                {hasSelected && <div style={{
                    marginBottom: '10px',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}>
                    <Tag color="red">Es sind noch keine Aktionen verfügbar</Tag>
                </div>}
                <Table
                    rowKey={(record) => record._id}
                    dataSource={data}
                    rowSelection={rowSelection}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    footer={() => (
                        <div>
                            Einträge ausgewählt: {selectedRowKeys.length} von {data.length}
                        </div>
                    )}
                    size="small"
                    columns={[
                        {
                            title: 'Projekt ID',
                            dataIndex: '_id',
                            key: '_id',
                        },
                        {
                            title: 'Projekt',
                            dataIndex: 'title',
                            key: '_id',
                        },
                        {
                            title: 'Kostenstelle',
                            dataIndex: 'costCentre',
                            key: '_id', 
                            filters: _.uniqBy(data.map((o) => ({ text: o.costCentre, value: o.costCentre })), 'value'),
                            onFilter: (value, record) => record.costCentre === value,
                        },
                        {
                            title: 'Soll-Werte anpassen',
                            dataIndex: '_id',
                            key: '_id',
                            render: (o, p) =>  <Space><Button type="link" onClick={() => navigate(`/projects/${o}/productivityquotes`)} >
            Produktivitätsquoten (Tabelle)
        </Button>
        <Button type="link" onClick={() => {setModalVisible(true); setSelectedModalProject(p)}} >
            Soll-Werte aus Kalkulation
        </Button></Space>,
                            width: 250
                        },
                    ]}
                    expandable={{
                        expandedRowRender: (record) => (
                            <Table
                                rowKey={(record) => record._id}
                                //Turn object into array
                                dataSource={record?.sollValues ? [record?.sollValues] : []}
                                components={components}
                                rowClassName={() => 'editable-row'}
                                size="small"
                                bordered
                                columns={[
                                    {
                                        title: 'Wetschöpfungsstundensatz',
                                        dataIndex: 'wss',
                                        key: '_id',
                                    },
                                    {
                                        title: 'Fluktuationsquote',
                                        dataIndex: 'fluktuation',
                                        key: '_id',
                                    },
                                    {
                                        title: 'Krankheitsquote',
                                        dataIndex: 'krankheit',
                                        key: '_id',
                                    },
                                    {
                                        title: 'Produktivitätsquote',
                                        dataIndex: 'produktivitaet',
                                        key: '_id',
                                    },
                                    {
                                      title: "Breakeven",
                                      dataIndex: "breakEvenPoint",
                                      key: "_id",
                                    }
                                ]}
                            />
                        ),
                        rowExpandable: (record) => !_.isEmpty(record?.sollValues),
                    }}
                />
            </div>
            </div>



        <Modal
          title="Soll-Werte aus Kalkulation"
          open={modalVisible}
          onOk={async () => await saveChangesOfSettable()}
          onCancel={() => setModalVisible(false)}
          width={"70%"}
        >
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Wertschöpfungsstundensatz">
              <Text editable={{ onChange: async (str) => {
                try {
                  onChangeSettable({ wss: parseFloat(_.replace(str, ',', '.')) })
                } catch (error) {
                  console.error('Fehler beim Speichern:', error);
                }
              } }}>{settableObject?.wss}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Fluktuationsquote">
              <Text editable={{ onChange: async (str) => {
                try {
                  onChangeSettable({ fluktuation: parseFloat(_.replace(str, ',', '.')) })
                } catch (error) {
                  console.error('Fehler beim Speichern:', error);
                }
              } }}>{settableObject?.fluktuation}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Krankheitsquote">
              <Text editable={{ onChange: async (str) => {
                try {
                  onChangeSettable({ krankheit: parseFloat(_.replace(str, ',', '.')) })
                } catch (error) {
                  console.error('Fehler beim Speichern:', error);
                }
              } }}>{settableObject?.krankheit}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Produktivitätsquote">
              <Text editable={{ onChange: async (str) => {
                try {
                  onChangeSettable({ produktivitaet: parseFloat(_.replace(str, ',', '.')) })
                } catch (error) {
                  console.error('Fehler beim Speichern:', error);
                }
              } }}>{settableObject?.produktivitaet}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Breakeven">
              <Text editable={{ onChange: async (str) => {
                try {
                  onChangeSettable({ breakEvenPoint: parseFloat(_.replace(str, ',', '.')) })
                } catch (error) {
                  console.error('Fehler beim Speichern:', error);
                }
              } }}>{settableObject?.breakEvenPoint}</Text>
            </Descriptions.Item>
          </Descriptions>
        </Modal>
        </div>
    );
};

