import { Button, Drawer, Tabs } from "antd";
import React from "react";
import { UserDetailsForm } from "../forms/UserManagementForms/UserDetailsForm";
import { UserSettingsForm } from "../forms/UserManagementForms/UserSettingsForm";
import { UserContractForm } from "../forms/UserManagementForms/UserContractForm";


function UserSettingsModal(props) {

    // const user = props.user;

    const user = props.user;

    const close = () => {
        props.onCancel();
        props.onClose();
    };

    const fetchTeam = () => {
        props.onClose();
    };

    const refetch = () => {
        props.onRefetch();
    };

    const items = [
       {
        key: "1",
        label: "Allgemeine Stammdaten",
        children: <UserDetailsForm user={user} fetchData={fetchTeam}  Refetch={refetch} onClose={close} />
       },
         {
        key: "2",
        label: "Kostenverteilung auf Projekte",
        children: <UserSettingsForm user={user} Refetch={refetch} onClose={close} />
         },
         {
        key: "3",
        label: "Vertragsdaten",
        children: <UserContractForm user={user} Refetch={refetch} onClose={close} />
         }
    ]

    return (
        <>
            <Drawer
                title={`Stammdaten von ${user.firstName} ${user.lastName}`}
                open={props.open}
                onOk={() => console.log("Ok")}
                onCancel={close}
                onClose={close}
                closable={true}
                destroyOnClose={true}
                width={"100%"}
                height={"100%"}
                footer={
                    <>
                        <Button type="primary" onClick={close}> Schließen </Button>
                    </>
                }
                // placement="top"
                
            >
                {/* Ab hier können die bearbeitungen für das Modal beginnen  */}
                <Tabs defaultActiveKey="1" centered items={items} />
                {/* Ab hier darf nichtsmehr eingefügt werden */}
            </Drawer>
        </>
    );
};

export { UserSettingsModal };