import { Box } from "@mui/system";
import { Tabs } from "antd";
import React from "react";


function MyFilesPage() {

    return (
        <>
            <Box sx={{
                mt: 5,
            }}>
                <Tabs defaultActiveKey="1"
                    type="card"
                    size="large"
                >
                    <Tabs.TabPane tab="Alle Dateien" key="1">
                        <Box sx={{
                            mt: 2,
                        }}>
                            <h2>Keine Dateien vorhanden.</h2>
                        </Box>
                    </Tabs.TabPane>
                </Tabs>
            </Box>
        </>
    );
};

export default MyFilesPage;