import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Card, Col, DatePicker, Row, Skeleton, Statistic, Table, Typography } from "antd";
import { DateTime } from "luxon";
import jsonLocale from "../../../locale/datePickerLocale.json";
import { statistic_fetchAll } from "../../../routing/v3_Routes/v3_Routes";
import CountUp from "react-countup";
import { automaticUpdate_AS0, automaticUpdate_AutoSession } from "../../../redux/updateUtils/automaticUpdate";
import SimpleBackdrop from "../../global/LoadingBackdrop"
import { useUpdateEffect } from "ahooks";
import { ResultModal } from "../../modals/ResultModal";
import { motion } from "framer-motion";

function V3InsightPage() {

    const formatter = (value) => <CountUp end={value} separator="." duration={0.3} />
    const project = useSelector((state) => state.projectState.selectedProject);
    const autoSession = useSelector((state) => state.mainState.autoSession);
    const localNow = DateTime.now().toJSDate();
    const datePickerRef = useRef();

    const [viewData, setViewData] = useState({});
    const [loading, setLoading] = useState(false);
    const [openActionHint, setOpenActionHint] = useState(true);
    const [filter, setFilter] = useState({
        startDate: localNow,
        endDate: localNow,
    });

    const backendRequest = async () => {
        setLoading(true);
        const tsk = await statistic_fetchAll({
            startDate: filter.startDate,
            endDate: filter.endDate,
        }, project._id)
        setLoading(false);
    };

    useUpdateEffect(() => {
        const generateViewData = () => {
            let dataset = {};
            if (_.has(autoSession, "statistics_main")) {
                dataset = {
                    ...autoSession.statistics_main,
                };
            } else {
                dataset = {};
            };
            setViewData(dataset);
        }
        generateViewData();
    }, [autoSession]);

    async function handleDateChange(dates) {
        if (dates && dates.length === 2) {
            const [startDate, endDate] = dates;
            const luxonStartDate = DateTime.fromJSDate(startDate.toDate());
            const luxonEndDate = DateTime.fromJSDate(endDate.toDate());

            // Jetzt haben Sie luxonStartDate und luxonEndDate, die Sie mit Luxon verwenden können.
            console.debug('Start Date (Luxon):', luxonStartDate.toString());
            console.debug('End Date (Luxon):', luxonEndDate.toString());

            setFilter({
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
            });

            console.debug(filter);
        };
    };

    useUpdateEffect(() => {
        const calculateData = async () => {
            await backendRequest();
        };
        calculateData();
    }, [filter]);

    useEffect(() => {
        return () => {
            // cleanup
            console.log("Cleaned up");
            automaticUpdate_AS0("statistics_main", {});
            automaticUpdate_AS0("costs", {});
            automaticUpdate_AS0("selectedCost", {});
            automaticUpdate_AutoSession("statistics_main", {});
            setViewData({});
        };
    }, []);

    return (
        <>
            {/* { DateTime.now().toISODate() < "2024-03-15" && <Alert message="AKTION ERFORDERLICH - Für die Nutzer muss die Kostenverteilung hinterlegt werden. Bitte kontaktieren Sie Ihren Administrator." type="error" showIcon style={{ textAlign: "left", marginTop: "20px" }} />} */}
            {(project?.released || false) === false ? <Alert message={`Hinweis: Die KPI's für ${project.title} wurden von der kaufmännischen Leitung noch nicht freigegeben. (Ausgeschlossen hiervon sind Umsatz und Arbeitszeit)`} type="warning" showIcon style={{ textAlign: "left", marginTop: "20px" }} /> : <></>}
            {DateTime.now().toISODate() < "2024-10-20" && <Alert message={`Achtung: Die Berechnungslogik wurde überarbeitet. Personalkosten sind nun immer auf dem Stand von -1 Tag. `} type="warning" showIcon style={{ textAlign: "left", marginTop: "20px" }} />}
            {DateTime.now().toISODate() <= "2024-10-18" && <Alert message={`ENERCON Daten werden neu eingespielt, Aktion voraussichtlich am 18.10 abgeschlossen`} type="error" showIcon style={{ textAlign: "left", marginTop: "20px" }} />}
            <div>
                <div>
                    <Typography.Title level={2}>Insights für {project.title}</Typography.Title>
                </div>
                <div>
                    <DatePicker.RangePicker
                        // defaultValue={[filter.startDate.getDate(), filter.endDate.getDate()]}
                        format={"DD.MM.YYYY"}
                        ref={datePickerRef}
                        locale={jsonLocale}
                        onChange={(value) => {
                            handleDateChange(value)
                        }}
                    />
                </div>

            </div>
            {loading ? <>
                <Row gutter={[16, 16]}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Col key={index} className="gutter-row" span={8}>
                            <Skeleton active />
                        </Col>
                    ))}
                </Row>
            </> :
                <>
                    <div>
                    </div>
                    <div>

                        <div>
                            {
                                _.has(viewData, "salesVolume") ? <div>
                                    <div div style={{
                                        marginTop: "50px",
                                    }}>
                                        <Row gutter={{
                                            xs: 8,
                                            sm: 16,
                                            md: 24,
                                            lg: 32,
                                        }}>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.1,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>

                                                            <Statistic
                                                                title="Umsatz"
                                                                value={viewData.salesVolume}
                                                                precision={2}
                                                                decimalSeparator=","
                                                                suffix="€"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.2,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Sonstige Projektkosten"
                                                                value={viewData.sum_projectCosts}
                                                                precision={2}
                                                                suffix="€"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.3,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Personalkosten"
                                                                value={viewData.contractCosts.costsTotal}
                                                                precision={2}
                                                                suffix="€"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.4,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Gesamtkosten (Alle)"
                                                                value={viewData.totalCosts}
                                                                precision={2}
                                                                suffix="€"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.5,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Deckungsbeitrag in €"                             //Kosten - Umsatz (Kosten fehlen noch)
                                                                value={viewData.salesVolume - viewData.totalCosts}
                                                                precision={2}
                                                                suffix="€"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.6,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Deckungsbeitrag in %"                             //Kosten - Umsatz (Kosten fehlen noch) in Prozent
                                                                value={Number((viewData.salesVolume - viewData.totalCosts) / viewData.salesVolume * 100)}
                                                                precision={2}
                                                                suffix="%"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.7,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Wertschöpfungsstundensatz (WSS)"
                                                                value={Number(viewData.salesVolume / viewData.projectTime.totalTime)}
                                                                precision={4}
                                                                suffix="€/h"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.8,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Deckungsbeitrag pro Eingesetzter Stunde (DB/h)"
                                                                value={Number((viewData.salesVolume - viewData.totalCosts) / viewData.projectTime.totalTime)}
                                                                precision={4}
                                                                suffix="€/h"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 0.9,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title="Eingesetze Zeit"
                                                                value={viewData.projectTime.totalTime}
                                                                precision={2}
                                                                suffix="h"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <motion.div
                                                    initial={{ opacity: 0, y: 50 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{
                                                        delay: 1,
                                                        duration: 0.5,
                                                        type: 'spring',
                                                        stiffness: 100,
                                                        damping: 10,
                                                    }}
                                                >
                                                    <div style={{ margin: "20px" }}>
                                                        <Card bordered={false}>
                                                            <Statistic
                                                                title={`Eingesetztes Personal (mit Vertrag: ${viewData.contractCosts.employeeCount} / ohne Vertrag: ${viewData.projectTime.userCount - viewData.contractCosts.employeeCount})`}
                                                                value={viewData.projectTime.userCount}
                                                                precision={2}
                                                                suffix="Person'en"
                                                                formatter={formatter}
                                                            />
                                                        </Card>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div> : <div></div>
                            }


                            <div
                                style={{
                                    marginTop: "50px",
                                }}
                            >
                                <Table
                                    dataSource={viewData.accumulatedKpi}
                                    columns={[
                                        {
                                            title: "Aufgabe",
                                            dataIndex: "taskTitle",
                                            key: "taskTitle",
                                        },
                                        {
                                            title: "Buchungen",
                                            dataIndex: "count",
                                            key: "count",
                                            render: (value) => {
                                                return <Statistic
                                                    value={value}
                                                    precision={0}
                                                    valueStyle={{
                                                        fontSize: "16px",
                                                        fontFamily: "Arial",
                                                    }}
                                                />
                                            }
                                        },
                                        {
                                            title: "Umsatz",
                                            dataIndex: "total",
                                            key: "total",
                                            render: (value) => {
                                                return <Statistic
                                                    value={value}
                                                    precision={2}
                                                    suffix="€"
                                                    valueStyle={{
                                                        fontSize: "16px",
                                                        fontFamily: "Arial",
                                                    }}
                                                />
                                            }
                                        }
                                    ]}  
                                />
                            </div>
                        </div>
                    </div>
                </>}
            <SimpleBackdrop open={loading} />
            <ResultModal open={openActionHint} onClose={() => setOpenActionHint(false)}
                title="Projektleitung: Aktion erforderlich"
                subTitle="Für alle Nutzer muss die Kostenverteilung hinterlegt sein, sonst kommt es zur fehlerhaften Berechnung von Personalkosten. Aktuell ist diese für niemanden hinterlegt. Bitte kontaktieren Sie Ihren Administrator. //Dieser Hinweis kann nur von Administratoren entfernt werden."
                status="error"
            />
        </>
    );
};

export default V3InsightPage;