import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();

/**
 * 
 * @param {File} file 
 * @returns 
 */
export async function v4_get_latest_project_data(_id) {
    try {
        let response = null;
        response = await axiosInstance.get(`${API_URL}/stream/${_id}/dataset/latest`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};


/**
 * 
 * @param {File} file 
 * @returns 
 */
export async function v4_updateData(data_id, project_id, update) {
    try {
        let response = null;
        response = await axiosInstance.patch(`${API_URL}/stream/${project_id}/data/edit`, update, { params: { 
            data_id: data_id
         } });
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};