import { Button, Space, Typography, Tooltip, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addListenerTo, unsubscribeFrom } from "../../../listener/firebaseSnapshotListener";
import { useSelector } from "react-redux";
import { useUpdate } from "ahooks";
//eslint-disable-next-line
import CountUp from "react-countup";
import Search from "antd/es/input/Search";



function CostDetailPage() {
    const { _id } = useParams();
    //eslint-disable-next-line
    const formatter = (value) => <CountUp end={value} separator="," duration={0.5} />
    const update = useUpdate();
    const dataWatcher = useSelector(state => state.mainState.autoSelect_0);
    const [newVarCostModalOpen, setNewVarCostModalOpen] = useState(false);
    const [costs, setCosts] = useState([]);
    // const [filteredCosts, setFilteredCosts] = useState(_.filter(costs, (cost) => cost.project._id === _id));
    //eslint-disable-next-line
    const [totalCosts, setTotalCosts] = useState(0);

    const closeCostModal = () => {
        setNewVarCostModalOpen(false);
    };

    const callback = async () => {
        console.log("Callback");
        
        update();
    }

    useEffect(() => {
        const listener = addListenerTo("accounting_costs", async (data) => {
            console.log(data);
            //Hier muss die aktion zum daten aktualisieren ausgeführt werden.
            await callback();
        });

        return () => {
            unsubscribeFrom(listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div>
            <div style={{
                marginBottom: "1rem",
            }}>
            <Typography.Title level={3}>Kostenverwaltung {dataWatcher?.selectedProject?.title || ""}</Typography.Title>
            </div>
            <div id="cost-actions"
            style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
            }}
            >
                <Space>
                    <Tooltip title="Exportiert die gefilterten Kosten.">
                    <Button disabled type="dashed">Kosten exportieren</Button>
                    </Tooltip>
                </Space>
                <Search disabled placeholder="Kosten suchen (Titel, Beschreibung)" onSearch={(value) => console.log(value)} style={{ marginLeft: 10}} />
            </div>

            <div id="cost-details">
               <Result
                    status="error"
                    title="Anzeige nicht möglich."
                    subTitle="Wir sind gerade dabei diese Seite zu übererbeiten. Bitte haben Sie noch etwas Geduld."
                />
            </div>
        </div>
    );
};

export { CostDetailPage };