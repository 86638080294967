import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projects: [],
    selectedProject: { },
    selectedProjectData: [],
    customFields: [],
    selectedProjectTimeData: [],
};

const projectSlicer = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        updateProjects: (state, action) => {
            state.projects = action.payload;
        },
        updateSelectedproject: (state, action) => {
            state.selectedProject = action.payload;
        },
        updateCustomFields: (state, action) => {
            state.customFields = action.payload;
        },
        updateSelectedprojectData: (state, action) => {
            state.selectedProjectData = action.payload;
        },
        updateSelectedprojectTimeData: (state, action) => {
            state.selectedProjectTimeData = action.payload;
        }
    }
});

export const {
    updateProjects,
    updateSelectedproject,
    updateCustomFields,
    updateSelectedprojectData,
    updateSelectedprojectTimeData
} = projectSlicer.actions;

export default projectSlicer.reducer;