import { setAPI } from "../routing/api";
import { displayErrorModal } from "../utils/messageServices/modalService";
import { axiosInstance } from "../axios/axiosInstance";
// Anmeldung mit Google OAuth2.0

  //Login Routes
  const API_URL = setAPI(); // URL zur API

  export async function fetchMongoData(id) {
    try {
      return await axiosInstance.post(`${API_URL}/api/db/mo/stream`, { mongoId: id, limit: true })
    } catch (error) {
      console.log(error);
      displayErrorModal("Fehler beim laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
  };

  export async function fetchMongoDataUnlimited(id) {
    try {
      return await axiosInstance.post(`${API_URL}/api/db/mo/stream`, { mongoId: id, limit: false })
    } catch (error) {
      console.log(error);
      displayErrorModal("Fehler beim laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
  };

  export async function fetchTimeData(id) {
    try {
      return await axiosInstance.post(`${API_URL}/api/db/mo/stream/timedata`, { mongoId: id, limit: false })
    } catch (error) {
      console.log(error);
      displayErrorModal("Fehler beim laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
  };

  export async function fetchCustomFields(id) {
    try {
        return axiosInstance.post(`${API_URL}/api/db/mo/stream/fields`, { mongoId: id})
    } catch (err) {
        console.log(err);
        displayErrorModal("Fehler beim laden der Daten", err?.response?.data?.error, err?.response?.data?.stack)
    };
  };

  export async function deleteStreamDoc(mongoDbProjectId, objectId) {
    try {
      console.log(`Props - MongoId: ${mongoDbProjectId} - DeleteId: ${objectId}`)
  
      return await axiosInstance.post(`${API_URL}/api/db/mo/stream/doc`,  { mongoId: mongoDbProjectId, deleteId: objectId });
    } catch (err) {
      console.log(err);
      displayErrorModal("Fehler beim löschen des Dokuments", err?.response?.data?.error, err?.response);
    }
  }

  export async function createEntry(id) {
    try {
        return axiosInstance.post(`${API_URL}/api/db/mo/stream/fields`, { mongoId: '6449220714ce8f71c06e80ba'})
    } catch (err) {
        console.log(err);
        displayErrorModal("Fehler beim laden der Daten", err?.response?.data?.error, err?.response?.data?.stack)
    };
  };