import React, { useEffect } from "react";
import { Alert, Row, Typography } from "antd";
import { KpiStatisticCard } from "../../cards/KpiStatistic.card";
import { FilterDateTimeModal } from "../../modals/filters/FilterDateTimeModal";
import { v4_get_org_kpi } from "../../../routing/v4_Routes/kpi.routes";
import _ from "lodash";
import { useUpdate } from "ahooks";
import { DateTime } from "luxon";

const { Title } = Typography;

export function TotalDashboardPage() {

    const update = useUpdate();
    const [loading, setLoading] = React.useState(false);
    const [kpis, setKpis] = React.useState(null);
    const [filter, setFilter] = React.useState(null);
    
    async function getKPIs() {
        if (filter === null) {
            return;
        };

        setLoading(true);
        const r = await v4_get_org_kpi(filter);
        console.log(r);
        setKpis(r.kpis);
        setLoading(false);
        update();
    };


    useEffect(() => {
        if (filter) {
            getKPIs();
        };

        return () => {
            setFilter(null);
            setKpis(null);
        };
    }, [filter])

    useEffect(() => {
        //Set window title
        document.title = "Assembly - My Business KPIs";
    }, []);

    return (
        <div>
        {DateTime.now().toISODate() < "2024-10-20" && <Alert message={`Achtung: Die Berechnungslogik wurde überarbeitet. Personalkosten sind nun immer auf dem Stand von -2 Tagen. `} type="warning" showIcon style={{ textAlign: "left", marginTop: "20px" }} />}
            <div>
                <Title level={3}>My Business</Title>
                <FilterDateTimeModal
                    title="Filter für die KPI abfrage anpassen"
                    text="Filter modifizieren"
                    OnFilter={(filter) => {
                        setKpis(null);
                        setFilter(filter);
                    }}
                />
            </div>
            
            
                <Row gutter={[16, 16]}>
                    {_.isArray(kpis) && kpis?.map((item, index) => {
                        const delay = index * 0.15;
                        return (
                            <KpiStatisticCard
                            key={index}
                            title={item.title}
                            value={item.value}
                            suffix={item.suffix}
                            description={item.description}
                            precision={item.precision}
                            delay={delay}
                            loading={loading}
                            />
                        )
                    })}
                </Row>
            
        </div>
    )
};