import React from "react";
import { Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState } from "react";
import _ from "lodash";
import { useEffect } from "react";
import { useSelector } from 'react-redux';

//import custom components
import { sendProjectCreation } from "../../../../routing/sendData";
import NewProjectForm from "../../../../components/forms/NewProjectForm";
import { fetchUsersV1 } from "../../../../modules/fetchUsers";


function CreateProjectPage() {

    const mongoUser = useSelector((state) => state.userState.mongoUser);

    const [progressOpen, setProgressOpen] = useState(false);
    const [alert, setAlert] = useState({open: false, severity: "success", message: ""})

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setAlert({open: false, severity: "success", message: ""});
      };

    useEffect(() => {
        fetchManagers();
        fetchConsultants();

        // eslint-disable-next-line
    }, []);

    const fetchManagers = async () => {
        await fetchUsersV1(false, false, false, true)
        .then((result) => {
            setManagers(result)
        }).catch((err) => {
            console.log(err)
        });
    };

    const fetchConsultants = async () => {
        await fetchUsersV1(false, false, true, false)
        .then((result) => {
            setConsultans(result);
            console.log(result)
        }).catch((err) => {
            console.log(err)
        });
    }
    const [managers, setManagers] = useState([]);
    const [consultants, setConsultans] = useState([]);


    return (

        <Container sx={{
            marginTop: 5,
        }}>
           
                <Box sx={{
                    padding: 5,
                }}>
                    {/* <StyledHeaderTitle Title={`Neues Projekt - ${projectTitle}`} /> */}
                    <NewProjectForm />
                </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={progressOpen}
                onClick={() => setProgressOpen(false)}
            > 
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Container>

    );
};

export default CreateProjectPage;