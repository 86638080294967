import { Card, Col, Statistic, Tooltip } from "antd";
import React from "react";
import { motion } from "framer-motion";
import CountUp from "react-countup";


export function KpiStatisticCard(props) {

    const formatter = (value) => <CountUp end={value} separator="." duration={2} decimals={2} decimal="," />
    const { title, value, loading, delay, description, suffix, key, precision } = props;

    return (
        <>
            <Col className="gutter-row" span={8} key={key}>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: delay || 0,
                        duration: 0.5,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                >
                    <div style={{ margin: "20px" }}>
                        <Card bordered={false}
                        loading={loading}
                        >
                        <Tooltip title={description}>
                            <Statistic
                                title={title}
                                value={value}
                                precision={precision || 2}
                                decimalSeparator=","
                                suffix={suffix}
                                formatter={formatter}
                                loading={loading}
                            />
                            </Tooltip>
                        </Card>
                    </div>
                </motion.div>
            </Col>
        </>
    )
};