import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, signOut, multiFactor, onAuthStateChanged, sendEmailVerification, RecaptchaVerifier, PhoneAuthProvider, setPersistence, browserSessionPersistence  } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import store from './redux/store';
import { updateFirebaseUser } from './redux/userSlices';
import { fetchCurrentUserV1 } from './modules/fetchUsers';
import { getPerformance } from "firebase/performance";
import { displayErrorNotification } from './utils/messageServices/notificationService';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const user = auth.currentUser;
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const locToke2 = process.env.REACT_APP_APPCHECK_KEY;

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(locToke2),
  isTokenAutoRefreshEnabled: true
});


const loginUser = async (email, password) => {
  const appVerifier  = new RecaptchaVerifier(auth, 'recaptcha-container', {
    'size': 'visible',
    'callback': (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      console.log("recaptcha solved")
    },
    'expired-callback': () => {
      // Response expired. Ask user to solve reCAPTCHA again.
      console.log("recaptcha expired")
    }
  });
  setPersistence(auth, browserSessionPersistence)
  signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in 
      store.dispatch(updateFirebaseUser(await fetchCurrentUserV1(userCredential.user)))
      const user = userCredential.user;
      if (user.emailVerified === false) {
        await sendEmailVerification(auth.currentUser);
      }
      return (true);
    })
    .catch((error) => {
      const errorCode = error.code;
      console.log(errorCode);
      if (errorCode === "auth/wrong-password") {
        console.log("Falsche kombination aus Passwort und Email");
        displayErrorNotification("Falsches Passwort");
      } else if (errorCode === "auth/user-not-found") {
        displayErrorNotification("Falsche kombination aus Passwort und Email");
      } else if (errorCode === "auth/firebase-app-check-token-is-invalid.") {
        displayErrorNotification("Login not possible low S-Rate. Please contact support.");
      } else {
        displayErrorNotification("Login not possible. Contact support.");
      }
      const errorMessage = error.message;
      console.log(errorMessage);
      return (false);
    });
};

const logOutUser = async () => {
  await signOut(auth).then(() => {
    // Sign-out successful.
    console.log("isgn out")
  }).catch((error) => {
    // An error happened.
    console.log(error)
  });
};

const userIsAuthenticated = () => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      return true;
    } else {
      // User is signed out
      return false;
    }
  });
};

function isLoggedIn() {
  const user = auth.currentUser;
  if (user) {
    // Der Nutzer ist eingeloggt
    console.log("Der user ist eingeloggt.")
    return(true);
  } else {
    // Der Nutzer ist nicht eingeloggt
    console.log("Der user ist nicht eingeloggt.")
    return(false);
  };
};


// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
perf.dataCollectionEnabled = true;



export { auth, isLoggedIn, loginUser, logOutUser, userIsAuthenticated, user, app, storage, db, analytics };