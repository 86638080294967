import axios from "axios";
import { auth } from "../../firebase";
import { v3_setAPI } from "../../routing/api";
import { displayErrorMessage, displayInfoMessage, displaySuccessMessage } from "../messageServices/messageService";
import { automaticUpdate_ProjectLocationState, automaticUpdate_ProjectState } from "../../redux/updateUtils/automaticUpdate";
import { displaySuccessNotification } from "../messageServices/notificationService";

const API_URL = v3_setAPI();

export async function util_ProjectDetailsUpdate(projectId, fieldName, fieldValue) {
    try {
      const token = await auth.currentUser.getIdToken();
      const task = await axios.post(`${API_URL}/projects/project/update/${projectId}`, { 
        fieldName: fieldName,
        fieldValue: fieldValue
       }, { headers: { Authorization: `Bearer ${token}` }});

       if (task.status === 200) {
        displaySuccessNotification("Das Projekt wurde erfolgreich aktualisiert.");
        // displayInfoMessage("Info", "Es kann bis zu 5 Minuten dauern bis die Änderungen sichtbar sind.");
        console.log(task.data);

        automaticUpdate_ProjectState(fieldName, fieldValue);
         return true;
       } else {
        displayErrorMessage("Fehler", "Das Projekt konnte nicht aktualisiert werden.")
         throw new Error("Something went wrong while updating the project.")
       }
    } catch (error) {
      console.log(error);
      return null;
    };
  };

  export async function util_ProjectLocationUpdate(projectId, fieldName, fieldValue) {
    try {
      const token = await auth.currentUser.getIdToken();
      const task = await axios.post(`${API_URL}/projects/project/update/${projectId}`, { 
        fieldName: "projectLocation."+fieldName,
        fieldValue: fieldValue
       }, { headers: { Authorization: `Bearer ${token}` }});

       if (task.status === 200) {
        displaySuccessNotification("Das Projekt wurde erfolgreich aktualisiert.");
        // displayInfoMessage("Info", "Es kann bis zu 5 Minuten dauern bis die Änderungen sichtbar sind.");

        automaticUpdate_ProjectLocationState(fieldName, fieldValue);
         return true;
       } else {
        displayErrorMessage("Fehler", "Das Projekt konnte nicht aktualisiert werden.")
         throw new Error("Something went wrong while updating the project.")
       }
    } catch (error) {
      console.log(error);
      return null;
    };
  };