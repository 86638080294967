import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { displayErrorMessage, displayWarningMessage } from "../../utils/messageServices/messageService";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();


export async function v4_update_user_details(uid, payload) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.post(`${API_URL}/users/firebase/user/${uid}`, payload, { headers: { Authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        displayErrorModal("Fehler beim Aktualisieren der Benutzerdaten", error?.response?.data?.error, error?.response?.data?.stack)
        console.log(error);
    };
};

/**
* 
* @param {ObjectId} userId - required
* @returns 
* @description - This function is used to create a new article in the database.
*/
export async function v4_get_my_absences(user_id) {
    if (user_id === undefined) {
        displayWarningMessage("Fehler! 😱", "Es wurden keine Daten übermittelt. Bitte versuche es erneut.")
        return null;
    }

    try {
        const token = await auth.currentUser.getIdToken();                 //Hier kann je nach anforderung das löschen der referenzen deaktiviert werden
        const data = await axios.get(`${API_URL}/timeboard/user/${user_id}`, { headers: { Authorization: `Bearer ${token}` } })
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim Laden der Abwesenheiten", error?.response?.data?.error, error?.response?.data?.stack)
        return [];
    }
};


export async function v4_change_user_role(uid, role) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.post(`${API_URL}/users/firebase/user/${uid}/role?role=${role}`, { headers: { Authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        displayErrorModal("Fehler beim Ändern der Benutzerrolle", error?.response?.data?.error, error?.response?.data?.stack)
        console.log(error);
    };
};