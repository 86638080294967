import { Button, Transfer } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../firebase";
import { useSelector } from "react-redux";
import _ from "lodash";

function CustomerDashboardPage() {

    const customerData = useSelector((state) => state.customerState.customerDataSet);
    const [keysFromDB, setKeysFromDB] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);


    const handleChange = (newTargetKeys, direction, moveKeys) => {
        setTargetKeys(newTargetKeys);
        console.log(newTargetKeys);
        console.log(direction);
      };
      const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        console.log('sourceSelectedKeys:', sourceSelectedKeys);
        console.log('targetSelectedKeys:', targetSelectedKeys);
      };
      const handleScroll = (direction, e) => {
        // console.log('direction:', direction);
        // console.log('target:', e.target);
      };



    useEffect(() => {
        const getKeys = () => {
            if (!_.has(customerData, "data.projectData")) {
                return;
            } else {
                const keys = Object.keys(customerData.data.projectData[customerData.data.projectData.length - 1]);
                keys.forEach((key, i) => {
                    setKeysFromDB((keysFromDB) => [...keysFromDB, {key: i, title: key}]);
                });
                console.log(keys);
            };
        };

        return getKeys();
    }, [customerData]);

    return (
        <div>
        <div
        style={{
            maxWidth: "100%",
        }}
        >
            <p>
            Hier findest du eine Übersicht der Daten-Schlüssel die ein der CSV-Datei enthalten sind. Bitte wähle aus, welche Datenschlüssel der Kunde in seiner Ansicht sehen darf.
            Wenn du einen Schlüssel nicht auswählst, wird dieser in der Ansicht des Kunden nicht angezeigt. Und der Kunde kann auch nicht nach diesem Schlüssel filtern.

            Sofern garkeine Auswahl getroffen wird, kann der Kunde nchts sehen.
            </p>
            </div>
            <div
            style={{
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                padding: 20,
            }}
            >
                <Transfer
                    dataSource={keysFromDB}
                    titles={['Unsichtbar', 'Sichtbar']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={handleChange}
                    onSelectChange={handleSelectChange}
                    onScroll={handleScroll}
                    render={item => item.title}
                    disabled
                />
            </div>


            <Button type="primary" onClick={() => {logOutUser()}} disabled>Übernehmen</Button>


        </div>
    );
}

export default CustomerDashboardPage;