import { Button, DatePicker, Table, Typography } from "antd";
import React from "react";
import { v4_setAPI } from "../../../routing/api";
import locale from '../../../locale/datePickerLocale.json'
import dayjs from "dayjs";
import _ from "lodash";
import { SingleDetailModal } from "../../modals/accounting/SingleDetail.modal";
import { TitleImage } from "../project/PublicProjectPage";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../../axios/axiosInstance";

const { useState, useEffect } = React;
const { Title, Text } = Typography;
const API_URL = v4_setAPI();

export function PersonalCostsPage() {
    const [privileged, setPrivileged] = useState(false);
    const currentUser = useSelector((state) => state.userState.firebaseUser);
    const [currentUserRole, setCurrentUserRole] = useState(currentUser?.role || "user");
    const privilegedRoles = ["manager", "projectLead", "hr", "project-assistance"];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });
    const [from, setFrom] = useState(dayjs().subtract(12, 'month').toISOString());
    const [to, setTo] = useState(dayjs().toISOString());

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/accounting/costs/database/personal`, {
                params: {
                    page: tableParams.pagination.current,
                    limit: tableParams.pagination.pageSize,
                    from: from,
                    to: to,
                },
            });
            setData(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.totalCount, // Annahme, dass der Server die Gesamtzahl der Einträge zurückgibt
                },
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams), from, to]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: sorter.order,
            sortField: sorter.field,
        });

        if (pagination.pageSize !== tableParams.pagination.pageSize) {
            setData([]);
        }
    };

    useEffect(() => {
        const initSite = () => {
            setCurrentUserRole(currentUser?.role || "user");
            console.log('includes', _.includes(privilegedRoles, currentUserRole));
            setPrivileged(_.includes(privilegedRoles, currentUserRole));
        };
        initSite();
        // eslint-disable-next-line
    }, []);

    async function handleDeleteSelected() {
        setLoading(true);
        try {
            const response = await axiosInstance.delete(`${API_URL}/accounting/costs/database/personal`, {
                data: {
                    ids: selectedRowKeys,
                },
            });
            console.log('Löschen erfolgreich:', response);
            setSelectedRowKeys([]);
            fetchData();
        } catch (error) {
            console.error('Fehler beim Löschen:', error);
        } finally {
            setLoading(false);
        };
    };

    React.useEffect(() => {
        //Fensternamen setzen
        document.title = `Personalkosten Übersicht (Gebucht)`;
        return () => {
          document.title = "Assembly";
        };
      }, []);

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <TitleImage overwrite={true} children={<>
                    <div>
                        <Title level={2} style={{ color: 'white' }}>
                            Personalkosten Übersicht (Gebucht)
                        </Title>
                    </div>

                    <div>
                        <DatePicker.RangePicker
                            onChange={(dates) => {
                                if (_.isArray(dates) && dates.length === 2) {
                                    setFrom(dayjs(dates[0]).toISOString());
                                    setTo(dayjs(dates[1]).toISOString());
                                }
                            }}
                            locale={locale}
                            style={{ marginBottom: '20px' }}
                        />
                    </div>
                </>}
                />
            </div>

            <div>
                {hasSelected && <div style={{
                    marginBottom: '10px',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}>
                    <Button type="primary" danger onClick={() => handleDeleteSelected()}>Ausgewählte Einträge löschen</Button>
                </div>}
                <Table
                    rowKey={(record) => record._id}
                    dataSource={data}
                    rowSelection={rowSelection}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    footer={() => (
                        <div>
                            Einträge ausgewählt: {selectedRowKeys.length} von {data.length}
                        </div>
                    )}
                    size="small"
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: '_id',
                            key: '_id',
                        },
                        {
                            title: 'Datum',
                            dataIndex: 'costDate',
                            key: '_id',
                            render: (o) => <Text>{new Date(o).toLocaleDateString()}</Text>,
                        },
                        {
                            title: 'Typ',
                            dataIndex: 'type',
                            key: '_id',
                        },
                        {
                            title: 'Details',
                            dataIndex: 'details',
                            key: '_id',
                            render: (o) => <SingleDetailModal><Text>{o}</Text></SingleDetailModal>,
                        },
                        {
                            title: 'Berechnungsdetails',
                            dataIndex: 'calcDetails',
                            key: '_id',
                            render: (o) => <SingleDetailModal><Text>{o}</Text></SingleDetailModal>,
                        },
                        {
                            title: 'Kostensatz',
                            dataIndex: 'splittedValue',
                            key: '_id',
                            render: (o) => {
                                if (privileged) {
                                    return <Text>{_.isNaN(parseFloat(o)) ? null : <>€ {parseFloat(o).toLocaleString()}</>}</Text>
                                } else {
                                    return <Text>€ ***,**</Text>
                                }
                            },
                        },
                        {
                            title: 'Teiler',
                            dataIndex: 'divider',
                            key: '_id',
                            render: (o) => <Text>{_.isNaN(parseFloat(o)) ? null : <>{parseFloat(o).toLocaleString()} Tage</>}</Text>,
                        },
                        {
                            title: 'Projekt',
                            dataIndex: 'project',
                            key: '_id',
                            render: (o) => <Text>{_.isObject(o) ? o?.title : o}</Text>,
                        },
                        {
                            title: 'User',
                            dataIndex: 'user',
                            key: '_id',
                            render: (o) => <Text>{_.isObject(o) ? o?.firstName + " " + o?.lastName : o}</Text>,
                        },
                        {
                            title: '',
                            dataIndex: '_id',
                            key: '_id',
                            render: (o) => <Button type="text" danger size="small">Entfernen</Button>,
                        },
                    ]}
                />
            </div>
        </div>
    );
};