// messageService.js
/**
 * @param {string} description
 * @description This function is used to display a message.
 */
import { message } from 'antd';

export const displayErrorNotification = (description) => {
  message.error({
    content: description,
    getContainer: () => document.getElementById('rootContainer'),
  });
}; 

export const displayInfoNotification = (description) => {
  message.info({
    content: description,
    getContainer: () => document.getElementById('rootContainer'),
  });
};

export const displayWarningNotification = (description) => {
  message.warning({
    content: description,
    getContainer: () => document.getElementById('rootContainer'),
  });
};

export const displaySuccessNotification = (description) => {
  message.success({
    content: description,
    getContainer: () => document.getElementById('rootContainer'),
  });
};