import { getDownloadURL, getMetadata, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import { displayErrorMessage } from "../utils/messageServices/messageService";
import resizeImage from "../utils/helpers/resizeImage";



export async function  fs_uploadArticleImage(article, file) {
    try {
        if (!file) {
            throw new Error("No file provided for upload");
        };

        if (!article || !article.project || !article.uniqueArticleNumber) {
            throw new Error("Invalid article data");
        };

        //Abort if fileSize is greater than 0.3MB (300KB)
        if (file.size > 300 * 1024) {
            console.debug("File size exceeds 300KB, resizing... from size:", Number(file.size/1024).toLocaleString(), " kilobytes");
            try {
                const resizedImg = await resizeImage(file, 800, 600);
                file = resizedImg;
                console.debug("Image resized successfully, new file size:", Number(file.size/1024).toLocaleString(), " kilobytes");
            } catch (error) {
                console.error("Error resizing image:", error);
                displayErrorMessage("Error resizing image", error.message);
                throw Error("Image resizing failed");
            };
        };

        const storageRef = ref(storage, `articles/${article.project}/${article.uniqueArticleNumber}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        console.error("Error uploading image:", error);
        return null;
    };
};

export async function fs_checkIfArticleImageExists(article) {
    try {
        if (!article || !article.project || !article.uniqueArticleNumber) {
            throw new Error("Invalid article data");
        };

        const fileRef = ref(storage, `articles/${article.project}/${article.uniqueArticleNumber}`);

        // Attempt to get the Metadata
        await getMetadata(fileRef)
        .then(() => {
            // If we reach this point, the file exists
            return true;
        }) //Else catch error and try once more
        .catch(async (error) => {
            let errTrys = 0;
            console.error("Error checking if article image exists:", error);
            // If the error is a 'not found' error, the file does not exist
            if (error.code === 'storage/object-not-found') {
                try {
                    // Retry once
                    errTrys++;
                    await getMetadata(fileRef);
                    return true;
                } catch (retryError) {
                    console.error("Retry failed:", retryError);
                    return false;
                }
            };
            return false;
        });
        return true;
    } catch (error) {
        console.error("Error checking if article image exists:", error);
        return false;
    };
};

/**
 * Checks if the article image exists in the Firebase storage and returns its download URL.
 * @param {Object} article - The article object.
 * @param {string} article.project - The project of the article.
 * @param {string} article.uniqueArticleNumber - The unique article number.
 * @returns {Promise<string|null>} - The download URL of the article image, or null if it doesn't exist.
 */
export async function fs_checkAndGetArticleImage(article) {
    try {

        if (!article || !article.project || !article.uniqueArticleNumber) {
            throw new Error("Invalid article data");
        };

        const exists = await fs_checkIfArticleImageExists(article);
        
        if (!exists) {
            return null;
        };

        const storageRef = ref(storage, `articles/${article.project}/${article.uniqueArticleNumber}`);
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        console.error("Error fetching article image:", error);
        return "https://placehold.co/600x400?text=Kein+Bild+verfügbar";
    }
}

