//Settings Button für die Projektseite, diese Button soll ein Modal öffnen wo Projekteinstellungen bearbeitet werden können
import { Button } from "antd";
import { DashboardOutlined } from "@ant-design/icons";
import React, { useState } from "react"
// import { useDispatch } from "react-redux";
import { EmbeddedReportsModal } from "../modals/EmbeddedReportsModal";

function EmbeddedReportsButton(props) {

    // const dispatch = useDispatch();

    // const [api, contextHolder] = notification.useNotification();
    const [open, setOpen] = useState(false);
    // const [loading, setLoading] = useState(false);

    // const openNotification = (type, message, description) => {
    //     api[type]({
    //         message: message,
    //         description: description,
    //         placement: "topLeft",
    //         duration: 10,
    //     });
    // };

    const buttonTask = async () => {
        setOpen(true);
    };

    return (
        <>
        {/* {contextHolder} */}
        <div style={{
            // float: "right",
        }}>
            <Button type="primary" icon={<DashboardOutlined />} onClick={buttonTask}>
                Einstellungen
            </Button>
        </div>
        <EmbeddedReportsModal open={open} project={props.project} onCancel={() => { setOpen(false)}} />
        </>
    );
};

export { EmbeddedReportsButton };