// messageService.js
/**
 * @param {string} message
 * @param {string} description
 * @description This function is used to display a message.
 */
import { notification } from 'antd';

export const displayErrorMessage = (message, description) => {
  notification.error({
    message: message,
    description: description,
    getContainer: () => document.getElementById('rootContainer'),
  }); 
};

export const displayInfoMessage = (message, description) => {
  notification.info({
    message: message,
    description: description,
    getContainer: () => document.getElementById('rootContainer'),
  });
};

export const displayWarningMessage = (message, description) => {
  notification.warning({
    message: message,
    description: description,
    getContainer: () => document.getElementById('rootContainer'),
  });
};

export const displaySuccessMessage = (message, description) => {
  notification.success({
    message: message,
    description: description,
    getContainer: () => document.getElementById('rootContainer'),
  });
};