import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { user_fetchFirUsers, user_fetchSingleProfile } from "../../../../routing/v3_Routes/v3_Routes";
import AddUserDialog from "../../../global/dialogs/AddUserDialog";
import { Box, Container, Stack } from "@mui/system";
import { Typography } from "@mui/material";
import { Button, Space, Table, Tooltip } from "antd";
import { CloudSyncOutlined, PersonAddAlt1Outlined } from "@mui/icons-material";
import { UserSettingsButton } from "../../../Buttons/UserSettingsButton";
import { AddUserModal } from "../../../modals/AddUserModal";
import { useUpdate } from "ahooks";



function UserManPage() {

    const session = useSelector((state) => state.mainState.autoSession);
    const update = useUpdate();
    // const userData = _.has(session, "users") ? session.users : [];
    // const currentUser = useSelector((state) => state.userState.firebaseUser)
    const [users, setUsers] = useState([]);
    const [dialog, setDialog] = useState(false);

    const columns = [
        {
            title: 'Vorname',
            dataIndex: 'firstName',
            key: '_id',
        },
        {
            title: 'Nachname',
            dataIndex: 'lastName',
            key: '_id',
        },
        {
            title: 'E-Mail',
            dataIndex: 'email',
            key: '_id',
        },
        {
            title: 'TimeBoard Id',
            dataIndex: 'timeBoardId',
            key: '_id'  
        },
        {
            title: "Aktionen",
            dataIndex: "actions",
            key: "_id",
            render: (text, record) => (
                <div>
                    <UserSettingsButton user={record} fetchData={fetchTeam} />
                </div>
            ),
        }
    ];

    const fetchTeam = async () => {
        await fetchFirebaseUsers();
    };

    const fetchFirebaseUsers = async () => {
        await user_fetchFirUsers();
        setUsers(session.firebaseUsers);
        update();
    };

    useEffect(() => {
        let counter = 0;
        const fetchUsers = async () => {
            if (counter === 0) {
                counter++;
                await fetchFirebaseUsers();
                
            } else {
                console.log("Counter is not 0");
            }
        }
        
        fetchUsers();
        counter++;
        
    }, []);

    return (
        <>
            {/* <HeaderWithBackButton backLink="/manage" /> */}
            <div>
                <Container>
                    <Box sx={{ p: 3 }}>
                        <Stack direction="row" alignItems="center">
                            <img
                                src="../../assembly-logo.png"
                                alt="Project_Logo_Image"
                                width="64px"
                                style={{ resize: 'both' }}
                                className="ButtonIcons"
                            />
                            <Stack sx={{ flexGrow: 1 }}>
                                <Typography variant="h4" sx={{ paddingLeft: 2 }}>
                                    Manage Team
                                </Typography>
                                <Typography variant="caption" sx={{ paddingLeft: 2 }}>
                                    Hier hast Du die Möglichkeit, die Nutzer*innen anzupassen.
                                </Typography>
                            </Stack>
                            <Stack sx={{ justifyContent: 'flex-end' }}>
                                <Box sx={{
                                    padding: 1
                                }}>
                                    <Space>
                                        <Tooltip title="Team laden">
                                            <Button onClick={fetchFirebaseUsers} type="text">
                                                <CloudSyncOutlined />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Teammitglied hinzufügen">
                                            <Button onClick={() => { setDialog(true) }} type="text">
                                                <PersonAddAlt1Outlined />
                                            </Button>
                                        </Tooltip>
                                    </Space>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Container>
                <div>
                    <Table
                        dataSource={users}
                        columns={columns}
                    />
                </div>
            </div>
            <div>
                <AddUserModal DetailedUserTypes={false} OpenDialog={dialog} setOpen={() => { setDialog(false) }} />
            </div>
        </>
    );
};

export { UserManPage };