import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Avatar, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Snackbar, TextField, Tooltip } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { HeaderWithBackButton } from "../../global/HeaderBackButton";
import _, { create } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { updateSchema } from "../../../routing/writeData";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { createCustomField, getCustomFields, getProject, initProjectSchema } from "../../../routing/crudActions";
import { updateSelectedproject } from "../../../redux/projectSlicer";
import { EmptyDataPaper } from "../../global/EmptyDataPaper";
import { BaseFields } from "../../../data/baseData";
import { converColumsForSchema, trimAndReplace } from "../../../utils/cleanArray";

//Import Icons
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { Button, Drawer, Form, Input, Space, Typography, Select, Alert, Checkbox, Table } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

function V3ProjectDbSchemaPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dataParam = searchParams.get("data");
    const project = useSelector((state) => state.projectState.selectedProject);
    const formRef = React.useRef();

    let data = {};
    if (dataParam) {
        try {
            const decodedData = decodeURIComponent(dataParam);
            data = JSON.parse(decodedData);
        } catch (error) {
            console.error("Error parsing data parameter", error);
        }
    }

    const [edit, setEdit] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showAddFieldDialog, setShowAddFieldDialog] = useState(false);
    const [showCalculationDetails, setShowCalculationDetails] = useState(false);

    const handleEditable = () => {
        setEdit(true);
        setEditDialog(false);
        setShowSnackbar(true);
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'fieldName', headerName: 'Feldname', width: 200, editable: edit },
        { field: 'fieldType', headerName: 'Feld Typ', width: 150, editable: edit },
        { field: 'fieldRequired', headerName: 'Erforderlich', width: 130, editable: edit },
        { field: 'fieldDefaultValue', headerName: 'Standardwert', width: 200, editable: edit },
        { field: 'fieldValidation', headerName: 'Validierung', width: 250, editable: edit },
    ];

    const antdColumns = [
        { dataIndex: 'id', title: 'ID' },
        { dataIndex: 'fieldName', title: 'Feldname', width: 200 },
        { dataIndex: 'fieldType', title: 'Feld Typ' },
        { dataIndex: 'systemField', title: 'Systemfeld'},
        { dataIndex: 'fieldRequired', title: 'Erforderlich', render: (text) => {
            return text ? "Ja" : "Nein";
        }
         },
        { dataIndex: 'fieldDefaultValue', title: 'Standardwert' },
        { dataIndex: 'fieldValidation', title: 'Validierung' },
    ];

    const [_fieldValidation, _setFieldValidation] = useState("");

    useEffect(() => {
        if (showCalculationDetails) {
            formRef.current.setFieldsValue({
                fieldType: 'Number',
                fieldRequired: 'false',
                systemField: 'number',
                fieldDefaultValue: '',
                fieldValidation: '',
            });
        };

    }, [showCalculationDetails]);

    const syncSchema = () => {
        updateSchema(project._Id, project.projectId, project)
            .then(result => {
                console.log(result)
            })
            .catch(err => {
                console.log(err)
            });
    };

    const fetchProject = () => {
        getProject(data.mongoId)
            .then((result) => {
                dispatch(updateSelectedproject(result.data))
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(updateFetchingData(false));
            });
    };

    const initSchema = () => {
        dispatch(updateFetchingData(true));
        initProjectSchema(data.mongoId, data.projectId)
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                fetchProject();
            });
    };

    const [fields, setFields] = useState([]);

    const initFields = () => {
        dispatch(updateFetchingData(true));
        getCustomFields(data.mongoId)
            .then((result) => {
                const newArray = _.sortBy(_.remove(_.concat(BaseFields, converColumsForSchema(result.data)), function (n) {
                    return !_.isEmpty(n);
                }), (item) => item.id);
                setFields(newArray);
                console.log(result.data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                console.log(fields)
            });
    };

    const createCalculationOptions = (options) => {
        const viewOptions = {
            calculate: true,
            viewOnly: true,
            viewOnlyAdmin: false,
            showInEntryMask: false,
            showOnSideBar: true
        };
        return {
            viewOptions: viewOptions,
            calculationOptions: options[0]
        };
    };

    const handleFieldSubmit = () => {
        
        const values = formRef.current.getFieldsValue();
        const selectValues = trimAndReplace(values.fieldValidation, values.systemField);
        values.fieldValidation = selectValues;
        values.calculatedField = showCalculationDetails;
        showCalculationDetails && _.set(values, 'calculationOptions', createCalculationOptions(values.calculationOptions).calculationOptions);
        showCalculationDetails && _.set(values, 'viewOptions', createCalculationOptions(values.calculationOptions).viewOptions);
        _.set(values, 'fieldNameId', _.snakeCase(values.fieldName));
        _.set(values, 'fieldRequired', values.fieldRequired === 'true' ? true : false);
        _.set(values, 'projectId', project.projectId);
        _.set(values, 'projectIdName', project.title);
        _.set(values, 'project_id', project._id);
        _.set(values, 'editable', showCalculationDetails ? false : true);
        console.log("Values: ", values);

        createCustomField(project._id, values)
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                initFields();
                setShowAddFieldDialog(false);
            })
    }

    useEffect(() => {
        dispatch(updateFetchingData(true));
        fetchProject();
        initFields();

    }, []);

    const calculatableFields = _.filter(fields, (item) => {
        return item.systemField === 'number' || item.systemField === 'decimal';
    });


    return (
        <>
            <HeaderWithBackButton backLink="/manage/projects" />
            <Container maxWidth="lg" sx={{ mt: 5 }}>
                <div>
                    <Box sx={{ m: 1, width: "100%" }}>
                        <Box sx={{
                            padding: 3,
                        }}>

                            <Stack direction="row">
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        p: 3,
                                        borderRadius: '50px',
                                        objectFit: 'contain'
                                    }}>
                                    <img
                                        src={project.imageUrl}
                                        alt="Project_Logo_Image"
                                        width="100px"
                                        style={{ resize: 'both', backgroundColor: 'white', objectFit: 'contain' }}
                                        className="ButtonIcons"
                                    />
                                </Box>
                                <Stack>
                                    <Typography style={{ paddingLeft: 2 }}>
                                        <Title>{project.title}</Title>
                                        <Paragraph>
                                            {/* Hier kurz info oder ähnliches */}
                                        </Paragraph>
                                    </Typography>
                                </Stack>
                            </Stack>

                        </Box>

                        <div>
                            <Space>
                                <IconButton onClick={() => { setShowAddFieldDialog(true) }}>
                                    <ControlPointDuplicateRoundedIcon />
                                </IconButton>
                                <IconButton sx={{ color: "red" }} onClick={syncSchema}>
                                    <CachedRoundedIcon />
                                </IconButton>
                            </Space>
                        </div>

                        <Box sx={{
                            padding: 3, mt: 3
                        }}>

                            <Grid2 lg={12} md={12} sm={6} xs={4}>

                                {!_.isEmpty(project.customSchema) &&
                                    <div style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}>
                                    <Table
                                            columns={antdColumns}
                                            dataSource={fields}
                                            pagination={false}
                                            scroll={{ y: 500, x: 1000 }}
                                        />
                                    </div>
                                        
                                }
                            </Grid2>


                            <Grid2 lg={12} md={12} sm={6} xs={4}>
                                <Box sx={{ mt: 2 }}>

                                    {edit && <Button type="primary" onClick={() => { setEdit(false) }}>Speichern</Button>}

                                </Box>
                            </Grid2>

                        </Box>

                        {_.isEmpty(project.customSchema) &&
                            <Box sx={{ mt: 3 }}>
                                <EmptyDataPaper>
                                    <Button variant="outlined" onClick={initSchema}>Neues schema initialisieren</Button>
                                </EmptyDataPaper>
                            </Box>
                        }

                    </Box>
                </div>


                <div>
                    <Dialog open={editDialog} onClose={() => { setEditDialog(false) }}>
                        <DialogTitle>
                            Warnung!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Das ausführen dieser Aktion sorgt dafür, dass das Schema bearbeitet werden kann. Wenn das Schema nicht richtig angepasst wird, kann es zu einem erheblichen Datenverlust kommen. Bist Du sicher, dass diese Aktion ausgeführt werden soll?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditable} color="error">Weiter</Button>
                            <Button onClick={() => { setEditDialog(false) }} autoFocus>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Drawer
                        title="Benutzerdefiniertes Feld erstellen"
                        placement="right"
                        closable={false}
                        onClose={() => { setShowAddFieldDialog(false) }}
                        open={showAddFieldDialog}
                        key="right"
                        width={"80%"}
                        footer={
                            <div>
                                <Space>
                                    <Button onClick={() => { setShowAddFieldDialog(false) }} style={{ marginRight: 8 }}>
                                        Abbrechen
                                    </Button>
                                    <Button onClick={handleFieldSubmit} type="primary">
                                        Erstellen
                                    </Button>
                                </Space>
                            </div>
                        }
                    >
                        <div style={{
                            padding: 10,
                            marginBottom: 20,
                        }}>
                            <Alert
                                message="Achtung!"
                                description={
                                    <Text type="secondary">
                                        Erstelle ein neues Feld für das Projekt.
                                        <br />
                                        Das beim erstellen der Felder gibt es einige Dinge zu beachten:
                                        <br />
                                        <ul>
                                            <li>String = Textfeld (es können belibige werte eingegben werden)</li>
                                            <li>Number = Nummberfeld (es können nur bestimmte Zahlenwerte eingegeben werden. Ganzzahlen oder Dezimalzahlen)</li>
                                            <li>Date = Datumsfeld (es erscheint ein feld für die Datumsauswahl)</li>
                                            <li>Select = Textfeld mit vordefinierter auswahl (Siehe Textfeld. Vordefinierte auswahl.)</li>
                                            <li>Boolean = Ein ja oder nein Feld. Der Standardwert wird immer "false/falsch/nein" sein.</li>
                                        </ul>
                                        <Text type="secondary"> Sofern Du nicht weisst welchen Datentyp du möchtest, wähle immer "string".</Text>
                                        <br />
                                        <Text type="secondary">Bei der Auswahl select - müssen die auswählbaren werte durch ein Semikolon (;) getrennt eingegben werden.</Text>
                                    </Text>
                                }
                                type="warning"
                                showIcon
                            />

                            <Alert
                                message="Berechnungen"
                                description="Alle weiteren detailreichen Berechnungen werden in Power BI durchgeführt. Die Datenbank ist nur für die Datenspeicherung zuständig."
                                type="info"
                                showIcon
                                style={{ marginTop: 10 }}
                            />

                        </div>

                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            name="customField"
                            ref={formRef}
                        >
                            <Form.Item
                                label="Feldname"
                                name="fieldName"
                                required
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Feldtyp"
                                name="fieldType"
                                tooltip="Der Feldtyp bestimmt, welcher Datentyp in der Datenbank gespeichert werden kann. Ein String kann alle werte speichern, allerdings sind einfache Berechnugnen nicht möglich. Ein Number kann nur Zahlen speichern. Ein Date kann nur Datumsangaben speichern. Ein Boolean kann nur Ja oder Nein speichern."
                                required
                            >
                                <Select
                                    defaultValue="string"
                                >
                                    {/* 'String', 'Number', 'Boolean', 'Date' */}
                                    <Select.Option disabled={showCalculationDetails} value="String">Textwert</Select.Option>
                                    <Select.Option value="Number">Nummer (auch Double, Int, Float)</Select.Option>
                                    <Select.Option disabled={showCalculationDetails} value="Date">Datumsauswahl</Select.Option>
                                    <Select.Option disabled={showCalculationDetails} value="Boolean">Ja/Nein Feld</Select.Option>
                                </Select>

                            </Form.Item>

                            <Form.Item
                                label="Systemfeld"
                                name="systemField"
                                tooltip="Ein Systemfeld ist ein Feld, welches automatisch von der Anwendung erstellt wird. Es kann nicht gelöscht werden. Diese Einstellung entscheidet, welches Feld in der Anwendung angezeigt wird."
                                required
                            >
                                <Select
                                    defaultValue={showCalculationDetails ? 'number' : 'text'}
                                >
                                    {/* 'String', 'Number', 'Boolean', 'Date' */}
                                    <Select.Option disabled={showCalculationDetails} value="text">Textfeld</Select.Option>
                                    <Select.Option disabled={showCalculationDetails} value="text-lg">Großes Textfeld</Select.Option>
                                    <Select.Option disabled={showCalculationDetails} value="select">Auswahlfeld</Select.Option>
                                    <Select.Option disabled={showCalculationDetails} value="date">Datumsfeld</Select.Option>
                                    <Select.Option value="number">Nummernfeld (nur Ganzzahlen)</Select.Option>
                                    <Select.Option value="decimal">Nummernfeld (inkl. Dezimalzahlen bis 15 Stellen nach Komma)</Select.Option>
                                    <Select.Option disabled={showCalculationDetails} value="boolean">Ja/Nein Feld</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Berechneter Wert"
                                name="calcualtedValue"
                                tooltip="Sofern eine Berechnung mit einem anderem Feld stattfinden soll, bitte dies auswählen."
                                initialValue={false}
                            >
                                <Checkbox value={showCalculationDetails} defaultChecked={false} onChange={
                                    (e) => {
                                        setShowCalculationDetails(e.target.checked);
                                    }
                                } />
                            </Form.Item>

                            {showCalculationDetails && <Form.Item name="useAsResult" label="Als Ergebnis verwenden" tooltip="Wenn dieses Feld als Ergebnis verwendet werden soll, muss es hier ausgewählt werden. Dann wird bei jeder Dateingabe der Berechnete Wert verwendet. Das ist optional und darf nur einmal vorkommen!">
                                <Checkbox />
                                </Form.Item>}

                            {showCalculationDetails && <Form.List name="calculationOptions">
                                {(fs, { add, remove }) => (
                                    <>
                                        {fs.map(({ key, name, ...restField }) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8,
                                                }}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'fieldId']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing first name',
                                                        },
                                                    ]}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Select placeholder="Select field" style={{
                                                        width: '250px'

                                                    }}>
                                                        {calculatableFields.map((field, index) => (
                                                            <Select.Option value={field.id} key={field.id}>{field.fieldName}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'calculationOperator']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing Calculation Operator',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select Operator" style={{
                                                        width: '250px'

                                                    }}>
                                                        <Select.Option value="add">Addition</Select.Option>
                                                        <Select.Option value="subtract">Subtraktion</Select.Option>
                                                        <Select.Option value="multiply">Multiplikation</Select.Option>
                                                        <Select.Option value="divide">Division</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'targetField']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing target field',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select field" style={{
                                                        width: '250px'

                                                    }}>
                                                        {calculatableFields.map((field, index) => (
                                                            <Select.Option value={field.id} key={field.id}>{field.fieldName}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'fieldVisibility']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing field visibility',
                                                        },
                                                    ]}
                                                    initialValue={'visible'}
                                                >
                                                    <Select placeholder="Wähle einen ansichtstyp" style={{
                                                        width: '250px'

                                                    }}>
                                                        <Select.Option value="visible">Sichtbar</Select.Option>
                                                        <Select.Option value="hidden">Unsichtbar</Select.Option>
                                                    </Select>
                                                </Form.Item>

                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}
                                            disabled={fs.length >= 1}
                                            >
                                                Feld hinzufügen
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>}


                            <Form.Item
                                label="Standardwert"
                                name="fieldDefaultValue"
                                tooltip="Der Standardwert ist der Wert, welcher automatisch in das Feld eingetragen wird, wenn ein neuer Datensatz erstellt wird. Und kein anderer Wert im Eingabeformular eingetragen wird."
                            >
                                <Input disabled={showCalculationDetails} />
                            </Form.Item>

                            <Form.Item
                                label="Validierung"
                                name="fieldValidation"
                                tooltip="Die Validierung ist eine Formel, welche überprüft, ob der eingegebene Wert korrekt ist. Wenn die Validierung nicht korrekt ist, kann der Datensatz nicht gespeichert werden."
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Erforderlich"
                                name="fieldRequired"
                                tooltip="Wenn das Feld erforderlich ist, muss ein Wert eingetragen werden, bevor der Datensatz gespeichert werden kann."
                            >
                                <Select
                                    defaultValue="false"
                                    disabled={showCalculationDetails}
                                >
                                    {/* true, false */}
                                    <Select.Option value="true">Ja</Select.Option>
                                    <Select.Option value="false">Nein</Select.Option>
                                </Select>
                            </Form.Item>

                        </Form>

                    </Drawer>
                </div>
            </Container>
        </>

    );
};

export default V3ProjectDbSchemaPage;