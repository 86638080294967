//eslint-disable-next-line
import { HistoryEduRounded, InfoOutlined, MoreTimeRounded, PlaylistAddCheckCircle } from "@mui/icons-material";
//eslint-disable-next-line
import { Chip, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Stack } from "@mui/system";
import React from "react";

export function StyledContainerPaper({ children }, props) {

    return (
        <Container sx={{
            marginTop: 5,
        }}>
            
                <Box
                sx={{ minHeight: "120px", padding: 3,  }}
                >
                    <Grid2 container>
                        <Grid2 lg={12} md={12} sm={6} xs={4}>
                        {children}
                        </Grid2>
                    </Grid2>
                </Box>
            
        </Container>
    );
};

export function StyledContainer({ children }) {
    return (
        <Container sx={{
            marginTop: 0,
        }}>
            <Box sx={{
                padding: 3,
            }}>
                {children}
            </Box>
        </Container>
    );
};

export function StyledHeaderTitle(props) {
    return (
        <Box>
            <Typography variant="h4" sx={{
                paddingLeft: 2
            }}>
                {props.Title}
            </Typography>
        </Box>
    );
};

export function StyledImageHeader({ children }, props) {
    return (

        <Stack direction="row" alignItems="center">
            <img
                src="../assembly-logo.png"
                alt="Project_Logo_Image"
                width="64px"
                style={{ resize: 'both' }}
                className="ButtonIcons"
            />
            <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="h4" sx={{ paddingLeft: 2 }}>
                    {props.HeaderTilte}
                </Typography>
                <Typography variant="caption" sx={{ paddingLeft: 2 }}>
                    {props.Subheadline}
                </Typography>
            </Stack>
            <Stack sx={{ justifyContent: 'flex-end' }}>
                <Box sx={{
                    padding: 1
                }}>
                    <Stack direction={"row"}>
                        {props.Actions}
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );
};

export function StyledImageH(props) {
    return (

        <Stack direction="row" alignItems="center">
            <img
                src="./assembly-logo.png"
                alt="Project_Logo_Image"
                width="64px"
                style={{ resize: 'both' }}
                className="ButtonIcons"
            />
            <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="h4" sx={{ paddingLeft: 2 }}>
                    {props.HeaderTitle}
                </Typography>
                <Typography variant="caption" sx={{ paddingLeft: 2 }}>
                    {props.Subheadline}
                </Typography>
            </Stack>
            <Chip label={props.ChipLabel} color={props.Severity} />
        </Stack>
    );
};