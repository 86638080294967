import React from "react";
import { Typography, Card, Row, Col, List } from "antd";
import { InfoOutlined } from "@mui/icons-material";
const { Title, Text } = Typography;

function AiHubExplanationPage() {
    return (
        <div>
        <div>
        <Title level={2}>AI Hub</Title>
        </div>
            <div style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
        
        }}>
            <InfoOutlined style={{ fontSize: 24 }} />
            </div>
            <div>
                        <Text strong>What is AI Hub?</Text>
                        <Text>
                            AI Hub is a new functionality in Assembly that leverages Google
                            BigQuery and MongoDB to train AI models based on the data in the
                            tool. It enables predictions and generates text using the trained
                            models.
                            <br/>
                            <br/>
                            Coming later this year...
                        <br/>
                        <br/>
                            <strong>© 2024 Kevin Carter. All rights reserved.</strong>
                        </Text>
            </div>

            <div style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "50px"
            }}>
            <List
            //direction
            itemLayout="vertical"
            >
            <List.Item>
                    <Text strong>Powered by</Text>
                    <br/>
                    <Text>
                        <strong>Google BigQuery</strong> - <strong>Google Cloud Storage</strong> - <strong>Google Cloud Functions</strong> - <strong>Google Cloud AI Platform</strong> - <strong>Google Cloud Run</strong> - <strong>Google Cloud Build</strong> - <strong>Google Cloud Scheduler</strong> -  <strong>Google Cloud Firestore</strong>  - <strong>MongoDB Atlas</strong> - <strong>deveca.dev</strong> - <strong>Microsoft Azure</strong>
                  
                    </Text>
                </List.Item>
                <List.Item>
                    <Text strong>How does it work?</Text>
                    <Text>
                        AI Hub is a new functionality in Assembly that leverages Google
                        BigQuery and MongoDB to train AI models based on the data in the
                        tool. It enables predictions and generates text using the trained
                        models.
                        <br/>
                        <br/>
                        Coming later this year...
                    </Text>
                </List.Item>
                <List.Item>
                    <Text strong>How can I use it?</Text>
                    <Text>
                        AI Hub is a new functionality in Assembly that leverages Google
                        BigQuery and MongoDB to train AI models based on the data in the
                        tool. It enables predictions and generates text using the trained
                        models.
                        <br/>
                        <br/>
                        Coming later this year...
                    </Text>
                </List.Item>
                <List.Item>
                    <Text strong>What is the benefit?</Text>
                    <Text>
                        AI Hub is a new functionality in Assembly that leverages Google
                        BigQuery and MongoDB to train AI models based on the data in the
                        tool. It enables predictions and generates text using the trained
                        models.
                        <br/>
                        <br/>
                        Coming later this year...
                    </Text>
                </List.Item>
            </List>
            </div>

                        

        </div>
    );
}

export { AiHubExplanationPage };