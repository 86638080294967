import { Button, Result, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import TimeBoardUploadComponent from "../../../global/fileManagement/TimeboardUploaderComponent";
import { addListenerTo, unsubscribeFrom } from "../../../../listener/firebaseSnapshotListener";
import { storage } from "../../../../firebase";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { useUpdate } from "ahooks";
import { ReAuthButtonModal } from "../../../security/ReAuth.button.component";
import { useSelector } from "react-redux";
import _ from "lodash";

const privilegedRoles = ["manager", "hr", "administrator", "project-assistance"];

function TimeBoardImportPage() {

    const update = useUpdate();
    const [open, setOpen] = useState(false);
    const [docs, setDocs] = useState([]);

    const currentUser = useSelector((state) => state.userState.firebaseUser);
    const [currentUserRole, setCurrentUserRole] = useState(currentUser?.role || "user");


    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        document.title = "Assembly - Timeboard Import";
    
        return () => {
            document.title = "Assembly (hpm)";
        }
      }, []);

    const handleDownload = async (record) => {
        // Firebasde/Storage download
        const store = storage;
        console.log(record);
        const storeRef = ref(store, record._location.path);

        try {
            const url = await getDownloadURL(storeRef);

            // Fetch the file as a blob
            const response = await fetch(url);
            const blob = await response.blob();

            // Automatischer Download der Datei
            const a = document.createElement('a');
            const urlBlob = window.URL.createObjectURL(blob);
            a.href = urlBlob;
            a.download = record.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(urlBlob); // Clean up the URL.createObjectURL reference
        } catch (error) {
            // Handle any errors
            console.error('Error downloading file:', error);
        }
    };

    useEffect(() => {
        setCurrentUserRole(currentUser?.role || "user");

        const listener = addListenerTo('firebaseStorage', async (snapshot) => {
            console.log(snapshot);
            const store = storage;
            const listRef = ref(store, 'times/csv');
            const res = await listAll(listRef)
                .then((result) => {
                    return result;
                }).catch((err) => {
                    console.log(err);
                    return [];
                });
            if (res.items.length === 0) {
                return;
            }
            const docs = res.items
            setDocs(docs);
            //console.log(docs);
            update();
        });
        

        listener();

        return () => {
            unsubscribeFrom(listener);
        };
    }, []);

    useEffect(() => {
        const intialDocs = async () => {
            const store = storage;
            const listRef = ref(store, 'times/csv');
            const res = await listAll(listRef)
                .then((result) => {
                    return result;
                }).catch((err) => {
                    console.log(err);
                    return [];
                });
            if (res.items.length === 0) {
                return;
            }
            const docs = res.items.map((itemRef) => itemRef);
            setDocs(docs);
            update();
        };

            if (_.includes(privilegedRoles, currentUserRole)) {
                intialDocs();
            } else {
                setDocs([]);
            };

            return () => {
                // cleanup
            }

    }, [currentUserRole]);

    return (
        <div>
            <Typography.Title level={2}>Zeitimport für TimeBoard Zeiten</Typography.Title>

            <div style={{
                marginTop: '60px'
            }}>
                {_.includes(privilegedRoles, currentUserRole) ? <>
                    <div style={{
                        marginBottom: '1rem'
                    }}>
                        <Button onClick={() => setOpen(true)}>Zeitimport starten</Button>
                    </div>
                    <Table dataSource={docs} columns={[
                        {
                            title: 'Dateiname',
                            dataIndex: 'name',
                            key: 'name',
                            sortDirections: ['descend', 'ascend'],
                            sorter: (a, b) => a.name.localeCompare(b.name),
                            sortOrder: 'descend',
                            render: (text, record) => (
                                <div>
                                    <Typography.Text>{record.name}</Typography.Text>
                                </div>
                            )
                        },
                        {
                            title: 'Integrität',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text, record) => (
                                <div>
                                    Diese Datei kann nicht gelöscht werden. Aufbewahrung: 3 Jahre.
                                </div>
                            ),
                        },
                        {
                            title: 'Aktionen',
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (text, record) => (
                                <div>
                                    <ReAuthButtonModal text="Herunterladen" title="Herunterladen" OnSuccess={async () => {
                                        await handleDownload(record);
                                    }} />
                                </div>
                            ),
                        }
                    ]}
                        pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '15', '25', '50', '100'],
                            showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
                            showQuickJumper: true,
                        }}
                        loading={docs.length === 0}
                    />
                </> : <>
                    <Result
                        status="403"
                        title="Privacy-handler access denied - Status 403"
                        subTitle="You have no right to view, download or edit this sensitive information or data. Please contact your administrator for further information. | Sie haben keine Berechtigung, diese sensiblen Informationen oder Daten einzusehen, herunterzuladen oder zu bearbeiten. Bitte kontaktieren Sie Ihren Administrator für weitere Informationen."
                    />
                </>}

            </div>

            <TimeBoardUploadComponent open={open} onCancel={null} handleCancel={handleCancel} />
        </div>
    )
};

export default TimeBoardImportPage;