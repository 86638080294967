import { Box, Container, Stack } from "@mui/system";
import { useLocation } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import { BaseFields } from "../../../data/baseData";
import { useSelector, useDispatch } from "react-redux";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { updateCustomFields } from "../../../redux/projectSlicer";
import _ from "lodash";
import { addTaskField, convertColumns } from "../../../utils/cleanArray";
import { checkConnection } from "../../../routing/api";
import { Button, Typography, Descriptions, Tooltip } from "antd";
import { CSVImportAttentionDialog } from "../../global/dialogs/CSVImportAttentionDialog";
import NewFileUploaderComponent from "../../global/fileManagement/NewFileUploaderComponent";

const { Title, Paragraph } = Typography;


function CSVDataEntryPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dataParam = searchParams.get("viewData");
    const project = useSelector((state) => state.projectState.selectedProject);
    const user = useSelector((state) => state.userState.mongoUser)
    const customFields = useSelector((state) => state.projectState.customFields);
    const tasks = useSelector((state) => state.projectState.selectedProject.tasks);

    //eslint-disable-next-line
    const [headerData, setHeaderData] = useState({});
    const [available, setAvailability] = useState(false);
    const [csvDialog, setCsvDialog] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);

    let data = {};
    if (dataParam) {
        try {
            const decodedData = decodeURIComponent(dataParam);
            data = JSON.parse(decodedData);
        } catch (error) {
            console.error("Error parsing data parameter", error);
        }
    };

    const handleOpenUpload = () => {
        setOpenUploadDialog(true);
    }

    const checkAvailability = () => {
        checkConnection()
            .then((response) => {
                if (response.status === 202) {
                    setAvailability(true);
                } else {
                    setAvailability(false);
                }
            }).catch((err) => {
                setAvailability(false);
                console.log(err);
            });
    }

    const [formData, setFormData] = useState({});
    const [fields, setFields] = useState(BaseFields);

    const fetchFields = async (id) => {
        dispatch(updateFetchingData(true));
        dispatch(updateCustomFields(convertColumns(customFields, fields)));
        const newArray = _.sortBy(_.remove(_.concat(BaseFields, convertColumns(customFields, fields), addTaskField(tasks)), function (n) {
            return !_.isEmpty(n);
        }), (item) => item.id);
        setFields(newArray);
        dispatch(updateFetchingData(false));
    };


    const handleFormReset = () => {
        const fieldsToKeep = {
            'task': formData.hasOwnProperty('task') && formData['task'],
            'createdAt': formData.hasOwnProperty('createdAt') ? formData['createdAt'] : new Date().toISOString(),
            'createdBy': formData.hasOwnProperty('createdBy') ? formData['createdBy'] : user._id, //User
            'modifiedAt': formData.hasOwnProperty('modifiedAt') ? formData['modifiedAt'] : new Date().toISOString(),
            'modifiedBy': formData.hasOwnProperty('modifiedBy') ? formData['modifiedBy'] : user._id //User
        };
        setFormData(fieldsToKeep);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Hier kannst du den Zustand der Seite überwachen
            // und entsprechende Aktionen ausführen
            console.log('Seite wird verlassen oder neu geladen');
          };

          const handleUnload = (event) => {
            // Hier kannst du die spezifische Aktion auslösen,
            // die nur bei Aktualisierung der Seite ausgeführt werden soll
            console.log('Seite wird aktualisiert');
          };
      
          window.addEventListener('beforeunload', handleBeforeUnload);
          window.addEventListener('unload', handleUnload);

        dispatch(updateFetchingData(true));
        // Funktion zum Sammeln der Felder
        const initializeFormData = () => {
            const initFields = BaseFields //Hier müssen irgendwann mal die defnierten felder abgerufen werden mit einer async axios
            const initialValues = {};
            initFields.forEach(field => {
                initialValues[field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_')] = field.fieldDefaultValue;
            });
            setFormData(initialValues);
            console.log(initialValues);
        };
        fetchFields(project._id);
        addTaskField(tasks);
        setHeaderData(data);
        initializeFormData();
        handleFormReset();
        checkAvailability();
        dispatch(updateFetchingData(false));

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
          };
    }, []);

    useEffect(() => {
        const checkDataMode = () => {
            if (project.dataMode === "csv") {
                setCsvDialog(true);
            };
        };
        return checkDataMode();
    }, [project.dataMode]);

    //DOM Fehlermeldungen unterbinden
    useEffect(() => {
        // Hier beginnt der Bereich, in dem DOM-Fehlermeldungen unterbunden werden sollen
        if (process.env.NODE_ENV === 'production') {
          window.addEventListener('error', handleDOMError);
        }
    
        return () => {
          // Hier entfernen Sie den Event-Listener, um Speicherlecks zu verhindern
          window.removeEventListener('error', handleDOMError);
        };
      }, []);
    
      // Funktion, um DOM-Fehlermeldungen zu behandeln und zu unterbinden
      const handleDOMError = (event) => {
        event.preventDefault();
        event.stopPropagation();
      };

    let content = (
            <Container sx={{ mt: 5 }}>
            <div>
                <div>
                    <Stack direction="row" alignItems="center">
                        <Stack direction="row">
                            <Box
                                sx={{
                                    backgroundColor: 'white',
                                    p: 3,
                                    borderRadius: '50px',
                                    objectFit: 'contain'
                                }}>
                                <img
                                    src={project.imageUrl}
                                    alt="Project_Logo_Image"
                                    width="100px"
                                    style={{ resize: 'both', backgroundColor: 'white', objectFit: 'contain' }}
                                    className="ButtonIcons"
                                />
                            </Box>
                            <Stack>
                                <Typography style={{ paddingLeft: 2 }}>
                                    <Title>{project.title}</Title>
                                    <Paragraph>{project._id} - {project.__v}</Paragraph>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box sx={{ flex: 1 }} />
                        <Chip label={available ? "CSV Daten können hochgeladen werden" : "CSV steht nicht zur verfügung"} color={available ? "success" : "error"} />
                    </Stack>
                </div>
                <div>
                    <Descriptions bordered>
                        <Descriptions.Item label="Benötigter Dateityp">
                            <Typography>
                                <code>
                                    .csv
                                </code>
                            </Typography>
                        </Descriptions.Item>
                        <Descriptions.Item label="Ziel-Import">
                            <Typography>
                                <Paragraph>Direktimport</Paragraph>
                                <code>imports @ production cluster #{project._id} && (merge: false)</code>
                            </Typography>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
                <div style={{ marginTop: '30px' }}>
                    {available ?
                        (<>
                            <div style={{ marginTop: '30px' }}>
                                <Descriptions title="Upload" bordered>
                                    <Descriptions.Item label="CSV Upload">
                                        <Tooltip title="Der Upload startet nach Datei-Auswahl sofort">
                                            <Button type="primary" onClick={handleOpenUpload} loading={openUploadDialog}>
                                                Datei hochladen
                                            </Button>
                                        </Tooltip>
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </>) : (
                            <>
                                Der CSV Import steht zur zeit nicht zur verfügung!
                            </>
                        )}
                </div>

            </div>
            <CSVImportAttentionDialog OpenDialog={csvDialog} setOpen={() => { setCsvDialog(false) }} />
            <NewFileUploaderComponent ProjectId={project.projectId} Project={project} open={openUploadDialog} setOpen={() => { setOpenUploadDialog(false) }} />
        </Container>
        )

    return content;

};

export default CSVDataEntryPage;