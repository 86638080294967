import Cookies from 'js-cookie';
import { displayInfoMessage } from '../utils/messageServices/messageService';

const consentCookieName = 'cookieConsent';
const consentCookieExpirationDays = 7; // Ablaufzeit in Tagen

// Überprüfen, ob der Cookie bereits vorhanden ist



export function hasConsentChecker() {
    const hasConsent = Cookies.get(consentCookieName);
    if (hasConsent) {
        return true;
    } else {
        return false;
    }
};

export function saveConsent(accepted) {
    if (accepted) {
        const consentInfo = {
            value: 'yes',
            timestamp: new Date().toISOString(),
            
        };

        // Setzen des Cookies und Festlegen der Ablaufzeit
        Cookies.set(consentCookieName, JSON.stringify(consentInfo), {
            expires: consentCookieExpirationDays,
        });

        // Hier können Sie weitere Aktionen ausführen, die nach Einwilligung erfolgen sollen
        console.log('Cookie-Einwilligung erteilt!');
    }

    // Schließen des Cookie-Banners
    displayInfoMessage('Cookie-Einwilligung erteilt!', "Wir setzen nun einen Cookie, um Ihre Entscheidung für 7-Tage zu speichern. Oder bis Sie den Browser-Cache löschen.");

    return true;
}