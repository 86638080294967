import { Select, Button, Input, Form } from "antd";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createUser } from "../../routing/userRouting";


const globalSize = "large"; 

/**
 * 
 * @param {*} props 
 * @returns 
 * @TODO: AÜ Preis eingabemöglichkeit --> Bezahln nur gearbeitete oder geleistete Stunden //Keine LNK berechnung // Keine Fehltage, Überstunden, Urlaub oder ähnlich.
 */
function NewUserForm(props) {

    const projects = useSelector((state) => state.projectState.projects);
    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const creatorUser = useSelector((state) => state.userState.firebaseUser);
    const creatorId = useSelector((state) => state.userState.firId);
    const creatorRole = creatorUser?.role || "employee";
    const isAdmin = creatorRole === "administrator" || creatorRole === "management";

    const handleCancel = () => {
        formRef?.current?.resetFields();
        props.handleCancel();
      };

    const handleFinish = () => {
        props.handleFinish();
    };

    const userTypesAdmin = [
        { title: "Projektleiter*in", projectLead: true, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false, role: "projectLead", isHR: false },
        { title: "Projektbetreuuer*in", projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: true, role: "consultant", isHR: false },
        { title: "Projektmitarbeiter*in", projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false, role: "employee", isHR: false },
        { title: "Administrator", projectLead: false, isManager: false, isAdmin: true, allProjectsVisible: true, isConsultant: false, isHR: false, role: "administrator" },
        { title: "Betrachter*in", projectLead: false, isManager: true, isAdmin: false, allProjectsVisible: true, isConsultant: false, isHR: false, role: "viewer" },
        { title: "Human Resources", projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: true, isConsultant: false, isHR: true, role: "hr" },
        { title: "Buchhaltung", projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: true, isConsultant: false, isHR: false, role: "accounting"},
        { title: "Management", projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: true, isConsultant: false, isHR: false, role: "management"}
    ]
    const userTypesStandard = [
        { title: "Projektleiter*in", projectLead: true, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false, role: "projectLead", isHR: false },
        { title: "Projektbetreuuer*in", projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: true, role: "consultant", isHR: false },
        { title: "Projektmitarbeiter*in", projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false, role: "employee", isHR: false },
    ]

    const onFinish = (values) => {
        console.log(values);
        processUserCreation(values);
    };


    const processUserCreation = async (values) => {
        setIsLoading(true);
        console.log(values);
        values.role = userType.role;
        values.creatorRole = creatorRole;
        values.creatorId = creatorId;
        const success = await createUser(values);
        if (success) {
            handleFinish();
        };
        setIsLoading(false);
    };
    const [userType, setUserType] = useState({});


    return(
        <>
        <div>

                        <Form
                            name="userDetails"
                            labelCol={{
                                span: 5,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 600,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                            ref={formRef}
                        >
                            <Form.Item
                                label="Vorname"
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib den Vornamen ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Nachname"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib den Nachnamen ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine Unternehmens-email ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="TimeBoard-Id"
                                name="timeBoardId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine TimeBoard-Id ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Telefon"
                                name="mobileNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine Telefonnummer ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="SP-Id" 
                                name="sharePointId"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Bitte gib eine SharePoint Id ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}

                                />
                            </Form.Item>

                            <Form.Item
                                label="Nutzertyp"
                                name="userType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib einen Nutzertypen an!',
                                    },
                                ]}
                            >
                                <Select
                                    size={globalSize}
                                    value={userType || undefined}
                                    onChange={(value, option) => { setUserType(option.data) }}
                                    style={{
                                        width: 320,
                                    }}
                                >
                                {isAdmin === true ? 
                                (
                                    userTypesAdmin.map((userType) => (
                                        <Select.Option value={userType.title} key={userType.title} data={userType}>
                                            {userType.title}
                                        </Select.Option>
                                    ))
                                ) : (
                                    userTypesStandard.map((userType) => (
                                        <Select.Option value={userType.title} key={userType.title} data={userType}>
                                            {userType.title}
                                        </Select.Option>
                                    ))
                                )
                                }
                                    
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Projekte"
                                name="selectedProjects"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Bitte wähle mindestens ein Projekt!',
                                    },
                                ]}
                            >
                                    <Select
                                    size={globalSize}
                                    // onChange={(e, o) => { console.log(o); handleChange(o)  }}
                                    style={{
                                        width: 320,
                                    }}
                                    mode="multiple"
                                >
                                    {projects.map((project) => (
                                        <Select.Option value={project._id} key={project._id} data={project}>
                                            {project.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    Speichern
                                </Button>
                                <Button danger={isLoading} type="text" onClick={handleCancel} loading={false}>
                                    Abbrechen
                                </Button>
                            </Form.Item>
                                                            
                            </Form>
        </div>
        </>
    );
};

export { NewUserForm };