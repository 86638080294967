import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4_createCsvSchemaData, v4_downloadSampleCsv, v4_getCsvSchemaData, v4_getProjectById, v4_syncSchema, v4_updateCsvSchemaData, v4_update_schema_details_in_project } from "../../../../routing/v4_Routes/project.routes.js";
import { Button, Descriptions, Select, Space, Table, Typography } from "antd";
import { SchemaCreatorForm } from "../../../modals/SchemaCreatorForm.modal.jsx";
import _ from "lodash";

const { Title, Text } = Typography;
const { Item } = Descriptions;

export function CsvSchemaPage() {

    const { _projectId } = useParams();
    const [project, setProject] = React.useState({});
    const [schemaDetails, setSchemaDetails] = React.useState({});
    const [fields, setFields] = React.useState([{}]);
    const [loading, setLoading] = React.useState(false);
    const [showSchemaCreation, setShowSchemaCreation] = React.useState(false);
    const [quantityField, setQuantityField] = React.useState("");
    const [articleField, setArticleField] = React.useState("");
    const [createdAtField, setCreatedAtField] = React.useState("");
    const [timeQuantityField, setTimeQuanityField] = React.useState("");

    async function fetchProjectData() {
        setLoading(true);
        const data = await v4_getProjectById(_projectId);
        setProject(data);
        setLoading(false);
    };

    async function fetchCsvSchemaData() {
        setLoading(true);
        const data = await v4_getCsvSchemaData(_projectId);
        setSchemaDetails(data);
        setFields(data?.fields || [{}]);
        setLoading(false);
    };

    async function saveChanges() {
        setLoading(true);
        //iterate over values and make _.kebabCase on each technicalName
        fields.forEach((field) => {
            field.technicalName = _.snakeCase(field.technicalName);
        });

        //save changes
        const response = await v4_createCsvSchemaData(_projectId, { 
            fields: fields, 
            project: _projectId,
            quantityFieldName: quantityField,
            articleFieldName: articleField,
            createdAtFieldName: createdAtField,
            timeQuantityFieldName: timeQuantityField,
         });

        if (!_.isEmpty(response)) {
            setSchemaDetails(response);
            setQuantityField(schemaDetails?.quantityField || "");
            setArticleField(schemaDetails?.articleFieldName || "");
            setCreatedAtField(schemaDetails?.createdAtFieldName || "");
            setTimeQuanityField(schemaDetails?.timeQuantityFieldName || "");
        } else {
            console.debug("Response is empty");
        };
        setLoading(false);
    };

    async function updateSchema() {
        setLoading(true);
        //iterate over values and make _.kebabCase on each technicalName
        fields.forEach((field) => {
            field.technicalName = _.snakeCase(field.technicalName);
        });

        //save changes
        const response = await v4_updateCsvSchemaData(schemaDetails._id, { 
            fields: fields, 
            quantityFieldName: quantityField,
            articleFieldName: articleField,
            createdAtFieldName: createdAtField,
            timeQuantityFieldName: timeQuantityField,
         });

        if (!_.isEmpty(response)) {
            setSchemaDetails(response);
            setQuantityField(response?.quantityFieldName || "");
            setArticleField(response?.articleFieldName || "");
            setCreatedAtField(response?.createdAtFieldName || "");
            setTimeQuanityField(response?.timeQuantityFieldName || "");
        } else {
            console.debug("Response is empty");
        };
        setLoading(false);
    };

    useEffect(() => {
        fetchProjectData();

        return () => {
            setProject({});
        };
 
    }, []);

    async function setProjectPropertiesForCSV() {
        if (_.has(project, "csvVersion") || project.csvSchema) {
            await v4_update_schema_details_in_project(_projectId);
            console.debug("Project properties for CSV set");
        };
    };

    useEffect(() => {
        setProjectPropertiesForCSV();
    }, [project]);

    useEffect(() => {
        fetchCsvSchemaData();

        setQuantityField(schemaDetails?.quantityFieldName || "");
        setArticleField(schemaDetails?.articleFieldName || "");
        setCreatedAtField(schemaDetails?.createdAtFieldName || "");
        setTimeQuanityField(schemaDetails?.timeQuantityFieldName || "");

        return () => {
            setSchemaDetails({});
        };

    }, []);

    useEffect(() => {
        setQuantityField(schemaDetails?.quantityFieldName || "");
        setArticleField(schemaDetails?.articleFieldName || "");
        setCreatedAtField(schemaDetails?.createdAtFieldName || "");
        setTimeQuanityField(schemaDetails?.timeQuantityFieldName || "");
        console.log(schemaDetails);
    }, [schemaDetails]);


    return (
        <div>
            <div>
                <h1>CSV Schema</h1>
                <p>Project ID: {project?.title}</p>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                padding: "20px"

            }}>
                <Space>
                    <Button type="primary" loading={loading} disabled={!schemaDetails?._id} onClick={async () => { await v4_downloadSampleCsv(schemaDetails?._id)}}>Beispiel Datei herunterladen</Button>
                    <Button type="primary" onClick={() => setShowSchemaCreation(true)} loading={loading}>Neues Feld hinzufügen</Button>
                    {!schemaDetails?._id && <Button type="primary" onClick={() => saveChanges()} loading={loading}>Schema erstellen</Button>}
                    {schemaDetails?._id &&  <Button type="primary" onClick={() => updateSchema()} loading={loading}>Änderungen Speichern</Button>}
                    {schemaDetails?._id && <Button type="primary" danger onClick={async () => {
                        setLoading(true);
                        await v4_syncSchema(_projectId);
                        setLoading(false);
                    }}
                    loading={loading}
                    >Schema Synchonisieren!</Button>}
                </Space>
            </div>

            <div>
                <Descriptions title="Hauptfelder" bordered column={2}>
                    <Item label="Mengenfeld">
                        <Select
                            placeholder="Mengenfeld auswählen"
                            mode="single"
                            onChange={(value) => {
                                setQuantityField(value);
                            }}
                            options={fields.map((field, index) => {
                                return {
                                    label: field.displayName,
                                    value: field.technicalName,
                                };
                            })}
                            optionRender={(option) => {
                                return (
                                    <Space direction='vertical'>
                                        <div>{option.label}</div>
                                    </Space>
                                );
                            }}
                            style={{ width: '100%' }}
                            value={quantityField}
                        />
                    </Item>
                    <Item label="Artikelfeld">
                        <Select
                            placeholder="Artikelfeld auswählen"
                            mode="single"
                            onChange={(value) => {
                                setArticleField(value);
                            }}
                            options={fields.map((field, index) => {
                                return {
                                    label: field.displayName,
                                    value: field.technicalName,
                                };
                            })}
                            optionRender={(option) => {
                                return (
                                    <Space direction='vertical'>
                                        <div>{option.label}</div>
                                    </Space>
                                );
                            }}
                            style={{ width: '100%' }}
                            value={articleField}
                        />
                    </Item>
                    <Item label="Erstellungsdatum">
                        <Select
                            placeholder="Erstellungsdatum auswählen"
                            mode="single"
                            onChange={(value) => {
                                setCreatedAtField(value);
                            }}
                            options={fields.map((field, index) => {
                                return {
                                    label: field.displayName,
                                    value: field.technicalName,
                                };
                            })}
                            optionRender={(option) => {
                                return (
                                    <Space direction='vertical'>
                                        <div>{option.label}</div>
                                    </Space>
                                );
                            }}
                            style={{ width: '100%' }}
                            value={createdAtField}
                        />
                    </Item>
                    <Item label="Zeitmenge">
                        <Select
                            placeholder="Zeitmenge auswählen"
                            mode="single"
                            onChange={(value) => {
                                    setTimeQuanityField(value);
                                    if (_.isEmpty(value)) {
                                        setTimeQuanityField("");
                                    };
                            }}
                            options={fields.map((field, index) => {
                                return {
                                    label: field.displayName,
                                    value: field.technicalName,
                                };
                            })}
                            optionRender={(option) => {
                                return (
                                    <Space direction='vertical'>
                                        <div>{option.label}</div>
                                    </Space>
                                );
                            }}
                            style={{ width: '100%' }}
                            value={timeQuantityField}
                            allowClear
                        />
                    </Item>
                </Descriptions>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                padding: "20px"

            }}>

                <div>
                    <Title level={4}>Felder</Title>

                    <div style={{
                        alignContent: 'center',
                        justifyContent: 'center',
                        padding: '20px'
                    }}>

                        <Table
                            dataSource={fields}
                            loading={loading}
                            scroll={{ x: 1000 }}
                            columns={[
                                {
                                    title: 'Feldname',
                                    dataIndex: 'displayName',
                                    key: 'key',
                                    render: (text, record) => (
                                        <Text>{text}</Text>
                                    ),
                                },
                                {
                                    title: 'Technischer Name',
                                    dataIndex: 'technicalName',
                                    key: 'technicalName',
                                    render: (text, record) => (
                                        <Text>{text}</Text>
                                    ),
                                },
                                {
                                    title: 'Typ',
                                    dataIndex: 'type',
                                    key: 'key',
                                    render: (text, record) => (
                                        <Text>{text}</Text>
                                    ),
                                },
                                {
                                    title: 'Referenzmodel',
                                    dataIndex: 'refModel',
                                    key: 'key',
                                }
                            ]}
                        />

                    </div>
                </div>
            </div>

            <SchemaCreatorForm
            Open={showSchemaCreation}
            Data={fields.map((field) => {
                return {
                    displayName: field.displayName,
                    technicalName: field.technicalName,
                    type: field.type,
                    refModel: field.refModel,
                };
            })}
            OnClose={() => setShowSchemaCreation(false)}
            OnConfirm={(values) => { setFields(values.fields) }}
            />
        </div>
    );

};