import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, DatePicker, Descriptions, Divider, FloatButton, Form, Input, InputNumber, Select, Space, Spin, Tag, Tooltip, Typography } from "antd";
import { SelectTaskModal } from "../../modals/dataEntryModals/SelectTaskModal";
import datePickerLocale from "../../../locale/datePickerLocale.json"
import dayjs from "dayjs";
import { Box, Stack } from "@mui/system";
import { Chip } from "@mui/material";
import { checkConnection } from "../../../routing/api";
import { setAxiosData, setAxiosDataSplittable } from "../../../routing/crudActions";
import _ from "lodash";
import { UpOutlined, ContainerOutlined } from "@ant-design/icons";
import { LastEntriesModal } from "../../modals/dataEntryModals/LastEntriesModal";
import { util_updateLastEntryState } from "../../../utils/updaterUtils/lastEntryUpdaterUtil";
import { util_theme_state_dark } from "../../../themes/modes/darkModeUtil";
import { LoadingOutlined } from '@ant-design/icons';
import { CalculatedResultModal } from "./CalculatedResultModal";
import { displayErrorMessage } from "../../../utils/messageServices/messageService";
import { statusArray, statusHelper } from "../../../utils/helpers/statusHelper";
import { v4_getCsvSchemaData } from "../../../routing/v4_Routes/project.routes";
import { displayInfoNotification } from "../../../utils/messageServices/notificationService";

const { Title, Paragraph, Text } = Typography;
const inputSize = "default";

function CreateDataEntryPage() {

    const project = useSelector((state) => state.projectState.selectedProject);
    const user = useSelector((state) => state.userState.mongoUser)
    const customFields = useSelector((state) => state.projectState.customFields);
    const filteredCustomFields = customFields.filter((field) => {
        if (_.has(field, "viewOptions")) {
            if (field.viewOptions.calculate) {
                return false;
            } else {
                return field;
            }
        } else {
            return field;
        }
    });
    const calculatableFields = customFields.filter((field) => {
        if (_.has(field, "viewOptions")) {
            if (field.viewOptions.calculate) {
                return field;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [localHistory, setLocalHistory] = useState(true);
    const [selectedTaskFromModal, setSelectedTaskFromModal] = useState(null);
    const [calculatedResult, setCalculatedResult] = useState([]);
    const [manualChangedUser, setManualChangedUser] = useState(user);
    const [splittable, setSplittable] = useState(false);
    const [splitStartDate, setSplitStartDate] = useState(null);
    const [splitEndDate, setSplitEndDate] = useState(null);

    const handleCalculatableFieldChange = () => {
        console.log("Calculatable Field Change");
        const results = [];
        calculatableFields.forEach((field) => {
            if (field !== undefined) {
                console.log("Field: ", field);
                if (field.systemField === "number" || field.systemField === "decimal") {
                    const calculationBehaviour = field.calculationOptions;
                    console.log("Calculation Behaviour: ", calculationBehaviour);
                    const { fieldId, targetField, calculationOperator } = calculationBehaviour;
                    //Get the target field
                    const targetFieldObject = _.find(customFields, { _id: targetField });
                    console.log("Target Field Object: ", targetFieldObject);
                    const targetFieldName = targetFieldObject.fieldNameId;

                    const firstField = _.find(customFields, { _id: fieldId });
                    console.log("First Field: ", firstField);
                    const firstFieldName = firstField.fieldNameId;

                    //Get the target field values from the form
                    let targetFieldValue = formRef.current.getFieldValue(targetFieldName);
                    let firstFieldValue = formRef.current.getFieldValue(firstFieldName);
                    let calcualtedValue = 0;

                    if (targetFieldValue !== undefined && targetFieldValue !== null && targetFieldValue !== "") {
                        if (firstFieldValue !== undefined && firstFieldValue !== null && firstFieldValue !== "") {
                            //Parse to float
                            targetFieldValue = parseFloat(targetFieldValue);
                            firstFieldValue = parseFloat(firstFieldValue);
                            //Calculate the new value
                            switch (calculationOperator) {
                                case "add":
                                    console.log("Addition");
                                    calcualtedValue = firstFieldValue + targetFieldValue;
                                    break;
                                case "subtract":
                                    console.log("Subtraction");
                                    calcualtedValue = firstFieldValue - targetFieldValue;
                                    break;
                                case "multiply":
                                    console.log("Multiplication");
                                    calcualtedValue = firstFieldValue * targetFieldValue;
                                    break;
                                case "divide":
                                    console.log("Division");
                                    //Check if divedes by zero
                                    if (firstFieldValue === 0 || targetFieldValue === 0) {
                                        console.log("Division by zero");
                                        calcualtedValue = 0;
                                    } else {
                                        calcualtedValue = firstFieldValue / targetFieldValue
                                    };
                                    break;
                                default:
                                    console.log("No calculation operator found");
                                    break;
                            }
                        }
                    }

                    console.log("Calculated Value: ", calcualtedValue);

                    formRef.current.setFieldsValue({
                        [field.fieldNameId]: parseFloat(calcualtedValue),
                    });
                    //Find and update the calculated result
                    const result = { [field.fieldName]: parseFloat(calcualtedValue) };
                    console.log("Result: ", result);
                    if (result !== undefined) {
                        //Set the results
                        //Pull where the key matche
                        _.remove(calculatedResult, function (n) {
                            return _.keys(n)[0] === field.fieldName;
                        });
                        //Push the new result
                        results.push(result);
                    };

                    if (field.useAsResult) {
                        formRef.current.setFieldsValue({
                            quantity: parseFloat(calcualtedValue),
                        });
                    };

                }
            }
        });
        setCalculatedResult(results);
    };

    const [calculatedResultCallback, setCalculatedResultDisplay] = useState(false);

    const handleSetCalculatedResult = () => {
        console.log("Set Calculated Result");
        setCalculatedResultDisplay(true);
    };

    const setCalculatedResultCallback = (result) => {
        console.log("Set Calculated Result Callback");
        console.log("Result: ", result);
        if (result === undefined) {
            result = 0;
            console.log("Result is undefined, set to 0");
            displayErrorMessage("Fehler bei der übernahme der Berechnung", "Es wurde kein Ergebnis übergeben, bitte versuche es erneut.");
        };
        formRef.current.setFieldsValue({
            quantity: parseFloat(result),
        });
        setCalculatedResultDisplay(false);
    };

    //Verfügbarkeit Datenbank
    const [available, setAvailability] = useState(false);
    const checkAvailability = () => {
        checkConnection()
            .then((response) => {
                if (response.status === 202) {
                    setAvailability(true);
                } else {
                    setAvailability(false);
                }
            }).catch((err) => {
                setAvailability(false);
                console.log(err);
            });
    }

    //Float Button Menu
    const [lastItemsOpen, setLastItemsOpen] = useState(false);
    const [floatButtonOpen, setFloatButtonOpen] = useState(false);

    useEffect(() => {
        checkAvailability();
    }, []);

    //Datum
    // Aktuelles Datum und Zeit erhalten
    const currentDateAndTime = dayjs();

    // Formatieren und ausgeben
    const formattedDateTime = currentDateAndTime.format('DD.MM.YYYY HH:mm');

    //Modal
    const [modalVisible, setModalVisible] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };

    //Multiple Tasks
    const [selectedTaskMultiple, setSelectedTaskMultiple] = useState(false);
    const [multipleValue, setMultipleValue] = useState(1);

    const checkTaskMultiple = (task) => {
        if (task) {
            if (task.hasOwnProperty('multiple')) {
                setSelectedTaskMultiple(task.multiple);
                console.log("Ein task mit multiple wurde gewählt")
            } else {
                setSelectedTaskMultiple(false);
                console.log("Ein task ohne multiple wurde gewählt")
            }
        }
    };

    useEffect(() => {
        const userData = project?.allowedUserId?.map((user) =>
        ({
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
            data: user
        })
        );
        if (userData !== undefined) {
            if (_.filter(userData, { value: user._id }).length === 0) {
                //User nicht in der Liste
                console.log("User nicht in der Liste, füge hinzu");
                userData.push({
                    value: user._id,
                    label: `${user.firstName} ${user.lastName}`,
                    data: user
                });
            };
        };
    }, [project]);

    //Set Task
    const handleSetTask = (task) => {
        console.log(task);
        formRef.current.setFieldsValue({
            task: task._id
        });
        checkTaskMultiple(task);
        setSelectedTaskFromModal(task);
        console.log(task);
        setSplittable(task?.splittable || false);
        closeModal();
    };

    const customizeRequiredMark = (label, { required }) => (
        <>
            {required ? <Tag color="error">erforderlich</Tag> : <Tag color="warning">optional</Tag>}
            {label}
        </>
    );

    //Set initial valuesf
    const initialValues = {
        createdAt: currentDateAndTime,
    };

    useEffect(() => {
        formRef.current.setFieldsValue(initialValues);
        setMultipleValue(1);
        setSelectedTaskMultiple(false);
    }, []);

    //Form reset
    const handleFormReset = () => {
        formRef.current.resetFields();
        formRef.current.setFieldsValue(initialValues);
        setMultipleValue(1);
        setSelectedTaskFromModal(null);
        setCalculatedResult([]);
        setSelectedTaskMultiple(false);
        setQuantityIsVariable(false);
        setSplittable(false);
    };

    //From submit
    const handleFormSubmit = async (values) => {
        setLoading(true);

        if (project?.dataMode === "csv") {
            displayInfoNotification('Das Projekt läuft überlicherweise als CSV-Projekt. Wir übernehmen die Daten und leiten sie an den Server weiter.');
            handleCSVManualFormSubmit(values);
            return;
        };

        _.set(values, "createdAt", values.createdAt.toISOString());
        _.set(values, "modifiedAt", values.createdAt);
        _.set(values, "task", selectedTaskFromModal);

        if (project?.creatorCanChangeUser === true) {
            _.set(values, "createdBy", manualChangedUser?._id ?? user._id);
        } else {
            _.set(values, "createdBy", user._id);
        };

        _.set(values, "modifiedBy", user._id);

        if (quantityIsVariable) {
            console.log("quantityIsVariable ist true - rufe Task ab und setze quantity");
            const newTask = { ...selectedTaskFromModal }
            console.log(newTask);
            _.set(newTask, "quantity", parseFloat(values.quantity));
            console.log("quantity wurde gesetzt, Menge: " + parseFloat(values.quantity));
            _.set(values, "task", newTask);

            if (calculatedResult !== undefined && !_.isEmpty(calculatedResult)) {
                console.log("Calculated Result ist nicht leer");
                calculatedResult.forEach((result) => {
                    console.log("Result: ", result);
                    _.set(values, _.keys(result)[0], _.values(result)[0]);
                });
            };
        };

        delete values.quantity;
        console.log("Submitting: ", values);
        let status;
        if (splittable) {
            status = await setAxiosDataSplittable(project._id, values, multipleValue, selectedTaskMultiple, splitStartDate?.toDate(), splitEndDate?.toDate())
            .then((response) => {
                return response;
            })
            .finally(() => {
                setLoading(false);
                handleFormReset();
            });
        

        } else {
            status = await setAxiosData(project._id, values, multipleValue, selectedTaskMultiple)
            .then((response) => {
                return response;
            })
            .finally(() => {
                setLoading(false);
                handleFormReset();
            });

        };

        if (localHistory) {
            util_updateLastEntryState({ ...values, axiosStatus: status });

        };
    };

    const handleCSVManualFormSubmit = async (values) => {
        setLoading(true);
        console.debug("CSV Manual Form Submit", values);
        _.set(values, "createdAt", values.createdAt.toISOString());
        _.set(values, "modifiedAt", values.createdAt);
        _.set(values, "task", selectedTaskFromModal);

        if (project?.creatorCanChangeUser === true) {
            _.set(values, "createdBy", manualChangedUser?._id ?? user._id);
        } else {
            _.set(values, "createdBy", user._id);
        };

        _.set(values, "modifiedBy", user._id);

        if (quantityIsVariable) {
            console.log("quantityIsVariable ist true - rufe Task ab und setze quantity");
            const newTask = { ...selectedTaskFromModal }
            console.log(newTask);
            _.set(newTask, "quantity", parseFloat(values.quantity));
            console.log("quantity wurde gesetzt, Menge: " + parseFloat(values.quantity));
            _.set(values, "task", newTask);

            if (calculatedResult !== undefined && !_.isEmpty(calculatedResult)) {
                console.log("Calculated Result ist nicht leer");
                calculatedResult.forEach((result) => {
                    console.log("Result: ", result);
                    _.set(values, _.keys(result)[0], _.values(result)[0]);
                });
            };
        };

        delete values.quantity;
        console.log("Submitting: ", values);
        let status;
        if (splittable) {
            status = await setAxiosDataSplittable(project._id, values, multipleValue, selectedTaskMultiple, splitStartDate?.toDate(), splitEndDate?.toDate())
            .then((response) => {
                return response;
            })
            .finally(() => {
                setLoading(false);
                handleFormReset();
            });
        

        } else {
            status = await setAxiosData(project._id, values, multipleValue, selectedTaskMultiple)
            .then((response) => {
                return response;
            })
            .finally(() => {
                setLoading(false);
                handleFormReset();
            });

        };

        if (localHistory) {
            util_updateLastEntryState({ ...values, axiosStatus: status });

        };
    };

    //Description
    const [description, setDescription] = useState("");
    const handleDescriptionChange = (e) => {
        setDescription(`Du legst ${selectedTaskMultiple ? `mehrere Vorgänge` : "einen Vorgang"} mit der Nummer ${formRef.current.getFieldValue("title")
            } mit dem Status ${formRef.current.getFieldValue("status")} an. Der Vorgang wird mit dem Datum: ${formRef.current.getFieldValue("createdAt")} im System gebucht. Der Vorgang wird mit deinem Benutzer erstellt.`);
    };

    const [quantityIsVariable, setQuantityIsVariable] = useState(false);
    useEffect(() => {
        const manageQuantityIsVariable = () => {
            if (selectedTaskFromModal !== null) {
                if (selectedTaskFromModal.hasOwnProperty('quantityIsVariable')) {
                    setQuantityIsVariable(selectedTaskFromModal.quantityIsVariable);
                    console.log("Ein task mit quantityIsVariable wurde gewählt")
                } else {
                    setQuantityIsVariable(false);
                    console.log("Ein task ohne quantityIsVariable wurde gewählt")
                }
            }
        };

        manageQuantityIsVariable();

        return () => {
            setQuantityIsVariable(false);
            console.log("quantityIsVariable wurde zurückgesetzt");
        };

    }, [selectedTaskFromModal])

    return (
        <div>
            <div>
                <Stack direction="row" alignItems="center">
                    <Stack direction="row">
                        <div
                            style={{
                                backgroundColor: util_theme_state_dark().backgroundColor,
                                p: 3,
                                marginRight: '15px',
                                borderRadius: '50px',
                                objectFit: 'contain'
                            }}>
                            <img
                                src={project.imageUrl}
                                alt="Project_Logo_Image"
                                width="80px"
                                style={{ resize: 'both', backgroundColor: 'white', objectFit: 'contain' }}
                                className="ButtonIcons"
                            />
                        </div>
                        <Stack>

                            <Title style={{
                                textAlign: 'left',
                                alignContent: 'left',
                                alignItems: 'left',
                                justifyContent: 'left',
                                justifyItems: 'left',
                                justifySelf: 'left',
                                alignSelf: 'left',
                                marginLeft: '-10px'
                            }}>
                                {project.title}
                            </Title>
                            <Paragraph style={{
                                textAlign: 'left',
                                alignContent: 'left',
                                alignItems: 'left',
                                justifyContent: 'left',
                                justifyItems: 'left',
                                justifySelf: 'left',
                                alignSelf: 'left',
                                marginLeft: '-10px'
                            }}>
                                Du erstellst einen Vorgang für: {project.title} - Version: {project.__v}
                            </Paragraph>

                        </Stack>
                    </Stack>
                    <Box sx={{ flex: 1 }} />
                    <Chip label={available ? "Server bereit zum empfangen von Daten" : "Die Datenbank ist nicht bereit"} color={available ? "success" : "error"} />
                </Stack>
            </div>
            <div>
                <div>
                    <Title level={4}>Vorgangsdetails</Title>
                </div>
            </div>

            <div>
                <Spin spinning={loading} fullscreen indicator={
                    <LoadingOutlined
                        style={{ fontSize: 60 }}
                        spin
                    />
                }
                    tip="Wir Übertragen deine Daten an den Server..."
                />
            </div>

            <div style={{ display: 'flex' }}>
                <div style={{
                    paddingRight: "1rem",
                    flex: "1 1 0",
                    width: "80%",
                    marginLeft: !_.isEmpty(calculatableFields) ? "100px" : "100px",
                    marginRight: !_.isEmpty(calculatableFields) ? "100px" : "100px"
                }}>

                    <Form
                        name="entryForm"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={customizeRequiredMark}
                        ref={formRef}
                        onFinish={handleFormSubmit}
                        onAbort={handleFormReset}
                        onChange={(values) => { handleDescriptionChange(values); handleCalculatableFieldChange() }}
                    >

                        <Form.Item
                            label="Titel / Vorgang"
                            name="title"
                            rules={[{ required: true, message: "Sie müssen einen Titel bzw. Vorgangsnummer angeben!" }]}
                            style={{
                                alignItems: "left",
                                textAlign: "left",
                            }}
                        >
                            <Input
                                size={inputSize}
                                placeholder="Titel / Vorgang"
                            // onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[{ required: true, message: "Sie müssen einen Status angeben!" }]}
                            style={{
                                alignItems: "left",
                                textAlign: "left",
                            }}
                        >
                            <Select
                                size={inputSize}
                                placeholder="Status"
                                options={project?.defaultTaskStatusSelections.map((status) => ({
                                    value: status,
                                    label: statusHelper(status)
                                })
                                ) ?? statusArray.map((status) => ({
                                    value: status,
                                    label: statusHelper(status)
                                }))
                                }
                            />
                        </Form.Item>



                        {filteredCustomFields.map((field) => {
                            switch (field.systemField) {
                                case "text":
                                    return (
                                        <Form.Item
                                            label={field.fieldName}
                                            name={`${_.toLower(field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_'))}`}
                                            rules={[{ required: field?.fieldRequired || false, message: "Sie müssen einen Wert angeben!" }]}
                                            style={{
                                                alignItems: "left",
                                                textAlign: "left",
                                            }}
                                        >
                                            <Input
                                                size={inputSize}
                                                placeholder={field.name}
                                            // onChange={(e) => setCustomField(e.target.value)}
                                            />
                                        </Form.Item>
                                    );
                                case "text-lg":
                                    return (
                                        <Form.Item
                                            label={field.fieldName}
                                            name={`${_.toLower(field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_'))}`}
                                            rules={[{ required: field?.fieldRequired || false, message: "Sie müssen einen Wert angeben!" }]}
                                            style={{
                                                alignItems: "left",
                                                textAlign: "left",
                                            }}
                                        >
                                            <Input.TextArea
                                                size={inputSize}
                                                placeholder={field.name}
                                            // onChange={(e) => setCustomField(e.target.value)}
                                            />
                                        </Form.Item>
                                    );
                                case "number":
                                    return (<Form.Item
                                        label={field.fieldName}
                                        name={`${_.toLower(field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_'))}`}
                                        rules={[{ required: field?.fieldRequired || false, message: "Sie müssen einen Wert angeben!" }]}
                                    >
                                        <InputNumber
                                            size={inputSize}
                                            placeholder={field.name}
                                            decimalSeparator=","
                                            type="number"
                                            style={{
                                                width: "100%",
                                                marginLeft: "-0.0rem",
                                                textAlign: "left",
                                                alignItems: "left",
                                            }}
                                        // onChange={(e) => setCustomField(e.target.value)}
                                        />
                                    </Form.Item>)
                                case "decimal":
                                    return (<Form.Item
                                        label={field.fieldName}
                                        name={`${_.toLower(field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_'))}`}
                                        rules={[{ required: field?.fieldRequired || false, message: "Sie müssen einen Wert angeben!" }]}
                                        style={{
                                            alignItems: "left",
                                            textAlign: "left",
                                        }}
                                    >
                                        <InputNumber
                                            size={inputSize}
                                            placeholder={field.name}
                                            decimalSeparator=","
                                            type="decimal"
                                            style={{
                                                width: "100%",
                                                marginLeft: "-0.0rem"
                                            }}
                                        // onChange={(e) => setCustomField(e.target.value)}
                                        />
                                    </Form.Item>)
                                case "date":
                                    return (
                                        <Form.Item
                                            label={field.fieldName}
                                            name={`${_.toLower(field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_'))}`}
                                            rules={[{ required: field?.fieldRequired || false, message: "Sie müssen einen Wert angeben!" }]}
                                            style={{
                                                alignItems: "left",
                                                textAlign: "left",
                                            }}
                                        >
                                            <DatePicker
                                                size={inputSize}
                                                style={{
                                                    width: "100%",
                                                    marginLeft: "-0.0rem"
                                                }}
                                                locale={datePickerLocale}
                                                format="DD.MM.YYYY"
                                            // onChange={(e) => setDate(e.target.value)}
                                            />
                                        </Form.Item>
                                    );
                                case "select":
                                    return (
                                        <Form.Item
                                            label={field.fieldName}
                                            name={`${_.toLower(field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_'))}`}
                                            rules={[{ required: field?.fieldRequired || false, message: "Sie müssen einen Wert angeben!" }]}
                                            style={{
                                                alignItems: "left",
                                                textAlign: "left",
                                            }}
                                        >
                                            <Select
                                                size={inputSize}
                                                placeholder={field.name}
                                            // onChange={(e) => setCustomField(e.target.value)}
                                            >
                                                {field.fieldValidation.map((option) => {
                                                    return (
                                                        <Select.Option value={option}>{option}</Select.Option>
                                                    );
                                                })}

                                            </Select>
                                        </Form.Item>
                                    );

                                default:
                                    return (
                                        <Form.Item
                                            label={field.fieldName}
                                            name={`${_.toLower(field.fieldNameId.replace(/ /g, '_').replace(/-/g, '_'))}`}
                                            rules={[{ required: false, message: "Sie müssen einen Wert angeben!" }]}
                                        >
                                            <Input
                                                size={inputSize}
                                                placeholder={field.name}
                                            // onChange={(e) => setCustomField(e.target.value)}
                                            />
                                        </Form.Item>
                                    );

                            }

                        }
                        )}


                        <Divider />
                        <div>
                            <Title level={4}>Aufgaben und Details des Vorgangs</Title>
                        </div>

                        <Form.Item
                            label="Aufgabe"
                            name="task"
                            rules={[{ required: true, message: "Sie müssen eine Aufgabe angeben!" }]}
                            style={{
                                alignItems: "left",
                                textAlign: "left",
                            }}
                        >
                            <Select
                                size={inputSize}
                                placeholder="Aufgabe auswählen"
                                disabled={true}
                                // onChange={(e) => setTask(e.target.value)}
                            >
                                {
                                    selectedTaskFromModal !== null && <Select.Option value={selectedTaskFromModal._id}>{
                                        selectedTaskFromModal.title
                                    }</Select.Option>
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "left",
                            textAlign: "left",
                            marginBottom: "1.5rem",
                            marginTop: "1.5rem",

                        }}>
                            <Button type="primary"
                                onClick={() => setModalVisible(true)}
                            >
                                Aufgabe wählen
                            </Button>
                        </Form.Item>

                        <Form.Item
                            label={selectedTaskFromModal !== null ? "Menge in " + selectedTaskFromModal.quantityUnit + "." : "Menge"}
                            name="quantity"
                            rules={[{ required: quantityIsVariable, message: "Sie müssen eine Menge angeben!" }]}
                            hidden={!quantityIsVariable}
                        >
                            <InputNumber
                                size={inputSize}
                                placeholder={selectedTaskFromModal !== null ? selectedTaskFromModal.quantityUnit : "Menge"}
                                type="decimal"
                                decimalSeparator=","
                                hidden={!quantityIsVariable}
                                disabled={!quantityIsVariable}
                                style={{
                                    width: "100%",
                                    marginLeft: "-0.0rem"
                                }}
                            // onChange={(e) => setQuantity(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            hidden={!quantityIsVariable}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "1.5rem",
                                marginTop: "1.5rem",

                            }}>
                            <Button type="primary"
                                onClick={handleSetCalculatedResult}
                                disabled={!quantityIsVariable}
                            >
                                Berechnetes Ergebnis übernehmen
                            </Button>
                        </Form.Item>

                        <Form.Item
                            label="Anzahl"
                            name="task_multiple"
                            rules={[{ required: selectedTaskMultiple, message: "Sie müssen eine Anzahl angeben!" }]}
                            hidden={!selectedTaskMultiple}
                        >
                            <Input
                                size={inputSize}
                                placeholder="Anzahl"
                                type="number"
                                disabled={!selectedTaskMultiple}
                                onChange={(e) => { setMultipleValue(e.target.value); console.log(e.target.value) }}
                                style={{
                                    width: "100%",
                                    marginLeft: "-0.0rem"
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Leistungsdatum"
                            tooltip="Das Datum, an dem die Leistung bzw. Aufgabe erbracht wurde. Nicht ändern, wenn Du dich hiermit nicht auskennst!"
                            name="createdAt"
                            rules={[{ required: true, message: "Sie müssen ein Datum angeben!" }]}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "1.5rem",
                                marginTop: "1.5rem"
                            }}
                            defaultValue={currentDateAndTime}
                        >
                            <DatePicker
                                size={inputSize}
                                placeholder="Datum"
                                style={{
                                    width: "100%",
                                    marginLeft: "-0.0rem"
                                }}
                                locale={datePickerLocale}
                                //defaultValue={currentDateAndTime}
                                format="DD.MM.YYYY HH:mm"
                                showTime={{
                                    format: 'HH:mm',
                                }}
                            // onChange={(e) => setDate(e.target.value)}
                            />
                        </Form.Item>

                        {/* <Form.Item
                            label="Erstellt von ändern"
                            name="createdBy"
                            rules={[{ required: false, message: "Sie müssen einen Wert angeben!" }]}
                            hidden={true}
                        >

                        </Form.Item> */}

                        {project?.creatorCanChangeUser && (
                            <Form.Item
                                label="Ersteller"
                                name="createdBy"
                                rules={[{ required: false, message: "Sie müssen einen Wert angeben!" }]}
                                hidden={false}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "1.5rem",
                                    marginTop: "1.5rem"
                                }}
                                initialValue={user._id}
                            >
                                <Select
                                    showSearch
                                    placeholder="Nutzer auswählen"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    value={user._id || undefined}
                                    onChange={(e, o) => { console.log(o.data); setManualChangedUser(o.data) }}
                                    style={{
                                        width: 320,
                                        textAlign: "left"
                                    }}
                                    loading={loading}
                                    options={project?.allowedUserId?.map((user) =>
                                    ({
                                        value: user._id,
                                        label: `${user.firstName} ${user.lastName}`,
                                        data: user
                                    })
                                    )}
                                />
                            </Form.Item>
                        )}

                        <Form.Item style={{
                            display: "flow"
                        }}>
                            <Space
                                size="large"
                            >
                                <Tooltip title={description}>
                                    <Button type="primary" htmlType="submit" disabled={
                                        formRef.current?.getFieldValue("task") === undefined || (project?.entryReleased || false) === false || selectedTaskFromModal?.splittable
                                    }
                                        loading={loading}
                                    >
                                        Speichern
                                    </Button>
                                </Tooltip>
                                <Tooltip title={description}>
                                    <Button type="primary" htmlType="submit" disabled={
                                        formRef.current?.getFieldValue("task") === undefined || (project?.entryReleased || false) === false || !selectedTaskFromModal?.splittable || _.isEmpty(splitStartDate) || _.isEmpty(splitEndDate)
                                    }
                                        loading={loading}
                                        onClick={() => {
                                            setSplittable(selectedTaskFromModal?.splittable || false);
                                        }}
                                    >
                                        Aufteilen
                                    </Button>
                                </Tooltip>
                                <Button type="dashed" onClick={handleFormReset}
                                    loading={loading}
                                >
                                    Abbrechen
                                </Button>
                            </Space>
                        </Form.Item>




                    </Form>
                    {splittable && (<div style={{
                        marginBottom: "2rem",
                    }}>
                        <DatePicker.RangePicker
                        locale={datePickerLocale}
                        format="DD.MM.YYYY"
                        onChange={(dates, dateStrings) => {
                            console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
                            setSplitStartDate(dates[0]);
                            setSplitEndDate(dates[1]);
                        }}
                        style={{
                            width: "100%",
                            borderColor: "red",
                            borderWidth: "10px",
                            borderRadius: "50px",
                            fontWeight: "bold",
                        }}
                    />
                    </div>)}
                </div>
                {/* Condition hinzufügen, um die nachfolgende Spalte nur bei Berechnungsfeldern anzuzeigen */}
                {!_.isEmpty(calculatableFields) && (
                    <div style={{
                        width: "20%",
                        backgroundColor: util_theme_state_dark().backgroundColor,
                        borderRadius: "10px",
                        // boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                    }}>

                        <div>
                            <Descriptions title="Berechnete Felder" column={1} size="small" bordered>
                                {!_.isEmpty(calculatedResult) && calculatedResult.map((result) => {
                                    return (
                                        <Descriptions.Item label={_.keys(result)[0]}>{_.values(result)[0]}</Descriptions.Item>
                                    );
                                })}
                            </Descriptions>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1.5rem",
                            marginTop: "1.5rem",

                        }}>
                            <Button type="primary"
                                onClick={() => setModalVisible(true)}
                            >
                                Aufgabe wählen
                            </Button>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1.5rem",
                            marginTop: "1.5rem",

                        }}>
                            <Button type="primary"
                                onClick={handleSetCalculatedResult}
                                disabled={!quantityIsVariable}
                            >
                                Berechnetes Ergebnis übernehmen
                            </Button>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1.5rem",
                            marginTop: "1.5rem",

                        }}>
                            <Button hidden={selectedTaskFromModal?.splittable || false} type="primary" disabled={
                                formRef.current?.getFieldValue("task") === undefined || (project?.entryReleased || false) === false
                            }
                                onClick={() => formRef.current.submit()}
                                loading={loading}
                            >
                                Speichern des Formulars
                            </Button>

                        </div>

                    </div>

                )}
            </div>

            <SelectTaskModal open={modalVisible} onCancel={closeModal} onSelectTask={handleSetTask} onNewTask={() => { }} />
            <LastEntriesModal open={lastItemsOpen} onCancel={() => setLastItemsOpen(false)} />
            <CalculatedResultModal open={calculatedResultCallback} onClose={() => setCalculatedResultDisplay(false)} calculatedResult={calculatedResult} callback={setCalculatedResultCallback} />

            <FloatButton.Group
                open={floatButtonOpen}
                trigger="click"
                style={{
                    right: 30,
                    bottom: 80,
                }}
                onClick={() => setFloatButtonOpen(!floatButtonOpen)}
                icon={<UpOutlined />}
            >
                <FloatButton tooltip="Erfasste Eingaben anzeigen" icon={<ContainerOutlined />} onClick={() => setLastItemsOpen(true)} />
            </FloatButton.Group>
        </div>
    );
};



export default CreateDataEntryPage;