import { Button, Descriptions, Modal } from "antd";
import _ from "lodash";
import React from "react";

export function CalculatedResultModal(props) {
    const { calculatedResult, onClose, open, callback } = props;
    return (
        <Modal
            title="Ergebnisse der Berechnung"
            open={open}
            onCancel={onClose}
            footer={null}
            width={"70%"}
        >
        <div>
                        <Descriptions title="Berechnete Felder" column={2} size="small" bordered>
                            {!_.isEmpty(calculatedResult) && calculatedResult.map((result) => {
                                return (
                                    <>
                                    <Descriptions.Item label={_.keys(result)[0]}>{_.values(result)[0]}</Descriptions.Item>
                                    <Descriptions.Item label={_.keys(result)[0]}><Button onClick={() => (callback(_.values(result)[0]))}>dieses Ergebnis übernehmen</Button></Descriptions.Item>
                                    </>
                                );
                            })}
                        </Descriptions>
                        <p>
                            Grundsätzlich werden alle Felder gespeichert, dennoch ist es für die Abrechnung wichtig dass entsprechende Feld zu wählen das die Mengenleistung darstellt. Dieses Ergbnis wird dann mit dem Einzelpreis verrechnet.
                        </p>
                    </div>
        </Modal>
    );
    }