import _ from "lodash";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL, listAll, list } from "firebase/storage";
import axios from "axios";

var makePath = "projects/"

export const uploadDocument = async (file, path) => {
  if (_.isEmpty(path)) {
    path = "uploads"
  }

  const fileRef = ref(storage, `${path}/${file.name}`);
  return uploadBytes(fileRef, file)
};

export const listDocuments = async (projectId) => {
  //eslint-disable-next-line
  const listRef = ref(storage, `${makePath}/${projectId}`)
  return await listDocumentsAndMakeArray(projectId)
}


export const listDocumentsAndMakeArray = async (projectId) => {
  let items = [];
  let folders = [];

  const listRef = ref(storage, `${makePath}/${projectId}`);

  try {
    const task = await listAll(listRef);

    await Promise.all(
      task.prefixes.map(async (storageRef) => {
        const subItems = await fetchOtherDocs(storageRef.storage, storageRef);
        folders.push({ prefix: storageRef.toString(), subItems });
      })
    );

    task.items.forEach((storageRef) => {
      items.push({ prefix: storageRef.toString(), item: storageRef });
    });

    console.log("Task: ", task);
    console.log("Items: ", items);
    console.log("Folders: ", folders);
    const result = { items: items, folders: folders  }
    console.log("Return result: ", result)
    return result
  } catch (error) {
    // Fehlerbehandlung
    console.log(error)
    return null
  }
};

export const fetchOtherDocs = async (storage, storageRef) => {
  console.log("Fetching Ref: ", storage, storageRef);
  try {
    const task = await list(storageRef);

    return task.items.map((item) => item.toString());
  } catch (error) {
    // Fehlerbehandlung
  }
};

export const downloadFile = async (storageRef, fileName) => {
  // getBlob(ref(storage, storageRef))
  //   .then((result) => {
  //     console.log(result)
  //   }).catch((err) => {
  //     console.log(err)
  //   });
  return await getDownloadURL(ref(storage, storageRef)).then((url) => {
    return axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data;
        // Datei erstellen
        const file = new File([blob], fileName, { type: blob.type });
        // URL für das Herunterladen der Datei erstellen
        const downloadUrl = URL.createObjectURL(file);

        // Datei herunterladen
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      });
  });
};

export const openFileInBrowser = async (storageRef) => {
  try {
    // Download-URL der Datei abrufen
    const downloadURL = await getDownloadURL(ref(storage, storageRef));

    // Datei im Browser öffnen
    window.open(downloadURL, '_blank');
  } catch (error) {
    // Fehlerbehandlung
    console.log(error);
  };
};


export const getFileUrl = async (url) => {
  /**
   * @description
   * Ruft die URL für eine Mediendatei (Bild) ab
   */
  try {
      const storageReference = ref(storage, url);
      const downloadRef = await getDownloadURL(storageReference);
      console.log(downloadRef)
      return downloadRef;
  } catch (error) {
      console.log("Error while getting file URL");
  };
};