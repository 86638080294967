import { Button, Card, Tooltip } from "antd";
import React from "react";
import { motion } from "framer-motion";
import { QuestionCircleOutlined } from "@ant-design/icons";


/**
 * 
 * @param {Timeout, Callback, Text, Icon} props 
 * @returns 
 */
export const CardButton = (props) => {

    const { title, callback, text, icon, disabled, timeout, tooltip, loading } = props;

    let content;

    if (timeout) {
        content = (
            <motion.div
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: timeout,
                    duration: 0.5,
                    type: 'spring',
                    stiffness: 100,
                    damping: 10,
                  }}
                >
            <Card
                title={text}
                style={{ width: 300 }}
                extra={tooltip ? <Tooltip title={tooltip}><QuestionCircleOutlined /></Tooltip> : null}
            >
                <Button type="primary" block
                    onClick={callback}
                    disabled={disabled}
                    icon={icon}
                    loading={loading}
                >
                    {title}
                </Button>
            </Card>
            </motion.div>
        );
    } else {
        content = (
            <Card
                title={text}
                style={{ width: 300 }}
                extra={tooltip ? <Tooltip title={tooltip}><QuestionCircleOutlined /></Tooltip> : null}
            >
                <Button type="primary" block
                    onClick={callback}
                    disabled={disabled}
                    icon={icon}
                    loading={loading}
                >
                    {title}
                </Button>
            </Card>
        );
    }


    return (
        <div>
            {content}
        </div>
    );
};




/**
 *                 
 */