import React, { useState } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { loginUser } from "../../firebase";
import { Recaptcha } from '../../AppCheckComponent';

export function LoginForm() {

    const navigate = useNavigate();
  
    const handleNavigate = () => {
      navigate('/register', { replace: true });
    };
  
    const handleLogin = async () => {
      try {
        const response = await loginUser(email, password);
        navigate("/"); 
        console.log(response);
      } catch (error) {
        // Fehlerbehandlung
        console.log(error);
      }
    };
  
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
  
    return (
      <Row justify="center" align="middle" style={{ minHeight: '0vh' }}>
        <Col xs={20} sm={16} md={12} lg={8}>
          <div style={{ textAlign: 'center'}}>
            <h1>Willkommen zurück!</h1>
            <p>Bitte melden Sie sich an, um fortzufahren.</p>
          </div>
          <Form name="login-form" onFinish={() => (console.log("finish"))}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Bitte geben Sie Ihre Email ein.' }]}
            >
              <Input
                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
                size='large'
                onChange={(e) => {setEmail(e.target.value)}}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Bitte geben Sie Ihr Passwort ein.' }]}
            >
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Passwort"
                size='large'
                onChange={(e) => {setPassword(e.target.value)}}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" size='large' block id='sign-in-button'
              style={{
                backgroundColor: '#DA3E52',
              }}
              onClick={handleLogin}
              >
                Anmelden
              </Button>
            </Form.Item>
            <Form.Item>
            <div id='recaptcha-container'></div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  };