import { Avatar, Badge, Button, Descriptions, Drawer, List, Modal, Space, Tag, Typography } from "antd";
import React, { useEffect } from "react";
import { PlusOutlined, DeliveredProcedureOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import _ from "lodash";
import { util_clearLastEntryState } from "../../../utils/updaterUtils/lastEntryUpdaterUtil";
import { statusArray, statusAxiosReturnColorHelper, statusColorHelper, statusHelper } from "../../../utils/helpers/statusHelper";
import { style } from "@mui/system";


function LastEntriesModal(props) {

    // const userDetails = user.;
    const userDetails = useSelector((state) => state.userState.firebaseUser)
    const lastEntries = useSelector((state) => state.mainState.lastDataEntries);
    const [filteredData, setFilteredData] = React.useState(lastEntries);
    const [searchValue, setSearchValue] = React.useState("");

    useEffect(() => {
        handleSearch(searchValue);
    }, [searchValue]);

    const handleSearch = (value) => {
        setSearchValue(value);
    }

    React.useEffect(() => {
        setFilteredData(
            _.sortBy(_.filter(lastEntries, (task) => {
                const { title, description, id } = task;
                const searchValueLower = searchValue.toLowerCase();
                return (
                    title.toLowerCase().includes(searchValueLower) ||
                    (description?.toLowerCase().includes(searchValueLower)) ||
                    id.includes(searchValueLower)
                );
            }), ["createdAt"]).reverse()
        );
    }, [searchValue, lastEntries]);



    return (
        <>
            <Drawer
                title={<div>
                    <Space>
                        <Typography.Text type="primary">Zuletzt erstellte Vorgänge von {userDetails.firstName}</Typography.Text>
                    </Space>
                </div>}
                open={props.open}
                closable={true}
                onCancel={props.onCancel}
                footer={[
                    <div>
                    <Space>
                    <Button onClick={() => {util_clearLastEntryState()}} type="primary">Liste leeren</Button>
                    <Button onClick={props.onCancel} type="dashed">Schließen</Button>
                    </Space>
                    </div>
                ]}
                width={"100%"}
                style={{
                    top: "5vh",

                }}
                onClose={props.onCancel}
            >
                <div>
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "1.5rem",
                        }}>
                            {/* <div>
                                <Button onClick={props.onCancel} type="primary">Schließen</Button>
                            </div> */}
                        </div>
                        <div style={{
                                marginBottom: "1rem",
                            }}>
                            <Typography.Text type="primary">Hier hast du eine Möglichkeit alle deine in dieser Sitzung erstellten Vorgänge zu sehen.</Typography.Text>
                        </div>
                        <div>
                            <div style={{
                                marginBottom: "1rem",
                            }}>
                            <Typography.Text type="primary">Legende der Statusfarben</Typography.Text>
                            <br/>
                                <Space>
                                {statusArray.map((status) => {
                                    return (
                                        <Tag color={statusColorHelper(status)}>{statusHelper(status)}</Tag>
                                    )
                                })}
                                </Space>
                            </div>
                        </div>
                        <div>
                            <List
                                itemLayout="horizontal"
                                //Remove Task.price from the list
                                dataSource={filteredData}
                                bordered
                                renderItem={item => (
                                    <List.Item
                                    {...statusAxiosReturnColorHelper(item.axiosStatus).badgeText === "Fehler" ? {...{style: {backgroundColor: "gray"}}} : null}
                                    >
                                        <List.Item.Meta
                                            avatar={<Badge status={statusAxiosReturnColorHelper(item.axiosStatus).badgeColorAntd} text={statusAxiosReturnColorHelper(item.axiosStatus).badgeText} />}
                                            title={<>Vorgang: {item.title}</>}
                                            
                                            description={<><div>
                                            <Descriptions size="small" column={4}>
                                                <Descriptions.Item label="Artikel / Aufgabe"><Typography.Text type="primary">{item.task.title}</Typography.Text></Descriptions.Item>
                                                <Descriptions.Item label="Gewählter Status"><Typography.Text type="primary">{statusHelper(item.status)}</Typography.Text></Descriptions.Item>
                                                <Descriptions.Item label="Interner Artikel"><Typography.Text type="primary"><Tag color={statusColorHelper(item.status)}>Artikel: {item.task.uniqueArticleNumber}</Tag></Typography.Text></Descriptions.Item>
                                                <Descriptions.Item label="Leistungsdatum"><Typography.Text type="primary">{item.createdAt}</Typography.Text></Descriptions.Item>
                                                <Descriptions.Item label="Stückmenge"><Typography.Text type="primary">{item.task.quantity} (nicht die Menge wie oft der Vorgang erstellt wurde)</Typography.Text></Descriptions.Item>
                                            </Descriptions>
                                            </div></>}
                                        />
                                        <div>
                                            <Button disabled onClick={() => console.log(item)} type="secondary">
                                            
                                            </Button>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>
                        <div>
                            <Typography.Text type="secondary">Hier findest Du eine übersicht über deine zuletzt erstellten Vorgänge. Zum löschen oder ändern deiner Vorgänge besuche bitte die "Datenbank".</Typography.Text>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    )
};

export { LastEntriesModal };