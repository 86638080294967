import { Empty, Table } from "antd";
import React, { useEffect, useState } from "react";
import { customerColumns } from "./configs/customerColumns";


function CustomerTable(props) {

    const data = props.Data;
    const [isEmpty, setIsEmpty] = useState(true);

    useEffect(() => {
        if (data.length > 0) {
            setIsEmpty(false);
        } else {
            setIsEmpty(true);
        };
       
    }, [data]);

    if (isEmpty) {
        return (
            <div>
                <Empty description="Es sind keine Kunden vorhanden" />
            </div>
        );
    } else {
        return (
            <div>
                <Table
                    columns={customerColumns}
                    dataSource={data}
                    pagination={false}
                    scroll={{ y: 500 }}
                    loading={props.Loading} 
                />
            </div>
        );
    };
};

export default CustomerTable;