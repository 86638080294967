import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();

export async function v4_getAccountingCostsByProjectId(_id) {
    try {
        const response = await axiosInstance.get(`${API_URL}/accounting/costs/${_id}`, { headers: { Operation: `Abrufen des Projekts ${_id}` } });
        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal('Fehler beim Abrufen der Kosten', error?.response?.data?.error, error?.response?.data?.stack);
    };
};

export async function v4_uploadAccountingCosts(file) {
    try {
        const token = await auth.currentUser.getIdToken();
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(`${API_URL}/accounting/costs/upload`, formData, { headers: { Authorization: `Bearer ${token}` } });
        displaySuccessNotification("Die datensätze wurden erfolgeich Transformiert, bitte überprüfen Sie die Daten und speichern Sie die Daten.");
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal('Fehler beim Hochladen der Daten', error?.response?.data?.error, error?.response?.data?.stack);
        return null;
    };
};

export async function v4_confirmUpload(data) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.post(`${API_URL}/accounting/costs/confirm`, { data: data }, { headers: { Authorization: `Bearer ${token}` } });
        displaySuccessNotification(`Status: ${response.status} - ${response.statusText}`);
        return true;
    } catch (error) {
        console.error(error);
        displayErrorModal('Fehler beim Bestätigen der Daten', error?.response?.data?.error, error?.response?.data?.stack);
        return null;
    };
};

export async function v4_sollValues_get_dataset(_id) {
    try {
        const response = await axiosInstance.get(`${API_URL}/accounting/sollValues/productivityquotes/${_id}`, { headers: { Operation: `READ/ Abrufen des Template Soll-Werte Katalogs für ${_id}` } });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        displayErrorModal('Fehler beim Abrufen des Sollwert-Katalogs', error?.response?.data?.error, error?.response?.data?.stack);
        return null;
    };
};

export async function v4_sollValues_get_dataset_template(_id) {
    try {
        const response = await axiosInstance.post(`${API_URL}/accounting/sollValues/productivityquotes/template/${_id}`,{
            endDate: new Date(new Date().setMonth(new Date().getMonth() + 60)).toISOString()
        } , { headers: { Operation: `CREATE/ Abrufen des Template Soll-Werte Katalogs für ${_id}` } });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        displayErrorModal('Fehler beim Abrufen des Sollwert-Katalogs', error?.response?.data?.error, error?.response?.data?.stack);
        return null;
    };
};

export async function v4_setSollKpi_Weeks(_id, body) {
    try {
        const response = await axiosInstance.patch(`${API_URL}/accounting/sollValues/productivityquotes/sollKpi/${_id}`, body, { headers: { Operation: `Update soll-values` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification('Sollwerte erfolgreich geändert');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal('Fehler beim Ändern der Sollwerte', error?.response?.data?.error, error?.response?.data?.stack);
    };
};

export async function v4_singleCost_booking(_id, body) {
    try {
        const response = await axiosInstance.post(`${API_URL}/accounting/costs/manual/cost/${_id}`, body, { headers: { Operation: `Buchen von Einzelkosten` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification('Kosten erfolgreich gebucht');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal('Fehler beim Buchen der Kosten', error?.response?.data?.error || error?.response?.status, error?.response?.data?.stack);
    };
};