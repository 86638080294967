import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteStreamDoc, fetchCustomFields, fetchMongoData } from "../../../data/fetchData";
import { HeaderWithBackButton } from "../../global/HeaderBackButton";
import { hardCodedFieldsAntd } from "../../../data/baseData";
import SimpleBackdrop from "../../global/LoadingBackdrop";
import { useSelector } from "react-redux";
import { Button, Input, Popconfirm, Space, Table, Tag, Typography } from "antd";
import { displayInfoNotification } from "../../../utils/messageServices/notificationService";
import { displayErrorMessage, displaySuccessMessage } from "../../../utils/messageServices/messageService";
import { statusArray, statusColorHelper, statusHelper } from "../../../utils/helpers/statusHelper";
import _ from "lodash";
import { TitleImage } from "../project/PublicProjectPage";
import { DatabaseElementDetailModal } from "../../modals/DatabaseElementDetail.modal";

const { Text, Paragraph, Title } = Typography;


function DatabasePage() {

  const [projectData, setProjectData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { mongoId } = useParams();
  const project = useSelector((state) => state.projectState.selectedProject);
  const [antdColumns, setAntdColumns] = useState([]);
  const [databaseElementDetailModalVisible, setDatabaseElementDetailModalVisible] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(projectData);

  React.useEffect(() => {
    setFilteredData(_.filter(projectData, (task) => {
      const { title, createdBy, } = task;
      const searchValueLower = searchValue.toLowerCase();
      return (
        title?.toLowerCase().includes(searchValueLower) ||
        (createdBy?.firstName?.toLowerCase().includes(searchValueLower)) ||
        (createdBy?.lastName?.toLowerCase().includes(searchValueLower))
      );
    }));
  }, [searchValue, projectData]);

  const detailsColumn = {
    title: null,
    dataIndex: '_id',
    render: (_, record) =>
      <Button type="primary" onClick={() => {
        setSelectedElement(record);
        setDatabaseElementDetailModalVisible(true);
      }} >
        Details anzeigen
      </Button>,
    // fixed: 'right',
    width: 180,
  };

  const fetchColums = () => {
    setIsFetching(true);
    fetchCustomFields(mongoId)
      .then(data => {
        setAntdColumns([detailsColumn, ...hardCodedFieldsAntd, ...convertAntdColumns(data.data)]);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(async () => {
        await fetchData();
      });
  };

  const handleDelete = async (record) => {
    await deleteStreamDoc(mongoId, record.id)
      .then((result) => {
        console.log(result);
        displaySuccessMessage("Erfolgreich", "Der Eintrag wurde erfolgreich gelöscht.");
      }).catch((err) => {
        console.log(err);
        displayErrorMessage("Fehler", "Der Eintrag konnte nicht gelöscht werden.");
      });

    await fetchData();

  };

  //Serialisieren der Type-Felder
  const convertAntdColumns = (data) => {
    const newData = data.map((field) => {
      let modifiedFieldType = "";
      switch (field.fieldType) {
        case "String":
          modifiedFieldType = "text";
          break;
        case "Number":
          modifiedFieldType = "number";
          break;
        case "Date":
          modifiedFieldType = "text";
          break;
        default:
          modifiedFieldType = "text";
      }
      console.log(field);
      return { ...field, fieldType: modifiedFieldType, id: field._id, title: field.fieldName, dataIndex: field.fieldNameId, render: (t) => <Typography.Text>{_.truncate(t, { length: 50 })}</Typography.Text> };
    });

    return newData;
  };

  const convertData = (data) => {
    const newData = data.map((field) => {
      const { _id, ...rest } = field;
      return { ...rest, id: _id };
    });
    return newData;
  };


  async function fetchData() {
    setIsFetching(true);
    await fetchMongoData(mongoId)
      .then((data) => {
        setProjectData(convertData(data.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsFetching(false);
        displayInfoNotification(`Es wurde ein aktuelles Dataset geladen.`);
      });
  }

  useEffect(() => {
    fetchColums();
    // eslint-disable-next-line
  }, [])

  const handleSearch = (value) => {
    setSearchValue(value);
  }

  React.useEffect(() => {
    //Fensternamen setzen
    document.title = `Datenübersicht ${project?.title}`;
    return () => {
      document.title = "Assembly";
    };
  }, [project]);


  return (
    <>
    <TitleImage project={project} props={{title: `Datenübersicht ${project?.title}`}} />
    <HeaderWithBackButton backLink={`/v2/projects/project/${mongoId}`} />
    <div style={{
        marginTop: 5,
      }}>
        <div>
          <div>
            <div style={{
              marginBottom: "1rem",
            }}>
              <br /><br /><br />
              <Typography.Text type="primary">Legende der Statusfarben</Typography.Text>
              <br />
              <Space>
                {project?.defaultTaskStatusSelections?.map((status) => {
                  return (
                    <Tag color={statusColorHelper(status)}>{statusHelper(status)}</Tag>
                  )
                }) ?? statusArray.map((status) => {
                  return (
                    <Tag color={statusColorHelper(status)}>{statusHelper(status)}</Tag>
                  )
                })}
              </Space>
            </div>
          </div>
          <div>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
              marginTop: "1.5rem",
            }}>
              {/* Search field for the table */}
              <Input.Search
                placeholder="Nach Vorgangsnummer oder Person suchen"
                enterButton
                size="large"
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div style={{
          height: 900,
          width: '100%'
        }}>

          {isFetching && <div>
            <SimpleBackdrop open={isFetching} />
          </div>}
          {!isFetching && projectData.length > 0 && (
            <div style={{
              // zIndex: -1,
            }}>
              <Table
                dataSource={filteredData}
                columns={antdColumns}
                pagination={{ pageSize: 100 }}
                scroll={{ y: 500, x: 2500 }}
                rowKey="_id"
                bordered
                size="small"
                style={{
                  width: '100%',
                }}
                footer={() => {
                  return (
                    <Space>
                      <Text type="secondary">Anzahl der Einträge: {filteredData?.length || "?"}</Text>
                    </Space>
                  )}}
              />
            </div>
          )}
          {!isFetching && projectData.length === 0 && (
            <div>No data available</div>
          )}
        </div>

      </div>
      <DatabaseElementDetailModal open={databaseElementDetailModalVisible} onClose={async () => {
        setDatabaseElementDetailModalVisible(false);
        await fetchData();
        setSelectedElement(null);
      }} element={selectedElement} _id={mongoId} fields={antdColumns} />
    </>
  )
};



export default DatabasePage;
