import { Alert, Button, Descriptions, Drawer, Popconfirm, Space, Tag } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { deleteStreamDoc, fetchCustomFields } from "../../data/fetchData";
import { statusColorHelper, statusHelper } from "../../utils/helpers/statusHelper";
import { displayErrorMessage, displaySuccessMessage } from "../../utils/messageServices/messageService";
import { DatabaseEditModal } from "./DatabaseEdit.modal";

const { Item } = Descriptions;

export function DatabaseElementDetailModal(props) {
    const { element, fields, open, _id } = props;
    const t = _.isObject(element) ? element?.task : {};
    console.log(fields)
    const [customFields, setCustomFields] = React.useState([]);
    const [visible, setVisible] = React.useState(false);

    async function getCustomFields() {
        try {
            const response = await fetchCustomFields(_id);
            setCustomFields(response.data);
            console.log(element);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async () => {
        await deleteStreamDoc(_id, element.id)
          .then((result) => {
            console.log(result);
            displaySuccessMessage("Erfolgreich", "Der Eintrag wurde erfolgreich gelöscht.");
          }).catch((err) => {
            console.log(err);
            displayErrorMessage("Fehler", "Der Eintrag konnte nicht gelöscht werden.");
          })
          .finally(async () => {
            await props.onClose();
          });
    
      };

    useEffect(() => {
        if (open && _id) {
            getCustomFields();
        };

        return () => {
            setCustomFields([]);
        };
    }, [open, _id, element]);

    return (
        <Drawer
            title={"Vorgang " + element?.id + " | " + _.truncate(t?.title, { length: 30 }) + " | Beschr.: " + _.truncate(element?.title, { length: 30 })}
            placement="right"
            closable={true}
            onClose={() => { props.onClose() }}
            open={open}
            width={"100%"}
            footer={<>
                <Space>
                    <Button type="dashed" onClick={() => { props.onClose() }}>Schließen</Button>
                    <Popconfirm title="Möchtest du diesen Eintrag wirklich bearbeiten?" onConfirm={() => setVisible(true)}
                    okText="Ja" cancelText="Nein"
                    okButtonProps={{ type: "link", danger: false }} cancelButtonProps={{ type: "primary" }}
                    >
                        <Button type="primary">Bearbeiten</Button>
                    </Popconfirm>
                    <Popconfirm title="Möchtest Du wirklich diesen Eintrag löschen?" onConfirm={() => handleDelete(element)}
                    okText="Ja, Vorgang löschen" cancelText="Nein"
                    okButtonProps={{ type: "link", danger: true }} cancelButtonProps={{ type: "primary" }}
                    >
                        <Button type="primary" danger>Löschen</Button>
                    </Popconfirm>
                   
                </Space>
            </>}
        >
        <Alert  message="Wir arbeiten gerade an der Funktion zum bearbeiten der hier vorhandenen Einträge..." type="info" showIcon style={{ marginBottom: 20}} />
        {element?.status === "completed" && <Alert  message={<>Dieser Vorgang wurde bereits gebucht. <Tag color={statusColorHelper(element?.status)}>{statusHelper(element?.status)}</Tag></>} type="success" showIcon style={{ marginBottom: 20}} />}
        {element?.status === "on hold" && <Alert  message={<>Dieser Vorgang wurde noch nicht gebucht. <Tag color={statusColorHelper(element?.status)}>{statusHelper(element?.status)}</Tag></>} type="warning" showIcon style={{ marginBottom: 20}} />}
        {element?.status !== "on hold" && element?.status !== "completed" ? <Alert  message={<>Sind Sie sicher, das dies der Richtige Status ist? <Tag color={statusColorHelper(element?.status)}>{statusHelper(element?.status)}</Tag></>} type="error" showIcon style={{ marginBottom: 20}} /> : null}


            <Descriptions title="Stammdetails" bordered column={2} style={{ marginBottom: 20}}>
                <Item label="Titel">{element?.title}</Item>
                <Item label="Leistung">{element?.task?.title}</Item>
                <Item label="Leistungsmenege">{Number(element?.task?.quantity).toFixed(2)} {element?.task?.quantityUnit}/Einheiten</Item>
                <Item label="Artikelnummer">{element?.task?.uniqueArticleNumber}</Item>
                <Item label="Integrität">Am {new Date(element?.createdAt)?.toLocaleString()} erstellt von {element?.createdBy?.firstName} {element?.createdBy?.lastName} | Geändert: Am {new Date(element?.updatedAt)?.toLocaleString()}</Item>
            </Descriptions>

        <Descriptions title="Vorgangsdetails" bordered column={1} style={{ marginBottom: 20}}>
        {customFields.map((f) => {
            const { fieldNameId, fieldName, fieldType, editable } = f;
            return (
                <Descriptions.Item key={fieldNameId} label={fieldName}>
                {!_.isObject(element[fieldNameId]) && !_.isArray(element[fieldNameId]) ? element[fieldNameId] : null}
                </Descriptions.Item>
            )
        })}
        </Descriptions>
        <DatabaseEditModal open={visible} onClose={() => {
            setVisible(false);
            props.onClose();
        }} element={element} project_id={_id} customFields={customFields} />
        </Drawer>
    )
};