import { Chip } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Avatar, Button, Descriptions, Space, Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../routing/userRouting";
import { logOutUser } from "../../firebase";
import { resetStore } from "../../redux/store";
import { useDispatch } from "react-redux";
import _ from "lodash";


function UserProfileDetailsComponent() {

    const { uid } = useParams();
    const user = useSelector((state) => state.userState.mongoUser);
    const firId = useSelector((state) => state.userState.firId);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleResetPassword = async () => {
        await resetPassword(firId)
            .then((result) => {
                console.log(result);
                logOutUser();
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                navigate('/login');
                dispatch(resetStore());
            })
    };

    const addPhoneNumber = () => {
        console.log("Add Phone Number");
    }

    return (
        <>
            <div>

            <Descriptions title={`User Info zu ${user.firstName} ${user.lastName}`} bordered>
                            <Descriptions.Item label="Profilbild">

                                <Avatar
                                    src={user.imageUrl || "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8UG9ydHIlQzMlQTR0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"}
                                    alt="User"
                                    sx={{ width: 96, height: 96 }}
                                />

                            </Descriptions.Item>
                            <Descriptions.Item label="Name" span={2}>

                                {user.firstName} {user.lastName}

                            </Descriptions.Item>
                            <Descriptions.Item label="UserId">

                                {uid}

                            </Descriptions.Item>
                            <Descriptions.Item label="FirId">

                                {firId}

                            </Descriptions.Item>
                            
                            {/* <Descriptions.Item label="Sicherheit">

                                <Tooltip title="Ändert sofort dein Passwort, loggt dich danach aus dem System aus und sendet dir ein neues Passwort zu. Aus sicherheitsgründen kannst du selbst keines erstellen.">
                                    <Button type="primary" onClick={handleResetPassword} sx={{
                                        marginRight: 40,
                                        marginLeft: 40
                                    }}>
                                        Passwort ändern
                                    </Button>
                                </Tooltip>

                            </Descriptions.Item> */}

                            <Descriptions.Item label="Account Status">

                                <Stack direction="row" alignItems="center">

                                    <Box sx={{ flex: 1 }} />
                                    {!_.isEmpty(user.systemPositions) && user.systemPositions === "administrator" && (
                                        <>
                                            <Chip label="Administratorrechte (System-Verwaltung)" variant="outlined" color="success" />
                                        </>
                                    )}
                                    {!_.isEmpty(user.systemPositions) && user.systemPositions === "project-lead" && (
                                        <>
                                            <Chip label="Projektleiter (Erweiterte Bearbeitung)" variant="outlined" color="inherit" />
                                        </>
                                    )}
                                    {!_.isEmpty(user.systemPositions) && user.systemPositions === "consultant" && (
                                        <>
                                            <Chip label="Projektbetreuuer*in (Abgestimmte Bearbeitungsrechte)" variant="outlined" color="inherit" />
                                        </>
                                    )}
                                </Stack>

                            </Descriptions.Item>
                            <Descriptions.Item label="Weitere Einstellungen" span={2}>
                                <Stack>
                                    <p>Deine Einstellungen werden von deiner Organisation verwaltet.</p>
                                </Stack>
                            </Descriptions.Item>
                        </Descriptions>
            </div>
        </>
    );

};

export default UserProfileDetailsComponent;