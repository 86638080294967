import QuestionMarkRounded from "@mui/icons-material/QuestionMarkRounded";
import { Avatar, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

export function EmptyDataPaper({ children }) {
    return (
        <Paper elevation={2}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '50vh' }}>
                <Stack sx={{ alignItems: 'center' }} spacing={3} >
                    <Avatar sx={{ width: 100, height: 100, bgcolor: 'orange' }}>
                        <QuestionMarkRounded sx={{ fontSize: 80 }} />
                    </Avatar>
                    <Typography variant="h6">
                        So wie es aussieht sind keine Daten vorhanden, möchten Sie welche hinzufügen?
                    </Typography>
                    {children}
                </Stack>
            </Box>
        </Paper>
    );
};