import { Typography, Drawer } from "antd";
import React from "react";
import { NewUserForm } from "../forms/NewUserForm";

const { Text } = Typography;

function AddUserModal(props, { onFinally }) {

    const handleCancel = () => {
        props.setOpen(false);
    };

    const handleFinish = (values) => {
        props.setOpen(false);
        props.HandleCreatedUser(values);
    };

    return (
        <>
            <div>
                <Drawer
                    open={props.OpenDialog}
                    onClose={handleCancel}
                    // onCancel={handleCancel}
                    destroyOnClose={true}
                    footer={
                        null
                    }
                    loading={false}
                    width={"40%"}
                    title={<Text>Neuen Nutzer anlegen</Text>}
                >
                    <div style={{
                        marginBottom: "20px",
                        textAlign: "justify",

                    }}>
                        <Text type="secondary">
                            Bitte die Daten des neuen Nutzers eintragen. Eine sharePointId ist nicht zwingend erforderlich.
                            <br />
                            Sofern der Nutzer auf Projekte gebucht werden muss, bitte ein entsprechendes Projekt auswählen.
                        </Text>
                    </div>
                    <NewUserForm handleCancel={handleCancel} handleFinish={handleFinish} />
                </Drawer>
            </div>
        </>
    );
};

export { AddUserModal };