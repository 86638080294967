export function ValueConverter(value, expectedType) {
    try {
        switch (expectedType) {
            case "string":
                return String(value);
            case "number":
                let wv = value;
                console.log("Vor der Konvertierung:", wv);
                if (typeof wv === "string" && wv.includes(",")) {
                    wv = Number(wv.replace(",", "."));
                    console.log("Nach dem Komma-Ersatz:", wv);
                }
                const numValue = Number(wv);
                console.log("Nach der finalen Konvertierung:", numValue);
                if (isNaN(numValue)) {
                    throw new Error("Invalid number");
                }
                return numValue;
            case "boolean":
                if (typeof value === "boolean") {
                    return value;
                } else {
                    throw new Error("Invalid boolean");
                }
            default:
                throw new Error("Unsupported expectedType");
        }
    } catch (error) {
        console.error(`Error converting value to ${expectedType}: ${error.message}`);
        return null; // Oder einen geeigneten Standardwert, je nach Bedarf
    }
}
