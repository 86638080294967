import _ from "lodash";
import store from "../store";
import { slice_upadteAutoSession, slice_updateAutoSelect_0 } from "../mainSlicer";
import { updateSelectedproject } from "../projectSlicer";
import { invokeSessionWithProject, refetchUpdatedProjectsArray } from "../../config/session/session";

/**
 * @description Updates given value the slice_updateAutoSelect_0 to the given objectPath
 * @param {string} objectPath 
 * @param {*} value 
 */
export function automaticUpdate_AS0(objectPath, value) {
    const currentState = store.getState().mainState.autoSelect_0;
    const newState = { ...currentState };

    if (!_.isString(objectPath)) {
        try {
            objectPath = objectPath.toString();
        } catch (error) {
            console.log(error);
            return null;
        };
    };

    //Set the new value at objecPath
    _.set(newState, objectPath, value);

    store.dispatch(slice_updateAutoSelect_0(newState));
    return true;
};
/**
 * @description Updates given value the slice_updateAutoSelect_0 to the given objectPath
 * @param {string} objectPath 
 * @param {*} value 
 */
export function automaticUpdate_AutoSession(objectPath, value) {
    const currentState = store.getState().mainState.autoSession;
    const newState = { ...currentState };

    if (!_.isString(objectPath)) {
        try {
            objectPath = objectPath.toString();
        } catch (error) {
            console.log(error);
            return null;
        };
    };

    //Set the new value at objecPath
    _.set(newState, objectPath, value);

    store.dispatch(slice_upadteAutoSession(newState));
    return true;
};


/**
 * @description Updates given value the slice_projectState to the given objectPath
 * @param {string} objectPath
 * @param {*} value
 */
 export function automaticUpdate_ProjectState(objectPath, value) {
    const currentState = store.getState().projectState.selectedProject;
    const newState = { ...currentState };

    if (!_.isString(objectPath)) {
        try {
            objectPath = objectPath.toString();
        } catch (error) {
            console.log(error);
            return null;
        };
    };

    //Set the new value at objecPath
    _.set(newState, objectPath, value);
    store.dispatch(updateSelectedproject(newState));
    refetchUpdatedProjectsArray();
    invokeSessionWithProject(newState);
    return true;
};

/**
 * @description Updates given value the slice_projectState to the given objectPath
 * @param {string} objectPath
 * @param {*} value
 * @description This function does not refetch the projects array
 */
export function automaticUpdate_ProjectStateWithoutRefetch(objectPath, value) {
    const currentState = store.getState().projectState.selectedProject;
    const newState = { ...currentState };

    if (!_.isString(objectPath)) {
        try {
            objectPath = objectPath.toString();
            console.log(objectPath);
        } catch (error) {
            console.log(error);
            return null;
        };
    };

    //Set the new value at objecPath
    _.set(newState, objectPath, value);
    console.log(newState);
    store.dispatch(updateSelectedproject(newState));
    return true;
};

/**
 * @description Updates given value the slice_projectState to the given objectPath
 * @param {string} objectPath
 * @param {*} value
 */
export function automaticUpdate_ProjectLocationState(objectPath, value) {
    const currentState = store.getState().projectState.selectedProject;
    const newState = { ...currentState };

    if (!_.isString(objectPath)) {
        try {
            objectPath = objectPath.toString();
        } catch (error) {
            console.log(error);
            return null;
        };
    };

    // //Set the new value at objecPath
    newState.projectLocation = { ...newState.projectLocation, [objectPath]: value };

    store.dispatch(updateSelectedproject(newState));
    refetchUpdatedProjectsArray();
    invokeSessionWithProject(newState);
    return true;
};

