import axios from "axios";
import { auth } from "../../firebase";
import { v3_setAPI } from "../../routing/api";
import { displayErrorMessage, displayInfoMessage, displaySuccessMessage } from "../messageServices/messageService";
import { automaticUpdate_AS0 } from "../../redux/updateUtils/automaticUpdate";
import { sendEmailVerification } from "firebase/auth";

const API_URL = v3_setAPI();

export async function util_UserUpdate(userId, fieldName, fieldValue) {
    try {
      const token = await auth.currentUser.getIdToken();
      const task = await axios.post(`${API_URL}/users/user/update/${userId}`, { 
        fieldName: fieldName,
        fieldValue: fieldValue
       }, { headers: { Authorization: `Bearer ${token}` }});

       if (task.status === 200) {
        displaySuccessMessage("Erfolgreich", "Der Benutzer wurde erfolgreich aktualisiert.");
        console.log(task.data.user);

        automaticUpdate_AS0("user", task.data.user);
         return true;
       } else {
        displayErrorMessage("Fehler", "Der Benutzer konnte nicht aktualisiert werden.")
         throw new Error("Something went wrong while updating the user.")
       }
    } catch (error) {
      console.log(error);
      return null;
    };
  };

  /**
   * 
   * @param {FirebaseId} docId  - The id of the user document in the firestore database
   * @param {Object} values - The values that should be updated (email, mobileNumber)
   * @returns 
   */
  export async function util_UserUpdateFS(docId, values) {
    try {
      const token = await auth.currentUser.getIdToken();
      const task = await axios.post(`${API_URL}/users/user/update/fs/${docId}`, { 
        ...values
       }, { headers: { Authorization: `Bearer ${token}` }});

       if (task.status === 200) {
        displaySuccessMessage("Account verändert", "Der Account wurde erfolgreich aktualisiert. Bis Änderungen sichtbar sind, können einige Minuten vergehen.");
        console.log(task.data.user);
         return true;
       } else {
        displayErrorMessage("Fehler", "Der Benutzer konnte nicht aktualisiert werden.")
         throw new Error("Something went wrong while updating the user.")
       }
    } catch (error) {
      console.log(error);
      return null;
    };
  };