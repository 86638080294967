import { Button, DatePicker, Drawer, Form, Input, InputNumber, Select, Space, Tag } from "antd";
import React, { useRef, useState } from "react";
import datePickerLocale from "../../../locale/datePickerLocale.json"
import { FAQCostModal } from "../faq/FAQCostModal";
// import { useSelector } from "react-redux";
import { globalCostTypes } from "../../../data/baseData";
import { v4_singleCost_booking } from "../../../routing/v4_Routes/projectManagement.routes";
import dayjs from "dayjs";


function NewVarCostModal(props) {

    const _id = props._id
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const [showFAQ, setShowFAQ] = useState(false);

    // const users = useSelector((state) => state.projectState.selectedProject?.allowedUserId || []);

    const customizeRequiredMark = (label, { required }) => (
        <>
            {required ? <Tag color="error">erforderlich</Tag> : <Tag color="warning">optional</Tag>}
            {label}
        </>
    );

    const handleFinish = async (values) => {
        setLoading(true);
        values.costDate = dayjs(values.date).toISOString();
        console.log(values);

        const task = await v4_singleCost_booking(_id, values);
        if (task !== null) {
            setLoading(false);
            handleAbort();
        } else {
            setLoading(false);
        };
    };

    const handleAbort = () => {
        form.current.resetFields();
        props.close();
    };

    return (
        <Drawer
            open={props.open}
            onCancel={handleAbort}
            closable={true}
            title="Einzelkosten buchen"
            confirmLoading={loading}
            onClose={handleAbort}
            width={"30%"}
            footer={
                <div
                    style={{
                        textAlign: "left",
                    }}
                >
                    <Space>
                    <Button onClick={handleAbort} type="default">
                        Abbrechen
                    </Button>
                    <Button onClick={() => setShowFAQ(true)} type="primary">
                        FAQ öffnen
                    </Button>
                    </Space>
                </div>
            }
        >
            <Form
                layout="vertical"
                name="newVarCostForm"
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
                ref={form}
                initialValues={{
                    canBeSplitOnCron: false,
                    splitCosts: false,
                    active: true,
                }}
            >
            <Form.Item label="Titel"
                    name="title"
                    required={[
                        {
                            required: true,
                            message: "Bitte geben Sie einen Titel ein.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Kostenbeschreibung/Name"
                    name="description"
                    required={[
                        {
                            required: true,
                            message: "Bitte geben Sie eine Kostenbeschreibung/Name ein.",
                        },
                    
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Typ/Art"
                name={"type"}
                required={[
                    {
                        required: true,
                        message: "Bitte geben Sie einen Typ/Art ein.",
                    },
                
                ]}
                tooltip="Was für Kosten sind es? (z.B. Miete, Strom, Gehälter, etc.) - Die Angabe ist extrem wichtig für Power BI und die Auswertung."
                >
                    <Select
                        placeholder="Bitte wählen"
                        options={globalCostTypes}
                    />
                </Form.Item>

                {/* <Form.Item label="Kostenzuordnung an User"
                    name="refuser"
                    tooltip="Sofern die Kosten einem User zugeordnet werden sollen, wählen Sie hier den User aus."
                >
                    <Select
                        placeholder="Search to Select"
                        allowClear
                        options={users.map((user) => {
                            return {
                                label: `${user.firstName} ${user.lastName}`,
                                value: user._id,
                            };
                        })}
                    />
                </Form.Item> */}

                <Form.Item label="Betrag (netto)"
                    name="value"
                    required={[
                        {
                            required: true,
                            message: "Bitte geben Sie einen Betrag ein.",
                        },
                    
                    ]}
                >
                    <InputNumber type="decimal" decimalSeparator="," lang="de-DE" />
                </Form.Item>


                <Form.Item label="Datum"
                    name="date"
                    tooltip="Das Datum an dem die Kosten entstanden sind. Oder der Vertrag gestartet ist."
                    required={[
                        {
                            required: true,
                            message: "Bitte geben Sie ein Datum ein.",
                        },
                    ]}
                >
                    <DatePicker locale={datePickerLocale} />
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        Speichern
                    </Button>
                </Form.Item>

            </Form>
            <FAQCostModal open={showFAQ} closeFAQModal={() => setShowFAQ(false)} />
        </Drawer>
    );
};

export { NewVarCostModal };