import { redirect } from "react-router-dom";
import { auth, logOutUser } from "../../firebase";
import store from "../../redux/store";
import { fetchCurrentUserV1 } from "../fetchUsers";
import _ from "lodash";
import { route_syncUserDataHardSync } from "../../routing/v3_Routes/v3_Routes";
import { adminSidebarItems, allProjectsSidebarItems, hrSidebarIstems, plSidebarItems, pmaSidebarItems, projectAssistanceItems, returnProjectsAsSidebarChildren } from "../../layout/config/sidebarItems";
import { displayWarningMessage } from "../../utils/messageServices/messageService";


async function userHasRequestedRole(requestedRoles) {
    let user = store.getState().userState.firebaseUser;

    if (_.has(user, "role") === false) {
        // Der Nutzer hat keine Rollen - Updaten des Profils im Backend und dann ausloggen.
       const result = await route_syncUserDataHardSync(user.mongoRef)
        .then(async () => {
            user = await fetchCurrentUserV1(auth.currentUser);
            if (_.has(user, "role") === false) {
                await logOutUser();
                redirect("/login");
                return { result: false, role: ""};
            } else {
                console.log("Der Nutzer hat eine Rolle - Weitermachen.");
                    if (_.isEmpty(requestedRoles) === false) {
                        const role = user.role;
                        console.log("Die Rolle des Nutzers ist: " + role + " - Die angeforderte Rolle ist: " + requestedRoles + " - Das Ergebnis ist: " + _.includes(requestedRoles, role))
                        const hasRole = _.includes(requestedRoles, role);
                        if (hasRole === true) {
                            console.log("Der Nutzer hat die Rolle: " + role + " - Weitermachen.");
                            return { result: true, role: role};
                        } else {
                            console.log("Der Nutzer hat nicht die Rolle: " + role + " - Abbrechen.");
                            return { result: false, role: role};
                        };
                    } else {
                        console.log("Es wurde keine Rolle angefordert - Weitermachen mit FALSE.");
                        return { result: false, role: ""};
                    };
            }
        })
        .catch((err) => {
            console.log(err);
            return { result: false, role: ""};
        });
        return result;
    } else {
        if (_.isEmpty(requestedRoles) === false) {
            const role = user.role;
            console.log("Die Rolle des Nutzers ist: " + role + " - Die angeforderte Rolle ist: " + requestedRoles + " - Das Ergebnis ist: " + _.includes(requestedRoles, role))
            const hasRole = _.includes(requestedRoles, role);
            if (hasRole === true) {
                console.log("Der Nutzer hat die Rolle: " + role + " - Weitermachen.");
                return { result: true, role: role};
            } else {
                console.log("Der Nutzer hat nicht die Rolle: " + role + " - Abbrechen.");
                return { result: false, role: role};
            };
        } else {
            console.log("Es wurde keine Rolle angefordert - Weitermachen mit FALSE.");
            return { result: false, role: "" };
        };
    };
};


export function getUsersCurrentRole() {
    let user = store.getState().userState.firebaseUser;

    if (_.has(user, "role") === false) {
        return {result: false, role: ""};
    } else {
        return { result: true, role: user.role};
    };
}

export function returnSidebarItems(role, uid, projects) {
    switch (role) {
        case "admin":
            console.debug("Admin-Items");
            return adminSidebarItems(uid, (returnProjectsAsSidebarChildren(projects)));
        case "manager":
            console.debug("Manager-Items");
            return plSidebarItems(uid, (returnProjectsAsSidebarChildren(projects)));
        case "consultant":
            console.debug("Consultant-Items");
            return allProjectsSidebarItems(uid, returnProjectsAsSidebarChildren(projects));
        case "projectLead":
            console.debug("ProjectLead-Items");
            return plSidebarItems(uid, (returnProjectsAsSidebarChildren(projects)));
        case "hr":
            console.debug("HR-Items");
            return hrSidebarIstems(uid, (returnProjectsAsSidebarChildren(projects)));
            case "project-assistance":
            console.debug("Assistance-Items");
            return projectAssistanceItems(uid, (returnProjectsAsSidebarChildren(projects)));
        default:
            console.debug("PMA-Items");
            return pmaSidebarItems(uid, (returnProjectsAsSidebarChildren(projects)));
    };
};


export async function checkRequestedRole(requestedRole) {
    try {
        let returnResult = null;
    const user = store.getState().userState.firebaseUser;
    if (_.has(user, "role") === false) {
        console.log("Der Nutzer hat keine Rollen - Erneutes abrufen erforderlich.")
        await fetchUserRole()
        .then((result) => {
                if (result.role === requestedRole) {
                    returnResult = { result: true, role: result.role};
                    return;
                } else {
                    returnResult = { result: false, role: result.role};
                    return;
                };
        })
        .catch((err) => {
            console.log("Fehler", err);
            returnResult = { result: false, role: null};
            return;
        });
        return returnResult;
    } else {
        if (user.role === requestedRole) {
            returnResult = { result: true, role: user.role};
        } else {
            returnResult = { result: false, role: user.role};
        };
    };
    console.log(returnResult);
    return returnResult;
    } catch (error) {
        let returnResult = { result: false, role: null};
        console.log(error);
        return returnResult;
    }
};

async function checkRole(role) {
    try {
        const returnResult = await checkRequestedRole(role);
        console.log(returnResult.result);
        console.log(returnResult.role);
    } catch (error) {
        console.error(error);
    }
};

async function fetchUserRole() {
    let user = store.getState().userState.firebaseUser;

    if (_.has(user, "role") === false) {
        // Der Nutzer hat keine Rollen - Updaten des Profils im Backend und dann ausloggen.
       const result = await route_syncUserDataHardSync(user.mongoRef)
        .then(async () => {
            user = await fetchCurrentUserV1(auth.currentUser);
            if (_.has(user, "role") === false) {
                await logOutUser();
                redirect("/login");
                return { result: false, role: null};
            } else {
                console.log("Der Nutzer hat eine Rolle - Weitermachen.");
                return { result: true, role: user.role};
            }
        })
        .catch((err) => {
            console.log(err);
            return { result: false, role: null};
        });
        return result;
    } else {
        return { result: true, role: user.role};
    };
};