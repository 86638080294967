import { slice_updateLastDataEntries } from "../../redux/mainSlicer";
import store from "../../redux/store";


export function util_updateLastEntryState(object) {
    const state = store.getState().mainState.lastDataEntries;
    store.dispatch(slice_updateLastDataEntries([...state, object]));
};

export function util_clearLastEntryState() {
    store.dispatch(slice_updateLastDataEntries([]));
};

export function util_updateLastEntryStateWithArray(array) {
    store.dispatch(slice_updateLastDataEntries(array));
};

export function util_clearLastEntryStateAfterTimeout() {
    setTimeout(() => {
        store.dispatch(slice_updateLastDataEntries([]));
    }, 60000);
};