import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Alert, Avatar, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Select, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { HeaderWithBackButton } from "../../global/HeaderBackButton";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { updateSchema } from "../../../routing/writeData";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { createCustomField, getCustomFields, getProject, initProjectSchema } from "../../../routing/crudActions";
import { updateSelectedproject } from "../../../redux/projectSlicer";
import { EmptyDataPaper } from "../../global/EmptyDataPaper";
import { BaseFields } from "../../../data/baseData";
import { converColumsForSchema, trimAndReplace } from "../../../utils/cleanArray";

//Import Icons
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { Button } from "antd";



function ProjectDbSchemaPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dataParam = searchParams.get("data");
    const project = useSelector((state) => state.projectState.selectedProject);

    let data = {};
    if (dataParam) {
        try {
            const decodedData = decodeURIComponent(dataParam);
            data = JSON.parse(decodedData);
        } catch (error) {
            console.error("Error parsing data parameter", error);
        }
    }

    const [edit, setEdit] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showAddFieldDialog, setShowAddFieldDialog] = useState(false);

    const handleEditable = () => {
        setEdit(true);
        setEditDialog(false);
        setShowSnackbar(true);
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'fieldName', headerName: 'Feldname', width: 200, editable: edit },
        { field: 'fieldType', headerName: 'Feld Typ', width: 150, editable: edit },
        { field: 'fieldRequired', headerName: 'Erforderlich', width: 130, editable: edit },
        { field: 'fieldDefaultValue', headerName: 'Standardwert', width: 200, editable: edit },
        { field: 'fieldValidation', headerName: 'Validierung', width: 250, editable: edit },
    ]

    const [_fieldType, _setFieldType] = useState("string");
    const [_systemField, _setSystemField] = useState("text");
    const [_fieldName, _setFieldName] = useState("");
    const [_fieldDefaultValue, _setFieldDefaultValue] = useState("");
    const [_fieldValidation, _setFieldValidation] = useState("");

    const syncSchema = () => {
        updateSchema(project._Id, project.projectId, project)
            .then(result => {
                console.log(result)
            })
            .catch(err => {
                console.log(err)
            });
    };

    const fetchProject = () => {
        getProject(data.mongoId)
            .then((result) => {
                dispatch(updateSelectedproject(result.data))
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(updateFetchingData(false));
            });
    };

    const initSchema = () => {
        dispatch(updateFetchingData(true));
        initProjectSchema(data.mongoId, data.projectId)
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                fetchProject();
            });
    };

    const [fields, setFields] = useState([]);

    const initFields = () => {
        dispatch(updateFetchingData(true));
        getCustomFields(data.mongoId)
            .then((result) => {
                const newArray = _.sortBy(_.remove(_.concat(BaseFields, converColumsForSchema(result.data)), function (n) {
                    return !_.isEmpty(n);
                }), (item) => item.id);
                setFields(newArray);
                console.log(result.data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                console.log(fields)
            });
    };

    const handleFieldSubmit = () => {
        const selectValues = trimAndReplace(_fieldValidation, 'select');

        const formData = {
            fieldName: _fieldName,
            fieldType: _fieldType,
            fieldNameId: _.kebabCase(_fieldName).replace(/ /g, '_').replace(/-/g, '_'),
            editable: true,
            fieldRequired: false,
            projectId: project.projectId,
            projectIdName: project.projectIdName,
            systemField: _systemField,
            fieldDefaultValue: _fieldDefaultValue,
            fieldValidation: _systemField === 'select' ? selectValues : "",
        };

        createCustomField(project._id, formData)
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                initFields();
                setShowAddFieldDialog(false);
            })

        console.log(formData);
    }

    useEffect(() => {
        dispatch(updateFetchingData(true));
        fetchProject();
        initFields();
        
    }, []);


    return (
        <>
            <HeaderWithBackButton backLink="/manage/projects" />
            <Container maxWidth="lg" sx={{ mt: 5 }}>
                <div>
                    <Box sx={{ m: 1, width: "100%" }}>
                            <Box sx={{
                                padding: 3,
                            }}>
                                <Grid2 container>
                                    <Grid2 lg={12} md={12} sm={6} xs={4}>
                                        <Stack direction="row" alignItems="center">
                                            <Avatar
                                                sx={{
                                                    width: '100px',
                                                    height: '100px',
                                                    backgroundColor: 'white'
                                                }}
                                            >
                                                <img
                                                    src={`${project.imageUrl}`}
                                                    alt="Project_Logo_Image"
                                                    width="100px"
                                                    style={{ resize: 'both' }}
                                                    className="ButtonIcons"
                                                />
                                            </Avatar>

                                            <Stack sx={{ flexGrow: 1 }}>
                                                <Typography variant="h4" sx={{ paddingLeft: 2 }}>
                                                    {data.title}
                                                </Typography>
                                                <Typography variant="caption" sx={{ paddingLeft: 2 }}>
                                                    {data.projectId}
                                                </Typography>
                                            </Stack>
                                            {edit && <Chip color="warning" label="In bearbeitung!" />}
                                            <Stack sx={{ justifyContent: 'flex-end' }}>
                                                <Box sx={{
                                                    padding: 1
                                                }}>
                                                    <Stack direction={"row"}>
                                                        <Tooltip title="Sync Schema - destructive">
                                                            <IconButton sx={{ color: "red" }} onClick={syncSchema}>
                                                                <CachedRoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Neues Feld hinzufügen (Unterstützt)">
                                                            {edit ?
                                                                <IconButton onClick={() => { setShowAddFieldDialog(true) }}>
                                                                    <ControlPointDuplicateRoundedIcon />
                                                                </IconButton>
                                                                :
                                                                <span>
                                                                    <IconButton disabled>
                                                                        <ControlPointDuplicateRoundedIcon />
                                                                    </IconButton>
                                                                </span>}

                                                        </Tooltip>
                                                        <Tooltip title="Schema zur bearbeitung freigeben.">
                                                            <IconButton onClick={() => { setEditDialog(true) }}>
                                                                <CreateRoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Stack>

                                        <Grid2 lg={12} md={12} sm={6} xs={4}>
                                            <Box sx={{ mt: 2 }}>
                                            </Box>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </Box>
                            
                            <Box sx={{
                                padding: 3, mt: 3
                            }}>

                                <Grid2 lg={12} md={12} sm={6} xs={4}>

                                    {!_.isEmpty(project.customSchema) &&
                                        <Box sx={{ height: 500, mt: 2, }}>
                                            <DataGrid
                                                rows={fields}
                                                columns={columns}
                                                isCellEditable={(params) => params.row.id > 9}
                                            />
                                        </Box>
                                    }
                                </Grid2>


                                <Grid2 lg={12} md={12} sm={6} xs={4}>
                                    <Box sx={{ mt: 2 }}>

                                        {edit && <Button type="primary" onClick={() => { setEdit(false) }}>Speichern</Button>}

                                    </Box>
                                </Grid2>

                            </Box>
                            
                        {_.isEmpty(project.customSchema) &&
                            <Box sx={{ mt: 3 }}>
                                <EmptyDataPaper>
                                    <Button variant="outlined" onClick={initSchema}>Neues schema initialisieren</Button>
                                </EmptyDataPaper>
                            </Box>
                        }

                    </Box>
                </div>
                <div>
                    <Dialog open={editDialog} onClose={() => { setEditDialog(false) }}>
                        <DialogTitle>
                            Warnung!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Das ausführen dieser Aktion sorgt dafür, dass das Schema bearbeitet werden kann. Wenn das Schema nicht richtig angepasst wird, kann es zu einem erheblichen Datenverlust kommen. Bist Du sicher, dass diese Aktion ausgeführt werden soll?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditable} color="error">Weiter</Button>
                            <Button onClick={() => { setEditDialog(false) }} autoFocus>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={showSnackbar}
                        autoHideDuration={100000}
                        onClose={() => { setShowSnackbar(false) }}
                    // anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
                    >
                        <Alert severity="warning">Schema zur bearbeitung freigegeben</Alert>
                    </Snackbar>
                </div>
                <div>
                    <Dialog open={showAddFieldDialog} onClose={() => { setShowAddFieldDialog(false) }}>
                        <DialogTitle>
                            Erstelle ein neues Feld für das Projekt.
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Das beim erstellen der Felder gibt es einige Dinge zu beachten:
                            </DialogContentText>
                            <Box component={'form'}>
                                <ul>
                                    <li>String = Textfeld (es können belibige werte eingegben werden)</li>
                                    <li>Number = Nummberfeld (es können nur bestimmte Zahlenwerte eingegeben werden. Ganzzahlen oder Dezimalzahlen)</li>
                                    <li>Date = Datumsfeld (es erscheint ein feld für die Datumsauswahl)</li>
                                    <li>Select = Textfeld mit vordefinierter auswahl (Siehe Textfeld. Vordefinierte auswahl.)</li>
                                    <li>Boolean = Ein ja oder nein Feld. Der Standardwert wird immer "false/falsch/nein" sein.</li>
                                </ul>
                                <Typography variant="caption"> Sofern Du nicht weisst welchen Datentyp du möchtest, wähle immer "string".</Typography>
                                <Typography variant="caption"> Das feld "Auftrag/Tätigkeit" wird standardmäßig anhand der im Projekt eingestellten Aufträge erzeugt. Es kann nicht geändert oder deaktiviert werden.</Typography>

                                <br />
                                <Typography variant="h6">
                                    Feld details:
                                </Typography>
                                <Grid2 container spacing={2}>
                                    <Grid2 lg={12}>
                                        <TextField
                                            required
                                            id="fieldNameOutlinedId"
                                            name="fieldName"
                                            label="Feldname"
                                            variant="outlined"
                                            onChange={(e) => { _setFieldName(e.target.value) }}
                                            sx={{
                                                width: '100%'
                                            }}
                                        />
                                        <Typography variant="caption"> Bitte trage hier einen Feld-Titel ein.</Typography>
                                    </Grid2>
                                    <Grid2 lg={12}>
                                        <Select
                                            required
                                            id="fieldTypeOutlindedId"
                                            name="systemField"
                                            label="System-Feld"
                                            variant="outlined"
                                            onChange={(e) => { _setSystemField(e.target.value) }}
                                            value={_systemField}
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <MenuItem index={1} value={'text'}> Textfeld</MenuItem>
                                            <MenuItem index={2} value={'select'}> Auswahlfeld (belibige Werte)</MenuItem>
                                            <MenuItem index={3} value={'date'}> Datumsfeld mit auswahl </MenuItem>
                                            <MenuItem index={4} value={'number'}> Nummernfeld </MenuItem>
                                            <MenuItem index={5} value={'boolean'}> True/False Toggler </MenuItem>
                                        </Select>
                                        <Typography variant="caption"> Bitte wähle hier den Feld-Typen welcher bei Datenerfassung angezeigt werden soll.</Typography>
                                    </Grid2>
                                    <Grid2 lg={12}>
                                        <Select
                                            required
                                            id="fieldTypeOutlindedId"
                                            name="fieldType"
                                            label="Feld-Typ"
                                            variant="outlined"
                                            onChange={(e) => { _setFieldType(e.target.value) }}
                                            value={_fieldType}
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <MenuItem index={1} value={'String'}> string (Dieser Typ kann alle werte speichern) </MenuItem>
                                            <MenuItem index={2} value={'Number'}> number </MenuItem>
                                            <MenuItem index={3} value={'Date'}> date </MenuItem>
                                            <MenuItem index={4} value={'Boolean'}> boolean </MenuItem>
                                            <MenuItem disabled index={5} value={'mixed'}> mixed (mehrere datentypen in einer Spalte) </MenuItem>
                                        </Select>
                                        <Typography variant="caption"> Bitte wähle hier den entsprechenden Datentypen aus.</Typography>
                                    </Grid2>
                                    <Grid2 lg={12}>
                                        <TextField
                                            name="fieldValidation"
                                            label="Feld-Validierung"
                                            variant="outlined"
                                            onChange={(e) => { _setFieldValidation(e.target.value) }}
                                            sx={{
                                                width: '100%'
                                            }}
                                            disabled={_systemField === 'select' ? false : true}
                                        />
                                        <Typography variant="caption"> Bitte gebe eine formel für die validierung des feldes ein. (Wenn du ein "select"-Feld erstellst, gib die einzeilnen optionen nach einem komma "," ein. z.B.: (Wasser, Feuer, Luft))</Typography>
                                    </Grid2>
                                    <Grid2 lg={12}>
                                        <TextField
                                            required
                                            name="fieldDefaultValue"
                                            label="Standardwert (dynamisch)"
                                            variant="outlined"
                                            onChange={(e) => { _setFieldDefaultValue(e.target.value) }}
                                            sx={{
                                                width: '100%'
                                            }}
                                        />
                                        <Typography variant="caption"> Bitte wähle einen Standardwert für das feld aus.</Typography>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { setShowAddFieldDialog(false) }} color="inherit" variant="outlined">Abbrechen</Button>
                            <Button onClick={handleFieldSubmit} autoFocus color="warning" variant="outlined">
                                Speichern und initialisieren
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Container>
        </>

    );
};

export default ProjectDbSchemaPage;