import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Modal, Select, Button, Input, Form, message } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createUser } from "../../../routing/userRouting";
import { Option } from "antd/es/mentions";


const globalSize = "large"; 


function AddUserDialog(props, { onFinally }) {

    const projects = useSelector((state) => state.projectState.projects);

    let userTypes = [{
        type: "Projektleiter*in"
    }, {
        type: "Projektbetreuuer*in"
    }, {
        type: "Projektmitarbeiter*in"
    }]

    let detailedUserTypes = [{
        type: "Projektleiter*in"
    }, {
        type: "Projektbetreuuer*in"
    }, {
        type: "Projektmitarbeiter*in"
    }, {
        type: "Administrator"
    }]

    const [selectedProjects, setSelectedProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [messageApi, contextHolder] = message.useMessage()
    
    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
          });
    };

    const errorMess = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
          });
    };

    const handleCancel = () => {
        props.setOpen(false);
      };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const handleChange = (option) => {
        let newSelectedProjects = [];
        option.forEach(data => {
            newSelectedProjects.push(data.data);
        });
        setSelectedProjects(
            newSelectedProjects
        );
        console.log(selectedProjects);
    };

    const processUserCreation = async () => {
        setIsLoading(true);
        let visibilitySettings = { projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false }
        switch (userType.type) {
            case "Projektleiter*in":
                visibilitySettings  = { projectLead: true, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false }
            break;

            case "Projektbetreuuer*in":
                visibilitySettings  = { projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: true }
            break;

            case "Projektmitarbeiter*in":
                visibilitySettings  = { projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false }
            break;

            case "Administrator":
                visibilitySettings  = { projectLead: false, isManager: false, isAdmin: true, allProjectsVisible: true, isConsultant: false }
            break;

            case "Manager":
                visibilitySettings  = { projectLead: false, isManager: true, isAdmin: false, allProjectsVisible: true, isConsultant: false }
            break;
        
            default:
                visibilitySettings  = { projectLead: false, isManager: false, isAdmin: false, allProjectsVisible: false, isConsultant: false }
                break;
        }

        const newUser = {
            firstName: formData["userDetails_first_name"],
            lastName: formData["userDetails_last_name"],
            eMail: formData["userDetails_email"],
            mobileNumber: formData["userDetails_mobile_number"],
            projectLead: visibilitySettings.projectLead,
            selectedProjects: selectedProjects,
            sharePointId: formData["userDetails_sharePointId"],
            timeJobId: formData["userDetails_timeJobId"],
            allProjectsVisible: visibilitySettings.allProjectsVisible,
            isManager: visibilitySettings.isManager,
            isAdmin: visibilitySettings.isAdmin,
            isConsultant: visibilitySettings.isConsultant,
        };

        setSelectedProjects([]);

        console.log(newUser);
        await createUser(newUser)
            .then(result => {
                // setDialog(false);
                success("Nutzer*in erfolgreich erstellt, Server Nachricht: ", result.data);
            })
            .catch(error => {
                console.log(error);
                errorMess("Ein Fehler ist aufgetreten. Server Nachricht: ", error);
            })
            .finally(() => {
                handleCancel();
                setIsLoading(false);
            });
    };


    const [formData, setFormData] = useState({});
    const [userType, setUserType] = useState({});

    const handleSetFormData = (e) => {
       
        console.log(e.target);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.id]: e.target.value,
        }));
        console.log(formData);
    };

    return(
        <>
        {contextHolder}
        <div>
            <Modal 
            open={props.OpenDialog} 
            onCancel={handleCancel}
            style={{
                                alignContent: "center",
                                textAlign: "center",
                                alignItems: "center"
                            }}
            footer={
                [
                    <Button key="submit" type="primary" onClick={processUserCreation} loading={isLoading}>
                        Speichern
                    </Button>
                ]
            }
            loading={isLoading}
            >
                <Box sx={{ mb: 5 }}>
                            <h1>Nutzer Details</h1>
                            <Typography variant="caption2" sx={{ px: 5, mt: 1, mb: 5 }}>
                                Jetzt benötigen wir noch Details über den Nutzer.
                            </Typography>
                        </Box>

                        <Form
                            name="userDetails"
                            labelCol={{
                                span: 5,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 600,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            onChange={(e) => { handleSetFormData(e) }}
                        >
                            <Form.Item
                                label="Vorname"
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib den Vornamen ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Nachname"
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib den Nachnamen ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine Unternehmens-email ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="TimeBoard-Id"
                                name="timeBoardId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine TimeBoard-Id ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Telefon"
                                name="mobile_number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine Telefonnummer ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="SP-Id" 
                                name="sharePointId"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Bitte gib eine SharePoint Id ein!',
                                    },
                                ]}
                            >
                                <Input
                                    size={globalSize}

                                />
                            </Form.Item>

                            <Form.Item
                                label="Nutzertyp"
                                name="user_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib einen Nutzertypen an!',
                                    },
                                ]}
                            >
                                <Select
                                    size={globalSize}
                                    value={userType || undefined}
                                    onChange={(value, option) => { setUserType(option.data) }}
                                    style={{
                                        width: 320,
                                    }}
                                >
                                {/* Hier die Nutzerdaten Abrufen und prüfen ob der User bereits Admin ist, damit Admins angelegt werden können. (Auch nur vom Admin) */}
                                {props.DetailedUserTypes === true ? 
                                (
                                    detailedUserTypes.map((userType) => (
                                        <Option value={userType.type} key={userType.type} data={userType}>
                                            {userType.type}
                                        </Option>
                                    ))
                                ) : (
                                    userTypes.map((userType) => (
                                        <Option value={userType.type} key={userType.type} data={userType}>
                                            {userType.type}
                                        </Option>
                                    ))
                                )
                                }
                                    
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Projekte"
                                name="selcted_projects"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte wähle mindestens ein Projekt!',
                                    },
                                ]}
                            >
                                    <Select
                                    size={globalSize}
                                    value={selectedProjects || undefined}
                                    onChange={(e, o) => { console.log(o); handleChange(o)  }}
                                    style={{
                                        width: 320,
                                    }}
                                    mode="multiple"
                                >
                                    {projects.map((project) => (
                                        <Option value={project.title} key={project._id} data={project}>
                                            {project.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                                                            
                            </Form>
            </Modal>
        </div>
        </>
    );
};

export default AddUserDialog;