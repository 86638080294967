import axios from "axios";
import { setAPI } from "./api";
import { auth } from "../firebase"

//API
const API_URL = setAPI();


export async function sendProjectCreation(newProject) {
  const token = await auth.currentUser.getIdToken();
  return await axios.post(`${API_URL}/api/db/projects/create`, newProject, { headers: { Authorization: `Bearer ${token}` }});
};

export async function sendRegistrationRequest(email) {
  const token = await auth.currentUser.getIdToken();
  return await axios.post(`${API_URL}/register-login/register-req`, { email: email }, { headers: { Authorization: `Bearer ${token}` }});
};

export async function saveProjectTime(Data, projectId, uid) {
  const token = await auth.currentUser.getIdToken();
  const timeData = {
    ...Data,
    uid: uid
  };

  console.log("Axios Data: ", timeData);

  return await axios.post(`${API_URL}/api/db/projects/time/track/${projectId}`, timeData, { headers: { Authorization: `Bearer ${token}` }})
};

export async function getProjects(userId) {
  try {
    const token = await auth.currentUser.getIdToken();
    return await axios.post(`${API_URL}/api/db/projects`, { userId: userId}, { headers: { Authorization: `Bearer ${token}` }})
  } catch (error) {
    console.log(error)
  }
};

export async function getCollections(userId) {
  try {
    const token = await auth.currentUser.getIdToken();
    return await axios.post(`${API_URL}/api/db/collections`, { userId: userId }, { headers: { Authorization: `Bearer ${token}` }})
  } catch (error) {
    console.log(error)
  }
};

//Future routers
export async function createTimeCollection(projectData) {
  const token = await auth.currentUser.getIdToken();
  return await axios.post(`${API_URL}/api/db/projects/time/newcollection`, projectData, { headers: { Authorization: `Bearer ${token}` }})
};

export async function filterAndFetchUser(filter) {
  const token = await auth.currentUser.getIdToken();
  return axios.post(`${API_URL}/api/db/users`, filter, { headers: { Authorization: `Bearer ${token}` }})
};

