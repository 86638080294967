import React, { useEffect } from "react";
import DashboardCard from "../cards/DashboardCard"
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getDashboardVolumes } from "../../routing/kpis";
import Search from "antd/es/input/Search";
import { Alert, List } from "antd";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

const DashboardPage = () => {

  const projects = useSelector((state) => state.projectState.projects);
  const [loading, setLoading] = React.useState(false);
  const [salesData, setSalesData] = React.useState(null);
  const [filteredProjects, setFilteredProjects] = React.useState(projects);
  const [searchValue, setSearchValue] = React.useState("");


  useEffect(() => {
    const fetchDbSales = async () => {
      setLoading(true);
      try {
        await getDashboardVolumes()
          .then((response) => {
            const data = response.data;
            setSalesData(data);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        return;
      }

    };

    fetchDbSales();

    return () => {
      setSalesData([]);
      setLoading(false);
    };

  }, [])

  const handleSearch = (value) => {
    console.log(value);
    setFilteredProjects(_.filter(projects, (project) => {
      const { title, projectId, _id, projectLead } = project;
      const searchValueLower = value.toLowerCase();
      return (
        title?.toLowerCase().includes(searchValueLower) ||
        (_id?.toLowerCase().includes(searchValueLower)) ||
        (projectId?.toLowerCase().includes(searchValueLower)) ||
        (projectLead?.firstName?.toLowerCase().includes(searchValueLower)) ||
        (projectLead?.lastName?.toLowerCase().includes(searchValueLower))

      );
    }));
  };

  useEffect(() => {
    if (searchValue === "") {
      setFilteredProjects(projects);
    } else {
      handleSearch(searchValue);
    }
  }, [searchValue, projects]);

  return (

    <div>
      <div>
      
        <Search
          placeholder="Projekt suchen... (ID, Name, Projektleiter etc.)"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }
          }
        />
      </div>
      <Container sx={{
        marginTop: 5
      }}>
      {DateTime.now().toISODate() <= "2024-10-18" && <Alert message={`Im Zeitraum zwischen dem 16.10 und dem 18.10 kann es zu kurzzeitigen Leistungsbeeinträchtigungen kommen.`} type="info" showIcon style={{ textAlign: "left", marginTop: "20px" }} />}
      {DateTime.now().toISODate() <= "2024-10-18" && <Alert message={`ENERCON Daten werden neu eingespielt, Aktion voraussichtlich am 18.10 abgeschlossen`} type="error" showIcon style={{ textAlign: "left", marginTop: "20px" }} />}
        <div>
          <List
            style={{
              display: "block",
              paddingTop: "20px",
            }}
            itemLayout="vertical"
            dataSource={filteredProjects}
            pagination={{
              pageSize: 6,
              position: "both",
              align: "end"
            }}
            grid={{ gutter: 16, column: 3 }}
            renderItem={(p, i) => {
              const delay = i * 0.1;

              return (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: delay,
                    duration: 0.5,
                    type: 'spring',
                    stiffness: 100,
                    damping: 10,
                  }}
                >
                  <List.Item
                    key={i}
                  >
                    <DashboardCard
                      key={i}
                      ProjectTitle={_.truncate(p.title, { length: 15 })}
                      ProjectSubtitle={p._id}
                      minHeight={160}
                      ImageUrl={p.imageUrl}
                      ProjectId={p.projectId}
                      MongoId={p._id}
                      Project={p}
                      Turnover={_.find(salesData, { _id: p._id })?.turnover || 0}
                    />
                  </List.Item>
                </motion.div>
              )
            }
            }
          />
        </div>
      </Container>
    </div>


  );
};

export default DashboardPage; 