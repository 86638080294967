import { Button, Descriptions, Result } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { ShareConfiguration } from "../../drawers/user/ShareConfiguration";


function UserSettingsForm(props) {

    const user = props.user;
    const projects = useSelector((state) => state.projectState.projects);
    const [open, setOpen] = React.useState(false);

    const onClose = () => {
        setOpen(false);
    };

    const openDrawer = () => {
        setOpen(true);
    };

    return (
        <div>
            {
                _.isEmpty(user?.projectSettings) ?
                <Result
                    status="error"
                    title="Keine Kostenverteilung"
                    subTitle="Der Benutzer hat keine Kostenverteilung auf Projekte vorgenommen."
                    extra={<Button type="primary" onClick={openDrawer}>Nutzer-Kostenverteilung erstellen</Button>}
                /> : <div>
                    <Descriptions title="Kostenverteilung auf Projekte" bordered column={1}>
            {
                user?.projectSettings.map((settings, index) => {
                    return (
                        <Descriptions.Item label={_.find(projects, { _id: settings.projectId })?.title} key={index}>
                            {settings.percentageCostShare} %
                        </Descriptions.Item>
                    );
                })
            }
            <Descriptions.Item label="Gesamtanteil:">
                {user?.projectSettings.reduce((total, current) => total + current.percentageCostShare, 0)} %
            </Descriptions.Item>
            </Descriptions>
            <div style={{
                marginTop: "20px"
            
            }}>
                <Button type="primary" onClick={openDrawer}>Nutzer-Kostenverteilung bearbeiten</Button>
            </div>
                </div>
            }
            <ShareConfiguration open={open} Projects={projects} User={user} onClose={onClose} P={true} />
        </div>
    );
};



export { UserSettingsForm };