import axios from "axios";
import { isEmpty } from "lodash";
import { setAPI } from "./api";
import { auth } from "../firebase";

const API_URL = setAPI();

export async function addFieldToCustomSchema(id, fields) {
  try {
    if (id === "" || isEmpty(fields)) {
      throw new Error("Preparing sync-stream failed. id or fields should not be empty.")
    }
    const token = await auth.currentUser.getIdToken();
    return await axios.post(`${API_URL}/api/db/schema/projects/create`, { mongoId: id, fields: fields }, { headers: { Authorization: `Bearer ${token}` } })
  } catch (error) {
    console.log(error);
  };
};

export async function updateSchema(id, projectIdName, project) {
  try {
    if (id || projectIdName === "") {
      throw new Error("Preparing sync-stream failed. id or projectIdName should not be empty.")
    }
    const token = await auth.currentUser.getIdToken();
    return await axios.post(`${API_URL}/api/db/schema/schema/sync/${projectIdName}`, { mongoId: id, project: project }, { headers: { Authorization: `Bearer ${token}` } })
  } catch (error) {
    console.log(error);
  };
};

//Not used yet
export async function getSchema(id) {
  try {
    const token = await auth.currentUser.getIdToken();
    return await axios.post(`${API_URL}/api/db/mo/stream`, { mongoId: id }, { headers: { Authorization: `Bearer ${token}` } })
  } catch (error) {
    console.log(error)
  }
}