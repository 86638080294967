import { Alert, Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Modal, Popconfirm, Select, Space, Typography } from "antd";
import React from "react";
import jsonLocale from "../../../locale/datePickerLocale.json";
import { v4_new_custom_property, v4_property_change } from "../../../routing/v4_Routes/article.routes";
import dayjs from "dayjs";


export function TaskNewCustomProperty(props) {

    const { task, open } = props;
    const formRef = React.useRef();
    const [reverse, setReverse] = React.useState(false); //Rückwirkend
    const [newPropertyDefault, setNewPropertyDefault] = React.useState(true);
    const [allArticles, setAllArticles] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [inputType, setInputType] = React.useState("number");
    const [showEinheit, setShowEinheit] = React.useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        console.log(values);
        console.log(task);
        try {
            await v4_new_custom_property(task?.project, task?._id, values);
            await props.onUpdate(task?._id);
        } catch (error) {
            console.error("Error in onFinish:", error);
        } finally {
            setLoading(false);
            reset();
            props.onClose();
        }
    };

    const reset = () => {
        formRef?.current?.resetFields();
        setReverse(false);
        setNewPropertyDefault(true);
        setAllArticles(false);
        setShowEinheit(false);
    };

    return (
        <Modal
            open={open}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            title="Neue Artikeleigenschaft hinzufügen"
            footer={null}
            destroyOnClose
            centered
            width={"70%"}
            style={{ top: 20 }}
            maskClosable={false}
        >
            <div>
                <div>
                    <Divider type="horizontal" orientation="left">
                        <Typography.Title level={5}>Artikelinformationen</Typography.Title>
                    </Divider>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <Typography.Title level={5}>Artikel: {task?.title}</Typography.Title>
                    <Typography.Text>Artikelnummer: {task?.uniqueArticleNumber}</Typography.Text>
                </div>
                <Divider type="horizontal" orientation="left">
                    <Typography.Title level={5}>Neue Eigenschaft</Typography.Title>
                </Divider>
            </div>
            <Form
                layout="vertical"
                onFinish={onFinish}
                ref={formRef}
                initialValues={{
                    // newPriceDefault: true,
                    reverseChange: false,
                    allArticles: false,
                    // defaultTimePc: task?.defaultTimePc
                }}
            >
                <Form.Item
                    label="Neuen Eigenschaftswert als Standard übernehmen?"
                    name="newPorpertyDefault"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn die neue Eigenschaft als neuer Standard übernommen werden soll"
                    initialValue={newPropertyDefault}
                >
                    <Checkbox onChange={(e) => setNewPropertyDefault(e.target.checked)}>
                        Als Standard übernehmen
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    label="Name der Benutzerdefinierten Eigenschaft"
                    name="valuePropertyName"
                    rules={[
                        {
                            required: true,
                            message: "Die Eigenschaft die geändert werden soll ist obligatorisch."
                        }
                    ]}
                >
                    <Input
                        style={{ width: "100%" }}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                    //
                    />
                </Form.Item>
                <Form.Item
                    label="Typ der Eigenschaft"
                    name="propertyType"
                    rules={[
                        {
                            required: true,
                            message: "Bitte wählen Sie den Typ der Eigenschaft. Datumswerte können noch nicht gewählt werden.#"
                        }
                    ]}
                >
                    <Select
                        placeholder="Name der Benutzerdefinierten Eigenschaft"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                            console.log(value);
                            switch (value) {
                                case "number":
                                    setInputType("number");
                                    setShowEinheit(true);
                                    break;
                                case "boolean":
                                    setInputType("boolean");
                                    setShowEinheit(false);
                                    break;
                                case "string":
                                    setInputType("string");
                                    setShowEinheit(false);
                                    break;
                                default:
                                    setInputType("string");
                                    setShowEinheit(false);
                                    break;
                            };
                        }}
                    >
                        <Select.Option value="number">Zahlenwert</Select.Option>
                        <Select.Option value="boolean">Ja/Nein-Wert</Select.Option>
                        <Select.Option value="string">Textwert</Select.Option>
                    </Select>

                </Form.Item>
                <Form.Item
                    label="Neuer Wert"
                    name="newValue"
                    rules={[
                        {
                            required: true,
                            message: "Bitte geben Sie den neuen Wert ein"
                        }
                    ]}
                >
                    {inputType === "number" && (
                        <InputNumber
                            style={{ width: "100%" }}
                            min={0}
                            step={0.01}
                            precision={5}
                            decimalSeparator=","
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                        />
                    )}
                    {inputType === "string" && (
                        <Input
                            style={{ width: "100%" }}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                        />
                    )}
                    {inputType === "boolean" && (
                        <Select
                            placeholder="Wert auswählen"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value={true}>Ja</Select.Option>
                            <Select.Option value={false}>Nein</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                {showEinheit && (<Form.Item
                    label="Einheit"
                    name="propertyUnit"
                    rules={[
                        {
                            required: true,
                            message: "Bitte geben Sie die Einheit ein"
                        }
                    ]}
                >
                    <Input
                        style={{ width: "100%" }}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                    />
                </Form.Item>)}
                <Form.Item
                    label="Ist die neue Eigenschaft eine 'private'-Eigenschaft?"
                    name="privateProperty"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn die neue Eigenschaft nur für diesen Artikel gelten soll"
                >
                    <Select
                        placeholder="Wert auswählen"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Select.Option value={true}>Ja</Select.Option>
                        <Select.Option value={false}>Nein</Select.Option>
                    </Select>
                </Form.Item>
                {!newPropertyDefault && !reverse && (<Form.Item>
                    <Alert
                        message="Eigenschaft nicht als Standard"
                        description="So kann man keine Eigenschaft ändern... Bitte wählen Sie die Option 'Eigenschaft als Standard übernehmen' aus, oder ändern Sie die Eigenschaft rückwirkend."
                        type="error"
                        showIcon
                        style={{ marginTop: 20 }}
                    />
                </Form.Item>)}
                {!newPropertyDefault && reverse && (<Form.Item>
                    <Alert
                        message="Eigenschaft nicht als Standard"
                        description="Die Eigenschaft wird für den gewählten Zeitraum geändert. Die neue Eigenschaft wird aber nicht beibehalten. Dazu müssen Sie die Option 'Eigenschaft als Standard übernehmen' aktivieren."
                        type="warning"
                        showIcon
                        style={{ marginTop: 20 }}
                    />
                </Form.Item>)}
                <Form.Item
                    label="Rückwirkende änderung?"
                    name="reverseChange"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn die Eigenschaft rückwirkend geändert werden soll"
                    hidden={true}
                >
                    <Checkbox onChange={(e) => {
                        setReverse(e.target.checked);
                        if (!e.target.checked) {
                            formRef.current.setFieldsValue({
                                reverse: null,
                                newPriceDefault: true
                            });
                            setNewPropertyDefault(true);
                        } else {
                            setNewPropertyDefault(false);
                            formRef.current.setFieldsValue({
                                newPriceDefault: false
                            });
                        };
                    }}>Rückwirkend</Checkbox>
                </Form.Item>
                {reverse && (<Form.Item
                    label="Alle Eigenschaften ändern?"
                    name="allArticles"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn die Eigenschaft rückwirkend geändert werden soll"
                >
                    <Checkbox onChange={(e) => {
                        setAllArticles(e.target.checked);
                        if (!e.target.checked) {
                            formRef.current.setFieldsValue({
                                reverse: null,
                                allArticles: false
                            });
                        } else {
                            formRef.current.setFieldsValue({
                                allArticles: true,
                                reverse: null,
                            });
                        };
                    }}>Alle Preise ändern</Checkbox>
                </Form.Item>)}
                {reverse && !allArticles && (<Form.Item
                    label="Zeitraum für Rückwirkende änderung"
                    name="reverse"
                    valuePropName="checked"
                >
                    <DatePicker.RangePicker
                        format={"DD.MM.YYYY"}
                        // ref={datePickerRef}
                        locale={jsonLocale}
                        maxDate={dayjs(new Date())}
                    // onChange={(value) => {
                    //     handleDateChange(value)
                    // }}
                    />
                </Form.Item>)}
                {reverse && !allArticles && (<Form.Item>
                    <Alert
                        message="Achtung! - Rückwirkende änderung"
                        description="Die rückwirkende änderung wird alle Buchungen mit den Entsprechenden Eigenschafte für den angegebenen Zeitraum ändern. Das betrifft auch bereits abgerechnete Aufgaben. Bitte prüfen Sie die Änderung sorgfältig."
                        type="warning"
                        showIcon
                        style={{ marginTop: 20 }}
                    />
                </Form.Item>)}
                {reverse && allArticles && (<Form.Item>
                    <Alert
                        message="Achtung! - Alle Preise rückwirkend ändern"
                        description="Die rückwirkende änderung wird alle Eigenschften für alle Aufgaben ändern. Das betrifft auch bereits abgerechnete Aufgaben. Bitte prüfen Sie die Änderung sorgfältig."
                        type="warning"
                        showIcon
                        style={{ marginTop: 20 }}
                    />
                </Form.Item>)}
                <Form.Item>
                    <Space direction="horizontal">
                        <Popconfirm
                            title={reverse ? "Sind Sie sicher, dass Sie die Eigenschaft rückwirkend ändern möchten?" : "Sind Sie sicher, dass Sie die Eigenschaft ändern möchten?"}
                            onConfirm={() => { }}
                            okText="Ja"
                            cancelText="Nein"
                            okButtonProps={{ type: "primary", htmlType: "submit", onClick: () => formRef.current.submit(), loading: loading, style: { backgroundColor: 'green', borderColor: 'green' } }}
                        >
                            <Button
                                type="primary"
                                danger
                                disabled={!newPropertyDefault && !reverse}
                                loading={loading}
                                style={{ backgroundColor: 'green', borderColor: 'green' }}
                            >
                                Eigenschaft ändern
                            </Button>
                        </Popconfirm>
                    </Space>
                </Form.Item>
            </Form>

        </Modal>
    );
};