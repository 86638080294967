import { Button, Col, Descriptions, Modal, Row, Tabs, Tooltip, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatisticsCard } from "../../global/StatisticsCard";
import _ from "lodash";
import { initCustomerSharing } from "../../../routing/customerApi";
import store from "../../../redux/store";
import { useNavigate } from "react-router";
import { updateProjects, updateSelectedproject } from "../../../redux/projectSlicer";
import { WarningOutlined } from "@ant-design/icons";

function OverviewPage() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const projectId = useSelector((state) => state.projectState.selectedProject._id);
    const project = useSelector((state) => state.projectState.selectedProject);
    const customerData = useSelector((state) => state.customerState.customerDataSet);
    const cData = _.has(customerData, 'data.projectData') ? customerData.data.projectData : [];
    const cDataLength = customerData.length || 0;
    const completeLength = customerData.completeDataLength || 0;
    const [isShared, setIsShared] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const customers = useSelector((state) => state.customerState.customerUsers);

    const initShareProject = async () => {
        setLoading(true);
        try {
            const id = await initCustomerSharing(projectId, project.projectId)
                .then((result) => {
                    console.log(result);

                    return result.data.data.customerId;
                }).catch((err) => {
                    console.log(err);
                    return null;
                    //TODO: Fehlerbehandlung - Popup erstellen
                });

            const newProject = {
                ...project,
                customerId: id,
            };

            const allProjects = store.getState().projectState.projects;
            var updatedProjects = [...allProjects];
            const keyToRemove = _.findKey(updatedProjects, (o) => { return o._id === projectId; });
            _.pullAt(updatedProjects, [keyToRemove]); // Entferne das alte Projekt aus der Kopie
            updatedProjects.push(newProject); // Füge das aktualisierte Projekt zur Kopie hinzu

            console.log(updatedProjects);

            dispatch(updateProjects(updatedProjects));
            dispatch(updateSelectedproject(newProject));

            console.log(id);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setOpen(false);
        };
    };

    useEffect(() => {
        const sharedProject = store.getState().projectState.selectedProject;
        const task = () => {
            if (_.has(sharedProject, 'customerId')) {
                return true;
            } else {
                return false;
            };
        };

        return setIsShared(task());
    }, [])

    return (
        <>
            <div>
                <Row gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}

                >
                    <Col span={6}>
                        <StatisticsCard
                            Title="Anzahl vorhandener Zugänge"
                            Value={customers.length}
                            Precision={0}
                            Prefix=""
                            Suffix=""
                            Bordered={false}
                        />
                    </Col>
                    <Col span={6}>
                        <StatisticsCard
                            Title="Datensätze gesamt"
                            Value={completeLength}
                            Precision={0}
                            Prefix=""
                            Suffix=""
                            Bordered={false}
                        />
                    </Col>
                    <Col span={6}>
                        <StatisticsCard
                            Title="Verwendbare Datensätze"
                            Value={cDataLength}
                            Precision={0}
                            Prefix={cDataLength / completeLength * 100 < 90 ? <>
                                <Tooltip title="Es werden weniger als 90% der Datensätze verwendet.">
                                    <WarningOutlined style={{ color: "red" }} />
                                </Tooltip>
                            </> : null}
                            Suffix=""
                            Bordered={false}
                        />
                    </Col>
                    <Col span={6}>
                        <StatisticsCard
                            Title="Verwendbarer Anteil"
                            Value={cDataLength / completeLength * 100}
                            Precision={0}
                            Prefix=""
                            Suffix="%"
                            Bordered={false}
                        />
                    </Col>
                </Row>

                <div style={{ marginTop: "20px" }}>
                    <Descriptions title="Einstellungen" bordered>
                        <Descriptions.Item label="ProjektId" span={6}>{project._id}</Descriptions.Item>
                        <Descriptions.Item label="Projektkennung" span={6}>{project.projectId}</Descriptions.Item>
                        <Descriptions.Item label="Automatische aktualisierung"></Descriptions.Item>
                        <Descriptions.Item label=""></Descriptions.Item>
                    </Descriptions>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <Descriptions title="Gefahrenzone" bordered style={{
                        color: "red",
                    }}>
                        <Descriptions.Item label="Teilen beenden" span={6} style={{
                            color: "red",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                        }}>
                            <Button type="primary" danger>Teilen beenden</Button>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>


            <Modal
                title="Kundenprojekt teilen"
                centered
                // Ausrufezeichen vor isShared invertiert den Wert - im Produktionssystem hinzufügen
                open={!isShared}
                onCancel={() => { navigate('/manage/projects') }}
                footer={
                    [
                        <><Button onClick={() => { navigate('/manage/projects') }}>Zurück zur Übersicht</Button></>,
                        <><Button type="primary" onClick={() => { setOpen(true) }}>Teilen aktivieren</Button></>
                    ]
                }
            >
                <p>Das Projekt wird nicht geteilt. Du kannst das Teilen dieses Projekt für Kunden aktivieren.</p>
            </Modal>

            <Modal
                title="Kundenprojekt teilen - bestätigen"
                centered
                open={open}
                onCancel={() => { setOpen(false) }}
                footer={
                    [
                        <><Button onClick={initShareProject} loading={loading}>Teilen dieses Projekts aktivieren</Button></>,
                        <><Button type="primary" onClick={() => { setOpen(false); navigate("/manage/projects", { replace: true }) }}>Abbrechen</Button></>
                    ]
                }
            >
                <p>Bitte bestätige das Teilen dieses Kundenprojekts.</p>
            </Modal>
        </>
    )
};

export default OverviewPage;