import store from "../../redux/store";
import { darkTheme, lightTheme } from "../antdTheme";
import React from "react";

/**
 * @description Returns the current theme state. True for dark mode, false for light mode.
 * @returns {object} - The theme state.
 * @param {string} lightmodeColor - The color for the light mode. Default is white.
 */
export function util_theme_state_dark(lightmodeColor, darkmodeColor) {
    const state = store.getState().userState.darkMode;
    return { 
        backgroundColor: state ? (darkmodeColor || "transparent") : (lightmodeColor || "white"),
        color: state ? "white" : "black",
        darkModeOn: state,
        theme: state ? darkTheme : lightTheme,
    }
}