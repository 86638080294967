import { Button, Descriptions, Form, Input, Modal, Skeleton, Tabs } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";


function EmbeddedReportsModal(props) {

    const formRef = useRef();
    const project = props.project;
    const [her, setHasEmbeddedReports] = useState(false);

    const close = () => {
        props.onCancel();
    };

    const onFinish = (values) => {
        console.log(values);
        //TODO: Hier muss noch die Funktion zum speichern der Daten rein
        //mit dem backend abgleichen
        //redux-Element aktualisieren
        close();
    };

    const onReset = () => {
        formRef.current?.resetFields();
    };

    useEffect(() => {
        const checkEmbeddedReports = () => {
            if (_.has(project, "embeddedReport")) {
                setHasEmbeddedReports(true);
            } else {
                setHasEmbeddedReports(false);
            }
        };

        return checkEmbeddedReports();
    }, []);

    useEffect(() => {
        if (her) {
            formRef.current?.setFieldsValue({
                embeddedReports: _.has(project, "embeddedReport") ? project.embeddedReports : "",
            });
        }
    }, [her, project]);

    return (
        <>
            <Modal
                title={`Eingebettete Berichte für ${project.title}`}
                open={props.open}
                onOk={() => console.log("Ok")}
                onCancel={close}
                closable={false}
                width={"80%"}
                height={"100%"}
                footer={
                    <>
                        <Button type="primary" onClick={close}> Schließen </Button>
                    </>
                }
            >
                {/* Ab hier können die bearbeitungen für das Modal beginnen  */}

                <Descriptions title="Embedded Reports" bordered>
                    <Descriptions.Item label="Beschreibung">
                    Embedded Reports sind Berichte, z.B. aus PowerBI oder Excel, die in der Anwendung eingebettet werden können.
                    Um die Einbettung dieser Berichte zu erleichtern, kann hier der entsprechende Link hinterlegt werden.
                    Aktuell werden nur Links unterstützt, die mit "https://" beginnen. Ausderdem kann nur ein Link hinterlegt werden.
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <Form
                            name="embeddedReports"
                            layout="vertical"
                            // initialValues={{
                            //     embeddedReports: false,
                            // }}
                            onFinish={onFinish}
                            onReset={onReset}
                            onAbort={close}
                        >
                            <Form.Item
                                name="embeddedReports"
                                label="Link"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie einen Link ein',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Speichern und Einbetten
                                </Button>
                            </Form.Item>
                        </Form>
                    </Descriptions.Item>
                </Descriptions>

                <div style={{ marginTop: "20px" }}>
                    <p>Hinweis: Diese Einstellung können nur Projektleiter oder Administratoren bearbeiten.</p>
                </div>
                {/* Ab hier darf nichtsmehr eingefügt werden */}
            </Modal>
        </>
    );
};

export { EmbeddedReportsModal };