import { AddRounded, DoneOutlineRounded, InputOutlined, LockOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { accounting } from 'accounting';
import _ from "lodash";
import { EmptyDataPaper } from "../../global/EmptyDataPaper";
import UploadFileDialog from "../../global/fileManagement/FileUploaderComponent";
import TeamUserCard from "../../cards/TeamUserCard";
import { useSelector } from "react-redux";
import { listDocuments } from "../../../utils/firebaseStorage";
import FileDownloaderButton from "../../global/fileManagement/FileDownloaderButton";
import FileViewInBrowserButton from "../../global/fileManagement/FileViewInBrowserButton";
import { setFileImage } from "../../../utils/storageRouting";
import DataModeButton from "../../global/dialogs/DataModeButton";
import AddUserDialog from "../../global/dialogs/AddUserDialog";
import TaskDialog from "../../global/dialogs/TaskDialog";
import { Descriptions, Segmented, Space, Table, Typography, Button, Card, Row, Col, Tooltip, Popconfirm, Result, Alert } from "antd";
import AddExistingUserDialog from "../../global/dialogs/AddExistingUserDialog";
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import { getMissingProjectUsers, initCsvSchema, modifyTasks } from "../../../routing/crudActions";
import store from "../../../redux/store";
import { updateSelectedproject } from "../../../redux/projectSlicer";
import { invokeSessionWithProject } from "../../../config/session/session";
import { EmbeddedReportsButton } from "../../Buttons/EmbeddedReportsButton";
import { BaseProjectDataForm } from "../../forms/projectDetails/BaseProjectDataForm";
import { displayInfoMessage, displayWarningMessage } from "../../../utils/messageServices/messageService";
import { route_upgadeTaskVersion } from "../../../routing/v3_Routes/v3_Routes";
import { util_ProjectDetailsUpdate } from "../../../utils/updaterUtils/projectUpdateUtil";
import { v4_getProjectById } from "../../../routing/v4_Routes/project.routes";
import { useUpdate } from "ahooks";
import { useDispatch } from "react-redux";

const { Title, Paragraph } = Typography;
const { Meta } = Card;

const tabs = [
    { value: "stammdaten", label: "Stammdaten" },
    { value: "team", label: "Team" },
    { value: "dokumente", label: "Dokumente" },
    { value: "faq", label: "FAQ" },
    { value: "leistungen", label: "Leistungen" },
];

function ProjectDetailPage(props) {

    const update = useUpdate();
    const dispatch = useDispatch();
    const rp = useSelector((state) => state.projectState.selectedProject)
    const [tabValue, setTabValue] = useState("stammdaten");
    const [round, setRound] = useState(0);
    const { mongoId } = useParams()
    const [openUpload, setOpenUpload] = useState(false);
    const [taskDialog, setTaskDialog] = useState(false);
    const [addUserDialog, setAddUserDialog] = useState(false);
    const [addExistingUser, setAddExistingUser] = useState(false);
    const [docs, setDocs] = useState({});
    const [users, setUsers] = useState([]);
    const [project, setProject] = useState(rp);

    async function fetchProject() {
        const data = await v4_getProjectById(mongoId, true);
        setProject(data);

        document.title = `Assembly - ${data?.title}`;
        update();
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(event);
    };

    const [expanded, setExpanded] = React.useState(false);
    //eslint-disable-next-line
    const [fetched, setFetched] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchDocs = (projectId) => {
        listDocuments(projectId)
            .then((res) => {
                setDocs(res)
            }).catch((err) => {
                console.log(err);
            });
    };

    const fetchUsers = async () => {
        const task = await getMissingProjectUsers(project.allowedUserId, project._id)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                return null;
            });
        return task
    }

    const handleOpenAddExistingUser = async () => {
            await fetchUsers()
            .then((res) => {
                setUsers(res);
                setAddExistingUser(true);
            })
            .catch((err) => {
                console.log(err)
            });
            
    };

    useEffect(() => {
        fetchDocs(project.projectId);
        fetchProject();
        console.log("Docs: ", docs)
    }, []);

    useEffect(() => {
        fetchDocs(project.projectId);
    }, [fetched])

    // useEffect(() => {
    //     fetchProject();
    //     dispatch(updateSelectedproject(project));
    //     return () => {
    //       document.title = "Assembly";
    //       dispatch(updateSelectedproject({}));
    //     };
    //   }, []);

    useEffect(() => {
        let timeoutId;
    
        if (addUserDialog) {

        } else {
            if (round === 0) {
                setRound(round+1);
                return;
            } else {
                timeoutId = setTimeout(() => {
                    //Der Code zur aktualisierung des Projekts
                    console.log("Timeout: ", round);
                }, 3000);
            }
        };
    
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [addUserDialog]);
    
    

    return (
        <>
            <Container sx={{
                marginTop: 5,
            }}
            maxWidth='lg'
            >
                    <Box sx={{
                        padding: 3,
                    }}>
                        <Box>
                            <Stack direction="row" alignItems="center">
                            <Stack direction="row">
                            <Box
                                sx={{
                                    backgroundColor: 'white',
                                    p: 3,
                                    borderRadius: '50px',
                                    objectFit: 'contain'
                                }}>
                                <img
                                    src={project.imageUrl}
                                    alt="Project_Logo_Image"
                                    width="100px"
                                    style={{ resize: 'both', backgroundColor: 'white', objectFit: 'contain' }}
                                    className="ButtonIcons"
                                />
                            </Box>
                            <Stack>
                                <Typography style={{ paddingLeft: 2 }}>
                                    <Title>{project.title}</Title>
                                    <Paragraph>{project._id} - {project.__v}</Paragraph>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box sx={{ flex: 1}} />
                                <Stack sx={{ justifyContent: 'flex-end' }}>
                                    <Box sx={{
                                        padding: 1
                                    }}>
                                        
                                        <Segmented size="large" options={tabs} onChange={(e) => {handleTabChange(e)}} style={{ marginBottom: 10}} />
                                        <Box sx={{ flex: 1, boxShadows: 'none', borderRadius: 1.5, border: '1px solid #e0e0e0', p: 1, minHeight: 88 }}>
                                            Schnellaktionen
                                            {tabValue === "stammdaten" && (
                                                <Stack direction={"row"}>
                                                 {project?.released || false ? <>
                                                    <Tooltip title="Projekt sperren (KPI Hinweis)">
                                                    <IconButton onClick={() => {util_ProjectDetailsUpdate(project._id, "released", Boolean(false))}}>
                                                        <LockOutlined />
                                                    </IconButton>
                                                    </Tooltip>
                                                 </> : <>
                                                 <Tooltip title="Projekt freigeben (KPI Hinweis)">
                                                    <IconButton onClick={() => {util_ProjectDetailsUpdate(project._id, "released", Boolean(true))}}>
                                                        <DoneOutlineRounded />
                                                    </IconButton>
                                                    </Tooltip>
                                                 </>}
                                                    
                                                    
                                                    {project?.entryReleased || false ? <>
                                                        <Tooltip title="Projekt sperren (Dateneingabe)">
                                                    <IconButton onClick={() => {util_ProjectDetailsUpdate(project._id, "entryReleased", Boolean(false))}}>
                                                        <LockOutlined />
                                                    </IconButton>
                                                    </Tooltip>
                                                    </> : <>
                                                    <Tooltip title="Projekt freigeben (Dateneingabe)">
                                                    <IconButton onClick={() => {util_ProjectDetailsUpdate(project._id, "entryReleased", Boolean(true))}}>
                                                        <InputOutlined />
                                                    </IconButton>
                                                    </Tooltip>
                                                    </>}
                                                </Stack>
                                            )}
                                            {tabValue === "team" && (
                                                <Stack direction={"row"}>
                                                    <Tooltip title="Neuen Nutzer erstellen">
                                                        <IconButton onClick={() => { setAddUserDialog(true) }}>
                                                            <AddRounded />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Bestehenden Nutzer hinzufügen">
                                                        <IconButton onClick={handleOpenAddExistingUser}>
                                                        <GroupAddRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            )}
                                            {tabValue === "leistungen" && (
                                                <Stack direction={"row"}>
                                                    {/* <Tooltip title="Weitere Leistung hinzufügen">
                                                        <IconButton onClick={() => { setTaskDialog(true) }}>
                                                            <AddRounded />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </Stack>
                                            )}
                                            {tabValue === "faq" && (
                                                <Stack direction={"row"}>
                                                    <Typography variant="caption">
                                                        keine Aktionen vorhanden
                                                    </Typography>
                                                </Stack>
                                            )}
                                            {tabValue === "dokumente" && (
                                                <Stack direction={"row"}>
                                                    <Tooltip title="Dokument hochladen">
                                                        <IconButton onClick={() => { setOpenUpload(true) }}>
                                                            <AddRounded />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            )}
                                        </Box>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>

                <Box sx={{
                    padding: 0,
                    mt: 3
                }}>
                    {/* Prüft ob die daten schon geladen sind, sofern diese das sind wird der content auch angezeigt und gerendert */}
                    {!_.isEmpty(project) && RenderContent(tabValue, project, expanded, { handleChange }, docs)}
                </Box>
            </Container>
            {/* <NewTaskcomponent ProjectName={project.title} MongoId={mongoId} open={taskDialog} setOpen={() => { setTaskDialog(false) }} /> */}
            <UploadFileDialog open={openUpload} MongoId={mongoId} ProjectId={project.projectId} setOpen={() => { setOpenUpload(false) }} fetchAgain={(id) => {fetchDocs(id)}} />
            <AddUserDialog OpenDialog={addUserDialog} setOpen={() => { setAddUserDialog(false) }} />
            <TaskDialog open={taskDialog} ProjectName={project.title} Project={project} ProjectId={project._id} Action="create" setOpen={() => { setTaskDialog(false) }} />
            <AddExistingUserDialog OpenDialog={addExistingUser} Users={users} setOpen={() => { setAddExistingUser(false) }} />
        </>
    );
};

function RenderContent(key, project, expanded, { handleChange }, docs) {
    const hasTaskVersion = _.has(project, "taskVersion");
    let taskVersion = null;
    const { mongoId } = useParams();
    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
    };

    const handleTransfer = async (projectId) => {
        console.log("Transfering tasks");
        console.log(projectId);

        if (project.__v <= 0) {
            //Upgarde to v2
            displayInfoMessage("Info", "Dieses Projekt wird auf die neue Version umgestellt. Bitte warten Sie einen Moment. Der Vorgang kann einige Minuten dauern, wir informieren Sie, sobald der Vorgang abgeschlossen ist.");
            const res = await route_upgadeTaskVersion(projectId);
            if (res) {
                navigate("/");
            }
        } else {
            displayWarningMessage("Achtung", "Dieses Projekt ist bereits auf die neue Version umgestellt worden.")
        };
    };



    switch (key) {
        case "stammdaten":
            const location = project.projectLocation;
            const user = store.getState().userState.mongoUser
            const handleCsvInit = async () => {
                await initCsvSchema(project._id, project.projectId, user)
                .then((result) => {
                    console.trace(result);
                }).catch((err) => {
                    console.error(err);
                    return
                });
            };

            return (
                <>
                    <BaseProjectDataForm Project={project} />

                    <Descriptions label="Sonstiges" bordered>
                    
                        <Descriptions.Item label='Corporate Design' span={2} style={{
                            marginBottom: 20
                        }}>
                            <Box>
                                <Box component={"img"}
                                    src={project.imageUrl}
                                    sx={{ width: '100%', height: '100px', objectFit: 'contain', padding: 2 }}
                                />
                                <Button variant="outlined" disabled>
                                    Logo ändern
                                </Button>
                            </Box>
                        </Descriptions.Item>
                        <Descriptions.Item label='Embedded Reports'>
                            <EmbeddedReportsButton project={project} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Datenmodus'>
                            <Box>
                                <Typography>
                                    Der Datenmodus für dieses Projekt ist aktuell auf "{project.dataMode}" gestellt. Ändern des Datenmoduses bedeutet, dass Projektmitarbeiter*innen ein anderes Layout angezeigt bekommen.
                                    Dies ändert zudem die Funktionalität der Datenerfassung für jedes Projekt. Fazit: please be careful!
                                </Typography>
                                <DataModeButton Project={project} />
                            </Box>
                        </Descriptions.Item>
                    </Descriptions>

                    {project.dataMode === "csv" &&
                        <Descriptions title="CSV Schema" bordered style={{ marginTop: '20px' }}>
                            <Descriptions.Item label="CSV initialisieren">
                                <Tooltip title="Initialisiert das Schema in der Datenbank, damit dateien hochgeladen werden können.">
                                    <Button type="primary" danger onClick={handleCsvInit}>
                                        CSV Upload freigeben
                                    </Button>
                                </Tooltip>
                            </Descriptions.Item>
                        </Descriptions>}
                </>
            );
        case "team":
            const team = project.allowedUserId
            let teamContent = (<></>)
            if (_.isEmpty(team)) {
                teamContent = (
                    <EmptyDataPaper>
                        <Button variant="outlined" disabled>
                            Personen diesem Projekt zuordnen
                        </Button>
                    </EmptyDataPaper>
                )
            } else {
                teamContent = (
                    <>
                    <Alert style={{ marginBottom: 18}} message="Die Einstellungsmöglichkeiten auf dieser Seite werden demnächst entfernt." type="info" showIcon />
                        <Box sx={{
                            m: -2,
                        }}>
                            <Grid2 container spacing={2}>
                                {!_.isEmpty(team) && team.map((user, index) => (
                                    <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <TeamUserCard imageUrl={user.imageUrl} FirstName={user.firstName} LastName={user.lastName} UserEmail={user.email} User={user} />
                                    </Grid2>
                                ))}
                            </Grid2>
                        </Box>
                    </>
                )
            }
            return (teamContent);
        case "dokumente":
            let docContent = (<></>)
            const docItems = docs.items;

            if (_.isEmpty(docs)) {

            } else {
                docContent = (
                    <>
                        <Row gutter={[16, 16]}>
                            {docItems.map((item, index) => (

                                <Col span={6}>
                                    <Card
                                        actions={[
                                            <FileDownloaderButton StorageRef={item.prefix} FileName={_.truncate(_.split(item.prefix, '/', 6)[_.split(item.prefix, '/', 6).length - 1], { length: 50 })} />,
                                            <FileViewInBrowserButton StorageRef={item.prefix} />,
                                        ]}
                                        cover={
                                            <img
                                                alt="Bild der Datei"
                                                src={setFileImage(item.prefix).path}
                                                style={{ width: '100%', height: '50px', objectFit: 'contain', padding: 1, paddingTop: 10 }}
                                            />
                                        }
                                    >
                                        <Meta
                                        title={_.truncate(_.split(item.prefix, '/', 6)[_.split(item.prefix, '/', 6).length - 1], { length: 16 })}
                                        description="Beschreibung"
                                        />
                                    </Card>


                                </Col>
                            ))}
                        </Row>
                    </>
                )
            }

            return docContent;
        case "faq":
            return (
                <>
                    <p>FAQ</p>
                </>
            );
        case "leistungen":
            const tasks = project.tasks;
            let content = (<></>);

            const handleDeleteTask = async (task) => {
                const data = {
                    projectId: project._id,
                    id: task.id,
                };

                await modifyTasks("delete", data)
                    .then(async (result) => {
                        console.log(result);

                        const currentProject = project;
                        const currentTasks = currentProject.tasks;
                        const updatedTasks = _.pull(currentTasks, task);
                        const updatedProject = { ...currentProject, tasks: updatedTasks };

                        store.dispatch(updateSelectedproject(updatedProject));
                        console.log(updatedProject);
                        await invokeSessionWithProject(project);
                    }).catch((err) => {
                        console.log(err)
                    });
            }

            if ((_.isEmpty(tasks) || project.__v >= 1) || (_.isEmpty(tasks) && project.__v === 0)) {
                content = (
                    <div>
                    <Result status="info" title="Aufgaben Version 2" subTitle="Die Aufgaben aus Version __v 1 werden hier nichtmehr Angezeigt." />
                    <Button variant="outlined" onClick={() => {handleNavigate("/project/articles/management/v1")}}>
                                    Zum neuen Task Management
                                </Button>
                    </div>
                );
            } else {
                content = (
                    <>
                            <div style={{
                                padding: 20,
                            }}>
                                <Typography variant="h6">
                                    Wir sind umgezogen!
                                </Typography>
                                <Space>
                                <Button variant="outlined" onClick={() => {handleNavigate("/project/articles/management/v1")}}>
                                    Zum neuen Task Management
                                </Button>
                                
                                        <Popconfirm title={<div style={{
                                            maxWidth: "400px"
                                        }}>
                                            Bevor die Aufgaben übertragen werden, müssen wir die Sitzung neu laden. Dies kann einige Minuten dauern. Wir werden Sie auf die Homepage Leiten, sobald der Vorgang abgeschlossen ist. Sind Sie sicher, dass Sie die Version des Projektes hochstufen möchten?
                                        </div>}
                                            okType="danger"
                                            style={{ width: "30%" }}
                                            onConfirm={() => { handleTransfer(mongoId) }} okText="Ja" cancelText="Nein">
                                            <Button variant="primary" danger onClick={() => { }}>
                                                Aufgaben übertragen
                                            </Button>
                                        </Popconfirm>
                                
                                </Space>
                            </div>
                            <Result status="warning" title="Aufgaben Version 2" subTitle="Die Aufgaben aus Version 2 (__v - 1) werden hier nichtmehr Angezeigt." />
                    </>
                );
            };
            return content;
        default:
            return (
                <>
                    <p>Es gibt ein Problem mit der Ansicht</p>
                </>
            );
    }
};


export default ProjectDetailPage;