import { Form, Input } from "antd";
import React from "react";


function NewCustomerForm(props) {

    // const data = props.Data;
    const customerId = props.CustomerId;
    const mongoId = props.SelectedProject._id;

    return (
        <>
            <h2>Neuer Kunde @ {customerId}</h2>
            <div>
                    <Form.Item
                        label="Kundenname"
                        name="name"
                        rules={[{ required: true, message: 'Bitte geben Sie einen Kundennamen ein!' }]}
                    >
                        <Input
                        placeholder="Vorname Nachname"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Unternehmen"
                        name="company"
                        rules={[{ required: true, message: 'Bitte geben Sie einen Kundennamen ein!' }]}
                    >
                        <Input
                        placeholder="Kundenname GmbH & Co. KG"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Bitte geben Sie eine Email ein!' }]}
                    >
                        <Input
                        placeholder="z.B. email@email.com"
                        type="email"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Mobiltelfonnummer"
                        name="phone"
                        rules={[{ required: true, message: 'Bitte geben Sie eine Handynummer ein!' }]}
                    >
                        <Input
                        placeholder="z.B. +49 123 456789"
                        type="tel"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Adresse"
                        name="address"
                        rules={[{ required: true, message: 'Bitte geben Sie eine Adresse und Hausnummer ein!' }]}
                    >
                        <Input
                        placeholder="Adresse & Hausnummer"
                        type="address"
                        />
                    </Form.Item>
                    <Form.Item
                        label="PLZ"
                        name="zip"
                        rules={[{ required: true, message: 'Bitte geben Sie eine Postleitzahl ein!' }]}
                    >
                        <Input
                        placeholder="z.B. 12345"
                        type="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Stadt"
                        name="city"
                        rules={[{ required: true, message: 'Bitte geben Sie eine Stadt ein!' }]}
                    >
                        <Input
                        placeholder="z.B. Berlin"
                        type="text"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Land"
                        name="country"
                        rules={[{ required: true, message: 'Bitte geben Sie ein Land ein!' }]}
                    >
                        <Input
                        placeholder="z.B. Deutschland"
                        type="text"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Hinweise (intern)"
                        name="notes"
                        rules={[{ required: false, message: 'Bitte geben Sie Hinweise ein!' }]}
                    >
                        <Input.TextArea
                        placeholder="z.B. Kundennummer, Ansprechpartner, etc."
                        />
                    </Form.Item>
                    <Form.Item
                        label="customerId"
                        name="customerId"
                        hidden
                        initialValue={customerId}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="mongoId"
                        name="mongoId"
                        hidden
                        initialValue={mongoId}
                    >
                        <Input />
                    </Form.Item>

            </div>
        </>
    );
};

export default NewCustomerForm;