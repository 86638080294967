import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Button, DatePicker, Drawer, Row, Col, Typography, List, Divider } from "antd";
import { getAllProjects, reqCsvExport, reqXlsxExport } from "../../../../routing/crudActions";
import { DateTime } from "luxon";
import jsonLocale from "../../../../locale/datePickerLocale.json";
import { useNavigate } from "react-router-dom";
import { slice_updateAutoSelect_0 } from "../../../../redux/mainSlicer";
import { useDispatch } from "react-redux";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../../firebase";
import _ from "lodash";
import { CardButton } from "../../../Buttons/CardButton.component";
import { motion } from "framer-motion";
import { CloudDownloadOutlined, FileDoneOutlined, FundProjectionScreenOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";


function CollectionsPage() {

    const [collections, setCollections] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userState.mongoUser)
    const datePickerRef = useRef();
    const localNow = DateTime.now().toJSDate();
    const [filter, setFilter] = useState({
        startDate: localNow,
        endDate: localNow,
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [filteredCollections, setFilteredCollections] = useState(collections);

    const handleInvoicePage = (p) => {
        dispatch(slice_updateAutoSelect_0(p));
        navigate(`/accounting/${p._id}`);
    };

    const handleOpenAccountingDrawer = (p) => {
        dispatch(slice_updateAutoSelect_0(p));
        if (p.__v === 1) {
            navigate(`/accounting/${p._id}/details`);
        };
    };

    function handleSearchChange(e) {
        console.log("Search Text:", e.target.value);
        if (e.target.value === "") {
            setFilteredCollections(collections);
        } else {
            const filtered = collections.filter((item) => {
                return item.title.toLowerCase().includes(e.target.value.toLowerCase());
            });
            setFilteredCollections(filtered);
        }
    };

    function handleSearchReset() {
        setFilteredCollections(collections);
    }

    function handleDateChange(dates) {
        if (dates && dates.length === 2) {
            const [startDate, endDate] = dates;
            const luxonStartDate = DateTime.fromJSDate(startDate.toDate());
            const luxonEndDate = DateTime.fromJSDate(endDate.toDate());

            // Jetzt haben Sie luxonStartDate und luxonEndDate, die Sie mit Luxon verwenden können.
            console.log('Start Date (Luxon):', luxonStartDate.toString());
            console.log('End Date (Luxon):', luxonEndDate.toString());

            setFilter({
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
            });
        }
    };

    const fetchCollections = async () => {
        setLoading(true)
        await getAllProjects(user._id)
            .then((response) => {
                setCollections(response.data);
                setFilteredCollections(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(
                console.log(collections)

            )
        setLoading(false)
    };

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const manageModalOpen = (data) => {
        setModalContent(data);
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        fetchCollections();
    }, []);

    const requestCSVExport = async (collectionId) => {
        setLoading(true)
        try {
            console.log("Requesting CSV Export for Collection: ", collectionId);
            const data = await reqCsvExport(DateTime.fromJSDate(filter.startDate).toJSDate(), DateTime.fromJSDate(filter.endDate).toJSDate(), collectionId)
                .then((result) => {
                    console.log(result);
                    return result.data;
                }).catch((err) => {
                    console.log(err);
                });

            //Erzeugen eines Blobs zum herunterladen der Datei
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.download = "export_" + collectionId + '.csv';
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };

    const requestXLSXExport = async (collectionId) => {
        setLoading(true)
        try {
            console.log("Requesting XLSX Export for Collection: ", collectionId);
            const data = await reqXlsxExport(DateTime.fromJSDate(filter.startDate).toJSDate(), DateTime.fromJSDate(filter.endDate).toJSDate(), collectionId)
                .then((result) => {
                    console.log("Download URL:", result.data);
                    console.log(result);
                    if (_.has(result, 'error') === true) throw new Error(result.error);
                    return result.data;
                })
                .catch((err) => {
                    console.log(err);
                });

            const filePath = data.filePath;
            const storeRef = ref(storage, filePath);
            const url = await getDownloadURL(storeRef)
            console.log("Download URL:", url);

            // Fetch the file blob
            const response = await fetch(url);
            const blob = await response.blob();

            // Create a temporary anchor element and trigger the download
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = 'assembly_exported_data.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Revoke the object URL after a short delay
            setTimeout(() => URL.revokeObjectURL(a.href), 100);

        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    };

    const handleExport = async () => {
        console.log("Exporting Data");
        await requestCSVExport(modalContent._id);
    };

    const handleXlsxExport = async () => {
        console.log("Exporting Data");
        await requestXLSXExport(modalContent._id);
    };

    return (
        <>
            <div>
                <div>
                    <Typography.Title level={2}>Abnahmebelege & Exporte</Typography.Title>
                    <Divider
                        orientation="left"
                        style={{
                            marginTop: "0px",
                            marginBottom: "10px",
                        }}
                    >
                        Abrechnungsmanagement
                    </Divider>
                    <Typography.Paragraph style={{
                        marginBottom: "10px",
                        textAlign: "left",
                        alignContent: "left",
                        justifyContent: "left",
                        alignItems: "left",
                    }}>
                        Hier können Sie die Abnahmebelege und Exporte für die einzelnen Projekte verwalten.
                    </Typography.Paragraph>

                    <div>
                        <Search
                            placeholder="Suche nach Projekten"
                            allowClear
                            enterButton="Suchen"
                            onChange={handleSearchChange}
                            style={{
                                marginBottom: "10px",
                            }}
                            onReset={handleSearchReset}
                        />
                    </div>
                </div>

                <List
                style={{
                    marginTop: "10px",
                    marginBottom: "50px",
                }}
                    header={<div>Exporte und Abnahmebelege</div>}
                    dataSource={filteredCollections}
                    renderItem={(item, i) => {
                        const delay = i * 0.105;
                        return (
                            <motion.div
                                initial={{ opacity: 0, y: 15 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                    delay: delay,
                                    duration: 0.5,
                                    type: 'spring',
                                    stiffness: 100,
                                    damping: 10,
                                }}
                            >
                                <List.Item
                                    actions={[
                                        <Button type="icon" icon={<CloudDownloadOutlined />} iconPosition="start" onClick={() => manageModalOpen(item)}>Exportoptionen</Button>,
                                        <Button type="icon" icon={<FileDoneOutlined />} iconPosition="start" onClick={() => handleInvoicePage(item)}>Abnahmebelege</Button>,
                                        <Button type="icon" icon={<FundProjectionScreenOutlined />} iconPosition="start" onClick={() => handleOpenAccountingDrawer(item)}>Kostenübersicht</Button>
                                    ]}
                                >
                                    <Typography.Text>{item.title}</Typography.Text>
                                </List.Item>
                            </motion.div>)
                    }}

                />
            </div>

            <Drawer open={openModal} onCancel={closeModal} footer={null} onClose={closeModal}
                width={"60%"}
                title={"Export der Rechnungsdaten für: " + modalContent.title}
                destroyOnClose={true}
            >
                <Alert message="Die Export Funktionalitäten werden gerade überarbeitet, es kann zu einschränkungen kommen."
                    description="Es kann durchaus möglich sein, dass ein Export eine beschädigte oder unvollständige Datei erzeugt. Sollte dies der Fall sein, bitte nochmals versuchen."
                    type="warning" showIcon style={{
                        marginBottom: "10px",
                        borderColor: "black",
                        borderWidth: "2px",
                    }} />
                <div style={{
                    marginTop: "10px",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }}>
                    <div>
                        Bitte wähle einen Zeitraum für den Export der Rechnungsdaten aus:
                    </div>

                    <div>
                        <div>
                            <DatePicker.RangePicker
                                // defaultValue={[filter.startDate.getDate(), filter.endDate.getDate()]}
                                format={"DD.MM.YYYY"}
                                ref={datePickerRef}
                                locale={jsonLocale}
                                onChange={(value) => {
                                    handleDateChange(value)
                                }}
                            />
                            {/* <div>
                            Aktueller Zeitraum für den Rechnungsdaten exportiert werden sollen: {filter.startDate.toLocaleDateString()} - {filter.endDate.toLocaleDateString()}
                        </div> */}
                        </div>
                    </div>
                    <div style={{
                        marginTop: "10px",
                    }}>
                        <Row gutter={[24, 24]} align="middle">
                            <Col xs={{ flex: '100%', }} sm={{ flex: '50%', }} md={{ flex: '40%', }} lg={{ flex: '20%', }} xl={{ flex: '10%', }}>
                                <CardButton title="CSV Export" text="CSV Export" icon="CSV" callback={handleExport} timeout={0.15} tooltip="Exportiert alle Daten als CSV" loading={loading} />
                            </Col>
                            <Col xs={{ flex: '100%', }} sm={{ flex: '50%', }} md={{ flex: '40%', }} lg={{ flex: '20%', }} xl={{ flex: '10%', }}>
                                <CardButton title="EXCEL Export" text="EXCEL Export" icon="EXCEL" callback={handleXlsxExport} timeout={0.25} tooltip="Exportiert alle Daten als EXCEL. Experimentelles Feature." loading={loading} />
                            </Col>
                            <Col xs={{ flex: '100%', }} sm={{ flex: '50%', }} md={{ flex: '40%', }} lg={{ flex: '20%', }} xl={{ flex: '10%', }}>
                                <CardButton title="PowerBi API" text="PowerBi API" icon="PowerBi" callback={() => { }} disabled timeout={0.35} loading={loading} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default CollectionsPage;