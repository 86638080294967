import _ from "lodash";

export function trimAndReplace(stringToArray, condition) {
    //Trimmt und ersetzt die Leerzeichen in den Strings
    //Wird für die Validierung der Felder benötigt um bei der anlage eines neuen Feldes die Felder zu trennen
    if (condition === 'select') {
        let newArray = []
        const array = _.split(stringToArray, ";").forEach((v, i) => {
            if (_.isString(v)) {
                newArray.push(_.trimStart(_.trimEnd(v)))
            } else {
                newArray.push(v)
            }
        })

        console.log(newArray)
        return newArray
    } else {
        return stringToArray;
    };
};


export function convertColumns(dataArray, fields) {
    //Konvertiert die Daten aus der Datenbank in das richtige Format für die Tabelle
    const convertedData = [];
    dataArray.forEach((data) => {
        const convertedItem = {
            id: fields.length++,
            fieldName: data.fieldName,
            fieldNameId: data.fieldNameId,
            fieldType: data.fieldType.toLowerCase(),
            systemField: data.systemField.toLowerCase(),
            fieldRequired: data.fieldRequired || false,
            fieldDefaultValue: data.fieldDefaultValue,
            fieldValidation: data.fieldValidation,
        };
        convertedData.push(convertedItem);
    });
    return convertedData;
};


export function converColumsForSchema(dataArray) {
    //Konvertiert die Daten aus der Datenbank in das richtige Format für die Tabelle in der das Schema angezeigt wird!
    // const columns = [
    //     { field: 'id', headerName: 'ID', width: 50 },
    //     { field: 'fieldName', headerName: 'Feldname', width: 200, editable: edit },
    //     { field: 'fieldType', headerName: 'Feld Typ', width: 150, editable: edit },
    //     { field: 'fieldRequired', headerName: 'Erforderlich', width: 130, editable: edit },
    //     { field: 'fieldDefaultValue', headerName: 'Standardwert', width: 200, editable: edit },
    //     { field: 'fieldValidation', headerName: 'Validierung', width: 250, editable: edit },
    // ]
    const convertedData = [];
    dataArray.forEach((data) => {
        const convertedItem = {
            id: data._id,
            fieldName: data.fieldName,
            // fieldNameId: data.fieldNameId,
            fieldType: data.fieldType?.toLowerCase() || data.fieldNameId,
            systemField: data.systemField?.toLowerCase() || data.fieldNameId,
            fieldRequired: data.fieldRequired || false,
            fieldDefaultValue: data.fieldDefaultValue,
            fieldValidation: data.fieldValidation,
        };
        convertedData.push(convertedItem);
    });
    return convertedData;

};

export function addTaskField(tasksObjectArray) {
    //Fügt einen neuen Task hinzu - der auf den Projektleistungen basiert
    if (_.isEmpty(tasksObjectArray)) {
        return console.log("No tasks found")
    } else {
        const newField = {
            id: 2,
            fieldName: "Tätigkeit",
            fieldNameId: "tätigkeit",
            fieldType: "string",
            systemField: "task",
            fieldRequired: true,
            fieldDefaultValue: "",
            fieldValidation: tasksObjectArray,
        };
        return newField;
    };
};

export function convertColumsForProjects(dataArray) {
    //Konvertiert die Daten aus der Datenbank in das richtige Format für die Tabelle in der das Schema angezeigt wird!
    // const columns = [
    //     { field: 'id', headerName: 'ID', width: 50 },
    //     { field: 'title', headerName: 'Projektname', width: 200, editable: edit },
    //     { field: 'projectId', headerName: 'Projekt ID', width: 150, editable: edit },
    //     { field: 'companyIdentifier', headerName: 'Unternehmenskennung', width: 130, editable: edit },
    //     { field: 'dataMode', headerName: 'Datenmodus', width: 200, editable: edit },
    //     { field: 'modelName', headerName: 'Modellname', width: 250, editable: edit },
    // ]
    const convertedData = [];
    dataArray.forEach((data) => {
        const location = data.projectLocation
        const convertedItem = {
            id: data._id,
            title: data.title,
            imageUrl: data.imageUrl,
            projectId: data.projectId.toLowerCase(),
            location: `${location.adress}, ${location.cityName}`,
            companyIdentififer: data.companyIdentififer || false,
            dataMode: data.dataModel,
            modelName: data.modelName,
        };
        convertedData.push(convertedItem);
    });
    return convertedData;

};