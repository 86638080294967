import { Alert, Button, Descriptions, Drawer, Form, Input, InputNumber, Modal, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { v4_setSollKpi_Weeks, v4_sollValues_get_dataset, v4_sollValues_get_dataset_template } from "../../../routing/v4_Routes/projectManagement.routes";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { TitleImage } from "../project/PublicProjectPage";
import { v4_getProjectById } from "../../../routing/v4_Routes/project.routes";
import { useUpdate } from "ahooks";

const { Title, Text, Link } = Typography;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <InputNumber type="decimal" decimalSeparator="," ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

/**
 * @description This is the drawer for the productivity quotes. Opened by clicking on the productivity quotes button.
 * @returns Button that opens the drawer with the productivity quotes.
 */
export function ProductivityQuotesPage() {

    const update = useUpdate();
    const { _id } = useParams();
    const [visible, setVisible] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [project, setProject] = React.useState(null);
    const [templateDataset, setTemplateDataset] = React.useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedModalMonth, setSelectedModalMonth] = useState(null);
    const [settableObject, setSettableObject] = useState({});

    const onChangeSettable = (object) => {
        const oldObject = settableObject;
        console.log(oldObject, object)
        setSettableObject(_.merge(oldObject, object))
        console.log(settableObject)
        update()
    };

    useEffect(() => {
        if (modalVisible) {
            setSettableObject(selectedModalMonth);
        };

        return () => {
            setSettableObject({});
        }
    }, [modalVisible])

    async function fetchData() {
        setLoading(true);
        try {
            const d = await v4_sollValues_get_dataset(_id);
            setData(d);
            setVisible(_.isEmpty(d));
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        };
    };

    async function fetchProject() {
        setLoading(true);
        try {
            if (_.isEmpty(_id) || _id === undefined) return;
            const p = await v4_getProjectById(_id);
            setProject(p);
        } catch (error) {
            console.error("Error fetching project: ", error);
        } finally {
            setLoading(false);
        };
    };

    async function getTemplateDataset() {
        setLoading(true);
        try {
            const d = await v4_sollValues_get_dataset_template(_id);
            setData(d);
            setVisible(false);
            console.log("Template dataset: ", d);
        } catch (error) {
            console.error("Error fetching template dataset: ", error);
        } finally {
            setLoading(false);
        };
    }

    useEffect(() => {
        fetchData();
        return () => {
            setData(null);
        };
    }, [_id]);

    useEffect(() => {
        fetchProject();
        return () => {
            setProject(null);
        }
    }, []);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const handleSave = async (row) => {
        console.log("before:", settableObject)
        const oldRow = _.find(settableObject.weeks, { _id: row._id });
        const changedPath = _.findKey(row, (v, k) => v !== oldRow[k]);
        const newRow = _.set(oldRow, changedPath, row[changedPath]);
        const oldSettableObject = settableObject;
        _.remove(oldSettableObject.weeks, (r) => r._id === row._id);
        oldSettableObject.weeks.push(newRow);
        setSettableObject(oldSettableObject);
        console.log("after:", settableObject);
        update();
    };

    const saveChangesOfSettable = async () => {
        try {
            await v4_setSollKpi_Weeks(settableObject._id, settableObject);
            fetchData();
            setModalVisible(false);
            update();
        } catch (error) {
            console.error("Error saving changes: ", error);
        };
    };

    React.useEffect(() => {
        //Fensternamen setzen
        document.title = `P&L Sollwerte`;
        return () => {
            document.title = "Assembly";
        };
    }, []);

    return (
        <div>
            <TitleImage title="Produktivität und Leistungsgrad - Sollwert Konfiguration" project={project} />
            <div>
                <div style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}>
                    <Text>Die Soll-Werte für Produktivität und Leistungsgrad können hier konfiguriert werden.</Text>
                </div>
                <div style={{
                    marginBottom: '20px',
                }}>

                    <Table
                        dataSource={data}
                        columns={[
                            {
                                title: 'Identifikator',
                                dataIndex: '_id',
                                key: '_id',
                            },
                            {
                                title: 'Bezeichnung',
                                dataIndex: '_id',
                                key: '_id',
                                render: (t, o) => {
                                    return <Text>Y{o?.year}VM{o?.month + 1}OR{o?.month}</Text>
                                }
                            },
                            {
                                title: 'Startdatum',
                                dataIndex: 'startOfMonth',
                                key: '_id',
                                render: (date) => {
                                    return <Text>{new Date(date).toLocaleDateString()}</Text>
                                },
                                sorter: (a, b) => a.startOfMonth - b.startOfMonth,
                                sortOrder: 'ascend',
                            },
                            {
                                title: 'Enddatum',
                                dataIndex: 'endOfMonth',
                                key: '_id',
                                render: (date) => {
                                    return <Text>{new Date(date).toUTCString()}</Text>
                                }
                            },
                            {
                                title: 'Monat',
                                dataIndex: 'month',
                                key: '_id',
                                render: (text) => {
                                    return <Text>{Number(text) + 1}</Text>
                                },
                            },
                            {
                                title: '',
                                dataIndex: '_id',
                                key: '_id',
                                render: (o, d) => {
                                    return <Button type="link" disabled={
                                        d?.week === 1
                                    }
                                        onClick={() => { setModalVisible(true); setSelectedModalMonth(d) }}
                                    >berechnen</Button>
                                }
                            },
                        ]}
                        pagination={false}
                        bordered
                        loading={loading}
                        size="small"
                        rowKey={(record) => record._id}
                        expandable={{
                            expandedRowRender: (record) => (
                                <Table
                                    rowKey={(record) => record._id}
                                    //Turn object into array
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    dataSource={record?.weeks}
                                    bordered
                                    size="small"
                                    columns={[
                                        {
                                            title: 'Woche',
                                            dataIndex: 'week',
                                            key: '_id',
                                        },
                                        {
                                            title: 'Wochenstart',
                                            dataIndex: 'startOfWeek',
                                            key: '_id',
                                            render: (date) => {
                                                return <Text>{new Date(date).toLocaleDateString()}</Text>
                                            }
                                        },
                                        {
                                            title: 'Wochenende',
                                            dataIndex: 'endOfWeek',
                                            key: '_id',
                                            render: (date) => {
                                                return <Text>{new Date(date).toLocaleDateString()}</Text>
                                            }
                                        },
                                        {
                                            title: 'Vergangene Woche',
                                            dataIndex: 'lastWeek',
                                            key: '_id',
                                        },
                                        {
                                            title: 'Vorgabemenge (Stückzahl)',
                                            dataIndex: 'vorgabemenge',
                                            key: '_id',
                                        },
                                        {
                                            title: 'Durchschnitt vergangene Woche (Stückzahl)',
                                            dataIndex: 'averageLastWeek',
                                            key: '_id',
                                        },
                                        {
                                            title: 'Erreicht (Stückzahl)',
                                            dataIndex: 'menge',
                                            key: '_id',
                                        },
                                        {
                                            title: 'Unproduktive Stunden (IST)',
                                            dataIndex: 'unproductiveHours',
                                            key: '_id',
                                        },
                                        {
                                            title: 'Unproduktive Stunden (vergangene Woche)',
                                            dataIndex: 'unproductiveHoursLastWeek',
                                            key: '_id',
                                        },
                                        {
                                            title: 'Austritte',
                                            dataIndex: 'austritte',
                                            key: '_id',
                                            render: (text, record) => {
                                                if (text === undefined) {
                                                    return <Text>NV</Text>
                                                } else {
                                                    return <Text>{text}</Text>
                                                }
                                            }
                                        }
                                    ]}
                                />
                            )
                        }}
                    />

                </div>
            </div>
            <Modal
                title="Soll-Werte aus Kalkulation"
                open={modalVisible}
                onOk={async () => await saveChangesOfSettable()}
                onCancel={() => setModalVisible(false)}
                width={"70%"}
            >
                <Table
                    rowKey={(record) => record._id}
                    //Turn object into array
                    components={components}
                    rowClassName={() => 'editable-row'}
                    dataSource={settableObject?.weeks || []}
                    bordered
                    size="small"
                    columns={[
                        {
                            title: 'Woche',
                            dataIndex: 'week',
                            key: '_id',
                        },
                        {
                            title: 'Wochenstart',
                            dataIndex: 'startOfWeek',
                            key: '_id',
                            render: (date) => {
                                return <Text>{new Date(date).toLocaleDateString()}</Text>
                            }
                        },
                        {
                            title: 'Wochenende',
                            dataIndex: 'endOfWeek',
                            key: '_id',
                            render: (date) => {
                                return <Text>{new Date(date).toLocaleDateString()}</Text>
                            }
                        },
                        {
                            title: 'Vergangene Woche',
                            dataIndex: 'lastWeek',
                            key: '_id',
                        },
                        {
                            title: 'Vorgabemenge (Stückzahl)',
                            dataIndex: 'vorgabemenge',
                            key: '_id',
                            onCell: (record) => ({
                                record,
                                editable: true,
                                dataIndex: 'vorgabemenge',
                                title: 'Vorgabemenge',
                                handleSave: handleSave,

                            }),
                        },
                        {
                            title: 'Durchschnitt vergangene Woche (Stückzahl)',
                            dataIndex: 'averageLastWeek',
                            key: '_id',
                            onCell: (record) => ({
                                record,
                                editable: true,
                                dataIndex: 'averageLastWeek',
                                title: 'Durchschnitt vergangene Woche (Stückzahl)',
                                handleSave: handleSave,
                            }),
                        },
                        {
                            title: 'Erreicht (Stückzahl)',
                            dataIndex: 'menge',
                            key: '_id',
                            onCell: (record) => ({
                                record,
                                editable: true,
                                dataIndex: 'menge',
                                title: 'menge',
                                handleSave: handleSave,
                            }),
                        },
                        {
                            title: 'Unproduktive Stunden (IST)',
                            dataIndex: 'unproductiveHours',
                            key: '_id',
                            onCell: (record) => ({
                                record,
                                editable: true,
                                dataIndex: 'unproductiveHours',
                                title: 'Unproduktive Stunden (IST)',
                                handleSave: handleSave,
                            }),
                        },
                        {
                            title: 'Unproduktive Stunden (vergangene Woche)',
                            dataIndex: 'unproductiveHoursLastWeek',
                            key: '_id',
                            onCell: (record) => ({
                                record,
                                editable: true,
                                dataIndex: 'unproductiveHoursLastWeek',
                                title: 'unproductiveHoursLastWeek',
                                handleSave: handleSave,
                            }),
                        },
                        {
                            title: 'Austritte',
                            dataIndex: 'austritte',
                            key: '_id',
                            onCell: (record) => ({
                                record,
                                editable: true,
                                dataIndex: 'austritte',
                                title: 'Austritte',
                                handleSave: handleSave,
                            }),
                        },
                        {
                            title: 'Personal(FTE)',
                            dataIndex: 'employees',
                            key: '_id',
                            onCell: (record) => ({
                                record,
                                editable: true,
                                dataIndex: 'employees',
                                title: 'Personal(FTE)',
                                handleSave: handleSave,
                            }),
                        }
                    ]}
                />


                {/* <Text editable={{ onChange: async (str) => {
                try {
                  console.log(str)
                } catch (error) {
                  console.error('Fehler beim Speichern:', error);
                }
              } }}>{settableObject?.wss}</Text> */}

            </Modal>

            <Modal
                title="Soll-Werte für Produktivität und Leistungsgrad"
                placement="right"
                closable={false}
                footer={null}
                open={visible}
                width={"80%"}
                destroyOnClose={true}
                maskClosable={false}
            >
                {_.isEmpty(data) && <div>
                    <Alert message="Es sind keine Soll-Werte hinterlegt." type="info" />
                </div>}

                <div style={{
                    marginTop: '20px',

                }}>
                    <Text>Die Soll-Werte für Produktivität und Leistungsgrad können hier konfiguriert werden.</Text>
                </div>

                <div style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    alignContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                }}>
                    <Tooltip title="Sie erstellen ein einmaliges Template. Der Vorgang nimmt einen Moment in Anspruch.">
                        <Button type="primary" onClick={getTemplateDataset} loading={loading} size="large">
                            Template erstellen
                        </Button>
                    </Tooltip>
                </div>
            </Modal>
        </div>
    )
};