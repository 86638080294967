import { Button, Collapse, Modal } from "antd";
import React from "react";


const Questions = [
    {
        label: "1. Was sind wiederkehrende Kosten?",
        key: "1",
        children: "Wiederkehrende Kosten sind Kosten, die regelmäßig anfallen. Beispiele hierfür sind Mieten, Gehälter, Versicherungen, etc. - diese Kosten sollten im optimalfall auf einen Intervall verteilt werden."
    },
    {
        label: "2. Was sind variable Kosten?",
        key: "2",
        children: "Variable Kosten sind Kosten, die nicht regelmäßig anfallen. Beispiele hierfür sind Reparaturen, Wartungen, etc. Diese können auf einen Stichtag gebucht werden oder auch auf einen Zeitraum verteilt werden. (Hierzu bitte die Kosten aufteilen Option aktivieren.)"
    },
    {
        label: "3. Was sind einmalige Kosten?",
        key: "3",
        children: "Einmalige Kosten sind Kosten, die nur einmalig anfallen. Beispiele hierfür sind Anschaffungen, etc. Diese zählen auch zu den variablen Kosten. Siehe 2."
    },
    {
        label: "4. Was sind fixe Kosten?",
        key: "4",
        children: "Fixe Kosten sind Kosten, die unabhängig von der Menge anfallen. Beispiele hierfür sind Mieten, Gehälter, etc. Diese Kosten werden immer auf einen intevall gebucht. (Monatlich, Jährlich, etc.)"
    },
    {
        label: "Weiteres wissen: Was sind direkte Kosten?",
        key: "5",
        children: "Direkte (auch Einzelkosten) Kosten sind Kosten, die direkt einem Produkt oder einer Dienstleistung zugeordnet werden können. Beispiele hierfür sind Materialkosten, etc."
    },
    {
        label: "Weiteres wissen: Was sind indirekte Kosten?",
        key: "6",
        children: "Indirekte (auch Gemeinkosten) Kosten sind Kosten, die nicht direkt einem Produkt oder einer Dienstleistung zugeordnet werden können. Beispiele hierfür sind Mieten, Gehälter, etc."
    },
    {
        label: "Weiteres wissen: Was sind Gemeinkosten?",
        key: "7",
        children: "Gemeinkosten (auch indirekte Kosten) sind Kosten, die nicht direkt einem Produkt oder einer Dienstleistung zugeordnet werden können. Beispiele hierfür sind Mieten, Gehälter, etc."
    },
    {
        label: "Weiteres wissen: Was sind Einzelkosten?",
        key: "8",
        children: "Einzelkosten (auch direkte Kosten) sind Kosten, die direkt einem Produkt oder einer Dienstleistung zugeordnet werden können. Beispiele hierfür sind Materialkosten, etc."
    },
];


export function FAQCostModal(props) {


    return (
        <Modal
            open={props.open}
            onCancel={props.closeFAQModal}
            closable={true}
            title="FAQ - Kostenarten"
            onClose={props.closeFAQModal}
            width={"70%"}
            footer={
                <div
                    style={{
                        textAlign: "left",
                    }}
                >
                    <Button onClick={props.closeFAQModal} style={{ marginRight: 8 }}>
                        Schließen
                    </Button>
                </div>
            }
        >
            <div>
                <Collapse defaultActiveKey={["1"]}
                items={Questions}
                />
            </div>
        </Modal>
    );
}