import * as React from 'react';
import { useState } from 'react';
import { Button, Form, message, Modal } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@mui/icons-material';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../firebase';
import { setAPI } from '../../../routing/api';

// const fileTypes = ["pdf", "docx", "xlsx", "jpeg", "gif", "png", "svg", "pptx", "jpg", "txt", "json", "csv", "zip", "xml", "txt", "gif"]

export default function UploadFileDialog(props) {

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: `Die Aktion war erfolgreich.`,
        });
    };

    const handleCancel = () => {
        props.setOpen(false);
    }

    const fetchAgain = () => {
        props.fetchAgain(props.ProjectId);
    }


    const [isUploading, setIsUploading] = useState(false);



    const handleFileUpload = async (file) => {
        try {
            const fileUrl = file.response.publicBlobUrl;
            const storageReference = ref(storage, fileUrl);
            const downloadRef = await getDownloadURL(storageReference)
            console.log(downloadRef)
            fetchAgain();
            success();
        } catch (error) {
            console.log("Error while getting file URL")
        }
    };

    const uploadPropsAntd = {
        name: 'file',
        multiple: false,
        action: `${setAPI()}/upload/file/project-file/${props.ProjectId}`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                console.log(info.file);

            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload: (file) => {
            console.log("Before Upload handler")
            return true;
          },
    };

    const handleChange = (values) => {
        console.log(values)
    };

    const handleFinish = (values) => {
        console.log(values)
    };


    let content = (
            <div>
                {contextHolder}
                <div>
                    <Modal
                        title={`Dokument hochladen`}
                        centered
                        open={props.open}
                        onCancel={handleCancel}
                        width={800}
                        confirmLoading={isUploading}
                        footer={[
                            <Button type='primary' onClick={handleCancel} key={1}>Fertig</Button>
                        ]}
                    >
                        <Form
                            name="upload"
                            onChange={handleChange}
                            onFinish={handleFinish}
                            >
                                <Form.Item
                                    name="file"
                                    label="Datei"
                                    valuePropName="file"
                                    >
                                        <Dragger {...uploadPropsAntd}
                            // onChange={async (e) => { setIsUploading(true); await handleFileUpload(e.file) }}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Projektdatei hochladen</p>
                            <p className="ant-upload-hint">
                                Lade die gewünschte Datei hoch. Der Upload startet umgehend.
                            </p>
                        </Dragger>
                                    </Form.Item>
                            </Form>
                    </Modal>
                </div>

            </div>

        )

    return content;
};