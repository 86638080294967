import axios from 'axios';
import { auth } from '../firebase';

export function setAPI() {

        if (process.env.REACT_APP_VERCEL_ENV === "development") {
                return process.env.REACT_APP_API_URL;
        } else {
                return process.env.REACT_APP_PROD_API_URL;
        }

        
};

export function v3_setAPI() {
        let url = "";
        
        if (process.env.REACT_APP_VERCEL_ENV === "development") {
                url = process.env.REACT_APP_API_URL + "/v3";
        } else {
                url = process.env.REACT_APP_PROD_API_URL + "/v3";
        };

        return url;
        
};

export function v4_setAPI() {
        let url = "";
        
        if (process.env.REACT_APP_VERCEL_ENV === "development") {
                url = process.env.REACT_APP_API_URL + "/v4";
        } else {
                url = process.env.REACT_APP_PROD_API_URL + "/v4";
        };

        return url;
        
};

export async function checkConnection() {
        try {
                const token = await auth.currentUser.getIdToken();
                return await axios.post(`${setAPI()}/api/status`, { check: "connection" }, { headers: { Authorization: `Bearer ${token}` } });
        } catch (error) {
                console.log(error);
        };
};

export async function getCsrfToken() {
        const response = await axios.get(`${setAPI()}/csrf-token`);
        return response.data.csrfToken;
    };