import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Container, Box, Stack } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { HeaderWithBackButton } from "../../global/HeaderBackButton";
import { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { fetchCustomFields } from "../../../data/fetchData";
import { updateCustomFields } from "../../../redux/projectSlicer";
import { AddRounded } from "@mui/icons-material";

//import custom components
import ContactCard from "../../ContactCard";

//import Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlaylistAddCircleRoundedIcon from '@mui/icons-material/PlaylistAddCircleRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import HistoryEduRoundedIcon from '@mui/icons-material/HistoryEduRounded';
import { Card, Descriptions, Empty, List, Button, Segmented, Typography, Row, Col, Badge, Result } from "antd";
import Meta from "antd/es/card/Meta";
import FileDownloaderButton from "../../global/fileManagement/FileDownloaderButton";
import FileViewInBrowserButton from "../../global/fileManagement/FileViewInBrowserButton";
import { setFileImage } from "../../../utils/storageRouting";
import { listDocuments } from "../../../utils/firebaseStorage";
import { PlusCircleOutlined, DatabaseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { displayInfoNotification } from "../../../utils/messageServices/notificationService";

const { Title, Paragraph } = Typography;

const tabs = [
    { value: "übersicht", label: "Übersicht" },
    { value: "dokumente", label: "Dokumente" },
]

function ProjectPage(props) {
    const [tabValue, setTabValue] = useState("übersicht");
    const [openDialog, setOpenDialog] = useState(false);
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dataParam = searchParams.get("projectData");
    const project = useSelector((state) => state.projectState.selectedProject);
    const user = useSelector((state) => state.userState.mongoUser);

    let data = {};
    if (dataParam) {
        try {
            const decodedData = decodeURIComponent(dataParam);
            data = JSON.parse(decodedData);
        } catch (error) {
            console.error("Error parsing data parameter", error);
        }
    };



    const handleTabChange = (event, newValue) => {
        setTabValue(event);
    };

    const handleOpenEntry = () => {
        dispatch(updateFetchingData(true));
        fetchCustomFields(data.mongoId)
        .then((result) => {
            dispatch(updateCustomFields(result.data));
            if (project.dataMode === "csv") {
                navigate(`/projects/${project.projectId}/database/create/csv?viewData=${JSON.stringify({ projectId: props.ProjectId, layout: "desktop", mongoId: data.mongoId}) } `)
            } else {
                navigate(`/projects/${project.projectId}/database/create?viewData=${JSON.stringify({ projectId: props.ProjectId, layout: "desktop", mongoId: data.mongoId}) } `)
            }
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            dispatch(updateFetchingData(false));
        });

    };

    useEffect(() => {

    }, []);


    return (
        <>
            <HeaderWithBackButton backLink="/" />
            <Container sx={{ mt: 5 }}>
                <Box>
                    <Stack direction="row" alignItems="center">
                        <Stack direction="row">
                            <Box
                                sx={{
                                    backgroundColor: 'white',
                                    p: 3,
                                    borderRadius: '50px',
                                    objectFit: 'contain'
                                }}>
                                <img
                                    src={project.imageUrl}
                                    alt="Project_Logo_Image"
                                    width="100px"
                                    style={{ resize: 'both', backgroundColor: 'white', objectFit: 'contain' }}
                                    className="ButtonIcons"
                                />
                            </Box>
                            <Stack>
                                <Typography style={{ paddingLeft: 2 }}>
                                    <Title>{project.title}</Title>
                                    <Paragraph>
                                        {/* Hier kurz info oder ähnliches */}
                                    </Paragraph>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box sx={{ flex: 1}} />
                        <Stack>
                            <Box sx={{
                                padding: 1
                            }}>
                                
                                <Segmented size="large" options={tabs} onChange={(e) => {handleTabChange(e)}} style={{ marginBottom: 10}} />
                                
                                <Box sx={{ flex: 1, boxShadows: 'none', borderRadius: 2, border: '1px solid #e0e0e0', p: 1, minHeight: 60 }}>
                                    Schnellaktionen
                                    {tabValue === "übersicht" && (
                                        <Stack direction={"row"}>
                                            <Stack sx={{ justifyContent: 'flex-end' }}>
                                                <Box sx={{
                                                    padding: 0
                                                }}>
                                                    <Stack direction={"row"}>
                                                        <Tooltip title="Daten erfassen">
                                                        <Button type="text" onClick={handleOpenEntry} shape="circle" icon={<PlusCircleOutlined />} />
                                                        </Tooltip>
                                                        <Tooltip title="Datenbank öffnen">
                                                        <Button type="text" shape="circle" icon={<DatabaseOutlined />} onClick={() => { navigate(`/projects/${projectId}/db/${data.mongoId}`) }} />
                                                        </Tooltip>
                                                        <Tooltip title="Informationen / Hilfe">
                                                    
                                                                <Button type="text" shape="circle" icon={<InfoCircleOutlined />} onClick={() => displayInfoNotification("Diese Funktion ist noch nicht verfügbar.")} />
                                                      
                                                        </Tooltip>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    )}
                                    {tabValue === "leistungen" && (
                                        <Stack direction={"row"}>
                                        <Typography variant="caption">
                                                keine Aktionen vorhanden
                                            </Typography>
                                        </Stack>
                                    )}
                                    {tabValue === "dokumente" && (
                                        <Stack direction={"row"}>
                                            <Tooltip title="Dokument hochladen">
                                            <Button type="text" shape="circle" icon={<PlusCircleOutlined />} disabled />
                                            </Tooltip>
                                        </Stack>
                                    )}
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                <Box
                    sx={{  padding: 3, mt: 3 }}
                >

                    {/* Prüft ob die daten schon geladen sind, sofern diese das sind wird der content auch angezeigt und gerendert */}
                    {!_.isEmpty(project) && RenderContent(tabValue, project)}
                </Box>
            </Container>
        </>
    )
};

function RenderContent(key, project) {
    const [docs, setDocs] = useState({});

    const fetchDocs = (projectId) => {
        listDocuments(projectId)
            .then((res) => {
                setDocs(res)
            }).catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
       return fetchDocs(project.projectId);
    }, []);

    switch (key) {
        case "übersicht":
            return (
                <>

                    <div>
                        <Descriptions bordered>
                            <Descriptions.Item key={1} span={1}>
                                <ContactCard Name={`${project.projectLead.firstName} ${project.projectLead.lastName}`}
                                    Position={"Projektleiter*in"}
                                    Phone={`${project.projectLead.mobileNumber}`}
                                    Image={`${project.projectLead.imageUrl}`}
                                    Mail={`${project.projectLead.email}`}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item key={2} span={1}>
                                <ContactCard Name={`${project.projectConsultant.firstName} ${project.projectConsultant.lastName}`}
                                    Position="Consultant*in - Betreuuer*in"
                                    Phone={`${project.projectConsultant.mobileNumber}`}
                                    Image={`${project.projectConsultant.imageUrl}`}
                                    Mail={`${project.projectConsultant.email}`}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item key={3} label="Organigramm" span={2}>
                                <Button type="primary" disabled>
                                    Organigramm öffnen
                                </Button>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>

                    <div style={{ marginTop: 10 }}>
                        {(_.isEmpty(project.tasks) || project.__v === 1) ? (
                            <div>
                            <Descriptions title="Aufgaben" style={{ marginTop: 25 }} />
                            {
                                project.__v === 1 ? (<>
                                    <Result status="info" title="Aufgaben Version 2" subTitle="Die Aufgaben aus Version 2 werden hier noch nicht Angezeigt." />
                                </>) : (<Empty />)
                            }
                            </div>
                        ) : (
                            <div>
                            <Descriptions title="Aufgaben" style={{ marginTop: 25 }}  bordered>
                            {
                                project.tasks.map(task => (<>
                                    <Descriptions.Item label={task.title} span={3} style={{
                                        alignContent: "left",
                                        justifyContent: "left",
                                        alignItems: "left",
                                        textAlign: "left",
                                    }}>
                                        <Typography.Text>
                                        <Badge status={task.status === 'active' ? "processing" : "warning"} text={task.status === 'active' ? "Status: OK" : "Status: Unbekannt"} />
                                       {" | "} {task.description}</Typography.Text>
                                    </Descriptions.Item>
                                </>))
                            }
                            </Descriptions>
                            </div>
                        )}

                    </div>
                </>
            );
        case "team":
            const team = project.allowedUserId
            let teamContent = (<></>)
            // Die vereinung muss wieder -> ! <- entfernt werden
            if (_.isEmpty(team)) {
                teamContent = (
                    <Empty />
                )
            } else {
                teamContent = (
                    <div>
                        <Row
                            gutter={[16, 16]}
                        >
                            {!_.isEmpty(team) ? (team.map(user => (
                                <Col span={6}>
                                    <Card>
                                        <Meta
                                            title={`${user.firstName} ${user.lastName}`}
                                            description={user.email}
                                        />
                                    </Card>
                                </Col>

                            ))) : (
                                <div>
                                    <Empty />
                                </div>
                            )}
                        </Row>
                    </div>
                )
            }
            return (teamContent);
        case "dokumente":
            let docContent = (<></>)
            const docItems = docs.items;

            if (_.isEmpty(docItems)) {
                docContent = (
                    <Result status="info" title="Keine Dokumente" subTitle="So wie es aussieht gibt es hier keine Dokumente..." />
                )
            } else {
                docContent = (
                    <>
                    <Row gutter={[16, 16]}>
                        {docItems.map((item, index) => (
    
                            <Col span={6}>
                                <Card
                                    actions={[
                                        <FileDownloaderButton StorageRef={item.prefix} FileName={_.truncate(_.split(item.prefix, '/', 6)[_.split(item.prefix, '/', 6).length - 1], { length: 50 })} />,
                                        <FileViewInBrowserButton StorageRef={item.prefix} />,
                                    ]}
                                    cover={
                                        <img
                                            alt="Bild der Datei"
                                            src={setFileImage(item.prefix).path}
                                            style={{ width: '100%', height: '50px', objectFit: 'contain', padding: 1, paddingTop: 10 }}
                                        />
                                    }
                                >
                                    <Meta
                                    title={_.truncate(_.split(item.prefix, '/', 6)[_.split(item.prefix, '/', 6).length - 1], { length: 16 })}
                                    description="Beschreibung"
                                    />
                                </Card>
    
    
                            </Col>
                        ))}
                    </Row>
                </>
                );
            }
            return docContent;
        default:
            return (
                <>
                    <p>Es gibt ein Problem mit der Ansicht</p>
                </>
            );
    }
};

export default ProjectPage;