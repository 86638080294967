import React, { useEffect, useState } from 'react';
import LoginPage from '../components/pages/LoginPage';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../firebase';
import { LayoutSiderHeader } from '../components/global/layout/LayoutSiderHeader';

const NewLayout = ({ children }) => {

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  //Set back to false before deployment
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });
    return unsubscribe;
  }, []);

  if (isAuthenticated) {
    return (
      <LayoutSiderHeader>
        {children}
      </LayoutSiderHeader>
    );
  } else {
    return <LoginPage />;
  }
};

export default NewLayout;
