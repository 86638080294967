import { Button, DatePicker, Space, Table, Typography } from "antd";
import React from "react";
import axios from 'axios';
import { auth } from "../../../firebase";
import { v4_setAPI } from "../../../routing/api";
import locale from '../../../locale/datePickerLocale.json'
import dayjs from "dayjs";
import _ from "lodash";
import { SingleDetailModal } from "../../modals/accounting/SingleDetail.modal";
import { TitleImage } from "../project/PublicProjectPage";

const { useState, useEffect } = React;
const { Title, Text } = Typography;
const API_URL = v4_setAPI();

export function ProjectCostsPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });
    const [from, setFrom] = useState(dayjs().subtract(12, 'month').toISOString());
    const [to, setTo] = useState(dayjs().toISOString());

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(`${API_URL}/accounting/costs/database/costs`, {
                params: {
                    page: tableParams.pagination.current,
                    limit: tableParams.pagination.pageSize,
                    from: from,
                    to: to,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.totalCount, // Annahme, dass der Server die Gesamtzahl der Einträge zurückgibt
                },
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams), from, to]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: sorter.order,
            sortField: sorter.field,
        });

        if (pagination.pageSize !== tableParams.pagination.pageSize) {
            setData([]);
        }
    };

    async function handleDeleteSelected() {
        setLoading(true);
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.delete(`${API_URL}/accounting/costs/database/costs`, {
                data: {
                    ids: selectedRowKeys,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Löschen erfolgreich:', response);
            setSelectedRowKeys([]);
            fetchData();
        } catch (error) {
            console.error('Fehler beim Löschen:', error);
        } finally {
            setLoading(false);
        };
    };

    React.useEffect(() => {
        //Fensternamen setzen
        document.title = `Projektkosten Übersicht (Gebucht)`;
        return () => {
          document.title = "Assembly";
        };
      }, []);

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <TitleImage overwrite={true} children={<>
                    <div>
                        <Title level={2} style={{ color: 'white'}}>
                            Projektkosten Übersicht (Gebucht)
                        </Title>
                    </div>

                    <div>
                        <DatePicker.RangePicker
                            onChange={(dates) => {
                                if (_.isArray(dates) && dates.length === 2) {
                                    setFrom(dayjs(dates[0]).toISOString());
                                    setTo(dayjs(dates[1]).toISOString());
                                }
                            }}
                            locale={locale}
                            style={{ marginBottom: '20px' }}
                        />
                    </div>
                </>}
                />
            </div>



            <div>
                {hasSelected && <div style={{
                    marginBottom: '10px',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}>
                    <Button type="primary" danger onClick={() => handleDeleteSelected()}>Ausgewählte Einträge löschen</Button>
                </div>}
                <Table
                    rowKey={(record) => record._id}
                    dataSource={data}
                    rowSelection={rowSelection}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    footer={() => (
                        <div>
                            Einträge ausgewählt: {selectedRowKeys.length} von {data.length}
                        </div>
                    )}
                    size="small"
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: '_id',
                            key: '_id',
                        },
                        {
                            title: 'Datum',
                            dataIndex: 'isoInvoiceDate',
                            key: '_id',
                            render: (o) => <Text>{new Date(o).toLocaleDateString()}</Text>,
                            sorter: (a, b) => new Date(a.isoInvoiceDate) - new Date(b.isoInvoiceDate),
                            defaultSortOrder: 'descend',
                        },
                        {
                            title: 'Kostenstelle',
                            dataIndex: 'costCentre1',
                            key: '_id',
                            filters: _.uniqBy(data.map((o) => ({ text: o.costCentre1, value: o.costCentre1 })), 'value'),
                            onFilter: (value, record) => record.costCentre1 === value,
                        },
                        {
                            title: 'Rechnungsnummer/Titel',
                            dataIndex: 'invoiceNumber',
                            key: '_id',
                        },
                        {
                            title: 'Buchungstext',
                            dataIndex: 'bookingText',
                            key: '_id',
                            render: (o) => <Text>{_.truncate(o, { length: 30 })}</Text>,
                            width: 250
                        },
                        {
                            title: 'Saldo',
                            dataIndex: 'saldo',
                            key: '_id',
                            render: (o) => <Text>{_.isNaN(parseFloat(o)) ? null : <>€ {parseFloat(o).toLocaleString()}</>}</Text>,
                        },
                        {
                            title: 'Soll',
                            dataIndex: 'soll',
                            key: '_id',
                            render: (o) => <Text>{_.isNaN(parseFloat(o)) ? null : <>€ {parseFloat(o).toLocaleString()}</>}</Text>,
                        },
                        {
                            title: 'Haben',
                            dataIndex: 'haben',
                            key: '_id',
                            render: (o) => <Text>{_.isNaN(parseFloat(o)) ? null : <>€ {parseFloat(o).toLocaleString()}</>}</Text>,
                        },
                        {
                            title: '',
                            dataIndex: 'transformingDetails',
                            key: '_id',
                            render: (t) => <Space>
                                <SingleDetailModal><Text>{t}</Text></SingleDetailModal>
                            </Space>,
                        },
                    ]}
                />
            </div>
        </div>
    );
};


//render: (o) => <SingleDetailModal><Text>{o}</Text></SingleDetailModal>