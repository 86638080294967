import { Button, Input, Modal, Space, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { displayErrorNotification } from "../../utils/messageServices/notificationService";
import { motion } from "framer-motion";
import _ from "lodash";

const maxErrorCount = 1;
const maxSecondsCount = 30;

/*
    * @description This is the re-authentication form modal. It is used to re-authenticate the user.

    * @param {Object} props - The props object.
    * @param {boolean} props.Open - The open state of the modal.
    * @param {Function} props.OnCancel - The cancel function.
    * @param {Function} props.OnSuccess - The success function.
    * @returns {JSX.Element} - The JSX element.
    * @example
    * <ReAuthenticationFormModal
    *    Open={true}
    *   OnCancel={() => console.log('Canceled')}
    *  OnSuccess={() => console.log('Success')}
    * />
    * @see ReAuthenticationFormModal
*/
export function ReAuthenticationFormModal(props) {

    const [errorCount, setErrorCount] = React.useState(0);
    const [secondsCount, setSecondsCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = useState(auth?.currentUser?.email || '');
    const [password, setPassword] = useState('');

    const reAuthenticateUser = async () => {
        setLoading(true);
        try {
            const user = auth.currentUser;
            const credential = EmailAuthProvider.credential(email, password);

            await reauthenticateWithCredential(user, credential);
            props.OnSuccess();
            props.OnCancel();
            Modal.destroyAll();
        } catch (error) {
            console.error("Re-Authentifizierung fehlgeschlagen:", error);
            // Hier kannst du zusätzliche Fehlerbehandlung hinzufügen
            displayErrorNotification("Re-Authentifizierung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.");
            setErrorCount(errorCount + 1);
            if (errorCount >= maxErrorCount) {
                props.OnCancel();
                Modal.destroyAll();
                auth.signOut();
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        let timer;
        if (props.Open) {
            timer = setTimeout(() => {
                props.OnCancel();
                displayErrorNotification("Zeitüberschreitung. Der Vorgang wurde abgebrochen.");
            }, maxSecondsCount * 1000);
        }
        return () => clearTimeout(timer);
    }, [props.Open]);


    return (
        <>
            <Modal
                title="Re-Authentifizierung"
                open={props.Open}
                onCancel={props.OnCancel}
                centered
                footer={[
                    <Tooltip title="Ist eine Authentifizierung nicht möglich, wird der Vorgang abgebrochen. Und der Nutzer wird ausgeloggt.">
                        <Button key="submit" type="primary" onClick={async () => { await reAuthenticateUser() }}
                            loading={loading}
                        >
                            Authentifizieren
                        </Button>
                    </Tooltip>,
                ]}
                maskClosable={false}
                mask={true}
                closable={false}
                styles={{
                    mask: {
                        backdropFilter: 'blur(10px)', // Hintergrund weichzeichnen
                        WebkitBackdropFilter: 'blur(10px)', // Unterstützung für Safari
                        backgroundColor: 'rgba(234, 40, 45, 0.8)'
                    }
                }}
            >
                <div style={{
                    marginBottom: '1rem',

                }}>
                    <Space direction="vertical">
                        <Typography.Text strong>Bitte bestätigen Sie Ihre Anmeldung erneut.</Typography.Text>
                        <Typography.Text type="secondary">User: {_.replace(auth.currentUser?.email || '', '@ikhofmann.net', '')}</Typography.Text>
                    </Space>
                </div>
                {/* {!auth.currentUser?.email && <> */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.1,
                        duration: 0.5,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                >
                    <Input
                        placeholder="E-Mail"
                        value={email}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ marginBottom: '1rem' }}
                    />
                </motion.div>
                {/* </>} */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.5,
                        duration: 0.2,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                >
                    <Input.Password
                        placeholder="Passwort"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </motion.div>
            </Modal>
        </>
    )
};