import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

function DataModeButton(props) {
    const [open, setOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [disabled, setDisabled] = useState(true);
    // const project = props.Project
    const confirmation = "Import-CSV"

    const handleChange = (event) => {
        console.log(event.target.value)
        setConfirmationText(event.target.value);
        // Überprüfen ob der eingegebene Text mit der Bestätigung übereinstimmt
        if (confirmationText === confirmation) {
            setDisabled(false);
        } else {
            setDisabled(true);
        };
    };

    const handleSubmit = () => {
        console.log("Submit datamode Change");
        // Hier noch die Funktion zum Speichern der neuen Adresse einfügen
    };

    const handleReset = () => {
        //Setzen der alten Werte
        setOpen(false);
        setConfirmationText("");
        setDisabled(true);
    };

    return (
        <>
            <Button color="error" variant="outlined" onClick={() => {setOpen(true)}}>
                Datenmodus ändern
            </Button>
            <Dialog open={open} onClose={handleReset}>
                <DialogContent>
                    <DialogContentText>
                        Du kannst den Datenmodus auf "Import" ändern, danach gibt es aber kein zurück mehr! Im Import-Modus, werden nurnoch csv-Dateien als gültige
                        eingabe Aktzeptiert.
                        <br /><br />
                        Schreibe <span style={{ fontWeight: 'bold', color: 'red', fontFamily: 'monospace' }}>Import-CSV </span>
                        zum bestätigen, dass du diese Aktion durchführen möchtest.
                    </DialogContentText>
                    <Box sx={{
                        mt: 2
                    }}>
                        <TextField
                        required
                        value={confirmationText}
                        onChange={(e) => { handleChange(e) }}
                        />
                    </Box>
                    <DialogActions>
                        <Button color="error" variant="outlined" onClick={handleReset}>
                            Abbrechen
                        </Button>
                        <Button color="success" variant="contained" onClick={handleSubmit} disabled={disabled}>
                            Änderung übermitteln
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DataModeButton;