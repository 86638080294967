//Settings Button für die Projektseite, diese Button soll ein Modal öffnen wo Projekteinstellungen bearbeitet werden können
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { useState } from "react"
import { UserSettingsModal } from "../modals/UserSettingsModal";
import { updateSelectedUser } from "../../redux/userSlices";
import { useDispatch } from "react-redux";
import { user_fetchSingleProfile } from "../../routing/v3_Routes/v3_Routes";

function UserSettingsButton(props) {

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchTeam = () => {
        props.fetchData();
    };
    
    const getUserDetails = async () => {
        dispatch(updateSelectedUser({}));
        setLoading(true);
        await user_fetchSingleProfile(props.user.mongoRef)
        .then((result) => {
            setOpen(true);
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
        <div style={{
            float: "right",
        }}>
            <Button type="primary" icon={<UserOutlined />} onClick={getUserDetails} loading={loading}>
                Einstellungen
            </Button>
        </div>
        <UserSettingsModal open={open} user={props.user} onCancel={() => { setOpen(false)}} fetchData={fetchTeam} />
        </>
    );
};

export { UserSettingsButton };