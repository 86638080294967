import { Button, Collapse, DatePicker, Descriptions, Form, Input, Modal, Popconfirm, Result, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import CountUp from "react-countup";
import Typography from "antd/es/typography/Typography";
import { CreateContractDrawerForm } from "../../drawers/contracts/CreateContractDrawerForm";
import { ModifyContractDrawerForm } from "../../drawers/contracts/ModifyContractDrawerForm";
import { route_manageContract } from "../../../routing/v3_Routes/v3_Routes";
import { useUpdate } from "ahooks";
import moment from "moment";
import { useSelector } from "react-redux";
import { ReAuthenticationFormModal } from "../../security/ReAuthenticationForm.modal";


function UserContractForm(props) {

    const [user, setUser] = useState(props.user);
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [hc, setHasContract] = useState(false);
    const [cd, setContractData] = useState({});
    const formatter = (value) => <CountUp duration={0.1} end={value} separator="." />;
    const update = useUpdate();
    const currentUser = useSelector((state) => state.userState.firebaseUser);
    const [currentUserRole, setCurrentUserRole] = useState(currentUser?.role || "user");

    const [showModalModify, setShowModalModify] = useState(false);
    const [showModalTerminate, setShowModalTerminate] = useState(false);
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [query, setQuery] = useState(null);
    const [showDrawerModify, setShowDrawerModify] = useState(false);
    const [privileged, setPrivileged] = useState(false);

    const formRefTermination = React.useRef();

    const privilegedRoles = ["manager", "projectLead", "hr", "project-assistance"];

    useEffect(() => {
        const initSite = () => {
            setCurrentUserRole(currentUser?.role || "user");
            setUser(props.user);
            setHasContract(!_.isEmpty(props.user.contract));
            setContractData(props.user.contract);
            update();
            console.log("UserContractForm", props.user);
            console.log("Current User Role", currentUserRole);
            console.log("hc", hc);
            console.log('includes', _.includes(privilegedRoles, currentUserRole));
            setPrivileged(_.includes(privilegedRoles, currentUserRole));
        };
        initSite();
        // eslint-disable-next-line
    }, [props]);

    const handleContractModification = async (values, query) => {
        //PupUp mit Frage ob änderung ohne Neuanlage oder mit Neuanlage
        console.log("Vertragsänderung", values, query);
        const data = await route_manageContract(values, query, user._id);
        props.onClose();
    };

    const handleContractDeletion = async () => {
        //Popconfirm, in dem unset anwenden
        await route_manageContract({}, "delete", user._id);
        props.onClose();
        console.log("Vertrag löschen");
    };

    const handleContractTermination = async (values) => {
        //PopUp mit Grund für Kündigung und Datum
        const data = await route_manageContract(values, "terminate", user._id);
        setShowModalTerminate(false)
        props.onClose();
        console.log("Vertrag kündigen", values);
    };

    const handleContractCreation = (values) => {
        //PopUp mit Formular
        const data = route_manageContract(values, "create", user._id);
        props.onClose();
        console.log("Vertrag erstellen", values);
    };

    const handleModificationWithQuery = (query) => {
        setQuery(query);
        setShowDrawerModify(true);
    };

    return (
        <>
            <div>
                <div>
                    <div>
                        <Descriptions title="Vertragsdetails" bordered column={2}>
                            <Descriptions.Item label="Vertragsbeginn"> {hc && cd.contractStart} </Descriptions.Item>
                            <Descriptions.Item label="Vertragsende"> {hc && cd.contractEnd !== null ? cd.contractEnd : "N/A"} </Descriptions.Item>
                            <Descriptions.Item label="Vertragsstatus"> {hc && cd.contractStatus} </Descriptions.Item>
                            <Descriptions.Item label="Vertragsart"> {hc && cd.contractType} </Descriptions.Item>
                            <Descriptions.Item label="Vertragsnummer"> {hc && cd.contractNumber} </Descriptions.Item>
                            <Descriptions.Item label="Vertragskosten"> {_.includes(privilegedRoles, currentUserRole) ? (hc && formatter(cd.contractDetails.salary.salaryCosts)) : "******"} EUR~</Descriptions.Item>
                            <Descriptions.Item label="Vereinbarte tägliche Arbeitszeit"> {hc && _.toNumber(cd.contractDetails.workTime.dailyWorkTime)} Stunden</Descriptions.Item>
                            <Descriptions.Item label="Vertragsurlaub"> {hc && _.toNumber(cd.contractDetails.vacation)} Tage</Descriptions.Item>
                            <Descriptions.Item label="Vertragsnotizen" span={24}> {hc && cd.contractDetails.contractNotes} </Descriptions.Item>
                            <Descriptions.Item label="AÜ-Vertrag (AÜV)"> {hc && (cd?.auev || false) ? "Ja" : "Nein"} </Descriptions.Item>
                        </Descriptions>
                    </div>

                    <div style={{
                        alignContent: "right",
                        alignItems: "right",
                        placeItems: "right",
                        textAlign: "right",
                        marginTop: "10px"
                    }}>
                        <Space>
                            <Popconfirm
                                title="Bist du dir sicher, dass du den Vertrag kündigen möchtest?"
                                onConfirm={() => setShowModalTerminate(true)}
                                onCancel={() => setShowModalTerminate(false)}
                                okText="Ja"
                                cancelText="Nein"
                                okType="danger"
                                okButtonProps={{ title: "Vertrag kündigen" }}
                            >
                                <Tooltip title="Kündigt den aktuellen Vertrag. Setzt den Stauts auf 'gekündigt' und berechnet die Kosten ausschließlich Vertrags-Zeitraum.">
                                    <Button type="primary" danger disabled={!hc}> Vertrag kündigen </Button>
                                </Tooltip>
                            </Popconfirm>
                            <Popconfirm
                                title="Bist du dir sicher, dass du den Vertrag löschen möchtest?"
                                onConfirm={handleContractDeletion}
                                okText="Ja"
                                cancelText="Nein"
                                okType="danger"
                                okButtonProps={{ title: "Vertrag löschen" }}

                            >
                                <Tooltip title="Löscht den aktuellen Vertrag. Und alle bisher entstandenen Datensätze.">
                                    <Button type="primary" danger disabled={!hc}> Vertrag löschen </Button>
                                </Tooltip>
                            </Popconfirm>
                            <Popconfirm
                                title="Bist du dir sicher, dass du den Vertrag ändern möchtest?"
                                onConfirm={() => setAuthModalOpen(true)}
                                onCancel={() => setShowModalModify(false)}
                                okText="Ja"
                                cancelText="Nein"
                                okType="primary"
                                okButtonProps={{ title: "Vertrag ändern" }}
                                disabled={!privileged}
                            >
                                <Button type="primary" disabled={!hc === true && !privileged}>Vertrag ändern</Button>
                            </Popconfirm>
                            <Button type="primary" disabled={hc} onClick={() => setShowModalCreate(true)}>Erstanlage (Neuvertrag)</Button>
                        </Space>
                    </div>
                </div>

                <div>
                    <Typography.Title level={5}> Vertragsverlauf </Typography.Title>
                    <div>
                        {
                            hc && (_.has(user, "archivedContracts") === true && !_.isEmpty(user.archivedContracts)) ? <div>
                                <Collapse accordion expandIconPosition="right"
                                    items={user.archivedContracts.map((archivedContract, index) => {
                                        return {
                                            key: index,
                                            label: `Vertrag ${archivedContract.contractNumber} | ${moment(archivedContract.contractStart).locale('de').format('d.MM.yyyy')} bis ${moment(archivedContract.contractEnd).locale('de').format('d.MM.yyyy')} | Status: ${archivedContract.contractStatus}`,
                                            children: <Descriptions title="Vertragsdetails" bordered column={2}>
                                                <Descriptions.Item label="Vertragsbeginn"> {archivedContract.contractStart} </Descriptions.Item>
                                                <Descriptions.Item label="Vertragsende"> {archivedContract.contractEnd !== null ? archivedContract.contractEnd : "N/A"} </Descriptions.Item>
                                                <Descriptions.Item label="Vertragsstatus"> {archivedContract.contractStatus} </Descriptions.Item>
                                                <Descriptions.Item label="Vertragsart"> {archivedContract.contractType} </Descriptions.Item>
                                                <Descriptions.Item label="Vertragsnummer"> {archivedContract.contractNumber} </Descriptions.Item>
                                                <Descriptions.Item label="Vertragskosten"> {_.includes(privilegedRoles, currentUserRole) ? formatter(archivedContract?.contractDetails?.salary?.salaryCosts || 0) : "******"} EUR~</Descriptions.Item>
                                                <Descriptions.Item label="Vereinbarte tägliche Arbeitszeit"> {_.toNumber(archivedContract.contractDetails.workTime.dailyWorkTime)} Stunden</Descriptions.Item>
                                                <Descriptions.Item label="Vertragsurlaub"> {_.toNumber(archivedContract.contractDetails.vacation)} Tage</Descriptions.Item>
                                                <Descriptions.Item label="Vertragsnotizen" span={24}> {archivedContract.contractDetails.contractNotes} </Descriptions.Item>
                                                <Descriptions.Item label="AÜV-Vertrag"> {archivedContract?.auev || false ? "Ja" : "Nein"} </Descriptions.Item>
                                            </Descriptions>
                                        }
                                    }
                                    )} />
                            </div> : <Result
                                title="Dieser Mitarbeiter hat noch keine archivierten Verträge."
                                status={"info"}
                                subTitle="Hier werden alle Verträge angezeigt, die nicht mehr aktiv sind. Dies können beispielsweise Verträge sein, die gekündigt wurden oder Verträge, die durch einen neuen Vertrag ersetzt wurden."
                            />
                        }

                    </div>
                </div>
            </div>

            <div>
                {/* Drawers */}
                <CreateContractDrawerForm open={showModalCreate} onCancel={() => setShowModalCreate(false)} HandleContractCreation={handleContractCreation} />
                <ModifyContractDrawerForm user={user} Query={query} open={showDrawerModify} onCancel={() => { setShowDrawerModify(false) }} HandleContractModification={handleContractModification} />
            </div>

            <div>
                <Modal
                    title="Wie soll der Vertrag geändert werden?"
                    open={showModalModify}
                    onCancel={() => setShowModalModify(false)}
                    width={"40%"}
                    footer={[
                        <Button key="back" onClick={() => setShowModalModify(false)}>
                            Aktion abbrechen
                        </Button>,
                        <Tooltip title="Ändert den aktuellen Datensatz. z.B. wenn man sich vertippt hat ganz nützlich.">
                            <Button key="changeExisting" type="primary" onClick={() => handleModificationWithQuery("changeExisting")}>
                                Inhalt ändern
                            </Button>
                        </Tooltip>,
                        <Tooltip title="Archiviert den alten Vertrag, erstellt einen neuen aus den Eingaben.
Als Enddatum für den alten Vertrag wird immer das neue „Start-Datum genommen, minus einen Tag.">
                            <Button key="archiveAndNew" type="primary" onClick={() => handleModificationWithQuery("archiveAndNew")}>
                                Alten archivieren und neuen erstellen
                            </Button>
                        </Tooltip>
                    ]}
                />

                <Modal
                    title="Vertrag kündigen"
                    open={showModalTerminate}
                    onCancel={() => setShowModalTerminate(false)}
                    width={"40%"}
                    footer={[
                        <Button key="back" onClick={() => setShowModalTerminate(false)}>
                            Aktion abbrechen
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => handleContractTermination(formRefTermination?.current?.getFieldsValue())}>
                            Vertrag kündigen
                        </Button>
                    ]}
                >
                    <Form
                        name="terminateContract"
                        layout="vertical"
                        onFinish={handleContractTermination}
                        ref={formRefTermination}
                    >
                        <Form.Item
                            name="terminationDate"
                            label="Kündigungsdatum"
                            rules={[
                                {
                                    required: true,
                                    message: "Bitte das Kündigungsdatum eintragen."
                                }
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            name="terminationReason"
                            label="Systemnotiz für die Auserkraftsetzung des Vertrages"
                            rules={[
                                {
                                    required: true,
                                    message: "Systemnotiz für die Auserkraftsetzung des Vertrages"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
            <ReAuthenticationFormModal Open={authModalOpen} OnCancel={() => setAuthModalOpen(false)} OnSuccess={() => setShowModalModify(true)} />
        </>
    );
};

export { UserContractForm };