import React, { useEffect, useRef, useState } from "react";
import { v4_getProjectById } from "../../../routing/v4_Routes/project.routes";
import { useParams } from "react-router-dom";
import { Alert, Button, Empty, Row, Space, Statistic, Table, Tour, Typography } from "antd";
import { KpiStatisticCard } from "../../cards/KpiStatistic.card";
import { v4_get_kpi } from "../../../routing/v4_Routes/kpi.routes";
import { FilterDateTimeModal } from "../../modals/filters/FilterDateTimeModal";
import _ from "lodash";
import { TitleImage } from "../project/PublicProjectPage";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ug from '../../../assets/Vorgänge.png';
import fm from '../../../assets/FilterModal.png';
import kpImg from '../../../assets/Kpis.png';
import { DateTime } from "luxon";

const { Title } = Typography;

export function KPIPage() {
    const { _id } = useParams();

    const [project, setProject] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [kpis, setKpis] = React.useState(null);
    const [filter, setFilter] = React.useState(null);

    const fetchInitialData = async () => {
        setLoading(true);
        const r = await v4_getProjectById(_id);
        setProject(r);
        setLoading(false);
    };

    useEffect(() => {
        fetchInitialData();

        return () => {
            setProject(null);
        };
    }, []);

    async function getKPIs() {
        if (!filter) {
            return;
        };

        setLoading(true);
        const r = await v4_get_kpi(_id, { ...filter, costCentre: project?.costCentre });
        console.log(r);
        setKpis(r);
        setLoading(false);
    };

    useEffect(() => {
        //Set window title
        document.title = `KPIs ${project?.title}`;
    }, [project]);

    useEffect(() => {
        !_.isEmpty(project) ? getKPIs() : fetchInitialData();

        return () => {
            setFilter(null);
            setProject(null);
            setKpis(null);
        };
    }, [filter]);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [open, setOpen] = useState(false);
  
    const steps = [
      {
        title: 'Öffnet das Filter-Modal',
        description: 'Hier hat man die Möglichkeit, einen Zeitraum auszuwählen. Start ist immer um 0:00 und Ende um 23:59.',
        cover: (
            <img
              alt="tour.png"
              src={fm}
            />
          ),
        target: () => ref1.current,
      },
      {
        title: 'KPI Anzeige',
        description: 'Hier werden die bereits im Backend berechneten KPIs angezeigt.',
        target: () => ref2.current,
        cover: (
            <img
              alt="tour.png"
              src={kpImg}
            />
          ),
      },
      {
        title: 'Umsatzgruppen',
        description: 'Hier werden die Umsatzgruppen nach Artikel angezeigt.',
        target: () => ref3.current,
        cover: (
            <img
              alt="tour.png"
              src={ug}
            />
          ),
      },
    ];

    return (
        <div>
        {DateTime.now().toISODate() < "2024-10-20" && <Alert message={`Achtung: Die Berechnungslogik wurde überarbeitet. Personalkosten sind nun immer auf dem Stand von -2 Tagen. `} type="warning" showIcon style={{ textAlign: "left", marginBottom: "20px" }} />}
        {DateTime.now().toISODate() <= "2024-10-18" && <Alert message={`ENERCON Daten werden neu eingespielt, Aktion voraussichtlich am 18.10 abgeschlossen`} type="error" showIcon style={{ textAlign: "left", marginBottom: "20px" }} />}
            <TitleImage project={project} children={<Space>
                <div ref={ref1}>
                <FilterDateTimeModal
                    title="Filter für die KPI abfrage anpassen"
                    text="Filter modifizieren"
                    OnFilter={(filter) => {
                        setKpis(null);
                        setFilter(filter);
                    }}
                />
                </div>
                {!_.isArray(kpis?.kpis) && <Button onClick={() => setOpen(true)} icon={<QuestionCircleOutlined />} shape="circle" type="icon" style={{ color: "white"}} />}
            </Space>} />
            
            <div>
                {!project?.costCentre && (
                    <Alert
                        message="Kein Kostenstelle"
                        description="Dieses Projekt hat keine Kostenstelle. Bitte fügen Sie eine Kostenstelle hinzu, um KPIs zu sehen."
                        type="warning"
                        showIcon
                    />
                )}

            </div>

            <div ref={ref2} >
                <Row gutter={[16, 16]}>
                    {_.isArray(kpis?.kpis) ? kpis?.kpis?.map((item, index) => {
                        const delay = index * 0.15;
                        return (
                            <KpiStatisticCard
                                key={index}
                                title={item.title}
                                value={item.value}
                                suffix={item.suffix}
                                description={item.description}
                                delay={delay}
                                loading={loading}
                            />
                        )
                    }) : <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                    }}>
                            <Empty
                                description="Keine Daten vorhanden oder Filter nicht gesetzt"
                            />
                        </div>}
                </Row>
            </div>


            <div
                style={{
                    marginTop: "50px",
                }}
                ref={ref3}
            >
                <Table
                    rowKey={(record) => record._id}
                    dataSource={kpis?.turnoverCollection}
                    locale={{
                        emptyText: "Keine Daten vorhanden oder Filter nicht gesetzt",
                    }}
                    columns={[
                        {
                            title: "Aufgabe",
                            dataIndex: "taskTitle",
                            key: "_id",
                        },
                        {
                            title: "Buchungen",
                            dataIndex: "count",
                            key: "_id",
                            render: (value) => {
                                return <Statistic
                                    value={value}
                                    precision={0}
                                    valueStyle={{
                                        fontSize: "16px",
                                        fontFamily: "Arial",
                                    }}
                                />
                            }
                        },
                        {
                            title: "Umsatz",
                            dataIndex: "totalTurnover",
                            key: "_id",
                            render: (value) => {
                                return <Statistic
                                    value={value}
                                    precision={2}
                                    suffix="€"
                                    valueStyle={{
                                        fontSize: "16px",
                                        fontFamily: "Arial",
                                    }}
                                />
                            }
                        }
                    ]}
                />
            </div>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        </div>
    )
};