//Role: project-assistance
import React from 'react';
import { Route } from 'react-router-dom';
import MyProjectsPage from '../../components/pages/project/MyProjectsPage';
import ProjectPage from '../../components/pages/project/ProjectPage';
import CreateDataEntryPage from '../../components/pages/dataEntry/CreateDataEntryPage';
import ProfilePage from '../../components/pages/user/ProfilePage';
import MyFilesPage from '../../components/pages/user/MyFilesPage';
import NotFoundPage from '../../components/pages/NotFoundPage';
import ProjectDetailPage from '../../components/pages/project/ProjectDetailPage';
import ProjectDbSchemaPage from '../../components/pages/project/ProjectDbSchemaPage';
import CreateProjectPage from '../../components/pages/admin/manager/CreateProjectPage';
import TimeBoardImportPage from '../../components/pages/admin/manager/TimeBoardImportPage';
import { TaskManagementPage } from '../../components/modals/tasks/TaskManagementPage';
import DatabasePage from '../../components/pages/database/DatabaseView';
import CollectionsPage from '../../components/pages/admin/admin/CollectionsPage';
import ManageProjectsBoard from '../../components/pages/admin/manager/ManageProjectsBoard';
import { UserManagementV2 } from '../../components/pages/userManagement/UserManagementV2';
import { ChangelogPage } from '../../components/pages/ChangelogPage';
import { CSVUploadPage } from '../../components/pages/dataEntry/CSVUpload.page.component';
import PublicProjectPage from '../../components/pages/project/PublicProjectPage';
import { PersonalCostsPage } from '../../components/pages/accounting/PersonalCosts.page';
import { ProjectCostsPage } from '../../components/pages/accounting/ProjectCosts.page';
import { ProjectsTableView } from '../../components/pages/project/ProjectsTableView';
import { ProductivityQuotesPage } from '../../components/pages/insights/ProductivityQuotesMask.page';
import { ProjectParentPage } from '../../components/pages/project/projectManagement/ProjectParent.page';
import { ProjectDetailCostPage } from '../../components/pages/project/projectManagement/ProjectDetailCostPage';
import { ProjectPersonalCostPage } from '../../components/pages/project/projectManagement/ProjectPersonalCostPage';

/**
 * @description This is the hr routes file. It contains all routes that are only accessible for hr.
 */
const projectAssistanceRoutes = [
    <Route path='/' element={<MyProjectsPage Arrow={false} />} />,
    <Route path='/projects' element={<MyProjectsPage Arrow={true} />} />,
    <Route path='/projects/:projectId' element={<ProjectPage />} />,
    <Route path='/projects/:projectId/database/create' element={<CreateDataEntryPage />} />,
    <Route path='/projects/:projectId/database/create/single-transaction' element={<CreateDataEntryPage />} />,
    <Route path='/projects/:projectId/database/create/csv' element={<CSVUploadPage />} />,
    <Route path='/projects/:projectId/db/:mongoId' element={<DatabasePage />} />,
    <Route path='/projects/:_id/productivityquotes' element={<ProductivityQuotesPage />} />,
    <Route path="/users/:uid/profile" element={<ProfilePage />} />,
    <Route path="/usermanagement" element={<UserManagementV2 />} />,
    <Route path="/users/:uid/files" element={<MyFilesPage />} />,
    <Route path="/manage/admin/settings/:userId" element={<NotFoundPage />} />,
    <Route path='/manage/projects/:mongoId/details' element={<ProjectDetailPage />} />,
    <Route path='/manage/projects/project/:_id/:_ctr' element={<ProjectParentPage />} />,
    <Route path='/manage/projects/:projectId/schema' element={<ProjectDbSchemaPage />} />,
    <Route path='/manage/projects/create' element={<CreateProjectPage />} />,
    <Route path='/invoices' element={<CollectionsPage />} />,
    <Route path='/manage/projects' element={<ManageProjectsBoard />} />,
    // <Route path='/manage/projects' element={<ManageProjectsBoard />} />,
    <Route path='/users/timeboard' element={<TimeBoardImportPage />} />,
    //TODO: V1 in ein redirect zu V2!
    <Route path="/project/articles/management/v1" element={<TaskManagementPage />} />,
    <Route path="/project/articles/management/v2" element={<TaskManagementPage />} />,
    <Route path='/management/projects/project/:_id/costs/:costCentre' element={<ProjectDetailCostPage />} />,
    <Route path='/management/projects/project/:_id/costs/personal/:costCentre' element={<ProjectPersonalCostPage />} />,
    <Route path="*" element={<NotFoundPage />} />,
    <Route path='/changelog' element={<ChangelogPage />} />,
    <Route path='/v2/projects/project/:_id' element={<PublicProjectPage />} />,
    <Route path='/tables/personalcosts' element={<PersonalCostsPage />} />,
    <Route path='/tables/projectcosts' element={<ProjectCostsPage />} />,
    <Route path='/tableview/manage/projects' element={<ProjectsTableView />} />,

];

export { projectAssistanceRoutes };