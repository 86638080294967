import { Button } from "antd";
import { updateCustomerAccess } from "../../../routing/customerApi";
import store from "../../../redux/store";
import { updateCustomerUsers } from "../../../redux/customerSlicer";

export const customerColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        fixed: 'left',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'E-Mail',
        dataIndex: 'email',
        key: 'email',
        width: 200,
        // sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: 'Telefon',
        dataIndex: 'phone',
        key: 'phone',
        width: 200,
        // sorter: (a, b) => a.phone.localeCompare(b.phone),
    },
    {
        title: 'Aktiv',
        dataIndex: 'active',
        key: 'active',
        render: (boolean) => <div> {boolean ? <>Ja</> : <>Nein</>} </div>,
        width: 100,
    },
    {
        title: '',
        dataIndex: '',
        key: '_id',
        width: 100,
        fixed: 'right',
        render: (record) => <div>
            <Button type="primary"
                onClick={async () => {
                    console.log(record);
                    updateCustomerAccess(!record.active, record._id)
                        .then((res) => {
                            store.dispatch(updateCustomerUsers(res.data.users));
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }}
            >
                {record.active ? <>Deaktivieren</> : <>Aktivieren</>}
            </Button>
        </div>
    }
];

// const menuItems = [
//     {
//         key: '0',
//         label: <Button type="primary" onClick={async () => (updateCustomerAccess())}>Zugang aktivieren</Button>,
//     },
//     {
//         key: '1',
//         label: <Button type="primary">Zugang deaktivieren</Button>,
//     }
// ]