import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fetchingData: false,
    streamingData: false,
};

const streamSlicer = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        updateFetchingData: (state, action) => {
            state.fetchingData = action.payload;
        },
        updateStreamingData: (state, action) => {
            state.streamingData = action.payload;
        },
    }
});

export const {
    updateFetchingData,
    updateStreamingData,
} = streamSlicer.actions;

export default streamSlicer.reducer;