import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { Tabs, Empty } from "antd";
import UserProfileDetailsComponent from "../../details/UserProfileDetailsComponent";
import UserProfileContractDetailsComponent from "../../details/UserProfileContractDetailsComponent";
import AbsencePage from "./AbsencePage";
import { useDispatch } from "react-redux";
import { manageAbsences } from "../../../routing/userManagement";
import { updateAbsences } from "../../../redux/userSlices";
import { AccountSecurityPage } from "./AccountSecurityPage";


function ProfilePage() {

    const { uid } = useParams();
    const user = useSelector((state) => state.userState.mongoUser)
    const firId = useSelector((state) => state.userState.firId)

    const dispatch = useDispatch();


    const userProfileItems = [
        {
            key: "1",
            label: "Profil",
            children: <UserProfileDetailsComponent />
        },
        {
            key: "2",
            label: "Abwesenheitsmanagement",
            children: <AbsencePage uid={uid} />
        },
        {
            key: "3",
            label: "Sicherheit",
            children: <AccountSecurityPage />
        }
    ]



    return (
        <Container
            sx={{
                marginTop: 5,
            }}
        >
            <Tabs defaultActiveKey="1"
                type="card"
                size="large"
                items={userProfileItems}
            />
        </Container>
    )
};

export default ProfilePage;