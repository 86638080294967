import { Modal, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph, Link } = Typography;

function CSVImportAttentionDialog(props) {

    const navigate = useNavigate();

    const handleOk = () => {
        props.setOpen(false);
    };

    const handleCancel = () => {
        props.setOpen(false);
        navigate('/', { replace: true });
    };

    return (
        <>
            <Modal
                open={props.OpenDialog}
                onCancel={handleCancel}
                onOk={handleOk}
                title="Hinweis"
            >
                <div>
                    <div>

                        <Typography>
                            <Paragraph style={{ textAlign: 'left' }}>
                                Für dieses Projekt wurde der CSV-Import eingestellt, dass bedeutet hier besteht ausschließlich die Möglichkeit die relevante datei (.csv)-Format hochzuladen.
                                Die hochgeladen dateien werden sofort gespeichert, nachdem im Anschluss der import bestätigt wurde werden die vorhandenen daten geschrieben.
                                <blockquote>
                                    Einen fehlerhaften import rückgängig zu machen ist extrem zeitaufwendig. Daher bitten wir um sorgfalt beim Import von Datensätzen. Sofern weitere Fragen bestehen, wenden Sie sich an die Systemadministratoren - bevor Sie die Datei hochladen.
                                </blockquote>
                            </Paragraph>
                        </Typography>
                    </div>

                </div>
            </Modal>
        </>
    )
};

export { CSVImportAttentionDialog };