import React from "react";
import { useState } from "react";
import { Container, Stack } from "@mui/system";
import { Box, Typography, Button, Tabs, Tab } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Avatar } from "@mui/material";
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import { sendRegistrationRequest } from "../../routing/sendData";
import { loginUser } from "../../firebase";
import { useNavigate } from "react-router-dom";


function LoginPage() {
  const [tabValue, setTabValue] = useState("one");
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("submit was handled");

    const registerUserData = {
      password: password,
      email: email,
    };

    sendRegistrationRequest(registerUserData);
    console.log(registerUserData);

  };

  const handleRegisterRequest = () => {
    console.log("Registration request was sent.");
    sendRegistrationRequest(email)
    .then(result => {
      console.log(result);
    })
    .catch(err => {
      console.log(err);
    })
    setEmail("");
    setPassword("");
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleLogin = async () => {
    try {
      const response = await loginUser(email, password);
      navigate("/"); 
      console.log(response);
    } catch (error) {
      // Fehlerbehandlung
      console.log(error);
    }
  };

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  return (
    <>
      <Container
        sx={{
          marginTop: 15,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper elevation={3}>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              padding: 10,
            }}
            autoComplete="off"
            noValidate
            onSubmit={handleLogin}
          >
            <Avatar
              alt="Assembly Logo"
              src="./assembly-logo.png"
              sx={{ width: 80, height: 80 }}
            />
            <Typography variant="h4">Assembly v2</Typography>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{ width: "100%", marginBottom: 2, justifyContent: "center" }}
            >
              <Tab value="one" label="Login" />
              <Tab value="two" label="Kundenlogin" />
            </Tabs>

            {tabValue === "one" ? (
              <>
                <TextField
                  required
                  id="outlined-email-input"
                  label="E-Mail"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => { setEmail(e.target.value) }}
                  sx={{ width: "calc(100% + 50px)" }}
                />
                <TextField
                  required
                  id="outlined-password1-input"
                  label="Passwort"
                  type="password"
                  autoComplete="password"
                  onChange={(e) => { setPassword(e.target.value) }}
                  sx={{ width: "calc(100% + 50px)" }}
                  onSubmit={handleLogin}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </>
            ) : (
              <>
              <TextField
                  required
                  id="outlined-email-customer-input"
                  label="E-Mail"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => { setEmail(e.target.value) }}
                  sx={{ width: "288px" }}
                />
                <TextField
                  required
                  id="outlined-project-id-input"
                  label="Project-Id"
                  type="text"
                  onChange={(e) => { setEmail(e.target.value) }}
                  sx={{ width: "288px" }}
                />
                <TextField
                  required
                  id="outlined-password-customer-input"
                  label="Password"
                  type="text"
                  onChange={(e) => { setEmail(e.target.value) }}
                  sx={{ width: "288px" }}
                />
                <Button variant="contained" color="primary" type="submit" startIcon={<AppRegistrationRoundedIcon />} onClick={handleRegisterRequest}>
                    <Typography>Login as customer</Typography>
                </Button>

              </>
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default LoginPage;