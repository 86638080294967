import { useUpdate } from "ahooks";
import { Descriptions, Divider, Form, Input, InputNumber, Modal, Select, Typography } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { v4_updateData } from "../../routing/v4_Routes/stream.routes";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import { statusArray, statusHelper } from "../../utils/helpers/statusHelper";

const { Item } = Form;

export function DatabaseEditModal(props) {

    const { element, fields, open, project_id } = props;
    const [form] = Form.useForm();
    const t = _.isObject(element) ? element?.task : {};
    const [updatedObject, setUpdatedObject] = React.useState(_.cloneDeep(element));
    const update = useUpdate();
    const [loading, setLoading] = React.useState(false);

    function onChange() {
        const values = form.getFieldValue();
        
        // Tiefe Kopie des Objekts
        const o = _.cloneDeep(element);
        o.title = values.title;
        o.task.quantity = values.quantity;
        o.status = values.status;

        setUpdatedObject(o);
        update();
    };

    async function updateDataInDatabase() {
        setLoading(true);
        const updateData = await v4_updateData(element.id, project_id, updatedObject);
        if (updateData) {
            displaySuccessNotification("Vorgang erfolgreich aktualisiert");
        };
        setLoading(false);
        props.onClose();
    };

    useEffect(() => {
        if (open && project_id) {
            console.log("Project ID: ", project_id);
            form.setFieldsValue({
                title: element?.title,
                quantity: element?.task?.quantity,
                status: element?.status
            });
        };

        return () => {
            console.log("Cleanup");
        };
    }, [open, project_id, element]);

    return (
        <>
            <Modal
                title={"Bearbeiten von Vorgang " + element?.id + " | " + _.truncate(t?.title, { length: 30 })}
                open={open}
                onOk={() => updateDataInDatabase()}
                onCancel={() => props.onClose()}
                maskClosable={false}
                okText="Speichern"
                cancelText="Abbrechen"
                width={"90%"}
                okButtonProps={{ style: { backgroundColor: "green" }, loading: loading }}
            >
                <Typography.Text>
                    Hier können Sie die Daten des Vorgangs bearbeiten. Es können nicht alle Felder bearbeitet werden.
                    Aus integritätsgründen sind einige Felder gesperrt. Bitte beachten Sie, dass die Änderungen sofort übernommen werden.
                    Zu den gesperrten Feldern gehören: ID, Erstellungsdatum, Ersteller, Projekt-ID, Vorgangs-ID, Artikel, Preis.
                </Typography.Text>

                <Divider orientation="left">Ursprungsvorgang</Divider>
                <Descriptions bordered column={2}>
                    <Descriptions.Item label="ID">{element?.id}</Descriptions.Item>
                    <Descriptions.Item label="Artikel">{t?.title}</Descriptions.Item>
                    <Descriptions.Item label="Title">{element?.title}</Descriptions.Item>
                    <Descriptions.Item label="Menge">{t?.quantity}</Descriptions.Item>
                </Descriptions>

                <Divider orientation="left">Anpassungen</Divider>
                <Typography.Paragraph>
                    Weitere Anpassungsfelder werden in der Zukunft hinzugefügt. (Projektspezifische Felder: z.B. Bemerkungen, etc.)
                </Typography.Paragraph>
                <Form
                    name="editTask"
                    onFinish={props.onFinish}
                    form={form}
                    labelCol={{
                        span: 2,
                    }}
                    wrapperCol={{
                        span: 6,
                    }}
                    layout="horizontal"
                    onValuesChange={onChange}
                >
                    <Item label="Titel" name="title">
                        <Input />
                    </Item>
                    <Item label="Menge" name="quantity">
                        <InputNumber decimalSeparator="," />
                    </Item>
                    <Item label="Status" name="status">
                        <Select
                            placeholder="Status auswählen"
                            children={statusArray.map((status, index) => {
                                return <Select.Option key={index} value={status}>{statusHelper(status)}</Select.Option>
                            })}
                        />
                    </Item>



                </Form>

                <Divider orientation="left">Angepasster Vorgang</Divider>
                <Descriptions bordered column={2}>
                    <Descriptions.Item label="ID">{updatedObject?.id}</Descriptions.Item>
                    <Descriptions.Item label="Artikel">{updatedObject?.title}</Descriptions.Item>
                    <Descriptions.Item label="Title">{updatedObject?.title}</Descriptions.Item>
                    <Descriptions.Item label="Menge">{updatedObject?.task?.quantity}</Descriptions.Item>  
                </Descriptions>
            </Modal>
        </>
    )
};