import { Alert, Space, Typography } from "antd";
import { displayBlankModal, displayErrorModal } from "./messageServices/modalService";


function catchErrors(event) {
    console.error(event);
    // Handle the error appropriately here
    displayBlankModal("Achtung!",
        <Space direction="vertical">
            <Alert message="Hinweis" description={
                <Typography.Text>Das tut uns leid, aber es ist ein schwerwiegender Fehler für deinen Client aufgetreten.</Typography.Text>
            } type="info" showIcon />
            
            {/* Error boundary! mit antd */}
        </Space>,
        event?.stack);
}

export default catchErrors;