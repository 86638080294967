import { uploadDocument } from "./firebaseStorage";
import _ from "lodash";
import pngImage from '../../src/assets/png.png';
import pdfImage from '../../src/assets/pdf.png';
import docImage from '../../src/assets/docx.png';
import xlsImage from '../../src/assets/xls.png';
import jsonImage from '../../src/assets/json.png';
import pptImage from '../../src/assets/ppt.png';
import fileImage from '../../src/assets/file.png';
import csvImage from '../../src/assets/csv.png';
import zipImage from '../../src/assets/zip.png';
import xmlImage from '../../src/assets/xml.png';
import txtImage from '../../src/assets/txt.png';
import jpgImage from '../../src/assets/jpg.png';
import svgImage from '../../src/assets/svg.png';
import gifImage from '../../src/assets/gif.png';





export const storageOptions = [
    { option: "project-documentation", path: "/documentation/", folderType: "documentation" },  //0
    { option: "project-faq", path: "/faq/", folderType: "faq" },                                //1
    { option: "project-information", path: "/information", folderType: "information" },         //2
    { option: "project-base", path: "/", folderType: "base" },                                  //3
    { option: "project-asset", path: "/assets/", folderType: "assets" },                        //4
    { option: "project-base", path: "/", folderType: "base" },                                  //5
    { option: "project", path: "/", folderType: "base" },                                       //6
];

// const fileTypes = ["pdf", "docx", "xlsx", "jpeg", "gif", "png", "svg","pptx","jpg"]

const paths = [
    { type: 'pdf', path: pdfImage },
    { type: 'docx', path: docImage },
    { type: 'xlsx', path: xlsImage },
    { type: 'jpeg', path: jpgImage },
    { type: 'gif', path: pngImage },
    { type: 'png', path: pngImage },
    { type: 'svg', path: svgImage },
    { type: 'pptx', path: pptImage },
    { type: 'jpg', path: jpgImage },
    { type: 'file', path: fileImage },
    { type: 'json', path: jsonImage },
    { type: 'csv', path: csvImage },
    { type: 'zip', path: zipImage },
    { type: 'xml', path: xmlImage },
    { type: 'txt', path: txtImage },
    { type: 'gif', path: gifImage },
];


export function setFileImage(path) {
    const splitPath = _.split(path,'.');
    const type = splitPath[splitPath.length-1];
    const src = _.find(paths, {'type': type })
    return src
};

export function storageRouter(file, projectId, option) {
    let makePath = ""
    makePath = "projects/"
    switch (storageOptions) {
        case storageOptions[option].option === "project":
            makePath += `${projectId}${storageOptions[option].path}`;
            console.log("Save file to path: " + makePath);
            return uploadDocument(file, makePath)
        
        default:
            makePath += `${projectId}${storageOptions[6].path}`;
            console.log("Save file to path: " + makePath);
            return uploadDocument(file, makePath)
    };
};