import { Button, List, Space, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { automaticUpdate_AS0 } from "../../../redux/updateUtils/automaticUpdate";
import Search from "antd/es/input/Search";
import { UploadCostFileModal } from "../../modals/costs/UploadCostFile.modal";


function CostManagementPage() {

  const [showUploadModal, setShowUploadModal] = useState(false);
  const navigate = useNavigate();
  const projects = useSelector(state => state.projectState.projects);

  const updateSelectedProject = (project) => {
    automaticUpdate_AS0("selectedProject", project);
    navigate(`/accounting/${project._id}`, { replace: true });
  };

  const [filteredProjects, setFilteredProjects] = useState(projects);

  const handleSearch = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    if (lowerCaseValue === "") {
      setFilteredProjects(projects);
    } else {
      const filteredData = projects.filter((item) => {
        return Object.keys(item).some((key) =>
          item[key].toString().toLowerCase().includes(lowerCaseValue)
        );
      });
      setFilteredProjects(filteredData);
    }
  };


  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  //Fensternamen setzen
  useEffect(() => {
    document.title = "hpm - Kostenverwaltung";
  }, []);

  return (
    <div>
      <Typography.Title level={1} style={{ marginBottom: "30px" }}>Kostenverwaltung</Typography.Title>
      <div style={{ marginBottom: "30px", flexDirection: "row", display: "flex"}}>
        <Space>
        <Search 
          placeholder="Projekt suchen"
          onSearch={(value) => {handleSearch(value)}}
          style={{ width: 200 }}
        />
        <Button type="primary" onClick={() => setShowUploadModal(true)}>Kostentabelle hochladen</Button>
        <Button type="primary" onClick={() => console.error("Funktion ist noch nicht verfügbar.") }>Seite aktualisieren</Button>
        </Space>
      </div>

    <div>
      <Table
        dataSource={filteredProjects}
        columns={[
          {
            title: "Projekt",
            dataIndex: "title",
            key: "_id",
            render: (text, record) => (<Typography.Text>{record.title}</Typography.Text>),
          },
          {
            title: "Kostenstelle",
                      dataIndex: "costCentre",
                      key: "_id",
                      render: (text, record) => (
                          <Typography.Text>
                              {record?.costCentre || "NV! - Bitte nachtragen!"}
                          </Typography.Text>
                      ),
          },
          {
            title: "",
            dataIndex: "actions",
            key: "_id",
            render: (text, record) => (
              <Space>
                <Button type="primary" onClick={() => updateSelectedProject(record)} disabled={record.__v === 1 ? true : false}>
                Details
              </Button>
              </Space>
            ),
          },
        ]}
        pagination={{ pageSize: 5 }}
      />
    </div>
        <UploadCostFileModal Open={showUploadModal} OnCancel={() => setShowUploadModal(false)} />
    </div>
  );
};

export { CostManagementPage };