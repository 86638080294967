import { DownloadRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { downloadFile } from "../../../utils/firebaseStorage";
import _ from "lodash";
import { Tooltip } from "antd";

function FileDownloaderButton(props) {
    const dispatch = useDispatch();

    const handleDownloadFile = () => {
        dispatch(updateFetchingData(true))
        downloadFile(props.StorageRef,_.trimStart(_.trimEnd(_.replace(props.FileName,' ','_'))) )
            .then(() => {
                console.log('Datei erfolgreich heruntergeladen');
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.log(error)
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                    default:
                        break;
                }
            })
            .finally(() => {
                dispatch(updateFetchingData(false));
            });
    };

    return (
        <Tooltip title="Datei herunterladen" >
        <IconButton onClick={handleDownloadFile}>
            <DownloadRounded />
        </IconButton>
        </Tooltip>
    );
};

export default FileDownloaderButton;