import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customerId: '',
    customerData: { },
    customerUsers: [ ],
    customerDataSet: [ ],
}

const customerSlicers = createSlice({
  name: 'customerSlicers',
  initialState: initialState,
  reducers: {
    updateCustomerId: (state, action) => {
        state.customerId = action.payload;
    },
    updateCustomerData: (state, action) => {
        state.customerData = action.payload;
    },
    updateCustomerUsers: (state, action) => {
        state.customerUsers = action.payload;
    },
    updateCustomerDataSet: (state, action) => {
        state.customerDataSet = action.payload;
    }
  },
});

export const {
    updateCustomerId,
    updateCustomerData,
    updateCustomerUsers,
    updateCustomerDataSet
} = customerSlicers.actions;

export default customerSlicers.reducer;
