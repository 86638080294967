import { Col, Row, Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { StatisticsCard } from "../../global/StatisticsCard";
import OverviewPage from "./OverviewPage";
import CustomerUserPage from "./CustomerUserPage";
import CustomerDashboardPage from "./CustomerDashboardPage";

function CustomerManagementPage() {

    const project = useSelector((state) => state.projectState.selectedProject)

    const tabItems = [
        {
            key: '1',
            label: 'Übersicht',
            children: <OverviewPage />,
        },
        {
            key: '2',
            label: 'Nutzer',
            children: <CustomerUserPage />,
        },
        {
            key: '3',
            label: 'Vorschau',
            children: <CustomerDashboardPage />,
        },
    ]

    return (
        <div>
            <h1>Kundenzugänge für {project.title}</h1>
            <div>
                <Tabs defaultActiveKey="1"
                    type="card"
                    size="large"
                    items={tabItems}
                />
            </div>
        </div>
    );
};



export default CustomerManagementPage;