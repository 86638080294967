import React from 'react';
import { Route } from 'react-router-dom';
import MyProjectsPage from '../../components/pages/project/MyProjectsPage';
import ProjectPage from '../../components/pages/project/ProjectPage';
import CreateDataEntryPage from '../../components/pages/dataEntry/CreateDataEntryPage';
import ProfilePage from '../../components/pages/user/ProfilePage';
import MyFilesPage from '../../components/pages/user/MyFilesPage';
import NotFoundPage from '../../components/pages/NotFoundPage';
import DatabasePage from '../../components/pages/database/DatabaseView';
import { ChangelogPage } from '../../components/pages/ChangelogPage';
import { CSVUploadPage } from '../../components/pages/dataEntry/CSVUpload.page.component';
import PublicProjectPage from '../../components/pages/project/PublicProjectPage';

/**
 * @description This is the employee routes file. It contains all routes that are only accessible for employee.
 */
const employeeRoutes = [
    <Route path='/' element={<MyProjectsPage Arrow={false} />} />,
    <Route path='/projects' element={<MyProjectsPage Arrow={false} />} />,
    <Route path='/projects/:projectId' element={<ProjectPage />} />,
    <Route path='/projects/:projectId/database/create' element={<CreateDataEntryPage />} />,
    <Route path='/projects/:projectId/database/create/single-transaction' element={<CreateDataEntryPage />} />,
    <Route path='/projects/:projectId/database/create/csv' element={<CSVUploadPage />} />,
    <Route path='/projects/:projectId/db/:mongoId' element={<DatabasePage />} />,
    <Route path="/users/:uid/profile" element={<ProfilePage />} />,
    <Route path="/users/:uid/files" element={<MyFilesPage />} />,
    <Route path="*" element={<NotFoundPage />} />,
    <Route path='/changelog' element={<ChangelogPage />} />,
    <Route path='/v2/projects/project/:_id' element={<PublicProjectPage />} />,
];

export { employeeRoutes };