import { Button, DatePicker, Drawer, Form, Input, InputNumber, Select, Space, Switch, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { SaveOutlined } from "@ant-design/icons";

function CreateContractDrawerForm(props) {

    const onFinish = (values) => {
        props.HandleContractCreation(values);
        props.onCancel();
    };

    const resetFields = () => {
        formRef.current?.resetFields();
    };

    const onReset = () => {
        resetFields();
        props.onCancel();
    };

    const cancel = () => {
        props.onCancel();
        resetFields();
    };

    const formRef = React.useRef(null);


    useEffect(() => {
        return () => {
            resetFields();
        }
    }, []);

        return (
            <Drawer
            open={props.open}
            title="Vertragsformular | Neuer Vertrag"
            onClose={cancel}
            width={"50%"}
            destroyOnClose={true}
            >
                    <div style={{
                        marginBottom: "10px",
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}>
                    <Typography.Text type="primary">Formular zum erstellen eines Vertrages</Typography.Text>
                    </div>
                  <Form
                    name="newContract"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    layout="horizontal"
                    onFinish={onFinish}
                    ref={formRef}
                    onAbort={() => {console.log("Abgebrochen")} }
                    // onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Vertragsnummer"
                        name="contractNumber"
                        rules={[{ required: true, message: 'Bitte geben Sie die Vertragsnummer an!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Vertragsart"
                        name="contractType"
                        rules={[{ required: true, message: 'Bitte geben Sie die Vertragsnummer an!' }]}
                    >
                        <Select 
                            showSearch
                            // defaultValue={"unbefristet"}
                        >
                            <Select.Option value="unbefristet">unbefristet</Select.Option>
                            <Select.Option value="befristet">befristet</Select.Option>
                        </Select>
                            
                    </Form.Item>

                    <Form.Item
                        label="Vertragsbeginn"
                        name="contractStart"
                        rules={[{ required: true, message: 'Bitte geben Sie den Vertragsbeginn an!' }]}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label="Vertragsende"
                        name="contractEnd"
                        rules={[{ required: false, message: 'Bitte geben Sie ggf. das VErtragsende an, sofern es sich um einen befristeten Vertrag handelt.' }]}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label="Bruttogehalt"
                        name="salaryValue"
                        rules={[{ required: true, message: 'Bitte geben Sie das Bruttogehalt an.' }]}
                        tooltip="Die Vertragskosten können später noch angepasst werden. Die Bruttogehälter können aber nichtmehr angezeigt werden.
                        Das Bruttogehalt muss immer als Montasgehalt angegeben werden."
                    >
                        <InputNumber type="decimal" decimalSeparator="," />
                    </Form.Item>

                    <Form.Item
                        label="Gehalt/Lohn Typ"
                        name="salaryType"
                        rules={[{ required: true, message: 'Bitte geben Sie an ob es sich um ein Festes-Gehalt oder einen Stundenlohn handelt.' }]}
                    >
                        <Select
                            // defaultValue={"monthly"}
                        >
                            <Select.Option value="monthly">Gehalt</Select.Option>
                            <Select.Option value="hourly">Stundenlohn</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Tägliche Arbeitszeit"
                        name="dailyWorkTime"
                        rules={[{ required: true, message: 'Bitte geben Sie die tägliche Arbeitszeit an!' }]}
                    >
                        <InputNumber type="decimal" decimalSeparator="," />
                    </Form.Item>

                    <Form.Item
                        label="AÜ"
                        name="auev"
                    >
                        <Switch defaultChecked={false} />
                    </Form.Item>

                    <Form.Item
                        label="Urlaubsanspruch"
                        name="vacation"
                        rules={[{ required: true, message: 'Bitte geben Sie den Urlaubsanspruch an!' }]}
                    >
                        <InputNumber type="decimal" decimalSeparator="," />
                    </Form.Item>

                    <Form.Item
                        label="Notizen (optional)"
                        name="contractNotes"
                        rules={[{ required: false, message: 'Bitte geben Sie ggf. Notizen an.' }]}
                    >
                        <TextArea />
                    </Form.Item>

                    <Form.Item>

                        <Space>

                        <Button htmlType="submit" icon={<SaveOutlined />}>
                            Speichern
                        </Button>

                        <Button htmlType="button" onClick={resetFields}>
                            Alle Felder zurücksetzen
                        </Button>

                        <Button htmlType="button" onClick={onReset} danger>
                            Abbrechen
                        </Button>

                        </Space>
                        
                    </Form.Item>

                </Form>
            </Drawer>
        );
};

export { CreateContractDrawerForm };