import { Button, Space, Table, Tag, Typography } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect } from "react";
import { UserAddOutlined, CloudSyncOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import _ from "lodash";
import { route_fetchAllUsers } from "../../../routing/v3_Routes/v3_Routes";
import { UserSettingsModal } from "../../modals/UserSettingsModal";
import { AddUserModal } from "../../modals/AddUserModal";
import { useUpdate } from "ahooks";
import { ReAuthButtonModal } from "../../security/ReAuth.button.component";

const { Title, Text, Paragraph } = Typography;

export function UserManagementV2() {

    const autoSessionData = useSelector((state) => state.mainState.autoSession.users);
    const users = _.isUndefined(autoSessionData) ? [] : autoSessionData;
    const [searchValue, setSearchValue] = React.useState("");
    const [filteredUsers, setFilteredUsers] = React.useState(users);
    const [userSettingsModalOpen, setUserSettingsModalOpen] = React.useState(false);
    const [addUserModalOpen, setAddUserModalOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const update = useUpdate();

    const columns = [
        {
            title: 'User',
            dataIndex: 'firstName',
            key: '_id',
            render: (text, record) => (
                <div>
                    <Text strong>{record.firstName} {record.lastName}</Text>
                    <br />
                    <Text type="secondary">{record.email}</Text>
                </div>
            )
        },
        {
            title: 'TimeBoard Id',
            dataIndex: 'timeBoardId',
            key: '_id',
            render: (text, record) => (
                <div>
                    <Tag color={(_.isUndefined(record.timeBoardId) || _.isEmpty(text)) && "red"}>{(_.isUndefined(record.timeBoardId) || _.isEmpty(text)) ? "N/V" : record.timeBoardId}</Tag>
                </div>
            )
        },
        {
            title: 'Kostenverteilung',
            dataIndex: '_id',
            key: '_id',
            render: (text, record) => (
                <div>
                    <Tag color={(_.isUndefined(record?.projectSettings) || _.isEmpty(record?.projectSettings)) ? "red" : "blue"}>{(_.isUndefined(record?.projectSettings) || _.isEmpty(record?.projectSettings)) ? "Nein" : record?.projectSettings.length}</Tag>
                </div>
            )
        },
        {
            title: 'UIDs',
            dataIndex: 'userName',
            key: '_id',
            render: (text, record) => (
                <Space direction="vertical">
                    <Text copyable code type="secondary">F:/{record.userName}</Text>
                    <Text copyable code type="secondary">M:/{record._id}</Text>
                </Space>
            )
        },
        {
            title: 'Kontostatus',
            dataIndex: 'active',
            key: '_id',
            render: (text, record) => (
                <div>
                    <Text type="secondary">{record.active ? "Aktiv" : "Inaktiv"}</Text>
                </div>
            )
        },

        {
            title: "Aktionen",
            dataIndex: "actions",
            key: "_id",
            render: (text, record) => (
                <div>
                    <ReAuthButtonModal text="Bearbeiten" title="Nutzer bearbeiten" OnSuccess={async () => { handleViewUser(record) }} />
                </div>
            ),
        }
    ]

    const handleCreatedUser = () => {
        console.log("Created User");
    };

    const handleViewUser = (userRecord) => {
        console.log("View User", userRecord);
        setSelectedUser(userRecord);
        setUserSettingsModalOpen(true);
    };

    const manageRefetch = async () => {
        fetchUsers();
        setSelectedUser({});
        update();
    };

    const manageCloseWithRefetch = async () => {
        fetchUsers();
        setSelectedUser({});
        setUserSettingsModalOpen(false);
        update();
        console.log("Closed");
    };

    const fetchUsers = async () => {
        const fetchedUsers = await route_fetchAllUsers();
        setFilteredUsers(fetchedUsers.users);
        update();
    };

    const updateSearch = (value) => {
        setSearchValue(_.lowerCase(value));
    };

    const handleSearch = () => {
        setFilteredUsers(_.filter(users, (task) => {
            const { firstName, lastName, _id, email, userName, role, timeBoardId, sharePointId } = task;
            const searchValueLower = searchValue;
            return (
              _.lowerCase(firstName).includes(searchValueLower) ||
                _.lowerCase(lastName).includes(searchValueLower) ||
              _.lowerCase(email).includes(searchValueLower) ||
              _.lowerCase(_id).includes(searchValueLower) ||
              userName.toLowerCase().includes(searchValueLower) ||
                _.lowerCase(role).includes(searchValueLower) ||
                _.lowerCase(timeBoardId)?.includes(searchValueLower) ||
                _.lowerCase(sharePointId)?.includes(searchValueLower)
            );
          }));
    };

    const updateFromSession = () => {
        const users = _.isUndefined(autoSessionData) ? [] : autoSessionData;
        setFilteredUsers(users);
    };

    useEffect(() => {
        handleSearch();
    }, [searchValue]);

      //Fensternamen setzen
  useEffect(() => {
    document.title = "Assembly - Nutzerverwaltung";

    return () => {
        document.title = "Assembly (hpm)";
    }
  }, []);

    useEffect(() => {
        let count = 0;
        if (count === 0) {
            fetchUsers();
            count++;
        };

        return () => {
            // cleanup
            count = 0;
        };

    }, []);

    useEffect(() => {
        updateFromSession();
    }, [autoSessionData]);



    return (
        <div>
            <div>
                <Title level={2}>Nutzer und Stammdatenverwaltung</Title>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1.5rem",
                    marginTop: "1.5rem"
                }}>
                    {/* Schnellaktionen und Filter */}
                    <Search placeholder="Suche nach Nutzern" size="large" 
                    onSearch={updateSearch} 
                    // onChange={updateSearch}
                     />
                    <Space size="large">
                        <Button style={{ marginLeft: 20 }} size="large" type="primary" icon={<UserAddOutlined size="large" />} shape="round" onClick={() => { setAddUserModalOpen(true)}} />
                        <Button style={{}} size="large" type="primary" icon={<CloudSyncOutlined size="large" />} shape="round" onClick={fetchUsers} />
                    </Space>
                </div>
            </div>


            {/* Tabelle */}
            <div>
            <Table
                        dataSource={filteredUsers}
                        columns={columns}
                    />
                

            </div>

            <div>
                {/* Modals */}
                <UserSettingsModal open={userSettingsModalOpen} user={selectedUser} onCancel={() => { setUserSettingsModalOpen(false)}} onRefetch={manageRefetch} onClose={manageCloseWithRefetch} />
                <AddUserModal OpenDialog={addUserModalOpen} setOpen={() => { setAddUserModalOpen(false) }} HandleCreatedUser={handleCreatedUser} />
            </div>
        </div>
    )
}