import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth } from "../../../../firebase";
import axios from "axios";
import { axiosInstance } from "../../../../axios/axiosInstance";
import { v4_setAPI } from "../../../../routing/api";
import { Button, DatePicker, Space, Table, Typography } from "antd";
import { TitleImage } from "../PublicProjectPage";
import locale from '../../../../locale/datePickerLocale.json';
import _ from "lodash";
import { SingleDetailModal } from "../../../modals/accounting/SingleDetail.modal";
import { v4_getProjectById } from "../../../../routing/v4_Routes/project.routes";
import { useUpdate } from "ahooks";

const { Title, Text } = Typography;

export function ProjectDetailCostPage() {
    const update = useUpdate();
    const { costCentre, _id } = useParams();
    const API_URL = v4_setAPI();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });
    const [from, setFrom] = useState(dayjs().subtract(12, 'month').toISOString());
    const [to, setTo] = useState(dayjs().toISOString());

    const [project, setProject] = useState({});

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/accounting/costs/database/costs`, {
                params: {
                    page: tableParams.pagination.current,
                    limit: tableParams.pagination.pageSize,
                    from: from,
                    to: to,
                    cst: costCentre,
                },
            });
            setData(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.totalCount, // Annahme, dass der Server die Gesamtzahl der Einträge zurückgibt
                },
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    async function fetchProject() {
        const data = await v4_getProjectById(_id);
        setProject(data);
        document.title = `Projektkosten - ${data?.title || costCentre}`;
        update();
    };


    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams), from, to]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: sorter.order,
            sortField: sorter.field,
        });

        if (pagination.pageSize !== tableParams.pagination.pageSize) {
            setData([]);
        }
    };

    React.useEffect(() => {
        fetchProject();
        return () => {
          document.title = "Assembly";
        };
      }, []);

    return (<>
        <div>
        <div style={{ marginBottom: 20 }}>
                <TitleImage overwrite={true} children={<>
                    <div>
                        <Title level={2} style={{ color: 'white'}}>
                            Projektkosten - {project?.title || costCentre}
                        </Title>
                    </div>

                    <div>
                        <DatePicker.RangePicker
                            onChange={(dates) => {
                                if (_.isArray(dates) && dates.length === 2) {
                                    setFrom(dayjs(dates[0]).toISOString());
                                    setTo(dayjs(dates[1]).toISOString());
                                }
                            }}
                            locale={locale}
                            style={{ marginBottom: '20px' }}
                        />
                    </div>
                </>}
                />
            </div>


            <div>
            <div>
                {hasSelected && <div style={{
                    marginBottom: '10px',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}>
                    <Button type="primary" disabled onClick={() => {console.warn("Keine Funktion hinterlegt")}}>Deaktiviert</Button>
                </div>}
                <Table
                    rowKey={(record) => record._id}
                    dataSource={data}
                    rowSelection={rowSelection}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    footer={() => (
                        <div>
                            Einträge ausgewählt: {selectedRowKeys.length} von {data.length}
                        </div>
                    )}
                    size="small"
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: '_id',
                            key: '_id',
                        },
                        {
                            title: 'Datum',
                            dataIndex: 'isoInvoiceDate',
                            key: '_id',
                            render: (o) => <Text>{new Date(o).toLocaleDateString()}</Text>,
                            sorter: (a, b) => new Date(a.isoInvoiceDate) - new Date(b.isoInvoiceDate),
                            defaultSortOrder: 'descend',
                        },
                        {
                            title: 'Kostenstelle',
                            dataIndex: 'costCentre1',
                            key: '_id',
                            filters: _.uniqBy(data.map((o) => ({ text: o.costCentre1, value: o.costCentre1 })), 'value'),
                            onFilter: (value, record) => record.costCentre1 === value,
                        },
                        {
                            title: 'Rechnungsnummer/Titel',
                            dataIndex: 'invoiceNumber',
                            key: '_id',
                        },
                        {
                            title: 'Buchungstext',
                            dataIndex: 'bookingText',
                            key: '_id',
                            render: (o) => <Text>{_.truncate(o, { length: 30 })}</Text>,
                            width: 250
                        },
                        {
                            title: 'Saldo',
                            dataIndex: 'saldo',
                            key: '_id',
                            render: (o) => <Text>{_.isNaN(parseFloat(o)) ? null : <>€ {parseFloat(o).toLocaleString()}</>}</Text>,
                        },
                        {
                            title: 'Soll',
                            dataIndex: 'soll',
                            key: '_id',
                            render: (o) => <Text>{_.isNaN(parseFloat(o)) ? null : <>€ {parseFloat(o).toLocaleString()}</>}</Text>,
                        },
                        {
                            title: 'Haben',
                            dataIndex: 'haben',
                            key: '_id',
                            render: (o) => <Text>{_.isNaN(parseFloat(o)) ? null : <>€ {parseFloat(o).toLocaleString()}</>}</Text>,
                        },
                        {
                            title: '',
                            dataIndex: 'transformingDetails',
                            key: '_id',
                            render: (t) => <Space>
                                <SingleDetailModal><Text>{t}</Text></SingleDetailModal>
                            </Space>,
                        },
                    ]}
                />
            </div>
            </div>
        </div>
    </>)

};