import React from 'react';
import { Route } from 'react-router-dom';
import MyProjectsPage from '../../components/pages/project/MyProjectsPage';
import ProjectPage from '../../components/pages/project/ProjectPage';
import CreateDataEntryPage from '../../components/pages/dataEntry/CreateDataEntryPage';
import ProfilePage from '../../components/pages/user/ProfilePage';
import { UserManPage } from '../../components/pages/admin/manager/UserManPage';
import MyFilesPage from '../../components/pages/user/MyFilesPage';
import NotFoundPage from '../../components/pages/NotFoundPage';
import ProjectDetailPage from '../../components/pages/project/ProjectDetailPage';
import ProjectDbSchemaPage from '../../components/pages/project/ProjectDbSchemaPage';
import CreateProjectPage from '../../components/pages/admin/manager/CreateProjectPage';
import ManageProjectsBoard from '../../components/pages/admin/manager/ManageProjectsBoard';
import CollectionsPage from '../../components/pages/admin/admin/CollectionsPage';
import CustomerManagementPage from '../../components/pages/customerPages/CustomerManagementPage';
import AdminPage from '../../components/pages/admin/admin/AdminPage';
import V3InsightPage from '../../components/pages/insights/V3InsightPage';
import TimeBoardImportPage from '../../components/pages/admin/manager/TimeBoardImportPage';
import { CostManagementPage } from '../../components/pages/accounting/CostManagementPage';
import { CostDetailPage } from '../../components/pages/accounting/CostDetailPage';
import { TaskManagementPage } from '../../components/modals/tasks/TaskManagementPage';
import { AiHubExplanationPage } from '../../components/pages/ai/AiHubExplanationPage';
import DashboardPage from '../../components/pages/DashboardPage';
import LoginPage from '../../components/pages/LoginPage';
import DatabasePage from '../../components/pages/database/DatabaseView';
import CSVDataEntryPage from '../../components/pages/dataEntry/CsvDataEntryPage';
import { StatusPage } from '../../components/pages/assemblyStatus/StatusPage';
import { UserManagementV2 } from '../../components/pages/userManagement/UserManagementV2';
import V3ProjectDbSchemaPage from '../../components/pages/project/V3_ProjectDbSchemaPage';
import { ChangelogPage } from '../../components/pages/ChangelogPage';
import { AccountingPage } from '../../components/pages/accounting/AccountingPage';
import { CsvSchemaPage } from '../../components/pages/admin/manager/CsvSchema.page';
import { CSVUploadPage } from '../../components/pages/dataEntry/CSVUpload.page.component';
import { KPIPage } from '../../components/pages/insights/Kpis.page';
import { TotalDashboardPage } from '../../components/pages/insights/TotalDashboard.page';
import PublicProjectPage from '../../components/pages/project/PublicProjectPage';
import { PersonalCostsPage } from '../../components/pages/accounting/PersonalCosts.page';
import { ProjectCostsPage } from '../../components/pages/accounting/ProjectCosts.page';
import { ProjectsTableView } from '../../components/pages/project/ProjectsTableView';
import { ProductivityQuotesPage } from '../../components/pages/insights/ProductivityQuotesMask.page';
import { ProjectDetailCostPage } from '../../components/pages/project/projectManagement/ProjectDetailCostPage';
import { ProjectPersonalCostPage } from '../../components/pages/project/projectManagement/ProjectPersonalCostPage';
import { ProjectParentPage } from '../../components/pages/project/projectManagement/ProjectParent.page';

/**
 * @description This is the admin routes file. It contains all routes that are only accessible for admins.
 */
const adminRoutes = [
    <Route path="/" element={<DashboardPage />} />,
    <Route path="/assembly/status" element={<StatusPage />} />,
    <Route path="/login" element={<LoginPage />} />,
    <Route path="/orders" element={<NotFoundPage />} />,
    <Route path='/projects' element={<MyProjectsPage Arrow={true} />} />,
    <Route path='/projects/:projectId' element={<ProjectPage />} />,
    <Route path='/projects/:projectId/database/create' element={<CreateDataEntryPage />} />,
    <Route path='/projects/:projectId/database/create/single-transaction' element={<CreateDataEntryPage />} />,
    <Route path='/projects/:projectId/database/create/csv' element={<CSVUploadPage />} />,
    <Route path="/users/:uid/profile" element={<ProfilePage />} />,
    <Route path='/projects/:projectId/db/:mongoId' element={<DatabasePage />} />,
    <Route path='/projects/:_id/productivityquotes' element={<ProductivityQuotesPage />} />,
    <Route path="/users/manage/team" element={<UserManPage />} />,
    <Route path="/usermanagement" element={<UserManagementV2 />} />,
    <Route path="/users/:uid/files" element={<MyFilesPage />} />,
    <Route path="/manage/admin/settings/:userId" element={<NotFoundPage />} />,
    <Route path='/manage/projects/:mongoId/details' element={<ProjectDetailPage />} />,
    <Route path='/manage/projects/:projectId/schema' element={<V3ProjectDbSchemaPage />} />,
    <Route path='/manage/projects/create' element={<CreateProjectPage />} />,
    <Route path='/manage/projects' element={<ManageProjectsBoard />} />,
    <Route path='/manage/projects/project/:_id/:_ctr' element={<ProjectParentPage />} />,
    <Route path='/invoices' element={<CollectionsPage />} />,
    <Route path='/manage/projects/:projectId/customers/access' element={<CustomerManagementPage />} />,
    <Route path='/admin/manage' element={<AdminPage />} />,
    <Route path='/insights/:project' element={<V3InsightPage />} />,
    <Route path='/project/insights/:_id' element={<KPIPage />} />,
    <Route path='/company/insights/' element={<TotalDashboardPage />} />,
    <Route path='/users/timeboard' element={<TimeBoardImportPage />} />,
    <Route path="/accounting" element={<CostManagementPage />} />,
    <Route path="/accounting/:_id" element={<AccountingPage />} />,
    <Route path="/accounting/:_id/details" element={<CostDetailPage />} />,
    <Route path="/project/articles/management/v1" element={<TaskManagementPage />} />,
    <Route path="/project/articles/management/v2" element={<TaskManagementPage />} />,
    <Route path="/ai-hub" element={<AiHubExplanationPage />} />,
    <Route path="*" element={<NotFoundPage />} />,
    <Route path='/changelog' element={<ChangelogPage />} />,
    <Route path='/manage/projects/csv/schema/:_projectId' element={<CsvSchemaPage />} />,
    <Route path='/v2/projects/project/:_id' element={<PublicProjectPage />} />,
    <Route path='/tables/personalcosts' element={<PersonalCostsPage />} />,
    <Route path='/tables/projectcosts' element={<ProjectCostsPage />} />,
    <Route path='/tableview/manage/projects' element={<ProjectsTableView />} />,
    <Route path='/management/projects/project/:_id/costs/:costCentre' element={<ProjectDetailCostPage />} />,
    <Route path='/management/projects/project/:_id/costs/personal/:costCentre' element={<ProjectPersonalCostPage />} />,
];

export { adminRoutes };