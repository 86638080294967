import axios from 'axios';
import { setAPI } from './api';
import { auth } from '../firebase';
import store from '../redux/store';
import { displayErrorModal } from '../utils/messageServices/modalService';

const API_URL = setAPI();


export async function checkId(id) {
    try {
        return await axios.post(`${API_URL}/customer/access/checkid`, { data: " " }, { headers: { Authorization: `CID ${id}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

//test route
export async function getCustomerUser(id) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) throw new Error("No token found.");

        const user = auth.currentUser;

        return await axios.post(`${API_URL}/customer/access/getuser/${user.uid}`, { customerId: id }, { headers: { Authorization: `Bearer ${token}`, customerId: `CID ${id}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function proofIfIsCustomer(id) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) throw new Error("No token found.");

        const user = auth.currentUser;

        return await axios.post(`${API_URL}/customer/access/proof/${user.uid}`, { customerId: id }, { headers: { Authorization: `Bearer ${token}`, customerId: `CID ${id}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function initCustomerSharing(mongoId, projectId) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        return await axios.post(`${API_URL}/customer/access/init/share`, { data: {
            mongoId: mongoId,
            projectId: projectId
        }}, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return;
    };
};

export async function getCustomerData(id, projectId, mongoId) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        return await axios.post(`${API_URL}/customer/${id}/data`, { data: {
            id: id,
            projectId: projectId,
            mongoId: mongoId
        }}, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
};


export async function addCustomer(formData, id) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        return await axios.post(`${API_URL}/customer/${id}/access/add`, { data: formData }, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function addExistingCustomer(customerData, id, mongoId) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        return await axios.post(`${API_URL}/customer/${id}/access/add/${mongoId}`, { data: customerData }, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function getCustomers() {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        return await axios.post(`${API_URL}/customer/access/getusers`, { data: " " }, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)    
    };
};

export async function updateCustomerAccess(active, _id) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        return await axios.patch(`${API_URL}/customer/access/${_id}/update`, { active: active }, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

//Diese Funktion gibt bereits das Dataset zurück (imgegensatz zu den anderen Funktionen die ein Promise zurückgeben)
export async function getPredefinedDataset(customerId, _id, projectId) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        const dataset = await axios.post(`${API_URL}/customer/${customerId}/dataset/dashboard/${_id}/${projectId}?predefined=true`, { data: {
            _id: _id,
            projectId: projectId
        }}, { headers: { Authorization: `Bearer ${token}` } })
        .then((result) => {
            return result.data;
        }).catch((err) => {
            console.log(err);
            return null;
        });
        return dataset;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
}

export async function getCustomerDatasetTableSchema(customerId) {
    try {
        const token = await auth.currentUser.getIdToken();
        if(token === null) return;

        return await axios.post(`${API_URL}/customer/${customerId}/projects`, { data: {
            customerId: customerId
        }}, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    };
}

// export async function getKeysFromDbRoute(projectId, _id) {
//     try {
//         const token = await auth.currentUser.getIdToken();
//         if(token === null) throw new Error("No token found.");

//         const data = await axios.post(`${API_URL}/customer/keys/schema/${_id}/${projectId}`, { data: null }, { headers: { Authorization: `Bearer ${token}` } })
//         .then((result) => {
//             console.log(result.data);
//             return result.data;
//         }).catch((err) => {
//             console.log(err);
//         });
//         return data;
//     } catch (error) {
//         console.log(error);
//     };
// };

///:customerId/dataset/dashboard/:_id/:projectId