import { Button, Modal, Table, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { ReAuthenticationFormModal } from "../../security/ReAuthenticationForm.modal";


export function UploadCheckTableDataModal(props) {

    const data = props.Data
    const [loading, setLoading] = React.useState(false);
    const [reAuthModal, setReAuthModal] = React.useState(false);

    const onConfirm = async () => {
        setLoading(true);
        setReAuthModal(true);
    };

    return (
        <>
            <Modal
                title="Kosten hochladen"
                open={props.Open}
                onCancel={props.OnCancel}
                footer={[
                    <Button key="back" onClick={props.OnCancel} loading={loading}>
                        Abbrechen
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={onConfirm}>
                        Bestätigen
                    </Button>,
                ]}
                maskClosable={false}
                width={"90%"}
            >
                <Typography.Text strong>Bitte überprüfen Sie die Daten und bestätigen Sie die Daten. Dann werden diese abgespeichert.</Typography.Text>
                <Table
                    dataSource={data}
                    columns={!_.isEmpty(data) ? Object.keys(data[0]).map((key) => {
                        return {
                            title: key,
                            dataIndex: key,
                            key: key,
                        }
                    }) : []}
                    pagination={false}
                    scroll={{ y: 400, x: 3000 }}
                />
            </Modal>
            <ReAuthenticationFormModal Open={reAuthModal} OnSuccess={
                async () => {
                    await props.OnConfirm(data);
                    setReAuthModal(false);
                    setLoading(false);
                }
            } OnCancel={() => {
                setReAuthModal(false);
                setLoading(false);
            }} />
        </>
    )
}