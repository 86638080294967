import { Alert, Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Modal, Popconfirm, Select, Space, Typography } from "antd";
import React from "react";
import jsonLocale from "../../../locale/datePickerLocale.json";
import { v4_property_change } from "../../../routing/v4_Routes/article.routes";
import dayjs from "dayjs";


export function TaskPropertyChangeModal(props) {

    const { task, open } = props;
    const formRef = React.useRef();
    const [reverse, setReverse] = React.useState(false); //Rückwirkend
    const [newPropertyDefault, setNewPropertyDefault] = React.useState(true);
    const [allArticles, setAllArticles] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [inputType, setInputType] = React.useState("number");
    const [showQuanityIsTimeAlert, setShowQuantityIsTimeAlert] = React.useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        console.log(values);
        console.log(task);
        const result = await v4_property_change(task?.project, task?._id, values);
        console.log(result);
        await props.onUpdate(task?._id);
        setLoading(false);
        reset();
        props.onClose();
    };

    const reset = () => {
        formRef?.current?.resetFields();
        setReverse(false);
        setNewPropertyDefault(true);
        setAllArticles(false);
        setShowQuantityIsTimeAlert(false);
    };

    return (
        <Modal
            open={open}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            title="Änderung einer Eigenschaft"
            footer={null}
            destroyOnClose
            centered
            width={"70%"}
            style={{ top: 20 }}
            maskClosable={false}
        >
        <div>
            <div>
            <Divider type="horizontal" orientation="left">
                <Typography.Title level={5}>Artikelinformationen</Typography.Title>
            </Divider>
            {/* <Alert
                message="Achtung! - Nur für den Basispreis"
                description="Es kann nur der Basispreis geändert werden. Sofern es sich um einen Artikel mit Preisabweichungen handelt ist eine Preisänderung hierüber nicht möglich. Bei änderung des Basispreises werden alle gebuchten Preise ggf. auch geändert und entsprechen nichtmehr den Vorgaben! - Kontaktieren Sie bitte den Administrator."
                type="warning"
                style={{ marginBottom: 20, backgroundColor: '#788BFF', borderColor: '#788BFF' }}
            /> */}
            </div>
            <div style={{ marginBottom: 20 }}>
                <Typography.Title level={5}>Artikel: {task?.title}</Typography.Title>
                <Typography.Text>Artikelnummer: {task?.uniqueArticleNumber}</Typography.Text>
            </div>
            <Divider type="horizontal" orientation="left">
                <Typography.Title level={5}>Hinweise zum Vorgehen</Typography.Title>
            </Divider>
            <div style={{ marginBottom: 20 }}>
                <Typography.Text>
                Über diese Funktion können die Eigenschaften des Artikels geändert werden. Eine Preisänderung ist über diese Maske nicht möglich.
                Die Eigenschaften die heir geändert werden können beschränken sich aktuell auf: Zeitvorgabe.
                Preisabweichungen können aktuell noch nicht geändert werden. Wann dieses Feature bereitgestellt wird ist unklar.
                </Typography.Text>
            </div>
            <Divider type="horizontal" orientation="left">
                <Typography.Title level={5}>Eigenschaftsänderung</Typography.Title>
            </Divider>
            {showQuanityIsTimeAlert && (<div>
                <Alert
                message="Achtung! - Änderung der Zeitangabeneigenschaft!"
                description="Die Änderung der Eigenschaft 'Mengenangabe ist Zeitangabe' wirkt sich nicht Rückwirkend auf den Umsatz aus! - Alle Vorgänge müssen erneut manuell hochgeladen werden, ggf. kann der administrator bei dieser änderung unterstützen."
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
                />

            </div>)}
        </div>
            <Form
                layout="vertical"
                onFinish={onFinish}
                ref={formRef}                                                
                initialValues={{
                    newPriceDefault: true,
                    reverseChange: false,
                    allArticles: false,
                    defaultTimePc: task?.defaultTimePc
                }}
            >
                <Form.Item
                    label="Neuen Eigenschaftswert als Standard übernehmen?"
                    name="newPorpertyDefault"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn die neue Eigenschaft als neuer Standard übernommen werden soll"
                    initialValue={newPropertyDefault}
                >
                    <Checkbox onChange={(e) => setNewPropertyDefault(e.target.checked)}>
                        Als Standard übernehmen
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    label="Eigenschaft"
                    name="valueProperty"
                    rules={[
                        {
                            required: true,
                            message: "Die Eigenschaft die geändert werden soll ist obligatorisch."
                        }
                    ]}
                >
                    <Select
                        placeholder="Eigenschaft auswählen"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                            console.log(value);
                            switch (value) {
                                case "defaultTimePc":
                                    setInputType("number");
                                    break;
                                case "defaultTimeUnitTimePc":
                                    setInputType("text");
                                    break;
                                case "quantityIsTime":
                                    setInputType("boolean");
                                    setShowQuantityIsTimeAlert(true);
                                    break;
                                default:
                                    setInputType("number");
                                    break;
                            };
                        }}
                    >
                        <Select.Option value="defaultTimePc">Zeitvorgabe</Select.Option>
                        <Select.Option value="defaultTimeUnitTimePc">Zeiteinheit</Select.Option>
                        <Select.Option value="quantityIsTime">Mengenangabe ist Zeitangabe</Select.Option>
                    </Select>
                    
                </Form.Item>
                <Form.Item
                    label="Neuer Wert"
                    name="newValue"
                    rules={[
                        {
                            required: true,
                            message: "Bitte geben Sie den neuen Wert ein"
                        }
                    ]}
                >
                    {inputType === "number" && (
                        <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        step={0.01}
                        precision={5}
                        decimalSeparator=","
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                    />
                    )}
                    {inputType === "text" && (
                        <Input
                        style={{ width: "100%" }}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                    />
                    )}
                    {inputType === "boolean" && (
                        <Select
                        placeholder="Wert auswählen"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Select.Option value={true}>Ja</Select.Option>
                        <Select.Option value={false}>Nein</Select.Option>
                    </Select>
                    )}
                </Form.Item>
                {!newPropertyDefault && !reverse && (<Form.Item>
                    <Alert
                message="Eigenschaft nicht als Standard"
                description="So kann man keine Eigenschaft ändern... Bitte wählen Sie die Option 'Eigenschaft als Standard übernehmen' aus, oder ändern Sie die Eigenschaft rückwirkend."
                type="error"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                {!newPropertyDefault && reverse && (<Form.Item>
                    <Alert
                message="Eigenschaft nicht als Standard"
                description="Die Eigenschaft wird für den gewählten Zeitraum geändert. Die neue Eigenschaft wird aber nicht beibehalten. Dazu müssen Sie die Option 'Eigenschaft als Standard übernehmen' aktivieren."
                type="warning"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                <Form.Item
                    label="Rückwirkende änderung?"
                    name="reverseChange"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn die Eigenschaft rückwirkend geändert werden soll"
                >
                    <Checkbox onChange={(e) => {
                        setReverse(e.target.checked);
                        if (!e.target.checked) {
                            formRef.current.setFieldsValue({
                                reverse: null,
                                newPriceDefault: true
                            });
                            setNewPropertyDefault(true);
                        } else {
                            setNewPropertyDefault(false);
                            formRef.current.setFieldsValue({
                                newPriceDefault: false
                            });
                        };
                    }}>Rückwirkend</Checkbox>
                </Form.Item>
                {reverse && (<Form.Item
                    label="Alle Eigenschaften ändern?"
                    name="allArticles"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn die Eigenschaft rückwirkend geändert werden soll"
                >
                    <Checkbox onChange={(e) => {
                        setAllArticles(e.target.checked);
                        if (!e.target.checked) {
                            formRef.current.setFieldsValue({
                                reverse: null,
                                allArticles: false
                            });
                        } else {
                            formRef.current.setFieldsValue({
                                allArticles: true,
                                reverse: null,
                            });
                        };
                    }}>Alle Preise ändern</Checkbox>
                </Form.Item>)}
                {reverse && !allArticles && (<Form.Item
                    label="Zeitraum für Rückwirkende änderung"
                    name="reverse"
                    valuePropName="checked"
                >
                    <DatePicker.RangePicker
                        format={"DD.MM.YYYY"}
                        // ref={datePickerRef}
                        locale={jsonLocale}
                        maxDate={dayjs(new Date())}
                    // onChange={(value) => {
                    //     handleDateChange(value)
                    // }}
                    />
                </Form.Item>)}
                {reverse && !allArticles && (<Form.Item>
                    <Alert
                message="Achtung! - Rückwirkende änderung"
                description="Die rückwirkende änderung wird alle Buchungen mit den Entsprechenden Eigenschafte für den angegebenen Zeitraum ändern. Das betrifft auch bereits abgerechnete Aufgaben. Bitte prüfen Sie die Änderung sorgfältig."
                type="warning"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                {reverse && allArticles && (<Form.Item>
                    <Alert
                message="Achtung! - Alle Preise rückwirkend ändern"
                description="Die rückwirkende änderung wird alle Eigenschften für alle Aufgaben ändern. Das betrifft auch bereits abgerechnete Aufgaben. Bitte prüfen Sie die Änderung sorgfältig."
                type="warning"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                <Form.Item>
                    <Space direction="horizontal">
                        <Popconfirm
                            title={reverse ? "Sind Sie sicher, dass Sie die Eigenschaft rückwirkend ändern möchten?" : "Sind Sie sicher, dass Sie die Eigenschaft ändern möchten?"}
                            onConfirm={() => { }}
                            okText="Ja"
                            okType="danger"
                            cancelText="Nein"
                            okButtonProps={{ type: "primary", htmlType: "submit", onClick: () => formRef.current.submit(), loading: loading }}
                        >
                            <Button
                                type="primary"
                                danger
                                disabled={!newPropertyDefault && !reverse}
                                loading={loading}
                            >
                                Eigenschaft ändern
                            </Button>
                        </Popconfirm>
                    </Space>
                </Form.Item>
            </Form>
            
        </Modal>
    );
};