import { Button, Input, Modal, Space, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { displayErrorNotification } from "../../utils/messageServices/notificationService";
import { motion } from "framer-motion";
import _ from "lodash";
import { LoginOutlined } from "@ant-design/icons";

const maxErrorCount = 2;
const maxSecondsCount = 10;

/**
 * 
 * @param {Object} props - title, text, OnSuccess
 * @returns 
 */
export function ReAuthButtonModal(props) {

    const [errorCount, setErrorCount] = React.useState(0);
    const [secondsCount, setSecondsCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = useState(auth?.currentUser?.email || '');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false);

    const reAuthenticateUser = async () => {
        setLoading(true);
        try {
            const user = auth.currentUser;
            const credential = EmailAuthProvider.credential(email, password);

            await reauthenticateWithCredential(user, credential);
            props.OnSuccess();
            setOpen(false);
            Modal.destroyAll();
        } catch (error) {
            console.error("Re-Authentifizierung fehlgeschlagen:", error);
            // Hier kannst du zusätzliche Fehlerbehandlung hinzufügen
            displayErrorNotification("Re-Authentifizierung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.");
            setErrorCount(errorCount + 1);
            if (errorCount >= maxErrorCount) {
                setOpen(false);
                Modal.destroyAll();
                auth.signOut();
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        let timer;
        if (open) {
            timer = setTimeout(() => {
                setOpen(false);
                displayErrorNotification("Zeitüberschreitung. Der Vorgang wurde abgebrochen.");
            }, maxSecondsCount * 1000);
        }
        return () => clearTimeout(timer);
    }, [open]);

    return (
        <>
            <Button
                type="primary"
                onClick={() => setOpen(true)}

            >
                {props?.text || 'Re-Authentifizieren'}
            </Button>
            <Modal
                title={props?.title || "Erneute Authentifizierung erforderlich"}
                open={open}
                centered
                onCancel={() => setOpen(false)}
                footer={[
                    <Tooltip title="Ist eine Authentifizierung nicht möglich, wird der Vorgang abgebrochen. Und der Nutzer wird ausgeloggt.">
                        <Button key="submit" type="primary" onClick={async () => { await reAuthenticateUser() }}
                            loading={loading}
                            style={{
                                backgroundColor: 'rgba(234, 40, 45, 1)'
                            }}
                            icon={<LoginOutlined />}
                        >
                            Authentifizieren
                        </Button>
                    </Tooltip>,
                ]}
                maskClosable={false}
                mask={true}
                closable={false}
                styles={{
                    mask: {
                        backdropFilter: 'blur(10px)', // Hintergrund weichzeichnen
                        WebkitBackdropFilter: 'blur(10px)', // Unterstützung für Safari
                        backgroundColor: 'rgba(234, 40, 45, 0.8)'
                    }
                }}
            >
                <div style={{
                    marginBottom: '1rem',

                }}>
                    <Space direction="vertical">
                        <Typography.Text strong>Bitte bestätigen Sie Ihre Anmeldung erneut.</Typography.Text>
                        <Typography.Text type="secondary">User: {_.replace(auth.currentUser?.email || '', '@ikhofmann.net', '')}</Typography.Text>
                    </Space>
                </div>
                {/* {!auth.currentUser?.email && <> */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.1,
                        duration: 0.5,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                >
                    <Input
                        placeholder="E-Mail"
                        value={email}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ marginBottom: '1rem' }}
                    />
                </motion.div>
                {/* </>} */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.5,
                        duration: 0.2,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                >
                    <Input.Password
                        placeholder="Passwort"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </motion.div>
            </Modal>
        </>
    )
};