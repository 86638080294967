import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
//eslint-disable-next-line
import { firebaseConfig, logOutUser } from './firebase';
import { useSelector, useDispatch } from 'react-redux';
import { updateBetaHint, updateFirId, updateMongoUser } from './redux/userSlices';

//Import Pages
import NotFoundPage from './components/pages/NotFoundPage';
import { getUserByFirId } from './routing/userManagement';
import { updateProjects } from './redux/projectSlicer';
import { getAllProjects } from './routing/crudActions';
import { updateFetchingData } from './redux/streamSlicer';
import { invokeSession } from './config/session/session';
import NewLayout from './layout/NewLayout';
//eslint-disable-next-line
import { Alert, Button, ConfigProvider, Descriptions, Image, Modal, Space, Typography, message, notification } from 'antd';
import { darkTheme, lightTheme } from './themes/antdTheme';
import NewLoginPage from './components/pages/NewLoginPage';
import PublicLayout from './layout/PublicLayout';
import { resetStore } from './redux/store';
import { util_theme_state_dark } from './themes/modes/darkModeUtil';
//eslint-disable-next-line
import { checkRequestedRole } from './modules/userRoles/userRoles';
import { adminRoutes } from './App/routes/adminRoutes';
import { projectLeadRoutes } from './App/routes/plManagerRoutes';
import { managerRoutes } from './App/routes/managerRoutes';
import { hrRoutes } from './App/routes/hrRoutes';
import { employeeRoutes } from './App/routes/employeeRoutes';
import { DateTime } from 'luxon';
import { WarningOutlined } from '@mui/icons-material';
import { UnhandledErrorPage } from './components/pages/UnhandledErrorPage';
import { displayErrorMessage } from './utils/messageServices/messageService';
import { addGlobalUserListener, addListenerTo, unsubscribeFrom } from './listener/firebaseSnapshotListener';
import cpcLogo from './assets/captchaImg.png';
import { accountingRoutes } from './App/routes/accountingRoutes';
import { projectAssistanceRoutes } from './App/routes/projectAssistanceRoutes';
import catchErrors from './utils/catchErrors';


function App() {
  const dispatch = useDispatch();
  const firUid = useSelector((state) => state.userState.firId);
  // const mongoUser = useSelector((state) => state.userState.mongoUser); //Update lter on
  const currentUser = useSelector((state) => state.userState.firebaseUser);
  const currentFirebaseRef = useSelector((state) => state.userState.firId);
  const showBetaHint = useSelector((state) => state.userState.displayBetaHint);
  const [error, setError] = useState(null);

  const handleCloseBetaHint = () => {
    dispatch(updateBetaHint(false));
  };

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      console.log("Unhandled rejection (promise: ", event.promise, ", reason: ", event.reason, ").");
      catchErrors(event);
      displayErrorMessage("Ein unerwarteter Fehler ist aufgetreten. Bitte kontaktieren Sie den Support.");
    };

    const handleError = (event) => {
      console.log("Unhandled error: ", event.message);
      catchErrors(event);
      displayErrorMessage("Ein unerwarteter Fehler ist aufgetreten. Bitte kontaktieren Sie den Support.");
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);
    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("unhandledrejection", handleUnhandledRejection);
      window.removeEventListener("error", handleError);
    };
  }, []);

  useEffect(() => {
    // Überprüfen, ob es sich um ein mobiles Gerät handelt
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    // Weiterleitung, wenn es sich nicht um ein mobiles Gerät handelt
    if (isMobileDevice) {
      window.location.href = 'https://mobile.hpm.assembly-data.app/';
    }
  }, [])

  const setProject = async () => {
    if (isAuthenticated) {
      await getAllProjects(currentUser.mongoRef)
        .then(result => {
          dispatch(updateProjects(result.data));
        })
        .catch(error => {
          console.log("Error while retriving information from API. ", error);
        })
        .finally(() => {
          dispatch(updateFetchingData(false));
        });
    } else {
      console.log("Could not update projects.");
    }
  }

  const updateFirUid = (id) => {
    if (isAuthenticated) {
      dispatch(updateFirId(id));
      console.log("Firebase User: " + firUid);
    } else {
      console.log("Could not update firId");
    };
  };

  /**
   * 
   * @param {*} error 
   * @deprecated
   */
  const handleUnhandleError = (error) => {
    // setError(error);
    // // dispatch(resetStore());
    // window.location.href = '/500';
  };

  const updateMongoId = async (id) => {
    await getUserByFirId(id)
      .then(result => {
        if (isAuthenticated) {
          dispatch(updateMongoUser(result.data));
        } else {
          console.trace("Could not update mongoUid");
        };
      })
      .catch(error => {
        console.trace("Could not update mongoUid");
      });
    };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);


  useEffect(() => {
    dispatch(updateFetchingData(true))
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
      setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        dispatch(resetStore());
      }
      try {
        dispatch(updateFirId(user.uid));
        await updateMongoId(user.uid);
      } catch (error) {
        console.log("Error while updating firId. ", error);
      };
      // updateFirUid(user.uid);
      if (isAuthenticated) {
        // setProject(); // Hier muss noch festgelegt werden, dass nur manager alle projekte abrufen können.
          invokeSession();
      };
    });
    return unsubscribe;
    //eslint-disable-next-line
  }, [isAuthenticated, auth]);

  // useEffect(() => {
  //   let gListener = null;
  //   if (currentFirebaseRef !== null && currentFirebaseRef !== undefined && currentFirebaseRef !== "" && isAuthenticated) {
  //     gListener = addGlobalUserListener(currentFirebaseRef, (u) => {
  //       console.log("Global User Listener triggered");
  //       // getAuth().currentUser.reload();
  //     });
  //   }
  //   console.log("Global Listener: ", currentFirebaseRef);

  //   return () => {
  //     if (gListener !== null) {
  //       unsubscribeFrom(gListener);
  //       console.log("Global Listener unsubscribed");
  //     };
  //   };
  // }, [currentFirebaseRef, isAuthenticated]);

  const theme = useSelector((state) => state.userState.darkMode) || false;
  const [currentTheme, setCurrentTheme] = useState(theme);
  useEffect(() => {
    setCurrentTheme(util_theme_state_dark().darkModeOn ? darkTheme : lightTheme);
  }, [theme]);

  const [userRole, setUserRole] = useState("employee");
  const checkRoleAndSetUserRole = async () => {
    if (isAuthenticated) {
      if ((await checkRequestedRole("administrator")).result) {
        setUserRole("administrator");
      } else if ((await checkRequestedRole("projectLead")).result) {
        setUserRole("projectLead");
      } else if ((await checkRequestedRole("consultant")).result) {
        setUserRole("consultant");
      } else if ((await checkRequestedRole("management")).result) {
        setUserRole("management");
      } else if ((await checkRequestedRole("hr")).result) {
        setUserRole("hr");
      } else if ((await checkRequestedRole("viewer")).result) {
        setUserRole("viewer");
      } else if ((await checkRequestedRole("accounting")).result) {
        setUserRole("accounting");
      } else if ((await checkRequestedRole("project-assistance")).result) {
        setUserRole("project-assistance");
      } else {
        setUserRole("employee");
      }
    }
  };
  useEffect(() => {
    checkRoleAndSetUserRole();
  }, [isAuthenticated, currentUser]);


  let content = null;

  const createBase = (children) => {
    return (
      <ConfigProvider theme={currentTheme}
      getPopupContainer={document.getElementById('rootContainer')}
      >
      <BrowserRouter>
        <NewLayout>
          <Routes>
          <Route path="/500" element={<UnhandledErrorPage error={error} />} />
            {children.map((child) => {
              return (
                child
              )
            })}
          </Routes>
          {/* <Modal open={showBetaHint} onCancel={handleCloseBetaHint} */}
          <Modal open={false} onCancel={handleCloseBetaHint}
            footer={
              <Button type='primary' onClick={handleCloseBetaHint}>Ich habe den Hinweis gelesen und bestätige hiermit</Button>
            }
            closable={false}
            width={"70%"}
            title="Starthinweis"
            style={{
              //blurr

            }}
            maskClosable={false}
          >
          {
            DateTime.now().toISODate() <= "2024-07-08" && (<Alert
            message="Ankündigung zur Wartung"
            description={<Typography.Text type="primary">Zeitraum vom <strong>05. JULI - 08. JULI</strong> steht das System <strong>nicht</strong> zur verfügung.
            <br />
            <strong>Grund:</strong> Release der Version 6c9d158. Bitte alle Vorgänge am 09. JULI nachtragen.
            </Typography.Text>}
            type="error"
            showIcon
            style={{
              marginBottom: "10px",
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            />)
          }

          { DateTime.now().toISODate() < "2024-06-30" && (
            <div>
          <Typography.Title level={5}>
            Angezeigt bis: 30.06.2024
          </Typography.Title>
            <Descriptions column={1}>
              <Descriptions.Item label="Aktuelle Version">296c8551</Descriptions.Item>
              {/* Git änderungen */}
              <Descriptions.Item label="Änderungen">
                <Typography.Text>
                Verbesserung der Sicherheitsfunktionen und Fehlerbehebungen im Backend.
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="SICHERHEIT">
              <Space direction='vertical'>
              <Typography.Text>
                Einführung von reCAPTCHA ENTERPRISE für die Anmeldung, um die Sicherheit zu erhöhen. Sowie AppCheck für die allgemeine Sicherheit. Wenn ein Login nichtmehr möglich ist, bitte den Browser Cache leeren, VPN ausschalten und die Seite neu laden.
                <br /><br />
                Recaptcha ist ein Dienst von Google, der Websites vor Spam und Missbrauch schützt. Es wird verwendet, um zu überprüfen, ob ein Benutzer ein Mensch ist, und nicht ein Bot, der automatisierte Software verwendet, um auf Websites zuzugreifen.
                </Typography.Text>
              <Image src={cpcLogo} />
              </Space>
              </Descriptions.Item>
            </Descriptions>
          </div>
          )}



          </Modal>
        </NewLayout>
      </BrowserRouter>
    </ConfigProvider>
    );
  };


  switch (userRole) {
    case "administrator":
      content = (
        createBase(adminRoutes)
      );
    
      break;
    
    case "projectLead":
      content = (
        createBase(projectLeadRoutes)
      );
    
      break;

    case "consultant":
      content = (
        createBase(projectLeadRoutes)
      );
    
      break;

    case "management":
      content = (
        createBase(managerRoutes)
      );
    
      break;

    case "hr":
      content = (
        createBase(hrRoutes)
      );
    
      break;

    case "viewer":
      content = (
        createBase(employeeRoutes)
      );
    
      break;

      case "accounting":
        content = (
          createBase(accountingRoutes)
        );
      
        break;

    case "employee":
      content = (
        createBase(employeeRoutes)
      );
    
      break;

    case "project-assistance":
      content = (
        createBase(projectAssistanceRoutes)
      );

      break;

    default:
      content = (
        createBase(employeeRoutes)
      );
  }

  if (isAuthenticated) {
    return (
      <Alert.ErrorBoundary>
        {content}
      </Alert.ErrorBoundary>
    );
  } else {
    return (
      <PublicLayout>
        <ConfigProvider theme={currentTheme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<NewLoginPage />} />
              <Route path="/login" element={<NewLoginPage />} />
              <Route path="/register" element={<NewLoginPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </PublicLayout>
    );
  };
};

export default App;
