import { Alert, Button, DatePicker, Form, Input, Modal, Select, Switch, Typography } from "antd";
import React from "react";
import SimpleBackdrop from "../../global/LoadingBackdrop";
import { v4_create_document_acceptance } from "../../../routing/v4_Routes/document.routes";


export function ProofOfAcceptanceModalV2(props) {

    const [loading, setLoading] = React.useState(false);
    const [allStatus, setAllStatus] = React.useState(true);
    const [addAdditionalProps, setAddAdditionalProps] = React.useState(false);

    const setLoadingState = (state) => {
        setLoading(state);
        props.onLoading(state);
    };

    const closeModal = () => {
        props.onCancel();
    };

    const createInvoice = async (values) => {
        console.log(values);
        await v4_create_document_acceptance(props.Project._id, props.User._id, values);
        returnInvoice();
        setLoadingState(false);
        closeModal();
    };

    const returnInvoice = () => {
        props.Callback();
    };

    const onFinish = async (values) => {
        setLoadingState(true);
        await createInvoice(values);
    };

    return (
        <Modal
            title="Abnahmebeleg erstellen (v2.0.0)"
            open={props.open}
            onCancel={closeModal}
            footer={[
                <Button key="back" onClick={closeModal} loading={loading}>
                    Abbrechen
                </Button>,
            ]}
            width={"85%"}
        >
            <div>
                {/* <div>
                    <Alert message="Hinweis" description={<Typography.Paragraph>Hierüber können lediglich Abnahmebelege belege erstellt werden.</Typography.Paragraph>} type="info" showIcon />
                </div> */}


                <div>
                <Typography.Title level={4}>Abnahmebelegsformular</Typography.Title>
                <Typography.Paragraph>Eine Abnahmebelegsnummer wird selbstständig vom System vergeben. Sie wählen lediglich den Zeitraum.</Typography.Paragraph>
                    <Form
                        name="invoiceForm"
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{
                            allStatusCheckbox: allStatus
                        }}
                        >
                        <Form.Item
                            label="Zeitraum"
                            name="timeframe"
                            rules={[{ required: true, message: 'Bitte geben Sie einen Zeitraum an!' }]}
                        >
                            <DatePicker.RangePicker />
                        </Form.Item>

                        <Form.Item
                            label="Alle Status abrechnen"
                            name="allStatusCheckbox"
                        >
                            <Switch onChange={(checked) => { setAllStatus(checked) }} />
                        </Form.Item>

                        <Form.Item
                            label="Nach Status gruppieren"
                            name="groupByStatus"
                            tooltip="Gruppiert die Vorgänge nach Status und exportiert diese in das Dokument."
                        >
                            <Switch disabled />
                        </Form.Item>

                        <Form.Item
                            label="Detailvorgänge exportieren (Achtung, das Dokument wird sehr groß.)"
                            name="showDetailOperations"
                            tooltip="Exportiert alle Detailvorgänge in das Dokument. Maximaler Zeitraum ca. 3 Monate!"
                        >
                            <Switch disabled />
                        </Form.Item>

                        {!allStatus && (<Form.Item
                            label="Status abrechnen"
                            name="status"
                            hidden={allStatus}
                            rules={[{ required: !allStatus, message: 'Bitte wählen Sie die abzurechnenden Status aus!' }]}
                        >
                            <Select allowClear style={{ width: '100%' }} placeholder="Bitte einen abzurechnenden Status auswählen."
                                options={[
                                    { label: "Abgeschlossen", value: "completed" },
                                    { label: "Offen", value: "open" },
                                    { label: "In Bearbeitung", value: "in progress" },
                                    { label: "Wartend / on hold", value: "on hold" },
                                    { label: "Reklamation Kunde", value: "reclamation_customer" },
                                    { label: "Reklamation HPM", value: "reclamation_hpm" },
                                    { label: "Interne Prüfung", value: "internalCheck" },
                                    { label: "Storniert", value: "canceled" }
                                ]}
                            />
                        </Form.Item>)}

                        <Form.Item
                            label="Notizen"
                            name="invoiceNotes"
                            tooltip="Notizen zur Abrechnung, werden auf der Abrechnung vermerkt."
                        >
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item
                            label="Zusätzliche Eigenschaften hinzufügen?"
                            name="additionalProperties"
                        >
                            <Switch onChange={(checked) => { setAddAdditionalProps(checked) }} />
                        </Form.Item>

                        {addAdditionalProps && (
                            <Form.Item
                                label="Zusätzliche Eigenschaften"
                                name="additionalPropertiesArray"
                                tooltip="Zusätzliche Eigenschaften, die auf der Abrechnung vermerkt werden, diese werden aus den Vorgangsobjektinformationen extrahiert. Bitte Rücksprache vor anwahl dieser Option, zu Administratoren."
                            >
                                <Select
                                    allowClear
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder="Bitte zusätzliche Eigenschaften hinzufügen."
                                    options={[
                                        { label: "Lieferscheinnummer anzeigen (Schmiedeberg)", value: "lieferschein" },
                                        { label: "Leiferscheindatum anzeigen (Schmiedeberg)", value: "lieferscheindatum" },
                                    ]}
                                />
                            </Form.Item>
                        )}

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Abnahmebeleg erstellen
                            </Button>
                        </Form.Item>
                        </Form>
                </div>
            </div>
            <SimpleBackdrop open={loading} />
        </Modal>
    )
};