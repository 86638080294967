import { Typography, Table } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { route_getAbsences } from "../../../routing/v3_Routes/v3_Routes";
import SimpleBackdrop from "../../global/LoadingBackdrop";
import { v4_get_my_absences } from "../../../routing/v4_Routes/user.routes";

const { Text } = Typography;

function AbsencePage(props) {

    const [loading, setLoading] = useState(false);
    const [absences, setAbsences] = useState([]);


    const fetchAbsences = async () => {
        setLoading(true);
        await v4_get_my_absences(props.uid)
            .then((result) => {
                console.log(result);
                setAbsences(_.filter(result?.user || [], item => _.startsWith(item._id, "A")));
            }).catch((err) => {
                setAbsences([]);
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchAbsences();
    }, []);

    return (
        <div>
            <div>
                <div
                    style={{
                        marginTop: 16,
                    }}
                >
                    <div style={{
                        marginBottom: 20,
                    }}>
                        <Text>Das sind die Jahressumen, welche im System hinterlegt wurden. Sofern nichts angezeigt wird, gibt es keine Abwesenheiten.</Text>
                    </div>
                    <Table
                        columns={[
                            {
                                title: "Bezeichnung (Abwesenheit)",
                                dataIndex: "_id",
                                key: "_id",
                            },
                            {
                                title: "Anzahl (Jahressumme)",
                                dataIndex: "count",
                                key: "_id",
                            },
                        ]}
                        dataSource={absences}
                    />
                </div>
            </div>
            <SimpleBackdrop open={loading} />
        </div>
    )
};

export default AbsencePage;