import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message, Steps, theme, Upload, Spin, Select, Switch, Result, Avatar } from 'antd';
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import { InboxOutlined } from "@mui/icons-material";
import _, { replace } from "lodash";
import { fetchSpecifiedUsers } from "../../routing/userManagement";
import { counties } from "../../data/baseData";
import { Option } from "antd/es/mentions";
import { sendProjectCreation } from "../../routing/sendData";
import { fetchUsersV1 } from "../../modules/fetchUsers";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../routing/crudActions";
import { updateProjects } from "../../redux/projectSlicer";
import { updateFetchingData } from "../../redux/streamSlicer";
import { storage } from "../../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { invokeSessionWithProject } from "../../config/session/session";
import { setAPI } from "../../routing/api";
const { Dragger } = Upload;

const globalSize = "large";

function NewProjectForm() {

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: `Die Aktion war erfolgreich.`,
        });
    };

    const uploadPropsAntd = {
        name: 'file',
        multiple: false,
        action: `${setAPI()}/upload/file/project-creation`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            } 
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                console.log(info.file);
                
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const user = useSelector((state) => state.userState.mongoUser);
    const dispatch = useDispatch();
    const fetchManagers = async () => {
        await fetchUsersV1(false, false, false, true)
            .then((result) => {
                setManagers(result)
            }).catch((err) => {
                console.log(err)
            });
    };

    const fetchConsultants = async () => {
        await fetchUsersV1(false, false, true, false)
            .then((result) => {
                setConsultans(result);
                console.log(result)
            }).catch((err) => {
                console.log(err)
            });
    }

    const setProject = () => {

        getAllProjects()
            .then(result => {
                dispatch(updateProjects(result.data));
            })
            .catch(error => {
                console.log("Error while retriving information from API. ", error);
            })
            .finally(() => {
                dispatch(updateFetchingData(false));
            });
    }

    const [managers, setManagers] = useState([]);
    const [consultants, setConsultans] = useState([]);
    const [projectLead, setProjectLead] = useState(null);
    const [csv_schema, setCsv_schema] = useState(false);
    const [projectConsultant, setProjectConsultant] = useState(null);
    const navigate = useNavigate();
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [formData, setFormData] = useState({});
    const [cid, setCid] = useState(null);
    const [county, setCounty] = useState("");
    const [imageName, setImageName] = useState("");

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const [resultData, setResultData] = useState({});

    const updateSessionData = async (data) => {
       await invokeSessionWithProject(data.data);
    }

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState({ message: "", type: "404" });

    const handleSetFormData = (e) => {
        console.log(e.target);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.id]: e.target.value,
        }));
        console.log(formData);
        console.log("CSV  Schema: ", csv_schema)
        console.log("Project Lead ", projectLead)
        console.log("Consultant ", projectConsultant)
    };

    const handleRegistration = async () => {
        setLoading(true);

        const projectData = {
            title: formData["Stammdaten_projectname"],
            projectId: _.replace(_.kebabCase(_.replace(formData["Stammdaten_projectname"], "/!§$%&/|\\/&/()=?`{}ßª©«∑€®†¨⁄øπ•æœ@∆ºª©ƒ∂‚å≤¥≈ç√∫~µ∞…–‘±", "-")), "_", "-"),
            imageUrl: imageName,
            projectLead: projectLead.mongoRef,
            projectConsultant: projectConsultant.mongoRef,
            companyIdentifier: "hpm.assembly-data.app",
            allowedUserId: [user._id] || [],
            administrationNote: formData["projectDetails_note"],
            adress: formData["companyDetails_adress"],
            country: "Deutschland",
            county: county,
            latitude: "",
            longitude: "",
            costCentre: formData["Stammdaten_costCentre"],
            cityName: formData["companyDetails_city"],
            zipCode: formData["companyDetails_zip_code"],
            companyName: formData["companyDetails_company_name"],
            dataMode: csv_schema ? "csv" : "standard",
            csvSchema: csv_schema,
        };
        console.log("Form Data: ", formData);
        console.log("Project Data: ", projectData);

        await sendProjectCreation(projectData)
            .then(async (result) => {
                setResult({ message: "Das Projekt wurde erfolgreich erstellt.", type: "success" });
                setResultData(result.data);
                await updateSessionData(result.data);
            }).catch((err) => {
                setResult({ message: "Das Projekt konnte nicht erstellt werden, bitte versuche es erneut", type: "500" });
                console.log("Error while Project creation: ", err);
            })
            .finally(() => {
                setLoading(false);
                setCurrent(current + 1);
            });
    }

    const handleSetImage = async (file) => {
        try {
            const fileUrl = file.response.publicBlobUrl;
            const storageReference = ref(storage, fileUrl);
            const downloadRef = await getDownloadURL(storageReference)
            setImageName(downloadRef);
            console.log(downloadRef)
            success();
        } catch (error) {
            console.log("Error while getting file URL")
        }
    }

    //--------------------------------------Formular--------------------------------------//

    // Formular Schritt 1

    const steps = [
        {
            title: 'Projekt',
            content: (
                <>

                    <Container maxWidth="md">
                        <Box sx={{ mb: 5 }}>
                            <h1>Erstellen eines neuen Projekts</h1>
                            <Typography variant="caption2" sx={{ px: 5, mt: 1, mb: 5 }}>
                                Damit wir ein neues Projekt erstellen können benötigen wir einige Informationen von Dir.
                            </Typography>
                        </Box>
                        <Form
                            name="Stammdaten"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 600,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            onChange={(e) => { handleSetFormData(e) }}
                        >
                            <Form.Item
                                label="Projektname"
                                name="projectname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte trage einen Projektnamen ein!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="z.B. XYZ"
                                    size={globalSize}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Kostenstelle"
                                name="costCentre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte trage eine Kostenstelle ein!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="Eindeutige Kostenstelle - z.B. 660"
                                    size={globalSize}
                                />
                            </Form.Item>
                            <Form.Item
                                label="ProjektId"
                                name="projectid"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Bitte trage eine ProjektId ein!',
                                    },
                                ]}
                                tooltip="Die ProjektId wird automatisch generiert. Wenn du eine eigene Id verwenden möchtest, trage sie hier ein."
                            >
                                <Input
                                placeholder="Wird automatisch generiert."
                                    size={globalSize}
                                />
                            </Form.Item>
                        </Form>
                    </Container>
                </>
            ),
        },
        // Formular Schritt 2
        {
            title: 'Stammdaten',
            content: (
                <>
                    <Container maxWidth="md">
                        <Box sx={{ mb: 5 }}>
                            <h1>Stammdaten</h1>
                            <Typography variant="caption2" sx={{ px: 5, mt: 1, mb: 5 }}>
                                Jetzt benötigen wir noch Informationen über das Unternehmen.
                            </Typography>
                        </Box>

                        <Form
                            name="companyDetails"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 600,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            onChange={(e) => { handleSetFormData(e) }}
                        >
                            <Form.Item
                                label="Firmenname"
                                name="company_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie Ihren Firmennamen oder Fiktiven Unternehmensnamen an!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="Firma XYZ GmbH"
                                    size={globalSize}
                                />
                            </Form.Item>

                            {/* <Form.Item
                                label="Kostenstelle"
                                name="costCentre"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie eine Kostenstelle an!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="z.B. 660"
                                    size={globalSize}
                                />
                            </Form.Item> */}

                            <Form.Item
                                label="Rechtsform"
                                name="legal"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie eine Rechtsform!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="z.B. GmbH"
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Adresse"
                                name="adress"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie eine gültige Adresse an!',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Straße und Hausnummer"
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Stadt"
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie eine Stadt an!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="z.B. Berlin"
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="PLZ"
                                name="zip_code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie eine PLZ an!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="z.B. 12345"
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Region"
                                name="region"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie eine Region an!',
                                    },
                                ]}
                            >
                                <Select
                                    size={globalSize}
                                    value={county}
                                    onChange={(e) => { setCounty(e); console.log(e) }}
                                    style={{
                                        width: 400,
                                    }}
                                    placeholder="Bitte wählen Sie eine Region"
                                >
                                    {counties.map((county) => (
                                        <Option value={county.name} key={county.name}>{county.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="USt. Nr."
                                name="tax_number"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Bitte geben Sie ihre USt-Id an!',
                                    },
                                ]}
                                tooltip="Die USt-Id ist optional. Und kann nachträglich hinzugefügt werden."
                            >
                                <Input
                                placeholder="z.B. DE123456789"
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                                name="project_image"

                            >
                                <Dragger {...uploadPropsAntd}
                                    onChange={async (e) => { await handleSetImage(e.file) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Projektbild hochladen</p>
                                    <p className="ant-upload-hint">
                                        Lade ein Projektbild hoch, damit das Projekt einfacher zum zuordnen ist. Dieser Schritt ist erforderlich.
                                    </p>
                                </Dragger>
                            </Form.Item>

                            {/* <Form.Item
                                name="agb"
                                valuePropName="checked"
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Checkbox>?</Checkbox>
                            </Form.Item> */}
                        </Form>
                    </Container>
                </>
            ),
        },
        // Formular Schritt 3
        {
            title: 'Projektdetails',
            content: (
                <>
                    <Container maxWidth="md">
                        <Box sx={{ mb: 5 }}>
                            <h1>Projektdetails</h1>
                            <Typography variant="caption2" sx={{ px: 5, mt: 1, mb: 5 }}>
                                Jetzt benötigen wir noch Details über das Projekt.
                            </Typography>
                        </Box>

                        <Form
                            name="projectDetails"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 600,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            onChange={(e) => { handleSetFormData(e) }}
                        >
                            <Form.Item
                                label="Projektnotiz"
                                name="note"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Bitte gib eine Notiz oder einen Hinweis ein!',
                                    },
                                ]}
                            >
                                <Input
                                placeholder="z.B. Projekt XYZ ist ein Testprojekt."
                                    size={globalSize}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Projektleiter*in"
                                name="project_lead"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine*n Projektleiter*in an!',
                                    },
                                ]}
                                tooltip="Kann später geändert werden."
                            >
                                <Select
                                    showSearch
                                    placeholder="Projektleiter*in suchen ..."
                                    filterOption={(input, option) =>
                                        (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    size={globalSize}
                                    value={projectLead || undefined}
                                    onChange={(value, option) => { setProjectLead(option.data) }}
                                    style={{
                                        width: 400,
                                    }}
                                    options={consultants.map((consultant) => ({
                                        value: consultant.mongoRef,
                                        label: `${consultant.firstName} ${consultant.lastName}`,
                                        data: consultant,
                                    }))}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Project Betreuer*in"
                                name="project_consultant"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte gib eine*n Projektbetreuuer*in an!',
                                    },
                                ]}
                                tooltip="Kann später geändert werden."
                            >
                                <Select
                                    showSearch
                                    placeholder="Projektbetreuuer suchen ..."
                                    filterOption={(input, option) =>
                                        (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    size={globalSize}
                                    value={projectConsultant || undefined}
                                    onChange={(value, option) => { setProjectConsultant(option.data) }}
                                    style={{
                                        width: 400,
                                    }}
                                    options={consultants.map((consultant) => ({
                                        value: consultant.mongoRef,
                                        label: `${consultant.firstName} ${consultant.lastName}`,
                                        data: consultant,
                                    }))}
                                />
                            </Form.Item>


                            <Form.Item
                                name="csv_schema"
                                label="CSV Schema"
                                valuePropName="checked"
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                                style={{
                                    minWidth: 300,
                                }}
                                tooltip="Kann später nicht mehr geändert werden. Bitte vor dem einschalten Rücksprache halten!"
                            >
                                <Switch onChange={(e) => { setCsv_schema(e) }}>

                                </Switch>

                            </Form.Item>

                        </Form>
                        <br /><br />
                        {csv_schema ? (<>
                            <Typography variant="caption2">
                                Du möchtest ein CSV-Schema verwenden. (Wenn du nicht weisst was das bedeutet, einfach wieder ausschalten.)
                            </Typography>
                        </>) : (<>
                            <Typography variant="caption2">
                                Du kannst später noch ein CSV Schema hinzufügen. Aber nichtmehr zurück wechseln!
                                (Wenn du nicht weisst was das bedeutet, einfach ausschalten.)
                            </Typography>
                        </>)}
                    </Container>
                </>
            ),
        },
        //  // Formular Schritt 4
        //  {
        //     title: 'Team & Aufgaben',
        //     content: (
        //         <>
        //             <Container maxWidth="lg">
        //                 <Box sx={{ mb: 5 }}>
        //                     <h1>Team</h1>
        //                     <Typography variant="caption2" sx={{ px: 5, mt: 1, mb: 5 }}>
        //                         Wir müssen jetzt Aufgaben anlegen und dem Projekt Teammitglieder hinzufügen (dieser Schritt kann auch später ausgeführt werden.)
        //                     </Typography>
        //                 </Box>

        //                 <Form
        //                     name="teamDetails"
        //                     labelCol={{
        //                         span: 8,
        //                     }}
        //                     wrapperCol={{
        //                         span: 16,
        //                     }}
        //                     style={{
        //                         maxWidth: 600,
        //                     }}
        //                     initialValues={{
        //                         remember: true,
        //                     }}
        //                     // onFinish={onFinish}
        //                     // onFinishFailed={onFinishFailed}
        //                     autoComplete="off"
        //                     onChange={(e) => { handleSetFormData(e) }}
        //                 >
        //                     <Form.Item
        //                         label="Projektnotiz"
        //                         name="note"
        //                         rules={[
        //                             {
        //                                 required: false,
        //                                 message: 'Bitte gib eine Notiz oder einen Hinweis ein!',
        //                             },
        //                         ]}
        //                     >
        //                         <Input
        //                             size={globalSize}
        //                         />
        //                     </Form.Item>

        //                     <Form.Item
        //                         label="Projektleiter*in"
        //                         name="project_lead"
        //                         rules={[
        //                             {
        //                                 required: true,
        //                                 message: 'Bitte gib eine*n Projektleiter*in an!',
        //                             },
        //                         ]}
        //                     >
        //                         <Select
        //                             size={globalSize}
        //                             value={projectConsultant || undefined}
        //                             onChange={(value, option) => { setProjectLead(option.data) }}
        //                             style={{
        //                                 width: 400,
        //                             }}
        //                         >
        //                             {!_.isEmpty(managers) && managers.map((manager) => (
        //                                 <Option value={manager._id} key={manager._id} data={manager}>
        //                                     {manager.firstName} {manager.lastName}
        //                                 </Option>
        //                             ))}
        //                         </Select>
        //                     </Form.Item>

        //                     <Form.Item
        //                         label="Project Betreuer*in"
        //                         name="project_consultant"
        //                         rules={[
        //                             {
        //                                 required: true,
        //                                 message: 'Bitte gib eine*n Projektbetreuuer*in an!',
        //                             },
        //                         ]}
        //                     >
        //                         <Select
        //                             size={globalSize}
        //                             value={projectConsultant || undefined}
        //                             onChange={(value, option) => { setProjectLead(option.data) }}
        //                             style={{
        //                                 width: 400,
        //                             }}
        //                         >
        //                             {!_.isEmpty(consultants) && consultants.map((consultant) => (
        //                                 <Option value={consultant._id} key={consultant._id} data={consultant}>
        //                                     {consultant.firstName} {consultant.lastName}
        //                                 </Option>
        //                             ))}
        //                         </Select>
        //                     </Form.Item>


        //                     <Form.Item
        //                         name="csv_schema"
        //                         label="CSV Schema"
        //                         valuePropName="checked"
        //                         wrapperCol={{
        //                             offset: 8,
        //                             span: 16,
        //                         }}
        //                         style={{
        //                             minWidth: 300,
        //                         }}
        //                     >
        //                         <Switch onChange={(e) => { setCsv_schema(e) }}>

        //                         </Switch>

        //                     </Form.Item>

        //                 </Form>
        //                 <br /><br />
        //                 {csv_schema ? (<>
        //                     <Typography variant="caption2">
        //                         Du möchtest ein CSV-Schema verwenden. (Wenn du nicht weisst was das bedeutet, einfach wieder ausschalten.)
        //                     </Typography>
        //                 </>) : (<>
        //                     <Typography variant="caption2">
        //                         Du kannst später noch ein CSV Schema hinzufügen. Aber nichtmehr zurück wechseln!
        //                         (Wenn du nicht weisst was das bedeutet, einfach ausschalten.)
        //                     </Typography>
        //                 </>)}
        //             </Container>
        //         </>
        //     ),
        // },

        // Formular Schritt 5
        {
            title: 'Bestätigung',
            content: (
                <>
                    <>
                        <Typography variant="caption2">
                            Wir warten noch kurz auf die Bestätigung der Datenbank.
                        </Typography>
                        {cid == null ? (<></>) : (<Typography variant="caption2"> Dein Projekt wurde erfolgreich erstellt.</Typography>)}
                        {!loading ? (<></>) : (<LoadingOutlined style={{ fontSize: 24 }} spin />)}
                    </>

                    {result.type === "loading" &&
                        (result.type === "success" ? (
                            <>
                                <Result
                                    status={result.type}
                                    title={result.message}
                                    subTitle="Du hast den Prozess beendet."
                                // extra={[
                                //   <Button type="primary" key="console">
                                //     Go Console
                                //   </Button>,
                                //   <Button key="buy">Buy Again</Button>,
                                // ]}
                                />
                            </>
                        ) : (
                            <>
                                <Result
                                    status={result.type}
                                    title={result.message}
                                    subTitle="Du hast den Prozess beendet."
                                // extra={[
                                //   <Button type="primary" key="console">
                                //     Go Console
                                //   </Button>,
                                //   <Button key="buy">Buy Again</Button>,
                                // ]}
                                />
                            </>
                        ))
                    }
                </>
            ),
        },
        // Formular Schritt 6
        {
            title: 'Deine nächsten Schritte',
            content: (
                <>
                    <>
                        <Typography variant="h6">
                            Die nächsten Schritte:
                        </Typography>
                        <Typography variant="caption1">
                            <ul style={{ textAlign: 'left' }}>
                                <li>Du solltest als nächstes, das Projektschema verwalten und initialisieren.</li>
                                <li>Füge dem Projekt Teammitglieder hinzu und starte durch.</li>
                                <li>Lade in Deinem Projektbereich notwendige Dokumentationen hoch.</li>
                            </ul>
                        </Typography>
                    </>
                </>
            ),
        },
    ];

    //-------------------Formular Ende-------------------------

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const contentStyle = {
        //   lineHeight: '260px',
        textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorBackgroundPrimary,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        marginBottom: 16,
        padding: 24,
    };

    // -------------------Use Effect Snippet-------------------------

    useEffect(() => {
        fetchManagers();
        fetchConsultants();

        // eslint-disable-next-line
    }, []);

    // -------------------Use Effect Snippet-------------------------
    // -------------------Formular Funktionen------------------------

    return (
        <>
            
            <Steps current={current} items={items} style={{ marginTop: 15 }} />
            <div style={contentStyle}>{steps[current].content}</div>
            <div
                style={{
                    marginTop: 24,
                }}
            >
            {contextHolder}
                {current < steps.length - 2 && (
                    <Button type="primary" onClick={() => next()}>
                        Weiter
                    </Button>
                )}
                {current === 3 && (
                    <Button type="primary" onClick={(e) => { handleRegistration(e); }}>
                        Abschließen
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={(e) => { navigate('/', replace); setProject() }} loading={loading}>
                        Fertigstellen
                    </Button>
                )}
                {current > 0 && current < 3 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        Zurück
                    </Button>
                )}
            </div>
        </>
    );
};

export default NewProjectForm;