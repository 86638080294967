import React from "react";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export function HeaderWithBackButton(props) {
  const { backLink } = props;
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(backLink);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'space-between', marginBottom: -10 }}>
      <Button type="link" onClick={() => { handleBack() }} icon={<LeftOutlined />}>
        Zurück
      </Button>
    </div>
  );
};

// comp={Link} to={props.backLink} edge="start" color="inherit" aria-label="back">