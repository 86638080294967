import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Card from "antd/es/card/Card";

//Icons
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedproject, updateSelectedprojectData, updateSelectedprojectTimeData } from "../../redux/projectSlicer";
import { updateFetchingData } from "../../redux/streamSlicer";
import { getFileUrl } from "../../utils/firebaseStorage";
import { Button, Space, Statistic, Tag, Tooltip } from "antd";
import CountUp from "react-countup";
import _ from "lodash";
import { fetchMongoDataUnlimited, fetchTimeData } from "../../data/fetchData";


function DashboardCard(props) {

    const formatter = (value) => <CountUp end={value} separator="." duration={2} />
    const user = useSelector((state) => state.userState.firebaseUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [imageUrl, setImageUrl] = useState(props.ImageUrl)
    const [loading, setLoading] = useState(false);

    const userRole = user?.role || "user"
    const canViewInsights = _.includes(["administrator", "administrator", "manager","management", "projectLead"], userRole);

    const handleProject = () => {
        // dispatch(updateFetchingData(true));
        // dispatch(updateSelectedproject(props.Project));
        // navigate(`/projects/${props.ProjectId}?projectData=${JSON.stringify({ mongoId: props.MongoId })}`);
        navigate(`/v2/projects/project/${props.MongoId}`);
        // dispatch(updateFetchingData(false));
    };

    const handleInsights = async () => {
       ///insights/:project 
       dispatch(updateFetchingData(true));
       dispatch(updateSelectedproject(props.Project));
       navigate(`/insights/${props.ProjectId}?projectData=${JSON.stringify({ query: props.MongoId })}`);
       dispatch(updateFetchingData(false));
    }

    const handleInsightsWithAllData = async () => {
        setLoading(true);
        dispatch(updateFetchingData(true));
      
        try {
          const data = await fetchMongoDataUnlimited(props.MongoId);
          const timeData = await fetchTimeData(props.MongoId);
          dispatch(updateSelectedprojectData(data.data));
          dispatch(updateSelectedproject(props.Project));
          dispatch(updateSelectedprojectTimeData(timeData.data));
          navigate(`/insights/${props.ProjectId}?projectData=${JSON.stringify({ query: props.MongoId })}`);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          dispatch(updateFetchingData(false));
        }
      };

    const handleNavigate = () => {
        navigate(`/insights/${props.ProjectId}?projectData=${JSON.stringify({ query: props.MongoId })}`);
    };

    const handleNavigateToNewKpi = () => {
        navigate(`/project/insights/${props.MongoId}`);
    };

    return (
        <>

            <Box sx={{ padding: 2 }}>
                <Card
                cover={
                    <img
                                                    src={props.ImageUrl}
                                                    alt="Project_Logo_Image"
                                                    width="64px"
                                                    // style={{ resize: 'both' }}
                                                    className="ButtonIcons"
                                                    style={{ width: '100%', height: '125px', resize: 'both', objectFit: "contain", padding: 25, backgroundColor: "transparent" }}
                                                />
                }
                >
                <Tooltip title={props.Project?.released || false ? "Alles OK." : "Projekt ist noch nicht freigegeben: KPIs & Kosten wurden noch nicht validiert oder müssen nochmal geprüft werden."}>
                <Tag 
                color={props?.Project?.released || false ? "green" : "red"}
                >{props?.Project?.released || false ? "Projekt ist freigegeben" : "Projekt ist noch durch kfm. durch FK freizugeben"}</Tag>
                </Tooltip>
                    <CardContent sx={{ padding: 2 }}>
                        <Tooltip title={
                            <Typography variant="body2" sx={{ fontSize: 14 }}>
                            ID: {props.ProjectSubtitle}
                        </Typography>
                        }>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {props.ProjectTitle}
                        </Typography>
                        </Tooltip>
                        <Statistic title="Tagesumsatz" value={props.Turnover || 0} decimalSeparator="." formatter={formatter} suffix="(EUR)" loading={loading || props.Turnover <= 0 ? true : false} />
                    </CardContent>

                    <Space>
                        <Tooltip title="Projekt öffnen">
                            <Button size="medium" type="primary" sx={{ mr: 1 }}
                                onClick={handleProject}>
                                Öffnen
                            </Button>
                        </Tooltip>
                        {/* <span> */}
                        {/* <Button size="medium" type="primary" sx={{ mr: 1 }}
                        disabled={!canViewInsights}
                                onClick={handleInsights}>
                                KPIs
                            </Button>
                        </span> */}
                        { canViewInsights && (
                            <Button size="medium" type="primary" sx={{ mr: 1 }}
                                onClick={handleNavigateToNewKpi}>
                                KPIs
                            </Button>
                        )}
                    </Space>
                </Card>
            </Box>

        </>
    );
};

export default DashboardCard;