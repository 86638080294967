import { Button, DatePicker, Form, Input, Select, Space, Typography, Drawer, InputNumber, Tooltip, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { SaveOutlined, FileSyncOutlined } from "@ant-design/icons";
import _ from "lodash";
import dayjs from "dayjs";
import { useUpdate } from "ahooks";

function ModifyContractDrawerForm(props) {

    const onFinish = (values) => {
        props.HandleContractModification(values, props.Query);
        props.onCancel();
    };

    const update = useUpdate();

    const onReset = () => {
        if (hc) {
            formRef.current?.setFieldsValue({
                contractNumber: cd?.contractNumber,
                contractType: cd?.contractType,
                salaryValue: parseFloat(cd?.contractDetails?.salary?.salaryValue),
                salaryType: cd?.contractDetails?.salary?.salaryType,
                dailyWorkTime: parseFloat(cd?.contractDetails?.workTime?.dailyWorkTime ),
                vacation: parseFloat(cd?.contractDetails?.vacation),
                contractNotes: cd?.contractDetails?.contractNotes,
            });
        }
        props.onCancel();
    };

    const initData = () => {
        if (!_.isEmpty(user)) {
            setHasContract(!_.isUndefined(props.user.contract));
            formRef.current?.setFieldsValue({
                contractNumber: cd?.contractNumber,
                contractType: cd?.contractType,
                salaryValue: parseFloat(cd?.contractDetails?.salary?.salaryValue),
                salaryType: cd?.contractDetails?.salary?.salaryType,
                dailyWorkTime: parseFloat(cd?.contractDetails?.workTime?.dailyWorkTime ),
                vacation: parseFloat(cd?.contractDetails?.vacation),
                contractNotes: cd?.contractDetails?.contractNotes,
            });
        };
    };


    const [user, setUser] = useState({});
    const [hc, setHasContract] = useState(false);
    const [cd, setContractData] = useState({});

    const formRef = React.useRef(null);

    useEffect(() => {
        const initSite = () => {
            setUser(props.user);
            update();
        };
        initSite();
        // eslint-disable-next-line

        return () => {
            setUser({});
            setHasContract(false);
            setContractData({});
        };

    }, []);

    useEffect(() => {
        if (!_.isEmpty(user)) {
            setHasContract(!_.isUndefined(props.user.contract));

            if (!_.isUndefined(props.user.contract)) {
                setContractData(props.user.contract);
                update();
            }
        };
    }, [user, props.user]);


    useEffect(() => {
        if (hc) {
            formRef.current?.setFieldsValue({
                contractNumber: cd?.contractNumber,
                contractType: cd?.contractType,
                salaryValue: parseFloat(cd?.contractDetails?.salary?.salaryValue),
                salaryType: cd?.contractDetails?.salary?.salaryType,
                dailyWorkTime: parseFloat(cd?.contractDetails?.workTime?.dailyWorkTime ),
                vacation: parseFloat(cd?.contractDetails?.vacation),
                contractNotes: cd?.contractDetails?.contractNotes,
                aeuv: cd?.auev || false,
            });
        }
    }, [hc, cd]);

    const cancel = () => {
        props.onCancel();
    };

    useEffect(() => {
        return () => {
            onReset();
        }
    }, []);


    return (
        <Drawer
        title="Vertragsformular"
        open={props.open}
        onClose={cancel}
        destroyOnClose={true}
        width={"100%"}
        >
            <div style={{
                marginBottom: "10px",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}>
                <Typography.Text type="primary">{
                    props.Query === "changeExisting" ? "Aktuellen Vertrag überarbeiten" : "Vorhandenen Vertrag deaktivieren und neuen Vertrag anlegen"
                }</Typography.Text>
            </div>
            <div style={{
                marginBottom: "18px",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            
            }}>
                <Space>
                <Tooltip title="Laden Sie die Vertragsdaten des Nutzers. In das aktuelle Formular">
                    <Button type="primary" shape="round" icon={<FileSyncOutlined />} onClick={initData}>
                        Vertragsdaten laden
                    </Button>
                </Tooltip>
                </Space>
            </div>
            <Form
                name="contractDetails"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                layout="horizontal"
                ref={formRef}
                onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Vertragsnummer"
                    name="contractNumber"
                    rules={[{ required: true, message: 'Bitte geben Sie die Vertragsnummer an!' }]}
                >
                    <Input
                    />
                </Form.Item>

                <Form.Item
                    label="Vertragsart"
                    name="contractType"
                    rules={[{ required: true, message: 'Bitte geben Sie die Vertragsnummer an!' }]}
                >
                    <Select
                        showSearch
                    // defaultValue={"unbefristet"}
                    >
                        <Select.Option value="unbefristet">unbefristet</Select.Option>
                        <Select.Option value="befristet">befristet</Select.Option>
                    </Select>

                </Form.Item>

                { props.Query !== "changeExisting" &&
                <Form.Item
                    label="Vertragsbeginn"
                    name="contractStart"
                    rules={[{ required: true, message: 'Bitte geben Sie den Vertragsbeginn an!' }]}
                >
                    <DatePicker />
                </Form.Item>
                }

                <Form.Item
                    label="Vertragsende"
                    name="contractEnd"
                    rules={[{ required: false, message: 'Bitte geben Sie ggf. das VErtragsende an, sofern es sich um einen befristeten Vertrag handelt.' }]}
                    tooltip={`Das aktuelle Vertragsende ist: ${(cd?.contractEnd || null) !== null || undefined ? cd.contractEnd : "unbefristet"} - zum ändern, bitte ein Vertragsende festlegen.`}
                >
                    <DatePicker />
                </Form.Item>

                <Form.Item
                    label="Bruttogehalt"
                    name="salaryValue"
                    rules={[{ required: true, message: 'Bitte geben Sie das Bruttogehalt an.' }]}
                    tooltip="Die Vertragskosten können später noch angepasst werden. Die Bruttogehälter können aber nichtmehr angezeigt werden.
                        Das Bruttogehalt muss immer als Montasgehalt angegeben werden."
                >
                    <InputNumber type="decimal" decimalSeparator="," />
                </Form.Item>

                <Form.Item
                    label="Gehalt/Lohn Typ"
                    name="salaryType"
                    rules={[{ required: true, message: 'Bitte geben Sie an ob es sich um ein Festes-Gehalt oder einen Stundenlohn handelt.' }]}
                >
                    <Select
                    // defaultValue={"monthly"}
                    >
                        <Select.Option value="monthly">Gehalt</Select.Option>
                        <Select.Option value="hourly">Stundenlohn</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Tägliche Arbeitszeit"
                    name="dailyWorkTime"
                    rules={[{ required: true, message: 'Bitte geben Sie die tägliche Arbeitszeit an!' }]}
                >
                    <InputNumber type="decimal" decimalSeparator="," />
                </Form.Item>

                <Form.Item
                        label="AÜ"
                        name="auev"
                    >
                        <Switch defaultChecked={false} />
                    </Form.Item>

                <Form.Item
                    label="Urlaubsanspruch"
                    name="vacation"
                    rules={[{ required: true, message: 'Bitte geben Sie den Urlaubsanspruch an!' }]}
                >
                    <InputNumber type="decimal" decimalSeparator="," />
                </Form.Item>

                <Form.Item
                    label="Notizen (optional)"
                    name="contractNotes"
                    rules={[{ required: false, message: 'Bitte geben Sie ggf. Notizen an.' }]}
                >
                    <TextArea />
                </Form.Item>

                <Form.Item>

                    <Space>

                        <Button htmlType="submit" shape="round" icon={<SaveOutlined />}>
                            Speichern
                        </Button>

                        <Button htmlType="button" shape="round" onClick={onReset} danger>
                            Änderungen verwerfen
                        </Button>

                    </Space>

                </Form.Item>
            </Form>
        </Drawer>
    );

};

export { ModifyContractDrawerForm };