import { Button, Empty, Form, Modal, Select, Space, notification } from "antd";
import _, { set } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NewCustomerForm from "../../forms/NewCustomerForm";
import { addCustomer, addExistingCustomer } from "../../../routing/customerApi";
import { useDispatch } from "react-redux";
import { updateCustomerUsers } from "../../../redux/customerSlicer";
import CustomerTable from "../../tables/CustomerTable";
import { getCustomers } from "../../../routing/customerApi";
import ActiveCustomersTable from "../../tables/activeCustomersTable";

function CustomerUserPage() {

    const dispatch = useDispatch();

    const customerData = useSelector((state) => state.customerState.customerData);
    const customerId = useSelector((state) => state.customerState.customerId);
    const customerUsers = useSelector((state) => state.customerState.customerUsers);
    const project = useSelector((state) => state.projectState.selectedProject);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeCustomerList, setActiveCustomerList] = useState([]);
    const [openActiveCustomerList, setOpenActiveCustomerList] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
          message: title,
          description:
            message,
            placement: "topLeft",
         });
      };

    const finish = async (values) => {
        setLoading(true);

        try {

            const customer = await addCustomer(values, customerId)
                .then((result) => {
                    console.log(result);
                    return result.data;
                }).catch((err) => {
                    console.log(err);
                    return null;
                });

            dispatch(updateCustomerUsers(customer.data));
            console.log(customer);

        } catch (error) {

        } finally {
            setLoading(false);
            setOpen(false);
        };

        console.log(values);
    };

    const handleFetchUsers = async () => {
        setLoading(true);

        try {
            const customers = await getCustomers()
                .then((result) => {
                    console.log(result);
                    return result.data.customers;
                }).catch((err) => {
                    console.log(err);
                    return null;
                });

            var arrayWithRemovedCustomers = [...customers];
            _.forEach(customerUsers, (customer) => {
                _.remove(arrayWithRemovedCustomers, (o) => {
                    return o._id === customer._id;
                });
            });
            console.log(arrayWithRemovedCustomers);

            setActiveCustomerList(arrayWithRemovedCustomers);
            setOpenActiveCustomerList(true);
        } catch(error) {
            console.log(error);
        } finally {
            setLoading(false);
        };
    };

    const handleSubmitUsers = async () => {
        setLoading(true);

        const getSelectedCustomerIndex = _.findIndex(activeCustomerList, function(o) { return o._id === selectedCustomer; });
        const getSelectedCustomer = activeCustomerList[getSelectedCustomerIndex];

        try {

          const customer = await addExistingCustomer(getSelectedCustomer, customerId, project._id)
                .then((result) => {
                    console.log(result);
                    return result.data;
                }).catch((err) => {
                    console.log(err);
                    return null;
                });

                dispatch(updateCustomerUsers(customer.data));
                openNotificationWithIcon('success', 'Nutzer hinzugefügt', 'Der Nutzer wurde erfolgreich hinzugefügt.');
            
        } catch (error) {
            console.log(error);
            openNotificationWithIcon('error', 'Nutzer hinzufügen fehlgeschlagen', 'Der Nutzer konnte nicht hinzugefügt werden.');
        } finally {
            setLoading(false);
            setOpenActiveCustomerList(false);
        }
    };

    useEffect(() => {
        // console.log("CustomerUserPage");
    }, []);

    return (
        <>
        {contextHolder}
            <div>

                {_.isEmpty(customerUsers) && <Empty description="Es sind keine Nutzer vorhanden" />}
                {_.isEmpty(customerUsers) && <div style={{ marginTop: 15 }}>
                <Space>
                    <Button type="primary" onClick={() => { setOpen(true) }} loading={loading}>Neuen Nutzer hinzufügen</Button>
                    <Button type="primary" onClick={handleFetchUsers} loading={loading}>Bestehende Nutzer hinzufügen</Button>
                    </Space>
                </div>}
                {!_.isEmpty(customerUsers) && <div>
                    <div style={{ margin: 15 }}>
                        <Space>
                        <Button type="primary" onClick={() => { setOpen(true) }} loading={loading}>Neuen Nutzer hinzufügen</Button>
                        <Button type="primary" onClick={handleFetchUsers} loading={loading}>Bestehende Nutzer hinzufügen</Button>
                        </Space>
                    </div>
                    <CustomerTable Data={customerUsers} Loading={loading} />

                </div>}
            </div>

            {/* Modal */}

            <Modal
                open={open}
                title="Nutzer hinzufügen"
                closable={false}
                footer={[

                ]}
            >
                <Form
                    layout="vertical"
                    name="customerForm"
                    // onChange={(e) => { console.log(e.target) }}
                    onFinish={finish}

                >
                    <NewCustomerForm Data={customerData} CustomerId={customerId} SelectedProject={project} />
                    <Button type="primary" htmlType="submit" style={{ margin: 2 }} loading={loading}>
                        Hinzufügen
                    </Button>
                    <Button key="back" onClick={() => { setOpen(false);; setLoading(false) }} style={{ margin: 2 }} disabled={loading}>
                        Abbrechen
                    </Button>
                </Form>
            </Modal>

            <Modal
            open={openActiveCustomerList}
            title="Bestehenden Nutzer hinzufügen"
            closable={false}
            footer={[
                <Button key="back" onClick={() => { setOpenActiveCustomerList(false); }} style={{ margin: 2 }} disabled={loading}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmitUsers} style={{ margin: 2 }} loading={loading}>
                    Hinzufügen
                </Button>
            ]}
            style={{
                minWidth: "50%"
            }}
            >

                <Select
                
                options={activeCustomerList.map((customer) => {
                    return {
                        label: customer.name,
                        value: customer._id
                    };
                })}
                style={{ width: "100%" }}
                onChange={(value) => {
                    setSelectedCustomer(value);
                }}
                />

            </Modal>

        </>
    );
};

export default CustomerUserPage;