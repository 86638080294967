import React from "react";
import '../../styles/notFound.css'


function NotFoundPage(props) {
    return (
        <>
            <h1>404 Wir konnten die Seite nicht finden</h1>
            <p className="zoom-area"><b>Assembly</b> hat die Seite die Du suchst anscheinend nicht. Oder diese ist noch nicht fertig. </p>
            <section className="error-container">
                <span className="four"><span className="screen-reader-text">4</span></span>
                <span className="zero"><span className="screen-reader-text">0</span></span>
                <span className="four"><span className="screen-reader-text">4</span></span>
            </section>
            <div className="link-container">
                <a target="_blank" href="/" className="more-link">Zurück zur Startseite</a>
            </div>
        </>
    );
};

export default NotFoundPage;