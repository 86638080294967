import { Button, DatePicker, Form, Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { sendServiceNotice } from "../../../routing/userManagement";
import datePickerLocale from "../../../locale/datePickerLocale.json";
import _ from "lodash";
import { useSelector } from "react-redux";

function ServiceNoticeDialog(props) {

    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const user = useSelector((state) => state.userState.mongoUser);

    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
          message: title,
          description:
            message,
            placement: "topLeft",
         });
      };

    const onFinish = async (values) => {
        setLoading(true);
        console.log('Received values of form: ', values);

        const cronQuery = `&cronStart=${values.cronQuery[0].format("DD.MM.YYYY")}&cronEnd=${values.cronQuery[1].format("DD.MM.YYYY")}&cronStartTime=${values.cronStartTime}&cronEndTime=${values.cronEndTime}`;
        var downtimeData = {...values};
        _.unset(downtimeData, 'cronQuery');
        _.unset(downtimeData, 'cronStartTime');
        _.unset(downtimeData, 'cronEndTime');
        console.log(cronQuery);
        console.log(downtimeData);
        await sendServiceNotice(downtimeData, cronQuery, user._id)
        .then((result) => {
            console.log(result)
            openNotificationWithIcon('success', 'Service Offline', 'Die Service Offline Notice wurde erfolgreich erstellt.');
        }
        ).catch((err) => {
            console.log(err)
            openNotificationWithIcon('error', 'Service Offline', 'Die Service Offline Notice konnte nicht erstellt werden.');
        }
        ).finally(() => {
            props.setOpen(false);
            setLoading(false);

        });
    };

    const handleCancel = () => {
        props.setOpen(false);
    };

    return (
        <>
            {contextHolder}
            <Modal title="Service Offline" open={props.OpenDialog} closable={false} footer={null}>
                <div>
                    <div>
                        <p>Ersstellen einer Service Offline Notice</p>
                    </div>
                    <div>
                        <Form name="service-notice-form" onFinish={onFinish}>
                            <Form.Item
                                name="downtimeStart"
                                rules={[{ required: true, message: 'Bitte geben Sie den Startzeitpunkt der Wartung ein.' }]}
                            >
                                <Input
                                    prefix={<ClockCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Startzeitpunkt (29.07.2023 12:00 Uhr)"
                                    size='large'
                                />
                            </Form.Item>
                            <Form.Item
                                name="downtimeEnd"
                                rules={[{ required: true, message: 'Bitte geben Sie den Endzeitpunkt der Wartung ein.' }]}
                            >
                                <Input
                                    prefix={<ClockCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Endzeitpunkt (29.07.2023 15:00 Uhr)"
                                    size='large'
                                />
                            </Form.Item>
                            <Form.Item
                                name="downtimeReason"
                                rules={[{ required: true, message: 'Bitte geben Sie den Grund der Wartung ein.' }]}
                            >
                                <Input
                                    placeholder="Grund der Wartung"
                                    size='large'
                                />
                            </Form.Item>
                            <Form.Item
                                name="latestLogout"
                                rules={[{ required: true, message: 'Bitte geben Sie den Zeitpunkt ein, an dem alle Benutzer ausgeloggt werden.' }]}
                            >
                                <Input
                                    prefix={<ClockCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="z.B. 30 Minuten"
                                    size='large'
                                />
                            </Form.Item>
                            <Form.Item
                                name="type"
                                rules={[{ required: true, message: 'Bitte geben Sie den Typ der Wartung ein.' }]}
                                tooltip="Der Typ der Wartung. z.B. service oder test. Der typ service wird für die Service Offline Notice verwendet. Das Betrifft alle Nutzerkonten."
                            >
                                <Input
                                    placeholder="z.B. service oder test"
                                    size='large'
                                />
                            </Form.Item>
                            <Form.Item
                                name="cronQuery"
                                rules={[{ required: true, message: 'Bitte geben Sie den Zeitraum der Wartung ein.' }]}
                            >
                                <DatePicker.RangePicker
                                    format={"DD.MM.YYYY"}
                                    locale={datePickerLocale}
                                    size="large"
                                    />
                            </Form.Item>
                            <Form.Item
                                name="cronStartTime"
                                rules={[{ required: true, message: 'Bitte geben Sie den Startzeitpunkt der Wartung ein.' }]}
                            >
                                <Input
                                    prefix={<ClockCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Startzeitpunkt (12:00)"
                                    size='large'
                                />
                            </Form.Item>
                            <Form.Item
                                name="cronEndTime"
                                rules={[{ required: true, message: 'Bitte geben Sie den Endzeitpunkt der Wartung ein.' }]}
                            >
                                <Input
                                    prefix={<ClockCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Endzeitpunkt (20:00)"
                                    size='large'
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="btn btn-primary btn-block" disabled={false}
                                style={{ marginRight: '10px' }}
                                loading={loading}
                                >
                                    Bestätigen und Nutzer infromieren
                                </Button>
                                <Button type="dashed" className="btn btn-primary btn-block" onClick={handleCancel}
                                style={{ marginRight: '10px' }}
                                loading={loading}
                                >
                                    Abbrechen
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ServiceNoticeDialog;